import { getCurrentInstance } from '@vue/composition-api';

import {
	NOTIFICATIONS_NAMESPACE,
	NOTIFY,
} from '@/store/builder/notifications';

export const useNotifications = () => {
	const { dispatch } = getCurrentInstance()?.$store ?? {};

	const notify = (props) => dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, props);

	return { notify };
};
