const websiteImporterElements = {
	gridImage: ({
		imageUrl,
		alt,
		objectFit,
	}) => ({
		type: 'GridImage',
		settings: {
			alt: `${alt}`,
			image: `${imageUrl}`,
			styles: {
				'm-element-margin': '0 0 16px 0',
				align: 'center',
				justify: 'center',
				'object-fit': objectFit,
			},
		},
	}),
	gridTextBox: ({ text }) => ({
		type: 'GridTextBox',
		content: `<p class="body-large">${text}</p>`,
		settings: {
			styles: {
				'm-element-margin': '0 0 16px 0',
				text: 'left',
				align: 'flex-start',
				justify: 'flex-start',
			},
		},
	}),
};

const blogPageElements = {
	getContentTextBox: (content) => ({
		type: 'GridTextBox',
		content: `<p class="body">${content}</p>`,
		settings: {
			styles: {
				'm-element-margin': '0 0 16px 0',
				text: 'left',
				align: 'flex-start',
				justify: 'flex-start',
				position: '2/3/4/15',
			},
		},
	}),
};

export {
	websiteImporterElements,
	blogPageElements,
};
