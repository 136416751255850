var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Transition',{attrs:{"name":("modal-from-" + _vm.position)}},[_c('div',{staticClass:"modal-backdrop",class:[_vm.position, { hide: _vm.hideBackdrop }],style:(_vm.computedStyles),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.preventDefault();return _vm.onCloseModal()}}},[_c('div',{ref:_vm.modalRef,staticClass:"modal",class:{ 'modal--rounded': _vm.isRounded },style:(_vm.modalStyle),attrs:{"role":"dialog","aria-labelledby":"modalTitle","aria-describedby":"modalDescription"}},[(_vm.title || _vm.$slots.title)?_c('h2',{staticClass:"modal__title",class:{
					'modal__title--has-subtitle': _vm.subtitle,
					'z-h5': !_vm.isTitleCentered,
					'modal__title--centered z-body-small z-body-small--strong': _vm.isTitleCentered
				}},[_vm._t("title",[_vm._v(" "+_vm._s(_vm.title)+" ")])],2):_vm._e(),(_vm.subtitle || _vm.$slots.subtitle)?_c('p',{staticClass:"modal__subtitle z-body-small z-body-small--light"},[_vm._t("subtitle",[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])],2):_vm._e(),_c('div',{staticClass:"modal__content",class:{
					'modal__content--no-overflow': _vm.noOverflow,
					'modal__content--display-flex': _vm.useFlexForContent
				}},[_vm._t("default")],2),(_vm.showCloseButton)?_c('ZyroButton',{staticClass:"modal__close",attrs:{"data-qa":"modal-btn-close","icon":"x","title":_vm.$t('common.close')},on:{"click":function($event){return _vm.onCloseModal()}}}):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"modal__footer"},[_vm._t("footer")],2):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }