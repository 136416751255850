/* Injected dependencies */
import Router from 'vue-router';

/* i18n */
import { selectLocale } from '@/api/UsersApi';
import {
	_000_WEBHOST,
	HOSTINGER_REF,
} from '@/constants';
import { loadLocaleMessagesAsync } from '@/i18n/setup';
import { useRedirects } from '@/use/useRedirects';
import {
	setDocumentDirectionPerLocale,
	setDocumentLang,
} from '@/utils/i18n/document';
import { QUERY_LOCALE } from '@/utils/i18n/getLocaleFromQuery';
import { getLocaleFromLocalStorage } from '@/utils/i18n/localStorageLocale';
import {
	getLocale,
	getLocaleFallback,
} from '@/utils/i18n/supportedLocales';

/* Site-settings pages */
const General = () => import('@/components/site-settings/pages/general/General.vue');
const Domain = () => import('@/components/site-settings/pages/domain/Domain.vue');
const Integrations = () => import('@/components/site-settings/pages/integrations/Integrations.vue');
const OnlineStore = () => import('@/components/site-settings/pages/onlineStore/OnlineStore.vue');
const Subscription = () => import('@/components/site-settings/pages/subscription/Subscription.vue');
const Seo = () => import('@/components/site-settings/pages/seo/Seo.vue');
const Forms = () => import('@/components/site-settings/pages/forms/Forms.vue');
// const Subscription =()=>import('@/components/site-settings/pages/subscription/Subscription.vue');

/* Wizard pages */
const Importer = () => import('@/pages/wizard/importer/Importer.vue');
const Generator = () => import('@/pages/wizard/generator/Generator.vue');

/* Builder pages */
const Builder = () => import('@/pages/Builder.vue');
const Preview = () => import('@/pages/Preview.vue');
const StoreManager = () => import('@/pages/StoreManager.vue');
const SiteSettings = () => import('@/pages/SiteSettings.vue');
const Wizard = () => import('@/pages/wizard/Wizard.vue');
const Blog = () => import('@/pages/Blog.vue');
const InstagramAuth = () => import('@/pages/instagram/Auth.vue');

const {
	redirectToWWW,
	redirectToWWWSignUp,
} = useRedirects();

export const BUILDER_ROUTE = 'builder';
export const PREVIEW_ROUTE = 'preview';
export const BLOG_ROUTE = 'blog';
export const WIZARD_ROUTE = 'wizard';
export const GENERATOR_WIZARD_ROUTE = 'generator';
export const IMPORTER_WIZARD_ROUTE = 'importer';
export const STORE_MANAGER_ROUTE = 'store-manager';
export const SETTINGS_ROUTE = 'site-settings';
export const GENERAL_SETTINGS_ROUTE = 'general';
export const DOMAIN_SETTINGS_ROUTE = 'domain';
export const INTEGRATIONS_SETTINGS_ROUTE = 'integrations';
export const ONLINE_STORE_SETTINGS_ROUTE = 'online-store';
export const SUBSCRIPTION_SETTINGS_ROUTE = 'subscription';
export const SEO_SETTINGS_ROUTE = 'seo';
export const FORMS_SETTINGS_ROUTE = 'forms';

const routeTransition = { transitionName: 'fade' };
const isDevelopmentMode = process.env.NODE_ENV === 'development';

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			alias: '*',
			name: BUILDER_ROUTE,
			component: Builder,
			meta: routeTransition,
		},
		{
			path: `/${PREVIEW_ROUTE}`,
			name: PREVIEW_ROUTE,
			component: Preview,
			meta: routeTransition,
		},
		{
			path: `/${BLOG_ROUTE}/:postId`,
			name: BLOG_ROUTE,
			component: Blog,
			meta: routeTransition,
		},
		{
			path: `/${WIZARD_ROUTE}`,
			name: WIZARD_ROUTE,
			component: Wizard,
			meta: {
				skipBuilderInit: true,
				...routeTransition,
			},
			async beforeEnter(to, from, next) {
				const {
					dispatch,
					state,
				} = router.app.$store;

				if (isDevelopmentMode) {
					next();

					return;
				}

				if (!state.user.user) {
					try {
						await dispatch('user/getUser');
					} catch {
						redirectToWWW();

						return;
					}
				}

				const {
					ref,
					paid,
				} = state.user.user;

				if (![
					_000_WEBHOST,
					HOSTINGER_REF,
				].includes(ref) && !paid && !to.query.trial) {
					redirectToWWW();

					return;
				}

				next();
			},
			children: [
				{
					path: IMPORTER_WIZARD_ROUTE,
					name: IMPORTER_WIZARD_ROUTE,
					component: Importer,
					meta: routeTransition,
				},
				{
					path: GENERATOR_WIZARD_ROUTE,
					name: GENERATOR_WIZARD_ROUTE,
					component: Generator,
					meta: routeTransition,
				},
			],
		},
		{
			// TODO: add route guard for users without store, not logged in users and allow designers :(
			path: `/${STORE_MANAGER_ROUTE}`,
			name: STORE_MANAGER_ROUTE,
			component: StoreManager,
			meta: routeTransition,
			async beforeEnter(to, from, next) {
				const { user } = router.app.$store.state.user ?? {};

				if (isDevelopmentMode || user) {
					next();
				} else {
					redirectToWWWSignUp();
				}
			},
		},
		{
			path: `/${SETTINGS_ROUTE}`,
			component: SiteSettings,
			meta: routeTransition,
			async beforeEnter(to, from, next) {
				const {
					user,
					isUserDesigner,
				} = router.app.$store.state.user ?? {};

				if (isUserDesigner && to.name !== ONLINE_STORE_SETTINGS_ROUTE) {
					next({ name: ONLINE_STORE_SETTINGS_ROUTE });

					return;
				}

				if (isDevelopmentMode || user) {
					next();
				} else {
					redirectToWWWSignUp();
				}
			},
			children: [
				{
					path: '',
					redirect: { name: GENERAL_SETTINGS_ROUTE },
					meta: routeTransition,
				},
				{
					path: GENERAL_SETTINGS_ROUTE,
					name: GENERAL_SETTINGS_ROUTE,
					component: General,
					meta: routeTransition,
				},
				{
					path: DOMAIN_SETTINGS_ROUTE,
					name: DOMAIN_SETTINGS_ROUTE,
					component: Domain,
					meta: routeTransition,
				},
				{
					path: INTEGRATIONS_SETTINGS_ROUTE,
					name: INTEGRATIONS_SETTINGS_ROUTE,
					component: Integrations,
					meta: routeTransition,
				},
				{
					path: ONLINE_STORE_SETTINGS_ROUTE,
					name: ONLINE_STORE_SETTINGS_ROUTE,
					component: OnlineStore,
					meta: routeTransition,
				},
				{
					path: SUBSCRIPTION_SETTINGS_ROUTE,
					name: SUBSCRIPTION_SETTINGS_ROUTE,
					component: Subscription,
					meta: routeTransition,
				},
				{
					path: SEO_SETTINGS_ROUTE,
					name: SEO_SETTINGS_ROUTE,
					component: Seo,
					meta: routeTransition,
				},
				{
					path: FORMS_SETTINGS_ROUTE,
					name: FORMS_SETTINGS_ROUTE,
					component: Forms,
				},
			],
		},
		{
			path: '/instagram/auth',
			component: InstagramAuth,
		},
		// TEST ROUTES:

		/**
		 * Opens plain editor without any API calls.
		 * Used for editor loading speed testing purposes
		 */
		{
			path: '/test/editor-plain',
			component: Builder,
			meta: {
				skipBuilderInit: true,
				...routeTransition,
			},
			async beforeEnter(to, from, next) {
				const {
					commit,
					dispatch,
				} = router.app.$store;
				const localTemplate = (await import('@/assets/templates/local.json')).default;

				commit('setWebsite', { website: localTemplate });
				dispatch('pages/setCurrentPage', { type: 'default' });
				next();
			},
		},
	],
	scrollBehavior: () => ({
		x: 0,
		y: 0,
	}),
});

router.beforeEach(async (to, from, next) => {
	const {
		state,
		getters,
		dispatch,
	} = router.app.$store;

	const isBuilderInitNeeded = !state.hasBuilderInitialized
		&& !to.matched.some((record) => record.meta.skipBuilderInit);

	if (isBuilderInitNeeded) {
		await dispatch('initBuilder');
	}

	const lang = to.query[QUERY_LOCALE] || getLocaleFromLocalStorage();
	const isExperimentSet = getters['experiment/isExperimentSet'];

	if (!isExperimentSet) dispatch('experiment/trackExperiment', { to });
	// if lang query exists, but is not an available locale, check for fallback
	if (lang && !getLocale(lang)) {
		const fallback = getLocaleFallback(lang);

		if (fallback) {
			// if has a fallback, redirect to it's version of the page
			next({
				...to,
				query: {
					...to.query,
					lang: fallback.code,
				},
			});

			return;
		}
	}

	if (lang) {
		const loadedLocale = await loadLocaleMessagesAsync(lang);

		// ? not awaiting, because this can be done in the background
		selectLocale(lang);

		setDocumentLang(loadedLocale);
		setDocumentDirectionPerLocale(loadedLocale);
		// TODO: localized document title - setDocumentTitle($t(...))
	}

	next();
});

export default router;
