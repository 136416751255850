<template>
	<Slideshow
		ref="slideshowRef"
		class="block-slideshow"
		:class="{
			'block-slideshow--full-height-site': !isPreviewMode
				&& slideshowSettings.isMobileFullScreenHeightEnabled && isMobileScreen,
			'block-slideshow--full-height-builder':
				slideshowSettings.isMobileFullScreenHeightEnabled && isMobileView
		}"
		:style="computedStyles"
		:slides-count="slidesCount"
		:current-slide-index="currentSlideIndex"
		:is-navigation-arrows-visible="slideshowSettings.isNavigationArrowsVisible"
		:is-navigation-bullets-visible="slideshowSettings.isNavigationBulletsVisible"
		:is-autoplay-enabled="slideshowSettings.isAutoplayEnabled"
		:is-loop-enabled="slideshowSettings.isLoopEnabled"
		:is-next-slide-navigation-visible="isNextSlideNavigationVisible"
		:is-previous-slide-navigation-visible="isPreviousSlideNavigationVisible"
		:autoplay-slides-interval-seconds="slideshowSettings.autoplaySlidesIntervalSeconds"
		:is-mobile-view="isMobileView"
		@previous-slide:click="goToPreviousSlide"
		@next-slide:click="goToNextSlide"
		@indicator:click="goToSlide"
	>
		<Slide
			v-for="(slide, index) in slides"
			:key="slide.blockId"
			:is-active="currentSlideIndex === index"
			:sliding-direction="currentSlidingDirection"
			@transition-start="setIsSlideTransitioning(true)"
			@transition-end="setIsSlideTransitioning(false)"
		>
			<BlockBackground
				:background="slide.data.background"
				:is-fixed="slide.data.settings.styles.attachment === 'fixed'"
				:is-next-to-transparent-header="isNextToTransparentHeader"
				is-lazy
			/>
			<BlockGridUser
				:block-id="slide.blockId"
				:data="slide.data"
				:is-preview-mode="isPreviewMode"
				:is-mobile-view="isMobileView"
				:components="components"
			/>
		</Slide>
	</Slideshow>
</template>
<script>
import {
	onBeforeUnmount,
	onMounted,
	ref,
} from '@vue/composition-api';
import { mapState } from 'vuex';

import { moveDirection } from '@/utils/moveDirection';
import BlockGridUser from '@user/components/block-grid/BlockGridUser.vue';
import Slide from '@user/components/block-slideshow/misc/Slide.vue';
import Slideshow from '@user/components/block-slideshow/misc/Slideshow.vue';
import {
	DIRECTION_LEFT,
	useBlockSlideshow,
} from '@user/components/block-slideshow/use/useBlockSlideshow';
import BlockBackground from '@user/components/block/BlockBackground.vue';
import { IPHONE_PREVIEW_HEIGHT } from '@user/constants';

export default {
	components: {
		Slideshow,
		BlockBackground,
		Slide,
		BlockGridUser,
	},
	props: {
		blocks: {
			type: Object,
			required: true,
		},
		components: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
		isNextToTransparentHeader: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {
			slideshowSettings,
			currentSlideIndex,
			currentSlidingDirection,
			slides,
			slidesCount,
			setSlidingDirection,
			setIsSlideTransitioning,
			goToSlide,
			goToNextSlide,
			goToPreviousSlide,
			isNextSlideNavigationVisible,
			isPreviousSlideNavigationVisible,
		} = useBlockSlideshow(props);
		const slideshowRef = ref(null);
		const autoPlayInterval = ref(null);

		const autoplaySlides = () => setInterval(() => {
			const isLoopDisabledAndReachedLastSlide = !slideshowSettings.value.isLoopEnabled
				&& (currentSlideIndex.value === slidesCount.value - 1);

			if (isLoopDisabledAndReachedLastSlide) {
				clearInterval(autoPlayInterval.value);

				return;
			}

			goToNextSlide();
		}, slideshowSettings.value.autoplaySlidesIntervalSeconds * 1000);

		const { enableMoveEvents } = moveDirection({
			move: {
				drag: false,
				swipe: true,
			},
			onMoveLeft: goToNextSlide,
			onMoveRight: goToPreviousSlide,
		});

		onMounted(() => {
			if (slideshowSettings.value.isAutoplayEnabled) {
				autoPlayInterval.value = autoplaySlides();
			}

			setSlidingDirection(DIRECTION_LEFT);
			setIsSlideTransitioning(false);
			enableMoveEvents(true, slideshowRef.value.$el);
		});

		onBeforeUnmount(() => {
			clearInterval(autoPlayInterval.value);
			enableMoveEvents(false, slideshowRef.value.$el);
		});

		return {
			slideshowRef,
			slideshowSettings,
			currentSlideIndex,
			currentSlidingDirection,
			slides,
			slidesCount,
			isNextSlideNavigationVisible,
			isPreviousSlideNavigationVisible,
			setIsSlideTransitioning,
			goToSlide,
			goToNextSlide,
			goToPreviousSlide,
		};
	},
	computed: {
		...mapState('gui', ['isMobileScreen']),
		...mapState('navigation', ['navigationHeight']),
		computedStyles() {
			const offsetStyle = this.isNextToTransparentHeader ? {
				paddingTop: `${this.navigationHeight}px`,
				marginTop: `${-this.navigationHeight}px`,
			} : {};

			return {
				'--m-blockMobileMinHeight': (this.isPreviewMode && this.isNextToTransparentHeader)
					? `${IPHONE_PREVIEW_HEIGHT - this.navigationHeight}px`
					: `${IPHONE_PREVIEW_HEIGHT}px`,
				'--m-blockUserMobileMinHeight': this.isNextToTransparentHeader
					? `calc(100vh - ${this.navigationHeight}px)`
					: '100vh',
				...offsetStyle,
			};
		},
	},
};
</script>

<style lang="scss">
@import './BlockSlideshow';
</style>
