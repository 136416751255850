<template>
	<div
		class="block-blog-list-item"
		:style="computedStyles"
	>
		<div
			v-if="showCoverImage"
			class="block-blog-list-item__cover-image-container"
		>
			<div class="block-blog-list-item__cover-image-wrapper">
				<img
					class="block-blog-list-item__cover-image"
					data-qa="blog-list-item-image"
					:src="post.customData.coverImage.src"
					:alt="post.customData.coverImage.alt"
					@click="$emit('post-click')"
				>
			</div>
		</div>
		<BlockBlogListItemCategories
			v-show="shownItems.categories && post.customData.categories.length"
			class="font-secondary"
			:categories="post.customData.categories"
			@filter-category="(category) => $emit('filter-category', category)"
		/>
		<div
			class="block-blog-list-item__content"
			@click="$emit('post-click')"
		>
			<p
				v-show="shownItems.title"
				class="block-blog-list-item__text font-primary"
			>
				{{ post.meta.title }}
			</p>
			<p
				v-show="shownItems.description"
				class="block-blog-list-item__text font-secondary"
			>
				{{ post.meta.description }}
			</p>
			<BlockBlogListItemMeta
				v-bind="{
					authorName: authorFullName,
					minutesAmount: post.customData.minutesToRead,
					date: post.customData.date,
					showAvatar: shownItems.avatar,
					showName: shownItems.authorFullName,
					showDate: shownItems.date,
					showMinutes: shownItems.minutesToRead
				}"
			/>
		</div>
	</div>
</template>

<script>
import BlockBlogListItemCategories from '@/components/block-blog/block-blog-list-item/BlockBlogListItemCategories.vue';
import BlockBlogListItemMeta from '@/components/block-blog/block-blog-list-item/BlockBlogListItemMeta.vue';

export default {
	components: {
		BlockBlogListItemMeta,
		BlockBlogListItemCategories,
	},
	props: {
		post: {
			type: Object,
			default: () => ({}),
		},
		authorFullName: {
			type: String,
			default: '',
		},
		shownItems: {
			type: Object,
			default: () => ({
				authorFullName: true,
				coverImage: true,
				title: true,
				description: true,
				date: true,
				categories: true,
				avatar: true,
				minutesToRead: true,
			}),
		},
		coverObjectFit: {
			type: String,
			default: 'cover',
		},
		cursor: {
			type: String,
			default: 'pointer',
		},
	},
	computed: {
		showCoverImage() {
			return !!this.shownItems.coverImage && this.post.customData.coverImage.src;
		},
		computedStyles() {
			return { '--cursor-style': this.cursor };
		},
	},
};
</script>

<style lang="scss" scoped>
@import './BlockBlogListItem';
</style>
