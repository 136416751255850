<template>
	<div
		class="drag-and-drop-overlay"
		:class="{ 'drag-and-drop-overlay--dark-mode': isDraggedOver }"
	>
		<ZyroSvg
			class="drag-and-drop-overlay__icon"
			name="cloud"
		/>
		<p class="z-body z-body--strong">
			{{ isGallery ?
				$t('builder.assetManager.gallery.addImagesWhenEmpty')
				: $t('builder.assetManager.tabUser.dragDrop')
			}}
		</p>
		<p class="z-body">
			{{ $t('builder.assetManager.tabUser.supportedFormats') }}
		</p>
		<template v-if="!isDraggedOver">
			<p class="drag-and-drop-overlay__or z-body">
				{{ $t('builder.assetManager.tabUser.or') }}
			</p>
			<ZyroButton
				v-if="isGallery"
				theme="primary"
				color="black"
				size="xs"
				icon-left="cloud-small"
				class="drag-and-drop-overlay__button"
				data-qa="chooseimage-btn-browsefiles"
				@click="$emit('open-asset-manager')"
			>
				{{ $t('builder.assetManager.gallery.addImages') }}
			</ZyroButton>
			<ZyroButton
				v-else
				theme="outline"
				size="xs"
				icon-left="cloud-small"
				class="drag-and-drop-overlay__button"
				data-qa="chooseimage-btn-browsefiles"
				@click="$emit('open-file-dialog')"
			>
				{{ $t('builder.assetManager.tabUser.browseFiles') }}
			</ZyroButton>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		isGallery: {
			type: Boolean,
			default: false,
		},
		isDraggedOver: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.drag-and-drop-overlay {
	--background: #{$light};
	--color: #{$dark};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--color);
	pointer-events: none;
	background: var(--background);

	&__icon {
		$size: 110px;

		width: $size;
		height: $size;
		margin-bottom: 24px;
		stroke-width: 0.5;
	}

	&__or {
		margin: 16px 0;
	}

	&__button {
		pointer-events: all;
	}

	&--dark-mode {
		--background: rgba(0, 0, 0, 0.8);
		--color: #{$light};
	}
}
</style>
