<template>
	<div
		class="user-image__image-container"
	>
		<img
			:src="image.base64Thumbnail || image.url"
			class="user-image__image"
			data-qa="chooseimage-uploadedimage"
			@dragstart.prevent
			@click.stop="$emit('click-image', {
				imageKey: id,
				e: $event
			})"
		>
		<slot />
		<div
			v-if="!image.url
				&& (image.transferProgress !== 100 || !image.animationFinished)"
			class="user-image__loader"
		>
			<ZyroSvg
				class="user-image__loader-cancel"
				name="x"
				@click="$emit('cancel-upload', id)"
			/>
			<!-- eslint-disable vue/no-mutating-props -->
			<UploadLoader
				class="user-image__loader-svg"
				:progress="image.transferProgress"
				@animation-finished="image.animationFinished = true"
			/>
			<!-- eslint-enable vue/no-mutating-props -->
		</div>
	</div>
</template>

<script>
import UploadLoader from '@/components/builder-modals/modals/asset-manager/user/UploadLoader.vue';

export default {
	components: { UploadLoader },
	props: {
		image: {
			type: Object,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.user-image {
	&__image-container {
		position: relative;
		flex: 1 1 auto;
		height: 150px;
		margin: 0;
		overflow: hidden;
		cursor: var(--cursor, pointer);
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: var(--object-fit, cover);
	}

	&__loader,
	&__loader-cancel {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&__loader {
		display: flex;
		background: rgba(0, 0, 0, 0.5);
	}

	&__loader-svg {
		margin: auto;
	}

	&__loader-cancel {
		$size: 24px;

		width: $size;
		height: $size;
		margin: auto;
		color: $light;
		opacity: 0;
		transition: opacity 0.1s $easing-standard;

		&:hover {
			opacity: 1;
		}
	}
}
</style>
