import { computed } from '@vue/composition-api';

import { useGridImage } from '@user/components/grid-components/image/useGridImage';

export const useGridGallery = (props) => {
	const gridImages = computed(() => props.data.images.map((image) => {
		const {
			src,
			srcset,
			title,
		} = useGridImage({ data: { settings: { image } } });

		return {
			src: src.value,
			srcset: srcset.value,
			title: title.value,
		};
	}));

	const columnCount = computed(() => Number.parseInt(props.data.settings.styles['column-count'], 10));
	const columnGap = computed(() => Number.parseInt(props.data.settings.styles['column-gap'], 10));
	const isMasonryLayout = computed(() => props.data.settings.layout === 'masonry');

	return {
		gridImages,
		columnCount,
		columnGap,
		isMasonryLayout,
	};
};
