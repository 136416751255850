<template>
	<div
		class="form"
		:class="{ 'form--single-field' : settings.type === 'singleField' }"
		:style="styles"
	>
		<FormulateForm
			v-model="model"
			class="form__control"
			:name="formId"
			:class="{ 'form__control--invisible': showSuccessMessage || isError }"
			@submit="$emit('on-submit', model)"
		>
			<template
				v-for="(item) in settings.schema"
			>
				<div
					v-if="item.children"
					:key="`${item.name}-${item.id}`"
					class="form__control-row"
				>
					<div
						v-for="(rowItem) in item.children"
						:key="`${rowItem.name}-${item.id}`"
					>
						<FormulateInput
							:data="rowItem"
							:name="rowItem.name"
							:validation="rowItem.validation"
							:theme="theme"
							:is-interactive="isUserSite"
							:validation-messages="rowItem['validation-messages']"
							:type="rowItem.type"
						/>
					</div>
				</div>
				<FormulateInput
					v-else
					:key="item.id"
					:data="item"
					:name="item.name"
					:validation="item.validation"
					:is-single-field="settings.schema.length === 1"
					:theme="theme"
					:is-interactive="isUserSite"
					:validation-messages="item['validation-messages']"
					:type="item.type"
				/>
			</template>
			<GridButton
				:style="{ 'justifySelf': submitButtonData.settings.styles.align }"
				:content="submitButtonData.content"
				:type="submitButtonData.settings.type"
				class="form__button"
				button-type="submit"
				tag-name="button"
			/>
		</FormulateForm>
		<slot />
	</div>
</template>

<script>
import GridButton from '@user/components/grid-components/button/GridButton.vue';

export default {
	components: { GridButton },
	props: {
		id: {
			type: String,
			required: true,
		},
		settings: {
			type: Object,
			required: true,
		},
		submitButtonData: {
			type: Object,
			required: true,
		},
		formId: {
			type: String,
			default: null,
		},
		styles: {
			type: Object,
			default: null,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isError: {
			type: Boolean,
			default: false,
		},
		showSuccessMessage: {
			type: Boolean,
			default: false,
		},
		isUserSite: {
			type: Boolean,
			default: false,
		},
		theme: {
			type: String,
			validator: (theme) => [
				'dark',
				'light',
			].includes(theme),
			default: 'light',
		},
	},
	data() {
		return { model: {} };
	},
};
</script>

<style lang="scss" scoped>
@include font-style('body', 'form');

.form {
	position: relative;
	display: grid;
	flex: var(--formFlex);
	grid-gap: var(--formSpacing);
	padding: var(--gridItemInnerPadding);
	background:
		$background-overlay-grid-item,
		var(--gridItemInnerBackground);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	::v-deep {
		.formulate-input-errors {
			display: none;
		}
	}

	&__button {
		::v-deep {
			align-self: center;
			justify-self: var(--formButtonJustifySelf);
		}
	}

	&--single-field {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr) min-content);
		grid-auto-flow: row;
		grid-gap: var(--formSpacing);
	}

	&__control {
		align-self: center;

		::v-deep {
			display: grid;
			grid-gap: var(--formSpacing);
		}
	}

	&__control-row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
		grid-gap: var(--formSpacing);
	}

	&__button,
	&__control {
		&--invisible {
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.15s;
		}
	}
}

@include zyro-media($media-grid) {
	.form {
		padding: var(--m-gridItemInnerPadding, var(--gridItemInnerPadding));

		&__button {
			::v-deep {
				align-self: center;
				justify-self: var(--m-formButtonJustifySelf, var(--formButtonJustifySelf));
			}
		}
	}
}
</style>
