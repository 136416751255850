<template>
	<div class="toast">
		<div class="toast__content">
			<div
				class="toast__message"
				v-text="message"
			/>
			<ZyroButton
				v-if="submitLabel"
				theme="primary"
				class="toast__submit"
				@click="$emit('submit')"
				v-text="submitLabel"
			/>
		</div>
		<ZyroButton
			class="toast__close"
			icon="x"
			theme="primary"
			:title="$t('common.close')"
			@click="$emit('close')"
		/>
	</div>
</template>

<script>
export default {
	props: {
		submitLabel: {
			type: String,
			default: null,
		},
		message: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.toast {
	display: flex;
	align-items: flex-start;
	max-width: 400px;
	padding: 8px;
	margin-top: 16px;
	font-size: 14px;
	color: $light;
	background-color: $dark;
	border-radius: $border-radius-small;

	&__content {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		padding-right: 16px;

		&::after {
			position: absolute;
			top: 8px;
			right: 0;
			bottom: 8px;
			width: 1px;
			content: '';
			background-color: $light;
			opacity: 0.5;
		}
	}

	&__message {
		padding: 8px 16px;
	}

	&__submit {
		align-self: flex-end;
		margin-left: 16px;
	}

	&__close {
		margin-left: 8px;
	}
}
</style>
