import {
	computed,
	getCurrentInstance,
} from '@vue/composition-api';

const BUTTON_TAG = 'button';
const ANCHOR_TAG = 'a';
const TARGET_BLANK = '_blank';
const SUBMIT_TYPE = 'submit';

export const useGridButton = (props) => {
	const {
		website,
		gui,
	} = getCurrentInstance()?.$store?.state ?? {};

	const isFormButton = computed(() => !!props.data.settings.isFormButton);

	const internalLink = computed(() => (
		(website?.pages[props.data.settings.href] && website?.pages[props.data.settings.href].path) || ''
	));

	const tagName = computed(() => {
		if (isFormButton.value) {
			return BUTTON_TAG;
		}

		return ANCHOR_TAG;
	});

	const href = computed(() => {
		if (tagName.value === BUTTON_TAG) {
			return null;
		}

		return internalLink.value || props.data.settings.href;
	});

	const isExternalLink = computed(() => (
		// If it not internal link, has href prop and does not contain site host in href
		!internalLink.value && !!href.value && !new RegExp(`/${window.location.host}/`).test(href.value)
	));

	const target = computed(() => {
		if (gui.isTemplatePreviewMode && isExternalLink.value) {
			return TARGET_BLANK;
		}

		if (!internalLink.value && tagName.value !== BUTTON_TAG) {
			return props.data.settings.target || TARGET_BLANK;
		}

		return null;
	});

	return {
		tagName,
		href,
		target,
		internalLink,
		isExternalLink,
		type: computed(() => props.data.settings.type),
		buttonType: computed(() => (isFormButton.value ? SUBMIT_TYPE : null)),
		content: computed(() => props.data.content),
		name: computed(() => (isFormButton.value ? SUBMIT_TYPE : null)),
	};
};
