import { getCurrentInstance } from '@vue/composition-api';

import {
	WWW_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	REDIRECT_PARAM_VALUES,
	REDIRECT_TYPES,
	DISCOUNT_CODE,
} from '@/constants';
import { getLocaleFromLocalStorage } from '@/utils/i18n/localStorageLocale';

const {
	BUILDER_REDIRECT,
	HOME,
	PAYMENTS,
	PRICING,
	SIGN_UP,
	SIGN_IN,
	SITES,
	TEMPLATES,
	WIZARD,
} = WWW_REDIRECT_PATHS;
const {
	PATH,
	LANG,
	CODE,
	AUTOUPGRADE,
	RETURN,
} = REDIRECT_PARAM_KEYS;
const {
	TRUE,
	RETURN_BUILDER,
} = REDIRECT_PARAM_VALUES;
const { ASSIGN } = REDIRECT_TYPES;

export const useRedirects = () => {
	/*
	 * Destructure store methods from the instance:
	 */
	const {
		state,
		commit,
	} = getCurrentInstance()?.$store ?? {};

	/*
	 * Accepts a WWW page path, to which redirect should be made, and custom params object.
	 * Function stringifies params, constructs the link to WWW with current locale param,
	 * so that after redirect, user would be presented the same language he had set in builder.
	 */
	const getRedirectToWWWUrl = ({
		path,
		params: parameters = {},
	}) => {
		const currentLocale = getLocaleFromLocalStorage();
		const stringifiedParameters = new URLSearchParams(parameters).toString();
		const parametersString = stringifiedParameters && `&${stringifiedParameters}`;

		return `${process.env.VUE_APP_HOMEPAGE_URL}/${BUILDER_REDIRECT}/?${LANG}=${currentLocale}&${PATH}=${path}${parametersString}`;
	};

	/*
	 * Executes redirect to WWW with provided redirect type.
	 */
	const redirectToWWW = ({
		path = HOME,
		params: parameters = {},
		redirectType = ASSIGN,
	} = {}) => {
		window.location[redirectType](getRedirectToWWWUrl({
			path,
			params: parameters,
		}));
	};

	const redirectToWWWPayments = ({
		discount,
		...rest
	} = {}) => {
		const path = state?.user ? PAYMENTS : PRICING;
		const parameters = {
			[AUTOUPGRADE]: TRUE,
			[RETURN]: RETURN_BUILDER,
			...rest,
		};

		if (discount) {
			parameters[CODE] = DISCOUNT_CODE;
		}

		redirectToWWW({
			path,
			params: parameters,
		});
	};

	const redirectToWWWSignUp = ({ returnTo } = RETURN_BUILDER) => {
		commit('user/setBeingRedirectedToSignup');
		redirectToWWW({
			path: SIGN_UP,
			params: { [RETURN]: returnTo },
		});
	};

	const redirectToWWWSignIn = () => {
		redirectToWWW({
			path: SIGN_IN,
			params: { [RETURN]: RETURN_BUILDER },
		});
	};

	const redirectToWWWWizard = () => {
		redirectToWWW({ path: WIZARD });
	};

	const logoUrl = getRedirectToWWWUrl({ path: state?.user ? SITES : TEMPLATES });

	return {
		getRedirectToWWWUrl,
		redirectToWWW,
		redirectToWWWPayments,
		redirectToWWWSignUp,
		redirectToWWWSignIn,
		redirectToWWWWizard,
		logoUrl,
	};
};
