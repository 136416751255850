<template>
	<div class="blog-list-item-meta">
		<!-- Remove avatar until we can change avatar image -->
		<!-- <div
			v-show="showAvatar"
			class="blog-list-item-meta__avatar"
		>
			{{ authorName[0].toUpperCase() }}
		</div> -->
		<div class="font-secondary">
			<p
				v-show="showName"
				class="blog-list-item-meta__author-name"
			>
				{{ authorName }}
			</p>
			<p
				v-show="showDate || showMinutes"
				class="blog-list-item-meta__subtitle"
			>
				<span v-if="showDate">
					{{ $options.sliceTimeFromISODate(date) }}
				</span>
				<span v-if="showMinutes">
					{{ `${minutesAmount} ${minReadText}` }}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
import { sliceTimeFromISODate } from '@/utils/date';

export default {
	sliceTimeFromISODate,
	props: {
		authorName: {
			type: String,
			default: '',
		},
		minutesAmount: {
			type: String,
			default: '0',
		},
		minReadText: {
			type: String,
			default: 'Min read',
		},
		date: {
			type: String,
			default: '',
		},
		showAvatar: {
			type: Boolean,
			default: true,
		},
		showName: {
			type: Boolean,
			default: true,
		},
		showDate: {
			type: Boolean,
			default: true,
		},
		showMinutes: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/block-blog/fonts';

.blog-list-item-meta {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__author-name {
		margin-bottom: 4px;
	}

	&__avatar {
		z-index: 1;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		margin-right: 16px;
		font-weight: 300;
		color: $dark;
		text-transform: uppercase;
		background-color: $grey-100;
		border-radius: 50%;
	}

	&__subtitle {
		span:nth-of-type(2)::before {
			content: "·";
		}
	}
}
</style>
