import { computed } from '@vue/composition-api';

import {
	unsplashImageApiRegExp,
	zyroImageApiRegExp,
	zyroImageApiDevelopmentRegExp,
	lastDotInStringRegExp,
} from '@/utils/globalRegExp';

const RESOLUTIONS = [
	400,
	500,
	800,
	1370,
];

export const useGridImage = (props) => {
	/**
	 * 'srcset' is only needed for user-app components.
	 * However, image optimization microservice kicks in when the image is accessed the first time.
	 * So, start accessing it already inside builder, where it's initially rendered.
	 */

	// Regexp for {width}, took from https://github.com/aFarkas/lazysizes/blob/gh-pages/plugins/rias/ls.rias.js#L27
	const generateSrcset = (url) => RESOLUTIONS.map((width) => `${url.replace(/\s*{\s*width\s*}\s*/i, width)} ${width}w`).join(', ');

	const getSrcset = (image) => {
		/*
		 * Returns a string for <img> data-srcset attribute
		 *  Which is handled by lazySizes RIaS extension (responsive image on demand)
		 *  "{width}" part is changed to actual widths
		 *  according config in @/plugins/lazysizesConfig.js
		 *  https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/rias
		 */

		// Handle unsplash api images
		if (unsplashImageApiRegExp.test(image)) {
			const imageUrl = new URL(image);
			const imageUrlParameters = imageUrl.searchParams;

			/*
			 * Handle old unsplash url links.
			 *  One of params that old url always have - &w=1080
			 *  Delete unneeded and add needed params
			 *  Remove this from client after handling it with mapper
			 */
			if (imageUrlParameters.get('w') === '1080') {
				imageUrlParameters.delete('fm');
				imageUrlParameters.delete('crop');
				imageUrlParameters.delete('fit');
				imageUrlParameters.delete('cs');
				imageUrlParameters.set('q', '70');
				imageUrlParameters.set('auto', 'format');
			}

			imageUrlParameters.set('w', '{width}');

			// Decode brackets in '...{width}...'
			return generateSrcset(decodeURI(imageUrl.toString()));
		}

		/*
		 * Handle our api optimize capable images
		 *  Optimize capable are all our API images, except those that have /_SITE-ASSETS/ in their url
		 *  Url splitting:
		 *  "assets.zyrosite.com/id/name.format" => ["assets.zyro.com/id/name", "format"]
		 *  Responsive image (400px width) request format - assets.zyrosite.com/id/name-w400.format
		 */
		if ((zyroImageApiRegExp.test(image) || zyroImageApiDevelopmentRegExp.test(image)) && !/\/_SITE-ASSETS\//.test(image)) {
			const [urlPrefix, format] = image.split(lastDotInStringRegExp);

			return generateSrcset(`${urlPrefix}-w{width}.${format}`);
		}

		return image;
	};

	return {
		title: computed(() => props.data.settings.alt),
		src: computed(() => props.data.settings.image),
		srcset: computed(() => getSrcset(props.data.settings.image)),
	};
};
