<template>
	<GridGallery
		:grid-images="gridImages"
		:is-lightbox-enabled="isLightboxEnabled"
		:column-count="columnCount"
		:column-gap="columnGap"
		:is-masonry-layout="isMasonryLayout"
		@image-click="handleGalleryImageClick"
	/>
</template>

<script>
import { computed } from '@vue/composition-api';

import { useLightbox } from '@/components/reusable-components/lightbox/useLightbox';
import GridGallery from '@user/components/grid-components/gallery/GridGallery.vue';
import { useGridGallery } from '@user/components/grid-components/gallery/useGridGallery';

const IMAGE_CLICK_ACTION_LIGHTBOX = 'lightbox';

export default {
	name: 'GridGalleryProviderUser',
	components: { GridGallery },
	props: {
		data: {
			type: Object,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			required: true,
		},
	},
	setup(props) {
		const {
			gridImages,
			columnCount,
			columnGap,
			isMasonryLayout,
		} = useGridGallery(props);
		const { addImagesToLightbox } = useLightbox();

		const isLightboxEnabled = computed(
			() => props.data.settings.imageClickAction === IMAGE_CLICK_ACTION_LIGHTBOX,
		);

		const handleGalleryImageClick = (index) => {
			if (isLightboxEnabled.value) {
				addImagesToLightbox(gridImages.value, index);
			}
		};

		return {
			gridImages,
			isLightboxEnabled,
			handleGalleryImageClick,
			columnCount,
			columnGap,
			isMasonryLayout,
		};
	},
};
</script>
