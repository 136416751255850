import Vue from 'vue';

import { EventBus } from '@/eventBus';
import {
	createObjectDiff,
	revertChangesByDiff,
} from '@/utils/jsondiffpatch';
import { cloneDeep } from '@/utils/object';

export default class {
	constructor(params) {
		this.type = 'REMOVE';
		this.params = params;
		this.diff = null;
	}

	execute() {
		const {
			state,
			blockId,
			slideshowBlockId,
		} = this.params;

		const websiteCloneBeforeAdd = cloneDeep(state.website);
		const slideToDeleteIndex = state.website.blocks[slideshowBlockId].slides.findIndex((slide) => slide.blockId === blockId);

		// Remove unused components
		state.website.blocks[blockId].components.forEach((componentId) => {
			Vue.delete(state.website.components, componentId);
		});

		// Delete slide in block slideshow
		Vue.delete(state.website.blocks[slideshowBlockId].slides, slideToDeleteIndex);
		// Remove block from blocks
		Vue.delete(state.website.blocks, blockId);

		this.diff = createObjectDiff(websiteCloneBeforeAdd, state.website);
	}

	undo() {
		EventBus.$emit('undo-before');
		const { state } = this.params;

		state.website = revertChangesByDiff(state.website, this.diff);

		EventBus.$emit('undo-after');
	}
}
