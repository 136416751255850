var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
		handler: _vm.onClose,
		middleware: function () { return _vm.isOpen; },
		events: ['mousedown'],
	}),expression:"{\n\t\thandler: onClose,\n\t\tmiddleware: () => isOpen,\n\t\tevents: ['mousedown'],\n\t}"}],staticClass:"color-picker",style:(_vm.computedStyles)},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPopup),expression:"isPopup"}],ref:"toggleButton",staticClass:"color-picker__toggle-button",attrs:{"title":_vm.title},on:{"click":_vm.toggleColorPicker}},[_c('div',{staticClass:"color-picker__toggle-button__color",class:{ 'active': _vm.isOpen },style:({ background: _vm.color })})]),_c('ColorPickerSettings',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen || !_vm.isPopup),expression:"isOpen || !isPopup"}],class:{
			'color-picker__picker-popup': _vm.isPopup,
			'color-picker__picker-static': !_vm.isPopup,
		},style:([_vm.isPopup ? _vm.popupOffset : {}]),attrs:{"value":_vm.value},on:{"input":_vm.selectColor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }