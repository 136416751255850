const FONT_BASE = {
	subsets: ['latin'],
	variants: [
		'regular',
		'700',
	],
};

export const WEB_SAFE_FONTS = [
	{
		family: 'Arial',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Arial Black',
		...FONT_BASE,
	},
	{
		category: 'serif',
		family: 'Bookman',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Calibri',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Comic Sans MS',
		...FONT_BASE,
	},
	{
		category: 'monospace',
		family: 'Courier',
		...FONT_BASE,
	},
	{
		category: 'monospace',
		family: 'Courier New',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Impact',
		...FONT_BASE,
	},
	{
		category: 'serif',
		family: 'Garamond',
		...FONT_BASE,
	},
	{
		category: 'serif',
		family: 'Georgia',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Helvetica',
		...FONT_BASE,
	},
	{
		category: 'serif',
		family: 'Palatino',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Tahoma',
		...FONT_BASE,
	},
	{
		category: 'serif',
		family: 'Times New Roman',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Trebuchet MS',
		...FONT_BASE,
	},
	{
		category: 'sans-serif',
		family: 'Verdana',
		...FONT_BASE,
	},
];
