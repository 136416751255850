/**
 * Name of the query parameter of current locale
 * @constant
 */
export const QUERY_LOCALE = 'lang';

/**
 * Gets locale from URL query parameter
 * @todo refactor with a simple function that would do the same
 * @export
 * @returns {string} - locale code
 */
export default function getLocaleFromQuery() {
	const queryParameters = new URLSearchParams(window.location.search);

	return queryParameters.get(QUERY_LOCALE);
}
