<template>
	<div
		:class="computedClass"
		:style="computedStyle"
	>
		<a
			v-for="({ link, svg }) in links"
			:key="link"
			:href="link"
			target="_blank"
			class="social-icons__link"
			v-on="{
				dragstart: preventLinks ? (e) => e.preventDefault() : () => null,
				click: preventLinks ? (e) => e.preventDefault() : () => null,
			}"
			v-html="svg"
		/>
	</div>
</template>

<script>
export default {
	props: {
		links: {
			type: Array,
			default: () => ([]),
		},
		preventLinks: {
			type: Boolean,
			default: false,
		},
		direction: {
			type: String,
			validator: (value) => [
				'row',
				'column',
			].includes(value),
			default: 'row',
		},
		preventSpacing: {
			type: Boolean,
			default: false,
		},
		fullHeight: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedClass: ({ direction }) => ([
			'social-icons',
			`social-icons--${direction}`,
		]),

		computedStyle: ({
			fullHeight,
			preventSpacing,
		}) => ({
			...(fullHeight && { height: '100%' }),
			...(preventSpacing && { '--space-between-icons': '0px' }),
		}),
	},
};
</script>

<style lang="scss" scoped>
.social-icons {
	display: flex;
	flex-direction: var(--icon-direction);
	justify-content: var(--icon-spacing, start);
	max-height: 100%;
	padding: 10px;
	padding: var(--gridItemInnerPadding);
	background: var(--gridItemInnerBackground);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	$this: &;

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		color: var(--icon-color, black);
		transition: color 0.2s ease;

		&:hover {
			color: var(--icon-color-hover, var(--colors-grey-800));
		}

		::v-deep svg {
			width: var(--icon-size, 25px);
			height: var(--icon-size, 25px);
			object-fit: contain;
		}
	}

	&--row {
		#{$this} {
			&__link {
				&:not(:last-child) {
					padding-right: var(--space-between-icons);
				}
			}
		}
	}

	&--column {
		#{$this} {
			&__link {
				&:not(:last-child) {
					padding-bottom: var(--space-between-icons);
				}
			}
		}
	}
}

</style>
