import { render, staticRenderFns } from "./ZyroToggle.vue?vue&type=template&id=530546a8&scoped=true&"
import script from "./ZyroToggle.vue?vue&type=script&lang=js&"
export * from "./ZyroToggle.vue?vue&type=script&lang=js&"
import style0 from "./ZyroToggle.vue?vue&type=style&index=0&id=530546a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530546a8",
  null
  
)

export default component.exports