<template>
	<div
		class="zyro-label"
		:style="computedStyles"
	>
		<label
			v-bind="$attrs"
			class="zyro-label__label"
			:class="{
				'z-body-small': theme === 'primary',
				'z-subheading-small': theme === 'secondary',
				'z-body-small--strong': bold
			}"
			v-on="$listeners"
		>
			<slot />
		</label>
		<ZyroInfo
			v-if="infoText"
			:info-text="infoText"
		/>
	</div>
</template>

<script>
import ZyroInfo from '@/components/site-settings/components/ZyroInfo.vue';

export default {
	components: { ZyroInfo },
	inheritAttrs: false,
	props: {
		bold: {
			type: Boolean,
			default: true,
		},
		theme: {
			type: String,
			default: 'primary',
			validator: (value) => [
				'primary',
				'secondary',
			].includes(value),
		},
		padding: {
			type: String,
			default: null,
		},
		infoText: {
			type: String,
			default: '',
		},
	},
	computed: {
		computedStyles() {
			return { '--padding': this.padding };
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-label {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: var(--padding);
	font-size: 13px;

	&__label {
		display: inline-block;
	}
}
</style>
