// TODO: Remove Vue.set when moving to vue3

import {
	ref,
	reactive,
	onBeforeUnmount,
} from '@vue/composition-api';
import axios from 'axios';
import loadImage from 'blueimp-load-image';
import { nanoid } from 'nanoid';
import Vue from 'vue';

import { getExtension } from '@/utils/modifyString';

const uploadedImages = reactive({});

const IMAGE_RESOLUTION_LIMIT = 8000;
// 15 mb
const IMAGE_SIZE_LIMIT = 1.5e+7;
// * Use lowercase formats
const SUPPORTED_EXTENSIONS = [
	'jpeg',
	'jpg',
	'png',
	'gif',
	'webp',
	'svg',
];

export const useUploadImages = (props) => {
	const isDraggedOver = ref(false);
	let validFiles = [];
	const uploadHasUnsupportedFiles = ref(false);
	const uploadHasImagesTooLarge = ref(false);

	const loadImageLocally = (file, loadedImage, imageId) => {
		/**
		 * Sometimes the image starts uploading before the filereader loads image
		 * and so other functions that use Vue.set crash everything
		 * when they find that uploadeImages[imageId]
		 * thats why its being set to an empty object here
		 */
		Vue.set(uploadedImages, imageId, {
			height: loadedImage.originalHeight,
			width: loadedImage.originalWidth,
			galleryId: props?.galleryId || null,
			name: file.name,
			type: file.type,
			transferProgress: 0,
			hasFailed: false,
			/**
			 * lastModifiied and size is used to compare images so you dont upload them twice
			 * Not using these two as imageid since its too long and hard do debug
			 */
			lastModified: file.lastModified,
			size: file.size,
			/**
			 * Save these for later in case we need to retry uploads
			 * will be cleared from memory once the image is uploaded
			 */
			file,
			loadedImage,
		});

		/**
		 * convert svg to base64
		 * svg is loaded without downscaling
		 * as blueimp throws security errors
		 * with some of them
		 * and downscaling svg doesnt do anything
		 * base64 is used for preview
		 */
		if (getExtension(file.name) === 'svg') {
			const reader = new FileReader();

			reader.addEventListener('load', (event) => {
				Vue.set(uploadedImages[imageId], 'base64Thumbnail', event.target.result);
			});
			reader.readAsDataURL(file);

			return;
		}

		// Convert non svg files to base64, also downscale for performance
		const reader = new FileReader();

		reader.addEventListener('load', (loadEvent) => {
			Vue.set(uploadedImages[imageId], 'base64Thumbnail', loadEvent.target.result);
		});

		// Scale the image and pass it to reader
		loadImage.scale(loadedImage.image, { maxWidth: 1370 })
			.toBlob((scaledImage) => { reader.readAsDataURL(scaledImage); });
	};

	const removeImage = (imageId) => {
		Vue.delete(uploadedImages, imageId);
	};

	const cancelUpload = (imageId) => {
		const uploadedImage = uploadedImages[imageId];

		/**
		 * TODO:
		 * I have no idea why cancelSource doesnt exist sometimes
		 * if you do - please let me know
		 */
		if (uploadedImage.transferProgress === 100 || !uploadedImage.cancelSource) {
			return;
		}

		uploadedImage.cancelSource.cancel('Cancelled by user');
		removeImage(imageId);
	};

	const cancelAllUploads = () => {
		Object.keys(uploadedImages).forEach((imageId) => cancelUpload(imageId));
	};

	const uploadFile = async (imageId, formData, file) => {
		// Create and save cancel token before uploading
		const source = axios.CancelToken.source();

		Vue.set(uploadedImages[imageId], 'cancelSource', source);
		Vue.set(uploadedImages[imageId], 'hasFailed', false);

		try {
			const result = await axios
				.post(`${process.env.VUE_APP_API_URL}/v1/assets`, formData, {
					cancelToken: source.token,
					headers: { 'Content-Type': 'multipart/form-data' },
					// Fyi: for some images this goes from 0 to 100 instantly
					onUploadProgress: (progressEvent) => {
						const progress = Math.min((progressEvent.loaded / file.size).toFixed(2) * 100, 100);

						Vue.set(uploadedImages[imageId], 'transferProgress', progress);
					},
				});

			Vue.set(uploadedImages[imageId], 'transferProgress', 100);
			Vue.set(uploadedImages[imageId], 'url', result.data[0].url);
			// These will not be used again
			Vue.delete(uploadedImages[imageId], 'cancelSource');
			Vue.delete(uploadedImages[imageId], 'file');
			Vue.delete(uploadedImages[imageId], 'loadedImage');
		} catch (error) {
			/**
			 * Fires when upload is cancelled too
			 * so the image might not exist
			 */
			if (uploadedImages[imageId]) {
				/**
				 * not deleting file and loadedImage as before,
				 * because they are used to retry the upload
				 */
				Vue.delete(uploadedImages[imageId], 'cancelSource');
				uploadedImages[imageId].hasFailed = true;
				uploadedImages[imageId].errorMessage = error.response.data.msg;
			}
		}
	};

	const prepareAndUploadFile = (file, loadedImage, imageId) => {
		const formData = new FormData();

		/**
		 * Not sure how to refactor this code as its hard to read
		 * and probably easy to break since I dont know what it
		 * is supposed to do
		 * copied from previous implementation
		 */
		const excludedFormats = [
			'svg',
			'gif',
			'webp',
		];
		const fileFormat = getExtension(file.name);
		const isFormatExcluded = fileFormat.includes(excludedFormats);
		const getFormatToConvertTo = () => {
			if (fileFormat === 'jpg') {
				return 'jpeg';
			}

			return isFormatExcluded ? 'png' : fileFormat;
		};

		/**
		 * Some svgs throw security errors if we try to load them using
		 * blueimp image loader
		 */
		const FORM_ENTRY_NAME = 'image';

		if (fileFormat === 'svg') {
			formData.append(FORM_ENTRY_NAME, file, file.name);
			uploadFile(imageId, formData, file);
		} else {
			loadedImage.image.toBlob((blob) => {
				formData.append(
					FORM_ENTRY_NAME,
					isFormatExcluded || !loadedImage.exif ? file : blob,
					file.name,
				);
				uploadFile(imageId, formData, file);
			}, `image/${getFormatToConvertTo()}`);
		}
	};

	/**
	 * Failed images could be a computed value
	 * But computed in objects doesnt work properly with
	 * composition api plugin
	 * TODO: Retry with vue3
	 */
	const removeFailedImages = () => {
		Object.entries(uploadedImages).forEach(([image, imageId]) => {
			if (image.hasFailed) {
				removeImage(imageId);
			}
		});
	};

	/**
	 * Hint for testing:
	 * Change image size limit to a small one in backend
	 * try uploading and when popup shows up that it failed
	 * change backend back to large file limit
	 * and retry in frontend
	 */
	const retryFailedImages = () => {
		Object.entries(uploadedImages).forEach(([image, imageId]) => {
			if (image.hasFailed) {
				prepareAndUploadFile(image.file, image.loadedImage, imageId);
			}
		});
	};

	/**
	 * Validates format and size
	 * @param {*} files
	 */
	const validateFiles = async (files) => {
		// Reset validation values
		uploadHasImagesTooLarge.value = false;
		uploadHasUnsupportedFiles.value = false;
		validFiles = [];
		/*
		 * Read before changing:
		 * https://www.coreycleary.me/why-does-async-await-in-a-foreach-not-actually-await
		 */
		/* eslint-disable no-continue */
		// eslint-disable-next-line no-restricted-syntax
		for (const file of files) {
			// Skip duplicates by file size and modified date (should be good enough)
			const isDuplicate = Object.values(uploadedImages)
				.some((uploadedImage) => uploadedImage.lastModified === file.lastModified
					&& uploadedImage.size === file.size);

			if (isDuplicate) {
				continue;
			}

			// Check file extension support
			const isFileNotSupported = !SUPPORTED_EXTENSIONS
				.includes(getExtension(file.name).toLowerCase());

			if (isFileNotSupported) {
				uploadHasUnsupportedFiles.value = true;
				continue;
			}

			// Check file size support
			const isFileTooLarge = file.size > IMAGE_SIZE_LIMIT;

			if (isFileTooLarge) {
				uploadHasImagesTooLarge.value = true;
				continue;
			}

			// TODO: use Promise.all
			// eslint-disable-next-line no-await-in-loop
			const loadedImage = await loadImage(file, {
				canvas: true,
				orientation: true,
				meta: true,
			});

			const resolutionLimitExceeded = loadedImage.originalHeight > IMAGE_RESOLUTION_LIMIT
				|| loadedImage.originalWidth > IMAGE_RESOLUTION_LIMIT;

			if (resolutionLimitExceeded) {
				uploadHasImagesTooLarge.value = true;
				continue;
			}

			validFiles.push({
				file,
				loadedImage,
			});
		}
		/* eslint-enable no-continue */
	};

	/**
	 * Loads files to uploadedImages object,
	 * generates preview, uploads to s3
	 * @param {*} files
	 */
	const uploadFiles = (files) => {
		files.forEach(({
			file,
			loadedImage,
		}) => {
			/**
			 * Create an id for image so we can access
			 * the image object by id
			 */
			const imageId = nanoid();

			loadImageLocally(file, loadedImage, imageId);
			prepareAndUploadFile(file, loadedImage, imageId);
		});
	};

	// Doesn't validate, just uploads valid files called on validation error popup button clicks
	const uploadValidFiles = () => {
		if (uploadHasUnsupportedFiles.value) {
			uploadHasUnsupportedFiles.value = false;
			// If second popup should also be visible show it first before continuing
			if (uploadHasImagesTooLarge.value) {
				return;
			}
		}

		if (uploadHasImagesTooLarge.value) {
			uploadHasImagesTooLarge.value = false;
		}

		uploadFiles(validFiles);
	};

	// Drag events
	const onDragEnter = () => {
		isDraggedOver.value = true;
	};

	const onDragOver = (event) => {
		event.preventDefault();
		isDraggedOver.value = true;
	};

	const onDragLeave = () => {
		isDraggedOver.value = false;
	};

	// Used on drop and on file selection
	const onSelectFiles = async (event) => {
		event.preventDefault();
		isDraggedOver.value = false;

		// Datatransfer is for drop events, target comes from dom
		const { files } = event?.dataTransfer || event?.target;

		if (!files) {
			return;
		}

		// Validate files
		await validateFiles(files);

		// validateFiles function sets these
		if (uploadHasUnsupportedFiles.value || uploadHasImagesTooLarge.value) {
			return;
		}

		uploadFiles(validFiles);
	};

	let newDomReference = null;
	const listenForDragAndDrop = (domReference) => {
		newDomReference = domReference;
		newDomReference.addEventListener('dragenter', onDragEnter);
		newDomReference.addEventListener('dragover', onDragOver);
		newDomReference.addEventListener('dragleave', onDragLeave);
		newDomReference.addEventListener('drop', onSelectFiles);
	};

	onBeforeUnmount(() => {
		newDomReference.removeEventListener('dragenter', onDragEnter);
		newDomReference.removeEventListener('dragover', onDragOver);
		newDomReference.removeEventListener('dragleave', onDragLeave);
		newDomReference.removeEventListener('drop', onSelectFiles);
	});

	return {
		cancelAllUploads,
		cancelUpload,
		isDraggedOver,
		listenForDragAndDrop,
		onSelectFiles,
		removeFailedImages,
		removeImage,
		retryFailedImages,
		uploadedImages,
		uploadHasImagesTooLarge,
		uploadHasUnsupportedFiles,
		uploadValidFiles,
	};
};
