/*
 * To add a new format customizer add a new object to the customFormatAdditions array
 * all keys are mandatory
 * iso and currency determine what formatter will be affected
 * customizer should make a transformation to the provided input string
 */

const customFormatAdditions = [
	{
		iso: 'es-MX',
		currency: 'MXN',
		// some browsers format with $ some with MX$ these two replaces solves that
		customizer: (input) => input.replace('MX$', '$').replace('$', 'MX$'),
	},
];

export const getPriceFormatter = (iso, currencyCode) => {
	if (!iso || !currencyCode) {
		return (price) => price;
	}

	const formatter = Intl.NumberFormat(
		iso,
		{
			currency: currencyCode,
			style: 'currency',
			currencyDisplay: 'symbol',
		},
	);

	const customFormatChange = customFormatAdditions.find(
		(addition) => addition.iso === iso && addition.currency === currencyCode,
	)?.customizer;

	return (price) => {
		if (price == null) {
			return price;
		}

		const formatted = formatter.format(price);

		return typeof customFormatChange === 'function' ? customFormatChange(formatted) : formatted;
	};
};
