<template>
	<Component
		:is="tag"
		class="zyro-button"
		:class="{
			[`zyro-button-${theme}`]: theme,
			[`zyro-button-${theme}--${color}`]: color,
			[`zyro-button-${theme}--${size}`]: size,
			[`zyro-button-${theme}--icon-only`]: icon,
			[`zyro-button--icon-left`]: iconLeft,
			[`zyro-button--icon-right`]: iconRight,
			[`zyro-button--icon-only-up-to-${iconOnlyUpToMedia}`]: iconOnlyUpToMedia,
			[`zyro-button--hide-icon-right-up-to-${hideIconRightUpToMedia}`]: hideIconRightUpToMedia,
		}"
		:style="computedStyles"
		:type="tag === 'button' ? $attrs.type || 'button' : undefined"
		:to="toValue"
		v-on="$listeners"
	>
		<ZyroSvg
			v-if="icon || iconLeft"
			:name="icon || iconLeft"
			:dimensions="iconDimensions"
		/>

		<div
			v-if="$slots.default"
			class="zyro-button__text"
			:class="{
				[textClass]: textClass,
				[`show-${iconOnlyUpToMedia}-up`]: iconOnlyUpToMedia
			}"
		>
			<slot />
		</div>

		<ZyroSvg
			v-if="iconRight"
			:name="iconRight"
			:dimensions="iconDimensions"
			:class="{
				[`show-${iconOnlyUpToMedia}-up`]: iconOnlyUpToMedia,
				[`show-${hideIconRightUpToMedia}-up`]: hideIconRightUpToMedia,
			}"
		/>
	</Component>
</template>

<script>
export default {
	props: {
		to: {
			type: [
				Object,
				String,
			],
			default: undefined,
		},
		theme: {
			type: String,
			validator: (value) => [
				'primary',
				'outline',
				'editor',
				'header',
				'plain',
			].includes(value),
			default: 'plain',
		},
		color: {
			type: String,
			validator: (value) => [
				'red',
				'blue',
				'plump-purple',
				'black',
				'white',
			].includes(value),
			default: 'black',
		},
		size: {
			type: String,
			validator: (value) => [
				'xxs',
				'xs',
				'sm',
				'md',
				'lg',
			].includes(value),
			default: 'xs',
		},
		icon: {
			type: String,
			default: '',
		},
		iconLeft: {
			type: String,
			default: '',
		},
		iconRight: {
			type: String,
			default: '',
		},
		iconOnlyUpToMedia: {
			type: String,
			validator: (value) => [
				'header-desktop',
				'tablet',
				'mobile',
			].includes(value),
			default: undefined,
		},
		hideIconRightUpToMedia: {
			type: String,
			validator: (value) => [
				'header-desktop',
				'tablet',
				'mobile',
			].includes(value),
			default: undefined,
		},
		borderRadius: {
			type: String,
			default: '',
		},
		iconDimensions: {
			type: String,
			default: '',
		},
		textClass: {
			type: String,
			default: '',
		},
	},
	computed: {
		tag() {
			if (this.$attrs.href) {
				return 'a';
			}

			if (this.to) {
				return 'router-link';
			}

			return 'button';
		},
		toValue() {
			if (typeof this.to === 'string') {
				return { name: this.to };
			}

			return this.to;
		},
		computedStyles() {
			return { '--z-border-radius': this.borderRadius || undefined };
		},
	},
};
</script>

<style lang="scss" scoped>
// TODO: https://github.com/zyro-inc/zyro/issues/1066

$button-xs-font-size: 10px;
$button-sm-font-size: 12px;
$button-md-font-size: 14px;

$button-xxs-padding-h: 24px;
$button-xs-padding-h: 32px;
$button-sm-padding-h: 40px;
$button-md-padding-h: 48px;
$button-lg-padding-h: 56px;

$button-xxs-height: 24px;
$button-xs-height: 36px;
$button-sm-height: 48px;
$button-md-height: 56px;
$button-lg-height: 64px;

$button-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);

// Adds ripple effect on click
@mixin ripple($inverse: false) {
	position: relative;
	outline: none;

	@include setRippleColor;

	&::after {
		$size: 70px;

		position: absolute;
		top: 50%;
		left: 50%;
		width: $size;
		height: $size;
		margin: (-$size/2) 0 0 (-$size/2);
		pointer-events: none;
		content: "";
		border-radius: var(--z-border-radius, 100px);
		opacity: 0;
		transition: transform 0.4s, opacity 0.6s;
		transform: scale(10, 10);
		transform-origin: center;

		@include mobile-view {
			$size: 28px;

			width: $size;
			height: $size;
			margin: (-$size/2) 0 0 (-$size/2);
		}
	}

	&:active,
	&.active {
		&::after {
			opacity: 0.3;
			transition: 0s;
			transform: scale(0, 0);
		}
	}
}

// Set/Updates ripple color
@mixin setRippleColor($color: $light) {
	&::after {
		background:
			radial-gradient(circle, #{$color} 10%, transparent 10%) no-repeat
			center;
	}
}

@mixin font-styles($type: 'caps') {
	@if $type == 'caps' {
		font-weight: 500;
		line-height: 1.6em;
		text-transform: uppercase;
		letter-spacing: 0.2em;
	}

	@else {
		line-height: 1.2em;
	}
}

// --- Common for all themes ----------------------------------------------------------------
.zyro-button {
	/* Do not set following props in here, set them in themes:
		- font-size, font-weight, line-height, text-transform, letter-spacing
		- border-width, border-color, padding, height, width */
	$this: &;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: inherit;
	text-decoration: none;
	cursor: pointer;
	border: 0 solid transparent;
	border-radius: var(--z-border-radius, 100px);
	transition: all 0.2s $easing-standard;

	@include ripple;

	::v-deep {
		svg {
			flex-shrink: 0;
			transition: inherit;
			transition-property: fill;
		}
	}

	// Space between text and icon
	&__text {
		display: flex; // for logo
		align-items: center;
		margin: 0 8px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&--icon-right {
		justify-content: right;
		text-align: right;
	}

	&--icon-left {
		justify-content: left;
		text-align: left;
	}

	&:disabled,
	&.disabled {
		pointer-events: none;
		cursor: default;
	}

	// When hiding text on breakpoint, align icon center again
	@each $name, $breakpoint in $breakpoints {
		@media screen and (max-width: map-get($breakpoints, $name)) {
			&--icon-only-up-to-#{$name} {
				justify-content: center;
			}

			&--hide-icon-right-up-to-#{$name} {
				#{$this}__text {
					margin-right: 0;
				}
			}
		}
	}
}

// --- Theme: Primary & Outline -------------------------------------------------------------
.zyro-button-primary,
.zyro-button-outline {
	$this: &;

	@include font-styles('caps');

	transition-property:
		border-color,
		background-color,
		color;

	// --- Sizes ---

	// Keeps button size consistent while changing border width
	@mixin setPaddingMinusBorder($border-width, $padding-vertical, $padding-horizontal) {
		padding: ($padding-vertical - $border-width) ($padding-horizontal - $border-width);
		border-width: $border-width $border-width;
	}

	// Sets paddings, handles border widths.
	// Holds logic for which button type and on what states should have borders
	@mixin setPaddingAndBorder($selector, $vertical, $horizontal) {
		@include setPaddingMinusBorder(3px, $vertical, $horizontal);

		&.zyro-button-outline {
			@include setPaddingMinusBorder(1px, $vertical, $horizontal);
		}

		&:focus,
		&.focus {
			&.zyro-button-outline {
				@include setPaddingMinusBorder(3px, $vertical, $horizontal);
			}

			// Removes border on ripple effect
			&:hover,
			&.hover {
				@include setPaddingMinusBorder(0, $vertical, $horizontal);
			}
		}
	}

	&--xxs {
		@include setPaddingAndBorder($this, $button-xs-font-size/2, $button-xxs-padding-h);

		min-width: $button-xxs-height;
		min-height: $button-xxs-height;
		font-size: $button-xs-font-size;
	}

	&--xs {
		@include setPaddingAndBorder($this, $button-xs-font-size/2, $button-xs-padding-h);

		min-width: $button-xs-height;
		min-height: $button-xs-height;
		font-size: $button-xs-font-size;
	}

	&--sm {
		@include setPaddingAndBorder($this, $button-sm-font-size/2, $button-sm-padding-h);

		min-width: $button-sm-height;
		min-height: $button-sm-height;
		font-size: $button-sm-font-size;
	}

	&--md {
		@include setPaddingAndBorder($this, $button-md-font-size/2, $button-md-padding-h);

		min-width: $button-md-height;
		min-height: $button-md-height;
		font-size: $button-md-font-size;
	}

	&--lg {
		@include setPaddingAndBorder($this, $button-md-font-size/2, $button-lg-padding-h);

		min-width: $button-lg-height;
		min-height: $button-lg-height;
		font-size: $button-md-font-size;
	}

	&--icon-only {
		padding: 0 !important; // override all modifiers and states
	}

	// --- Colors ---

	// Takes 2 colors as arguments and sets one or another depending on $inverse
	// Helps generate all color options.
	@mixin setTextColor($inverse, $color: $light, $color-inverse: $dark) {
		@if ($inverse) {
			color: $color-inverse;
		}

		@else {
			color: $color;
		}
	}

	// Colors: text, bg, border, hover, focus, active
	@mixin setColors($selector, $bg, $bg-hover, $border-focus, $inverse: false) {
		@include setTextColor($inverse);

		background-color: $bg;
		border-color: $bg;

		&.zyro-button-outline {
			@include setTextColor(false, $bg);

			background-color: transparent;
		}

		// Ripple
		@if ($inverse) {
			@include setRippleColor($border-focus);
		}

		@else {
			@include setRippleColor($light);
		}

		// States
		&:focus,
		&.focus {
			@include setTextColor($inverse);

			background-color: $bg; // repeats for outline
			border-color: $border-focus;
			box-shadow: none;
		}

		&:hover,
		&.hover {
			@include setTextColor($inverse);

			background-color: $bg-hover;
			border-color: $bg-hover;

			&.zyro-button-outline {
				background-color: $bg;
				border-color: $bg;
			}
		}

		&:active,
		&.active {
			@include setTextColor($inverse);

			background-color: $bg; // repeats for outline
		}
	}

	&--red {
		@include setColors(
			$this,
			$secondary,
			$secondary-hover,
			$secondary-focus
		);
	}

	&--blue {
		@include setColors(
			$this,
			$accent-two,
			$accent-two-hover,
			$accent-two-focus
		);
	}

	&--plump-purple {
		@include setColors(
			$this,
			$primary,
			$primary-hover,
			$primary-focus
		);
	}

	&--black {
		@include setColors($this, $dark, $dark-hover, $dark-focus);
	}

	&--white {
		@include setColors(
			$this,
			$light,
			$light-hover,
			$light-focus,
			true
		);
	}

	&:disabled,
	&.disabled {
		color: $dark;
		background-color: $grey-600;
		border-color: $grey-600;
	}
}

// --- Theme: Plain -------------------------------------------------------------------------
.zyro-button-plain {
	$this: &;
	$border-width: 2px;

	min-width: $button-xs-height;
	min-height: $button-xs-height;
	padding: ($button-xs-font-size - $border-width)/2 1px;

	@include font-styles('caps');

	font-size: $button-xs-font-size;
	background-color: transparent;
	border-width: 2px 0;
	border-radius: 0;
	transition-property: color;

	@include setRippleColor('inverse');

	&--icon-only {
		padding: 0;
		border-width: 2px;
		border-radius: var(--z-border-radius, 100px);
	}

	// --- Colors ---
	@mixin setStateColors($color, $color-focus) {
		&:focus,
		&.focus,
		&:hover,
		&.hover {
			color: $color;
		}

		&:focus,
		&.focus {
			border-bottom-color: $color-focus;

			&#{$this}--icon-only {
				border-color: $color-focus;
			}
		}
	}

	&--black {
		color: $dark;

		@include setRippleColor($accent-two-focus);
		@include setStateColors($accent-two, $accent-two);
	}

	&--red {
		color: $secondary;

		@include setRippleColor($secondary-focus);
		@include setStateColors($secondary, $secondary-focus);
	}

	&:disabled,
	&.disabled {
		color: $grey-300;
	}
}

// --- Theme: Editor ------------------------------------------------------------------------
.zyro-button-editor {
	$this: &;
	$border-width: 2px;

	min-width: $button-xs-height;
	min-height: $button-xs-height;
	padding: ($button-md-font-size/2 - $border-width) ($button-xs-padding-h - $border-width);

	@include font-styles('regular');

	font-size: $button-md-font-size;
	color: $dark;
	background-color: $light;
	border-color: $light;
	border-width: $border-width;
	box-shadow: $button-shadow;
	transition-property:
		border-color,
		box-shadow,
		color;

	@include setRippleColor($accent-two-focus);

	&--icon-only {
		padding: 0;
	}

	&:hover,
	&.hover {
		color: $accent-two;
	}

	&:focus,
	&.focus {
		color: $accent-two;
		border-color: $accent-two;
	}

	&:disabled,
	&.disabled {
		color: $grey-300;
	}
}

// --- Theme: Header ------------------------------------------------------------------------
.zyro-button-header {
	$border-width: 2px;

	min-width: $header-height;
	height: 100%;
	min-height: $header-height;
	padding: ($button-md-font-size - $border-width)/2 17px;
	overflow: hidden;
	font-size: $button-md-font-size;
	border-radius: 0;
	transition-property:
		border-color,
		color;

	@include font-styles('regular');

	// Reduce size for tablet
	@media screen and (max-width: $media-tablet) {
		min-width: 40px;
		padding-right: 14px;
		padding-left: 14px;
	}

	// --- Colors ---
	&--white {
		color: $dark;
		background-color: transparent;
		border-width: 2px 0;

		@include setRippleColor($secondary-focus);

		&:focus,
		&.focus {
			color: $secondary;
			border-bottom-color: $secondary;
		}

		&:hover,
		&.hover {
			color: $secondary;
		}

		&:disabled,
		&.disabled {
			color: $grey-300;
			background-color: $light;
		}
	}

	&--black {
		padding-right: 40px;
		padding-left: 40px;
		color: $light;
		background-color: $dark;
		border-width: 2px 0;

		@include setRippleColor($secondary-focus);

		&:focus,
		&.focus {
			color: $secondary;
			border-bottom-color: $secondary;
		}

		&:hover,
		&.hover {
			color: $secondary;
		}

		&:disabled,
		&.disabled {
			color: $grey-300;
			background-color: $light;
		}
	}

	// Specific to publish
	&--red {
		padding-right: 27px;
		padding-left: 27px;
		color: $light;
		background-color: $secondary;
		border-width: 2px;

		&:focus,
		&.focus {
			border-color: $secondary-focus;
		}

		&:hover,
		&.hover {
			background-color: $secondary-hover;
			border-color: $secondary-hover;
		}

		&:disabled,
		&.disabled {
			color: $light;
			background-color: $accent-two;
			border-color: $accent-two;
		}

		// Reduce size for tablet
		@media screen and (max-width: $media-tablet) {
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}
</style>
