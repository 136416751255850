<template>
	<div class="zyro-checkbox">
		<input
			:id="id"
			type="checkbox"
			v-bind="$attrs"
			class="zyro-checkbox__input"
			v-on="$listeners"
		>
		<label
			:for="id"
			class="zyro-checkbox__label"
		>
			<ZyroSvg
				class="zyro-checkbox__check-mark"
				name="check-mark-thick"
			/>
		</label>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';

export default {
	inheritAttrs: false,
	computed: {
		id() {
			return this.$attrs.id || nanoid();
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-checkbox {
	$this: &;

	display: inline-block;
	font-size: 24px;

	&__label {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1em;
		height: 1em;
		color: $primary;
		background: transparent;
		border: 2px solid $grey-400;
		border-radius: 5px;
		transition: border 300ms ease;

		&:hover {
			border: 2px solid $primary;
		}

		#{$this}__check-mark {
			margin-top: 1px;
			transition: transform 75ms $easing-accelerate;
			transform: rotateY(-90deg) scale(0);
			backface-visibility: hidden;
		}
	}

	&__input {
		display: none;
		visibility: hidden;
		appearance: none;

		&:checked + #{$this}__label {
			border: 2px solid $primary;

			#{$this}__check-mark {
				transform: rotateY(0) scale(1);
			}
		}

		&:disabled + #{$this}__label {
			background: $grey-300;

			&:hover {
				border: 2px solid $grey-400;
			}
		}

		&:focus + #{$this}__label {
			border: 2px solid $primary;
		}
	}
}
</style>
