<template>
	<div class="block-grid">
		<BlockGridItemUser
			v-for="componentId in data.components"
			:id="componentId"
			:key="componentId"
			:data="components[componentId] || website.components[componentId]"
			class="grid__item"
			:class="{ 'block-grid--overflow-visible': getIsOverflowVisible(componentId) }"
			:style="{ '--element-z-index': data.zindexes.indexOf(componentId) + 1 }"
			:is-preview-mode="isPreviewMode"
			:is-mobile-view="isMobileView"
			:mobile-block-padding="data.settings.styles['m-block-padding']"
			:block-data="data"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import BlockGridItemUser from '@user/components/block-grid-item/BlockGridItemUser.vue';

export default {
	components: { BlockGridItemUser },
	props: {
		data: {
			type: Object,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		components: {
			type: Object,
			default: () => ({}),
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
	},
	computed: mapState(['website']),
	methods: {
		getIsOverflowVisible(componentId) {
			return this.components[componentId].type === 'GridForm';
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'BlockGrid.scss';

// for users don't overflow content if row height is too small and text wraps
.block-grid {
	grid-template-rows: repeat(var(--rows), minmax(var(--row-size), auto));

	&--overflow-visible {
		overflow: visible;
	}
}
</style>
