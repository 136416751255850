<template>
	<div
		class="zyro-radio"
	>
		<input
			type="radio"
			v-bind="$attrs"
			class="zyro-radio__input"
			:checked="value === model"
			v-on="$listeners"
			@change="$emit('model-change', value)"
		>
		<label
			:for="$attrs.id"
			class="zyro-radio__label"
		/>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	model: {
		prop: 'model',
		event: 'model-change',
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		model: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-radio {
	$this: &;

	display: inline-block;
	height: 20px;
	font-size: 20px;

	&__label {
		display: inline-block;
		width: 1em;
		height: 1em;
		background: $light;
		border: 1px solid $dark;
		border-radius: 1em;
		transition: all 250ms ease;
	}

	&__input {
		display: none;
		visibility: hidden;
		appearance: none;

		&:checked + #{$this}__label {
			background-color: $dark;
			box-shadow: inset 0 0 0 4px $light;
		}

		&:disabled + #{$this}__label {
			background: $grey-300;
			border-color: $grey-300;
			box-shadow: inset 0 0 0 4px $light;
		}

		&:focus + #{$this}__label {
			border-color: $dark;
			outline: none;
		}
	}
}
</style>
