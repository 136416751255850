<template>
	<Transition :name="`modal-from-${position}`">
		<div
			class="modal-backdrop"
			:class="[position, { hide: hideBackdrop }]"
			:style="computedStyles"
			@click.self.prevent="onCloseModal()"
		>
			<div
				:ref="modalRef"
				class="modal"
				:class="{ 'modal--rounded': isRounded }"
				:style="modalStyle"
				role="dialog"
				aria-labelledby="modalTitle"
				aria-describedby="modalDescription"
			>
				<h2
					v-if="title || $slots.title"
					class="modal__title"
					:class="{
						'modal__title--has-subtitle': subtitle,
						'z-h5': !isTitleCentered,
						'modal__title--centered z-body-small z-body-small--strong': isTitleCentered
					}"
				>
					<slot name="title">
						{{ title }}
					</slot>
				</h2>
				<p
					v-if="subtitle || $slots.subtitle"
					class="modal__subtitle z-body-small z-body-small--light"
				>
					<slot name="subtitle">
						{{ subtitle }}
					</slot>
				</p>
				<div
					class="modal__content"
					:class="{
						'modal__content--no-overflow': noOverflow,
						'modal__content--display-flex': useFlexForContent
					}"
				>
					<slot />
				</div>
				<ZyroButton
					v-if="showCloseButton"
					class="modal__close"
					data-qa="modal-btn-close"
					icon="x"
					:title="$t('common.close')"
					@click="onCloseModal()"
				/>
				<div
					v-if="$slots.footer"
					class="modal__footer"
				>
					<slot name="footer" />
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
import {
	disableBodyScroll,
	enableBodyScroll,
} from 'body-scroll-lock';
import { nanoid } from 'nanoid';

export default {
	props: {
		position: {
			validator(value) {
				return [
					'center',
					'right',
					'left',
					'top',
					'bottom',
				].includes(value);
			},
			default: 'center',
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		maxWidth: {
			type: [
				Number,
				String,
			],
			default: '100%',
		},
		maxHeight: {
			type: [
				Number,
				String,
			],
			default: '100%',
		},
		width: {
			type: [
				Number,
				String,
			],
			default: '100%',
		},
		height: {
			type: [
				Number,
				String,
			],
			default: '100%',
		},
		overflow: {
			type: String,
			default: 'auto',
		},
		hideBackdrop: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		delay: {
			type: Number,
			default: 0,
		},
		noOverflow: {
			type: Boolean,
			default: false,
		},
		useFlexForContent: {
			type: Boolean,
			default: false,
		},
		isRounded: {
			type: Boolean,
			default: true,
		},
		contentPadding: {
			type: String,
			default: '',
		},
		isTitleCentered: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			blockClosing: false,
			closingTimeout: null,
			modalId: nanoid(),
		};
	},
	computed: {
		computedStyles() {
			return { '--z-content-padding': this.contentPadding };
		},
		// Todo: move to computedStyles
		modalStyle() {
			return `max-width: ${this.maxWidth}; max-height: ${this.maxHeight}; width: ${this.width}; height: ${this.height};`;
		},
		modalRef() {
			return `modal-${this.modalId}`;
		},
	},
	mounted() {
		if (this.delay) {
			this.blockClosing = true;
			setTimeout(() => {
				this.blockClosing = false;
			}, this.delay);
		}

		disableBodyScroll(this.modalRef);
	},
	beforeDestroy() {
		enableBodyScroll(this.modalRef);
	},
	methods: {
		onCloseModal() {
			if (!this.blockClosing) {
				this.$emit('close-modal');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	position: relative;
	display: flex;
	flex-direction: column;
	cursor: default;
	background: $light;
	box-shadow: 0 5px 5px rgba($dark, 0.05), 0 10px 30px rgba($dark, 0.2);
	transition: all 0.3s ease;

	&--rounded {
		border-radius: $border-radius-large;
	}
}

.modal__close {
	position: absolute;
	right: 0;
}

.modal__title {
	padding: 16px 24px;

	&--has-subtitle {
		padding: 16px 24px 0;
	}
}

.modal__subtitle {
	padding: 8px 24px;
	color: $grey-600;
}

.modal__content {
	height: 100%;
	padding: var(--z-content-padding, 0 24px 8px 24px);
	overflow: auto;

	&--display-flex {
		display: flex;
		flex-direction: column;
	}

	&--no-overflow {
		overflow: hidden;
	}
}

.modal__footer {
	display: flex;
	justify-content: space-between;
	padding: 16px 24px;
	margin-top: auto;
	border-top: 1px solid $grey-300;
}

.modal-backdrop {
	@include backdrop(z-index(modal));

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s ease;

	&.right {
		justify-content: flex-end;
	}

	&.left {
		justify-content: flex-start;
	}

	&.up {
		align-items: flex-start;
	}

	&.down {
		align-items: flex-end;
	}

	&.hide {
		background-color: rgba($dark, 0);
	}
}
</style>
