// TODO: Document ZyroBackground; Add tests;
<template>
	<div
		v-if="!!backgroundObject.current"
		class="zyro-background-selector"
	>
		<ZyroSegmentControl
			class="zyro-background-selector__tabs"
			:controls="$options.tabs"
			:active-control="currentTab"
			@change="changeTab"
		/>
		<Component
			:is="currentTab.component"
			:value="currentBackgroundValue"
			:selected-color="currentBackgroundValue"
			:is-popup="false"
			:object-fit="objectFit"
			:default-mode="$options.COLOR_PICKER_MODE_INTEGRATED"
			@input="setBackgroundValue($event.url ? $event.url : $event)"
		/>
		<template v-if="isBackgroundImage">
			<ZyroLabel class="background-overlay-label">
				{{ $t('builder.editBlockTabBackground.overlayOpacity') }}
			</ZyroLabel>
			<ZyroRange
				v-model="overlayOpacity"
				v-qa="`backgroundsettings-overlayopacity-${overlayOpacity}`"
				units="%"
				type="number"
				min="0"
				step="1"
				max="100"
			/>
			<ZyroSeparator class="background-overlay-separator" />
		</template>
	</div>
</template>

<script>

import { COLOR_PICKER_MODE_INTEGRATED } from '@/components/global/zyro-color-picker/constants';
import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';

const tabs = [
	{
		title: i18n.t('common.color'),
		id: 'color',
		component: 'ZyroColorPicker',
	},
	{
		title: i18n.t('common.image'),
		id: 'image',
		component: 'ZyroImageSelector',
	},
];

export default {
	tabs,
	COLOR_PICKER_MODE_INTEGRATED,
	props: {
		backgroundObject: {
			type: Object,
			required: true,
		},
		objectFit: {
			type: String,
			default: 'contain',
		},
	},
	data() {
		return {
			currentTab: null,
			backgroundObjectLocal: {},
		};
	},
	computed: {
		isBackgroundImage() {
			return this.backgroundObjectLocal.current === 'image';
		},
		currentBackgroundValue() {
			return this.backgroundObjectLocal[this.backgroundObjectLocal.current] || '';
		},
		overlayOpacity: {
			get() {
				return Math.round((Number.parseFloat(this.backgroundObjectLocal['overlay-opacity'], 10) || 0) * 100);
			},
			set(event) {
				this.backgroundObjectLocal = {
					...this.backgroundObjectLocal,
					'overlay-opacity': `${(event.target.value / 100).toFixed(2)}`,
				};
				this.updateBackground();
			},
		},
	},
	beforeMount() {
		if (!this.backgroundObject.current) return;
		this.backgroundObjectLocal = cloneDeep(this.backgroundObject);
		this.currentTab = this.$options.tabs
			.find((tab) => tab.id === this.backgroundObjectLocal.current);

		if (!this.currentTab) {
			this.backgroundObjectLocal = {
				current: 'color',
				image: '',
				color: '',
			};
			[this.currentTab] = this.$options.tabs;
			this.updateBackground();
		}
	},
	methods: {
		updateBackground() {
			this.$emit('update:backgroundObject', this.backgroundObjectLocal);
		},
		setBackgroundValue(value) {
			this.backgroundObjectLocal[this.backgroundObjectLocal.current] = value;
			this.updateBackground();
		},
		changeTab(value) {
			this.currentTab = value;
			this.backgroundObjectLocal.current = value.id;
			this.updateBackground();
		},
	},
};
</script>
<style lang="scss" scoped>
.zyro-background-selector {
	width: 100%;

	&__tabs {
		margin-bottom: 16px;
	}
}

.background-overlay-label,
.background-overlay-separator {
	margin: 16px 0 8px;
}
</style>
