// TODO: Document innerBackgroundValue
<template>
	<div
		v-if="!hidden"
		class="block-grid-item use-m-margin"
		:style="variablesString + innerBackgroundValue"
	>
		<Component
			:is="data.type"
			:id="id"
			class="block-grid-item__component"
			:data="data"
			:is-preview-mode="isPreviewMode"
			:is-mobile-view="isMobileView"
			:mobile-block-padding="mobileBlockPadding"
			:component-width="componentWidth"
			:component-height="componentHeight"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { useInlineCSSVariables } from '@/use/useInlineCSSVariables';
import GridButtonProviderUser from '@user/components/grid-components/button/GridButtonProviderUser.vue';
import GridFormProviderUser from '@user/components/grid-components/form/GridFormProviderUser.vue';
import GridGalleryProviderUser from '@user/components/grid-components/gallery/GridGalleryProviderUser.vue';
import GridImageProviderUser from '@user/components/grid-components/image/GridImageProviderUser.vue';
import GridInstagramFeedProviderUser from '@user/components/grid-components/instagramFeed/GridInstagramFeedProviderUser.vue';
import GridMapProviderUser from '@user/components/grid-components/map/GridMapProviderUser.vue';
import GridSocialIconsProviderUser from '@user/components/grid-components/socialIcons/GridSocialIconsProviderUser.vue';
import GridStripeButtonProviderUser from '@user/components/grid-components/stripeButton/GridStripeButtonProviderUser.vue';
import GridTextBoxProviderUser from '@user/components/grid-components/textBox/GridTextBoxProviderUser.vue';
import GridVideoProviderUser from '@user/components/grid-components/video/GridVideoProviderUser.vue';
import { getGridItemSize } from '@user/utils/getGridItemSize';

export default {
	name: 'BlockGridItemUser',
	components: {
		GridButton: GridButtonProviderUser,
		GridStripeButton: GridStripeButtonProviderUser,
		GridForm: GridFormProviderUser,
		GridVideo: GridVideoProviderUser,
		GridTextBox: GridTextBoxProviderUser,
		GridMap: GridMapProviderUser,
		GridSocialIcons: GridSocialIconsProviderUser,
		GridGallery: GridGalleryProviderUser,
		GridImage: GridImageProviderUser,
		GridInstagramFeed: GridInstagramFeedProviderUser,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		mobileBlockPadding: {
			type: String,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
		blockData: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const { variablesString } = useInlineCSSVariables(props);

		return { variablesString };
	},
	computed: {
		...mapState('gui', ['isMobileScreen']),
		innerBackgroundValue() {
			const { innerBackground } = this.data;

			if (!innerBackground) {
				return null;
			}

			const currentBackground = innerBackground[innerBackground.current];

			if (innerBackground.current === 'image') {
				const backgroundValue = `--gridItemInnerBackground:url(${currentBackground});`;
				const backgroundOverlay = `--gridItemInnerBackgroundOverlayOpacity: ${innerBackground['overlay-opacity']};`;

				return 'overlay-opacity' in innerBackground ? `${backgroundValue}${backgroundOverlay}` : backgroundValue;
			}

			return `--gridItemInnerBackground:${currentBackground}`;
		},
		settings() {
			return this.data.settings;
		},
		hidden() {
			return this.isMobileScreen && this.settings.hiddenOn === 'mobile';
		},
		componentWidth({
			data,
			blockData,
		}) {
			const { width } = getGridItemSize(blockData, data.settings.styles.position);

			return width;
		},
		componentHeight({
			data,
			blockData,
		}) {
			const { height } = getGridItemSize(blockData, data.settings.styles.position);

			return height;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'BlockGridItem.scss';

.block-grid-item {
	/**
			Make only the actual component clickable,
			prevents for example buttons that are over
			textboxes from being non clickable
		*/
	pointer-events: none;

	&__component {
		pointer-events: all;
	}
}

@include zyro-media($media-grid) {
	.block-grid-item {
		// TODO: Move to mapper
		width: var(--m-width, 100%);

		&.use-m-margin {
			margin: var(--m-element-margin);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
</style>
