const USER_DATA_API = `${process.env.VUE_APP_API_URL}/u1/data/post`;

export const saveData = (token, data) => {
	fetch(`${USER_DATA_API}/${token}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json;charset=UTF-8' },
		credentials: 'omit',
		mode: 'cors',
		body: JSON.stringify(data),
	}).then((response) => {
		if (!response.ok) {
			throw new Error(response.statusText);
		}

		return response;
	});
};
