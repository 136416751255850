<template>
	<Component
		:is="component"
		v-if="component"
		:settings="activeModalSettings"
	/>
</template>

<script>
import { mapState } from 'vuex';

import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

const OPEN_MODAL_QUERY_PARAM = 'open-modal';

export default {
	data() {
		return { component: null };
	},
	computed: mapState('gui', [
		'activeModalName',
		'activeModalSettings',
	]),
	watch: {
		activeModalName() {
			this.component = this.activeModalName ? () => import(`./modals/${this.activeModalName}.vue`) : null;
		},
	},
	mounted() {
		const queryModalName = this.$route.query[OPEN_MODAL_QUERY_PARAM];

		if (queryModalName) {
			this.openModal({ name: queryModalName });
		}
	},
	methods: mapActionsGui({ openModal: OPEN_MODAL }),
};
</script>
