<template>
	<div class="status-bar z-button-small">
		<i18n path="builder.assetManager.tabUser.photosUploading">
			{{ inProgressImageCount }}
		</i18n>
		<ZyroButton
			theme="outline"
			data-qa="chooseimage-btn-cancelupload"
			@click="$emit('cancel-all-uploads')"
		>
			{{ $t('builder.assetManager.tabUser.cancelUpload') }}
		</ZyroButton>
	</div>
</template>

<script>
export default {
	props: {
		inProgressImageCount: {
			type: Number,
			default: 0,
		},
	},
};
</script>

<style lang="scss" scoped>
.status-bar {
	display: flex;
	grid-area: 3/1/3/3;
	align-items: center;
	justify-content: space-between;
	padding: 34px 22px;
	border-top: 1px solid $grey-400;
}
</style>
