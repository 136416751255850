// https://github.com/vuejs/vue/issues/214
function makeRecomputable(watcher, key, recomputed) {
	const original = watcher.getter;

	// eslint-disable-next-line no-param-reassign
	recomputed[key] = true;
	// eslint-disable-next-line no-param-reassign, no-sequences
	watcher.getter = (vm) => (recomputed[key], original.call(vm, vm));
}

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				const recomputed = Object.create(null);
				// eslint-disable-next-line no-underscore-dangle
				const watchers = this._computedWatchers; // Warning: Vue internal

				if (!watchers) return;

				// eslint-disable-next-line guard-for-in, no-restricted-syntax
				for (const key in watchers) {
					makeRecomputable(watchers[key], key, recomputed);
				}

				// eslint-disable-next-line no-return-assign
				this.$recompute = (key) => (recomputed[key] = !recomputed[key]);
				Vue.observable(recomputed);
			},
		});
	},
};
