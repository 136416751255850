import { create } from 'jsondiffpatch';

import { cloneDeep } from '@/utils/object';

export const jsondiffpatch = create({
	objectHash(object) {
		// eslint-disable-next-line no-underscore-dangle
		return object._id || object.id;
	},
	arrays: {
		detectMove: true,
		includeValueOnMove: false,
	},
	textDiff: { minLength: 60 },
	propertyFilter(name) {
		return name.slice(0, 1) !== '$';
	},
	cloneDiffValues: false,
});

export const revertChangesByDiff = (object, diff) => {
	const objectClone = cloneDeep(object);

	jsondiffpatch.unpatch(objectClone, diff);

	return objectClone;
};

export const createObjectDiff = (objectBeforeChanges, objectAfterChanges) => {
	const objectAfterChangesClone = cloneDeep(objectAfterChanges);

	return jsondiffpatch.diff(objectBeforeChanges, cloneDeep(objectAfterChangesClone));
};
