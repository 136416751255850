import {
	reactive,
	ref,
	onBeforeMount,
	computed,
} from '@vue/composition-api';
import Vue from 'vue';

import { getAssets } from '@/api/AssetsApi';
import { useNotifications } from '@/use/useNotifications';
// Keep this in memory so it doesn't refetch everytime this file is used
const userResults = reactive({});
// Helps keep track if we have request images from backend yet or not
const haveUserImagesLoaded = ref(false);

export const useUserImages = () => {
	const { notify } = useNotifications();

	onBeforeMount(async () => {
		if (!haveUserImagesLoaded.value) {
			try {
				const assets = await getAssets();

				assets.forEach((url, index) => {
					Vue.set(userResults, index, { url });
				});
				haveUserImagesLoaded.value = true;
			} catch {
				notify({
					message: 'An error occurred while trying to load your images',
					origin: 'useUserImages.js',
				});
			}
		}
	});

	const removeUserImage = (index) => {
		Vue.delete(userResults, index);
	};

	return {
		userResults,
		removeUserImage,
		haveUserImagesLoaded: computed(() => haveUserImagesLoaded.value),
	};
};
