import axios from 'axios';

const VIMEO_OEMBED_API = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com';

// Vimeo API disallows custom headers, so create a new instance and delete them from it:
const axiosInstance = axios.create();

delete axiosInstance.defaults.headers['z-app'];
delete axiosInstance.defaults.headers['z-window-id'];

export const getVimeoThumbnails = async (id) => {
	const { data } = await axiosInstance.get(`${VIMEO_OEMBED_API}/${id}`, { withCredentials: false });
	/**
	 * thumbnail_url` retrieved from API usually comes with '_295x166' size suffix.
	 * To retrieve higher quality image replace it with higher resolution (via Video thumbnail CDN).
	 *
	 *  NB: video ID is different from thumbnail ID retrieved from Vimeo CDN.
	 */
	const hqThumbnailUrl = data.thumbnail_url.replace('_295x166', '_720');

	return {
		jpg: hqThumbnailUrl.replace('.webp', '.jpg'),
		webp: hqThumbnailUrl.replace('.jpg', '.webp'),
	};
};
