const eventList = {
	getEvent(eventName) {
		// Converts String to Object path and returns the accessed event
		try {
			return eventName.split('.').reduce((object, item) => object[item], this);
		} catch {
			return null;
		}
	},
	// PRO Plan events
	user: {
		'plan-upgrade-success': {
			amplitude: 'user.plan-upgrade-success',
			gtm: {
				event: 'upgradeSuccess',
				category: 'plan',
			},
		},
		'plan-upgrade-failed': {
			amplitude: 'user.plan-upgrade-failed',
			gtm: {
				event: 'upgradeFailed',
				category: 'plan',
			},
		},
		activatedUnusedPlan: {
			amplitude: 'user.activatedUnusedPlan',
			gtm: {
				event: 'activatedUnused',
				category: 'plan',
			},
		},
		productView: {
			amplitude: 'user.product-view',
			gtm: { event: 'productView' },
		},
		// Authentication events
		signup: {
			amplitude: 'user.signup',
			gtm: {
				event: 'signup',
				category: 'authentication',
			},
		},
		login: {
			amplitude: 'user.login',
			gtm: {
				event: 'login',
				category: 'authentication',
			},
		},
		logout: {
			amplitude: 'user.logout',
			gtm: {
				event: 'logout',
				category: 'authentication',
			},
		},
		changedPassword: {
			amplitude: 'user.changedPassword',
			gtm: {
				event: 'updatePassword',
				category: 'authentication',
			},
		},
		updateProfile: {
			amplitude: 'user.updateProfile',
			gtm: {
				event: 'updateProfile',
				category: 'authentication',
			},
		},
		// Builder events
		domain_check: {
			amplitude: 'user.domain-check',
			gtm: {
				event: 'domainCheck',
				category: 'site',
			},
		},
		addCustomDomain: {
			amplitude: 'user.addCustomDomain',
			gtm: {
				event: 'addCustomDomain',
				category: 'site',
			},
		},
		openBlockLibrary: {
			amplitude: 'user.openBlockLibrary',
			gtm: {
				event: 'openBlockLibrary',
				category: 'site',
			},
		},
		addBlockFromLibrary: {
			amplitude: 'user.addBlockFromLibrary',
			gtm: {
				event: 'addBlockFromLibrary',
				category: 'site',
			},
		},
	},
	// User site events
	site: {
		published: {
			amplitude: 'site.published',
			gtm: {
				event: 'publish',
				category: 'site',
			},
		},
		eshopDoNotContainBlock: {
			amplitude: 'site.eshopDoNotContainBlock',
			gtm: {
				event: 'eshopDoNotContainBlock',
				category: 'site',
			},
		},
		'ribbonTooltip(upgrade&save)': {
			'click-cta': {
				amplitude: 'site.ribbonTooltip(upgrade&save).click-cta',
				gtm: {
					event: 'ribbonTooltip(upgrade&save).click-cta',
					category: 'site',
				},
			},
			close: {
				amplitude: 'site.ribbonTooltip(upgrade&save).close',
				gtm: {
					event: 'ribbonTooltip(upgrade&save).close',
					category: 'site',
				},
			},
			loaded: {
				amplitude: 'site.ribbonTooltip(upgrade&save).loaded',
				gtm: {
					event: 'ribbonTooltip(upgrade&save).loaded',
					category: 'site',
				},
			},
		},
	},
	template: {
		'eshop-block-added': {
			amplitude: 'template.eshop-block-added',
			gtm: {
				event: 'eshop-block-added',
				category: 'template',
			},
		},
		load: {
			amplitude: 'template.load',
			gtm: {
				event: 'load',
				category: 'template',
			},
		},
	},
	// AI events
	ai: {
		'select-text': {
			amplitude: 'ai.select-text',
			gtm: {
				event: 'selectText',
				category: 'ai',
			},
		},
		'start-search': {
			amplitude: 'ai.start-search',
			gtm: {
				event: 'startSearch',
				category: 'ai',
			},
		},
	},
	// eshop events
	eshop: {
		trigger_upgrade: {
			amplitude: 'eshop.trigger_upgrade',
			gtm: {
				event: 'trigger_upgrade',
				category: 'eshop',
			},
		},
		trigger_setup: {
			amplitude: 'eshop.trigger_setup',
			gtm: {
				event: 'trigger_setup',
				category: 'eshop',
			},
		},
	},
	// form events
	form: {
		addForm: {
			amplitude: 'form.add_form',
			gtm: {
				event: 'add_form',
				category: 'form',
			},
		},
	},
	// instagram events
	instagram: {
		addInstagram: {
			amplitude: 'instagram.add_instagram',
			gtm: {
				event: 'add_instagram',
				category: 'template',
			},
		},
		connectAccount: {
			amplitude: 'instagram.connect_account',
			gtm: {
				event: 'connect_instagram',
				category: 'template',
			},
		},
	},
	builder: {
		header: { replace_logo: { amplitude: 'builder.header.replace_logo' } },
		slideshow: {
			add: {
				amplitude: 'builder.slideshow.add',
				gtm: {
					event: 'builder.slideshow.add',
					category: 'builder',
				},
			},
			add_new_slide: {
				amplitude: 'builder.slideshow.add_new_slide',
				gtm: {
					event: 'builder.slideshow.add_new_slide',
					category: 'builder',
				},
			},
			duplicate_slide: {
				amplitude: 'builder.slideshow.duplicate_slide',
				gtm: {
					event: 'builder.slideshow.duplicate_slide',
					category: 'builder',
				},
			},
			remove_slide: {
				amplitude: 'builder.slideshow.remove_slide',
				gtm: {
					event: 'builder.slideshow.remove_slide',
					category: 'builder',
				},
			},
		},
		text_element: {
			add: {
				amplitude: 'builder.text_element.add',
				gtm: {
					event: 'builder.text_element.add',
					category: 'builder',
				},
			},
			change_color: {
				amplitude: 'builder.text_element.change_color',
				gtm: {
					event: 'builder.text_element.change_color',
					category: 'builder',
				},
			},
		},
		button_element: {
			add: {
				amplitude: 'builder.button_element.add',
				gtm: {
					event: 'builder.button_element.add',
					category: 'builder',
				},
			},
		},
		image_element: {
			add: {
				amplitude: 'builder.image_element.add',
				gtm: {
					event: 'builder.image_element.add',
					category: 'builder',
				},
			},
			choose_image: { amplitude: 'builder.image_element.choose_image' },
		},
		video_element: {
			add: {
				amplitude: 'builder.video_element.add',
				gtm: {
					event: 'builder.video_element.add',
					category: 'builder',
				},
			},
		},
		gallery_element: {
			add: {
				amplitude: 'builder.gallery_element.add',
				gtm: {
					event: 'builder.gallery_element.add',
					category: 'builder',
				},
			},
			manage: { amplitude: 'builder.gallery_element.manage' },
		},
		image_gallery: {
			set_layout: { amplitude: 'builder.image_gallery.set_layout' },
			add_images: { amplitude: 'builder.image_gallery.add_images' },
		},
		map_element: {
			add: {
				amplitude: 'builder.map_element.add',
				gtm: {
					event: 'builder.map_element.add',
					category: 'builder',
				},
			},
		},
		instagram_feed_element: {
			add: {
				amplitude: 'builder.instagram_feed_element.add',
				gtm: {
					event: 'add_instagram',
					category: 'template',
				},
			},
			connect_account: {
				amplitude: 'builder.instagram_feed_element.add',
				gtm: {
					event: 'connect_instagram',
					category: 'template',
				},
			},
		},
		social_icons_element: { add: { amplitude: 'builder.social_icons_element.add' } },
		form_element: {
			add: {
				amplitude: 'builder.form_element.add',
				gtm: {
					event: 'add_form',
					category: 'form',
				},
			},
		},
		block_library: {
			add_block: {
				amplitude: 'builder.block_library.add_block',
				gtm: {
					event: 'addBlockFromLibrary',
					category: 'site',
				},
			},
		},
		blog: {
			open_sidebar: { amplitude: 'builder.blog.open_sidebar' },
			add_blog_page: { amplitude: 'builder.blog.add_blog_page' },
			insert_blog_list: { amplitude: 'builder.blog.insert_blog_list' },
			create_new_post: { amplitude: 'builder.blog.create_new_post' },
			publish_post: { amplitude: 'builder.blog.publish_post' },
		},
		styles: {
			open: { amplitude: 'builder.styles.open' },
			replace_typography: { amplitude: 'builder.styles.replace_typography' },
			replace_color_palette: { amplitude: 'builder.styles.replace_color_palette' },
		},
		website_importer: {
			open_sidebar: {
				amplitude: 'builder.website_importer.open_sidebar',
				gtm: {
					event: 'builder.website_importer.open_sidebar',
					category: 'builder',
				},
			},
			scan_website: {
				amplitude: 'builder.website_importer.scan_website',
				gtm: {
					event: 'builder.website_importer.scan_website',
					category: 'builder',
				},
			},
		},
		publish_modal: {
			loaded: { amplitude: 'builder.publish_modal.loaded' },
			close: { amplitude: 'builder.publish_modal.close' },
			select_custom_domain: { amplitude: 'builder.publish_modal.select_custom_domain' },
			click_button_continue: {
				custom_domain: { amplitude: 'builder.publish_modal.click_button_continue.custom_domain ' },
				free_domain: { amplitude: 'builder.publish_modal.click_button_continue.free_domain' },
			},
		},
		connect_domain_modal: {
			loaded: { amplitude: 'builder.connect_domain_modal.loaded' },
			click_button_close: { amplitude: 'builder.connect_domain_modal.click_button_close' },
			click_button_choose_plan: { amplitude: 'builder.connect_domain_modal.click_button_choose_plan' },
		},
		published_website_modal: {
			close: { amplitude: 'builder.published_website_modal.close' },
			click_button_see_all_plans: { amplitude: 'builder.published_website_modal.click_button_see_all_plans' },
			click_button_view_site: { amplitude: 'builder.published_website_modal.click_button_view_site' },
		},
		ai_heatmap: {
			generate_heatmap: { amplitude: 'builder.ai_heatmap.generate_heatmap' },
			close: { amplitude: 'builder.ai_heatmap.close' },
		},
		manage_store_modal: {
			click_button: { amplitude: 'builder.manage_store_modal.click_button' },
			close: { amplitude: 'builder.manage_store_modal.close' },
		},
		upgrade_store_modal: {
			click_button: { amplitude: 'builder.upgrade_store_modal.click_button' },
			close: { amplitude: 'builder.upgrade_store_modal.close' },
		},
		storefront: { 'click_button(manage_store)': { amplitude: 'builder.storefront.click_button(manage_store)' } },
	},
	// site settings events
	site_settings: {
		custom_domain: {
			start: { amplitude: 'site_settings.custom_domain.start' },
			enter_domain_name: { amplitude: 'site_settings.custom_domain.enter_domain_name' },
			see_instructions: { amplitude: 'site_settings.custom_domain.see_instructions' },
			verify_connection: { amplitude: 'site_settings.custom_domain.verify_connection' },
			domain_connected: { amplitude: 'site_settings.custom_domain.domain_connected' },
			domain_connection_failed: { amplitude: 'site_settings.custom_domain.domain_connection_failed' },
			remove_domain: { amplitude: 'site_settings.custom_domain.remove_domain' },
		},
		online_store: {
			not_upgraded_click_button: { amplitude: 'site_settings.online_store.not_upgraded_click_button' },
			upgraded_click_button: { amplitude: 'site_settings.online_store.upgraded_click_button' },
		},
	},
	// QA wizard events
	qa_wizard: {
		welcome_screen: {
			generator: {
				amplitude: 'qa_wizard.welcome_screen.generator',
				gtm: {
					event: 'welcome_screen.generator',
					category: 'qa_wizard',
				},
			},
			importer: {
				amplitude: 'qa_wizard.welcome_screen.importer',
				gtm: {
					event: 'welcome_screen.importer',
					category: 'qa_wizard',
				},
			},
		},
		website_importer: {
			enter_domain: {
				amplitude: 'qa_wizard.website_importer.enter_domain',
				gtm: {
					event: 'website_importer.enter_domain',
					category: 'qa_wizard',
				},
			},
			import_done: {
				amplitude: 'qa_wizard.website_importer.import_done',
				gtm: {
					event: 'website_importer.import_done',
					category: 'qa_wizard',
				},
			},
			import_failed: {
				amplitude: 'qa_wizard.website_importer.import_failed',
				gtm: {
					event: 'website_importer.import_failed',
					category: 'qa_wizard',
				},
			},
		},
		website_type: {
			select_category: {
				amplitude: 'qa_wizard.website_type.select_category',
				gtm: {
					event: 'website_type.select_category',
					category: 'qa_wizard',
				},
			},
			select_subcategory: {
				amplitude: 'qa_wizard.website_type.select_subcategory',
				gtm: {
					event: 'website_type.select_subcategory',
					category: 'qa_wizard',
				},
			},
		},
		website_functions: {
			select_function: {
				amplitude: 'qa_wizard.website_functions.select_function',
				gtm: {
					event: 'website_functions.select_function',
					category: 'qa_wizard',
				},
			},
			unselect_function: {
				amplitude: 'qa_wizard.website_functions.unselect_function',
				gtm: {
					event: 'website_functions.unselect_function',
					category: 'qa_wizard',
				},
			},
		},
		website_ready: {
			select_variation: {
				amplitude: 'qa_wizard.website_ready.select_variation',
				gtm: {
					event: 'website_ready.select_variation',
					category: 'qa_wizard',
				},
			},
		},
	},
};

export { eventList as default };
