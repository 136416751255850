import { getSites as getSitesChargeBee } from '@/api/BillingApi';
import { getDesignerBySlug } from '@/api/CuratedApi';
import { getSites } from '@/api/SitesApi';
import { getUser as getUserApi } from '@/api/UsersApi';

const CURATED_TEMPLATES_USER_TYPE = 'DESIGNER';

export default {
	namespaced: true,
	state: {
		user: null,
		isAuthInitiated: false,
		beingRedirectedToSignup: false,
		isUserDesigner: false,
		userHasSites: false,
		userHasTemplates: false,
	},
	getters: {
		isZyroUser: (state) => state.user && /@(zyro|hostinger)\.com$/.test(state.user.email),
		isChargeBeeUser: ({ user }) => !!user?.chargebeeUser,
	},
	mutations: {
		initiateAuth: (state) => {
			state.isAuthInitiated = true;
		},
		setUser: (state, user) => {
			state.user = user;
		},
		setBeingRedirectedToSignup: (state) => {
			state.beingRedirectedToSignup = true;
		},
		setIsUserDesigner: (state) => {
			state.isUserDesigner = state.user.type === CURATED_TEMPLATES_USER_TYPE;
		},
		setIfUserHasSites: (state, hasSites) => {
			state.userHasSites = hasSites;
		},
		setIfUserHasTemplates: (state, hasTemplates) => {
			state.userHasTemplates = hasTemplates;
		},
	},
	actions: {
		getUser: async ({
			state,
			commit,
			getters,
		}) => {
			if (state.isAuthInitiated) {
				return;
			}

			commit('initiateAuth');
			const user = await getUserApi();

			if (user) {
				commit('setUser', user);
				commit('setIsUserDesigner');
			}

			const userSites = getters.isChargeBeeUser ? await getSitesChargeBee() : await getSites();

			if (Array.isArray(userSites) && userSites.some((item) => item.templateId === 'blank')) {
				commit('setIfUserHasSites', true);
			}

			if (user && user.slug) {
				const { data } = await getDesignerBySlug(user.slug);
				const designerTemplates = data[0];

				if (designerTemplates?.templates.templateId) {
					commit('setIfUserHasTemplates', true);
				}
			}
		},
	},
};
