import Vue from 'vue';

import { EventBus } from '@/eventBus';
import {
	createObjectDiff,
	revertChangesByDiff,
} from '@/utils/jsondiffpatch';
import { cloneDeep } from '@/utils/object';

export default class {
	constructor(parameters) {
		this.type = 'ADD';
		this.params = parameters;
		this.pageId = parameters.state.pages.currentPageId;
		this.addToPage = !!parameters.addToPage;
		this.diff = null;
	}

	execute() {
		const {
			state,
			newBlockId,
			newBlock,
			newComponents,
			newSlides,
			previousBlockId,
			addToPage,
		} = this.params;

		const websiteCloneBeforeBlockAdd = cloneDeep(state.website);

		Object.entries(newComponents).forEach(([componentId, componentValue]) => {
			Vue.set(state.website.components, componentId, componentValue);
		});

		// Add slideshow blocks
		Object.entries(newSlides).forEach(([slideId, slideValue]) => {
			Vue.set(state.website.blocks, slideId, slideValue);
		});

		// Add block to blocks in all cases
		Vue.set(state.website.blocks, newBlockId, newBlock);

		// Add to page only if flag is set to true
		if (addToPage) {
			const blockPage = state.website.pages[this.pageId];
			const currentPageBlocks = blockPage.blocks.slice();

			currentPageBlocks.splice(currentPageBlocks.indexOf(previousBlockId) + 1, 0, newBlockId);
			Vue.set(blockPage, 'blocks', currentPageBlocks);
		}

		const websiteCloneAfterAdd = JSON.parse(JSON.stringify(state.website));

		this.diff = createObjectDiff(websiteCloneBeforeBlockAdd, websiteCloneAfterAdd);
	}

	undo() {
		EventBus.$emit('before-undo');

		const { state } = this.params;

		state.website = revertChangesByDiff(state.website, this.diff);

		EventBus.$emit('after-undo');
	}
}
