import Vue from 'vue';

import { EventBus } from '@/eventBus';

export default class {
	constructor(parameters) {
		this.type = 'ADD';
		this.params = parameters;
		this.oldPageBlocks = undefined;
		this.pageId = parameters.state.pages.currentPageId;
		this.addToPage = !!parameters.addToPage;
	}

	execute() {
		const {
			state,
			newBlockId,
			newBlock,
			newComponents,
			previousBlockId,
			addToPage,
		} = this.params;

		Object.entries(newComponents).forEach(([componentId, componentValue]) => {
			Vue.set(state.website.components, componentId, componentValue);
		});
		// Add block to blocks in all cases
		Vue.set(state.website.blocks, newBlockId, newBlock);

		// Add to page only if flag is set to true
		if (addToPage) {
			const blockPage = state.website.pages[this.pageId];
			const currentPageBlocks = blockPage.blocks.slice();

			this.oldPageBlocks = currentPageBlocks.slice();
			currentPageBlocks.splice(currentPageBlocks.indexOf(previousBlockId) + 1, 0, newBlockId);
			Vue.set(blockPage, 'blocks', currentPageBlocks);
		}
	}

	undo() {
		EventBus.$emit('before-undo');
		const {
			state,
			newComponents,
			newBlockId,
			addToPage,
		} = this.params;

		// Remove from page only if block was added to page
		if (addToPage) {
			const blockPage = state.website.pages[this.pageId];

			Vue.set(blockPage, 'blocks', this.oldPageBlocks);
		}

		// Delete block in all cases
		Vue.delete(state.website.blocks, newBlockId);
		// Delete components in all cases
		Object.keys(newComponents).forEach((component) => {
			Vue.delete(state.website.components, component);
		});

		EventBus.$emit('after-undo');
	}
}
