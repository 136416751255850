var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-blog-header"},[_c('div',{staticClass:"block-blog-header__content"},[_c('h1',{staticClass:"font-primary block-blog-header__title block-blog-header__text-block"},[_vm._v(" "+_vm._s(_vm.post.meta.title)+" ")]),(_vm.data.settings.shownItems.description)?_c('p',{staticClass:"font-secondary block-blog-header__text-block"},[_vm._v(" "+_vm._s(_vm.post.meta.description)+" ")]):_vm._e(),(_vm.data.settings.shownItems.categories && _vm.post.customData.categories.length)?_c('BlockBlogListItemCategories',{staticClass:"font-secondary",attrs:{"categories":_vm.post.customData.categories}}):_vm._e(),_c('BlockBlogListItemMeta',_vm._b({staticClass:"block-blog-header__meta"},'BlockBlogListItemMeta',{
				authorName: _vm.userFullName,
				minutesAmount: _vm.post.customData.minutesToRead,
				date: _vm.post.customData.date,
				showAvatar: _vm.data.settings.shownItems.avatar,
				showName: _vm.data.settings.shownItems.authorFullName,
				showDate: _vm.data.settings.shownItems.date,
				showMinutes: _vm.data.settings.shownItems.minutesToRead
			},false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }