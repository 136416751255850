<template>
	<Transition
		appear
		name="slide-right"
	>
		<div
			:class="`pill ${textClass}`"
			:style="computedStyles"
		>
			<div
				class="pill__text"
				:class="{ 'pill__text--uppercase': textToUppercase }"
			>
				{{ text }}
			</div>
			<div
				v-if="subtext"
				class="pill__subtext"
			>
				{{ subtext }}
			</div>
		</div>
	</Transition>
</template>

<script>
import i18n from '@/i18n/setup';

export default {
	props: {
		text: {
			type: String,
			default: i18n.t('common.premium'),
		},
		subtext: {
			type: String,
			default: '',
		},
		textToUppercase: {
			type: Boolean,
			default: true,
		},
		textClass: {
			type: String,
			default: 'z-button-small',
		},
		pillTextColor: {
			type: String,
			default: 'var(--secondary)',
		},
		pillBackgroundColor: {
			type: String,
			default: 'var(--secondary-lighter)',
		},
		pillVerticalPadding: {
			type: String,
			default: '4px',
		},
	},
	computed: {
		computedStyles() {
			return {
				'--pill-text-color': this.pillTextColor,
				'--pill-background-color': this.pillBackgroundColor,
				'--pill-vertical-padding': this.pillVerticalPadding,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.pill {
	display: flex;
	justify-content: space-between;
	padding: var(--pill-vertical-padding) 8px;
	margin-right: 8px;
	color: var(--pill-text-color);
	text-align: center;
	text-transform: none;
	background: var(--pill-background-color);
	border-radius: 100px;

	&--uppercase {
		text-transform: uppercase;
	}

	&__subtext {
		margin-left: 3px;
	}
}
</style>
