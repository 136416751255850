import ALL_LOCALES from './localesTarget.json';

function localeFilter(locale) {
	if (process.env.VUE_APP_ENV_NAME === 'DEV') {
		return locale.isLive || locale.isDev;
	}

	return locale.isLive;
}

export default process.env.VUE_APP_ENV_NAME === 'local' || process.env.VUE_APP_TRANSLATIONS_ENABLED !== 'true' ? [ALL_LOCALES[ALL_LOCALES.length - 1]] : ALL_LOCALES.filter((locale) => localeFilter(locale));
