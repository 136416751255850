/**
 * @todo create and export a single function, that would use all of the below
 */

/**
 * Sets the html document direction
 * @export i18n/utils/document
 * @param {string} locale - locale code
 */
export function setDocumentDirectionPerLocale(locale) {
	document.dir = locale === 'ar' ? 'rtl' : 'ltr';
}

/**
 * Sets html document `lang` attribute
 * @export
 * @param {string} lang - locale to set as the `lang` attribute
 */
export function setDocumentLang(lang) {
	document.documentElement.lang = lang;
}

/**
 * Sets the document title
 * @export
 * @param {string} newTitle - document title to set
 */
export function setDocumentTitle(newTitle) {
	document.title = newTitle;
}
