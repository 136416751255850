var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zyro-input",class:[
		( _obj = {}, _obj[("zyro-input--" + _vm.theme)] = _vm.theme, _obj ),
		{ 'z-body-small': _vm.theme === 'primary' },
		{ 'z-body': _vm.theme === 'secondary' },
		{ 'zyro-input--light' : _vm.color },
		{ 'zyro-input--error' : _vm.error },
		{ 'zyro-input--spinner-disabled' : _vm.isSpinnerDisabled } ],style:(_vm.computedStyles)},[_c('input',_vm._g(_vm._b({directives:[{name:"qa",rawName:"v-qa",value:(_vm.dataQa),expression:"dataQa"}],ref:"inputRef",staticClass:"zyro-input__input",attrs:{"autocomplete":"off","autofocus":_vm.autofocus,"min":_vm.minValue},on:{"keydown":_vm.handleInputControls,"keyup":_vm.resetPressedKeyModifiers}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.suffix)?_c('span',{ref:"inputSuffix",staticClass:"zyro-input__suffix"},[_vm._v(" "+_vm._s(_vm.suffix)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }