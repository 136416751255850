<template>
	<div
		v-click-outside="{
			handler: onClose,
			middleware: () => isOpen,
			events: ['mousedown'],
		}"
		:style="computedStyles"
		class="color-picker"
	>
		<button
			v-show="isPopup"
			ref="toggleButton"
			class="color-picker__toggle-button"
			:title="title"
			@click="toggleColorPicker"
		>
			<div
				:style="{ background: color }"
				class="color-picker__toggle-button__color"
				:class="{ 'active': isOpen }"
			/>
		</button>
		<ColorPickerSettings
			v-show="isOpen || !isPopup"
			:value="value"
			:class="{
				'color-picker__picker-popup': isPopup,
				'color-picker__picker-static': !isPopup,
			}"
			:style="[isPopup ? popupOffset : {}]"
			@input="selectColor"
		/>
	</div>
</template>

<script>
import { Chrome as ColorPickerSettings } from 'vue-color';

const DEFAULT_SIZE = '16px';
const DEFAULT_BORDER_RADIUS = '16px';

export default {
	components: { ColorPickerSettings },
	props: {
		value: {
			type: String,
			default: '#ffffff',
		},
		isPopup: {
			type: Boolean,
			default: true,
		},
		pickerSize: {
			type: String,
			default: DEFAULT_SIZE,
		},
		borderRadius: {
			type: String,
			default: DEFAULT_BORDER_RADIUS,
		},
		title: {
			type: String,
			default: '',
		},
		styleDrawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			color: this.value,
			isOpen: false,
			popupOffset: {
				width: '225px',
				left: '0',
			},
		};
	},
	computed: {
		computedStyles() {
			return {
				'--z-picker-size': this.pickerSize,
				'--z-picker-border-radius': this.borderRadius,
			};
		},
	},
	watch: {
		value(newValue) {
			this.color = newValue;
		},
		isOpen(newValue) {
			if (!newValue) {
				return;
			}

			this.setPosition();
		},
	},
	methods: {
		selectColor(value) {
			const selectedColor = value.rgba.a === 1 ? value.hex : `rgba(${Object.values(value.rgba).join(', ')})`;

			this.color = selectedColor;
			this.$emit('input', this.color);
		},
		setPosition() {
			const toggleButtonOffset = this.$refs.toggleButton.getBoundingClientRect();
			const popupOffsetLeft = toggleButtonOffset.left + Number.parseInt(this.popupOffset.width, 10);

			/*
			 * Custom solution for Color picker posiitoning at Style drawer.
			 * '350' is the width of side bar, where Color picker is placed.
			 */
			const stylesDrawerPickerPosition = (popupOffsetLeft > 350 ? '-200%' : '0');
			const regularPickerPosition = (popupOffsetLeft > window.innerWidth ? `-${this.popupOffset.width}` : '0');

			// TODO Add color picker into tooltip because of positioning.
			this.popupOffset.left = this.styleDrawer ? stylesDrawerPickerPosition : regularPickerPosition;
		},
		onClose() {
			this.isOpen = false;
			this.$emit('close', this.color);
		},
		toggleColorPicker() {
			if (!this.isPopup) {
				return;
			}

			this.isOpen = !this.isOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
.color-picker {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: inherit;
	height: inherit;

	&__toggle-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: inherit;
		height: inherit;
		cursor: pointer;
		background: none;

		&__color {
			width: var(--z-picker-size);
			height: var(--z-picker-size);
			border: 1px solid $grey-300;
			border-radius: var(--z-picker-border-radius);

			&.active {
				border: 1px solid $accent-two;
			}
		}
	}

	&__picker-popup {
		position: absolute;
		top: calc(100% + 10px);
		z-index: 1;
	}

	&__picker-static {
		width: 100%;
		box-shadow: none;
	}
}
</style>
