<template>
	<div
		class="zyro-field-text"
	>
		<ZyroLabel
			v-if="$attrs.label"
			:for="$attrs.id"
			:bold="bold"
			class="zyro-field-text__label"
			:class="{ 'zyro-field-text__label--disabled': disabled }"
			:theme="labelTheme"
			:info-text="infoText"
		>
			{{ $attrs.label }}
		</ZyroLabel>

		<ZyroFieldWrapper>
			<template #prefix>
				<slot name="prefix" />
			</template>

			<ZyroInput
				v-if="inputTag === 'input'"
				v-bind="$attrs"
				class="zyro-field-text__input"
				:disabled="disabled"
				:theme="inputTheme"
				:padding="padding"
				:tag="inputTag"
				:autofocus="autofocus"
				:input-type="inputType"
				:suffix="suffix"
				:focus-on-mount="focusOnMount"
				v-on="$listeners"
			/>
			<ZyroTextArea
				v-else-if="inputTag === 'textarea'"
				v-bind="$attrs"
				class="zyro-field-text__input"
				:disabled="disabled"
				:theme="inputTheme"
				:tag="inputTag"
				:autofocus="autofocus"
				v-on="$listeners"
			/>

			<template #suffix>
				<slot name="suffix" />
			</template>
		</ZyroFieldWrapper>

		<div
			v-if="$attrs.message || $attrs.error"
			class="zyro-field-text__message z-body-small"
			:class="{
				'zyro-field-text__message--error': $attrs.error,
				'zyro-field-text__message--absolute': isMessageAbsolute
			}"
		>
			{{ $attrs.error ? $attrs.error : $attrs.message }}
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		autofocus: {
			type: Boolean,
			default: false,
		},
		bold: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		labelTheme: {
			type: String,
			default: 'primary',
		},
		infoText: {
			type: String,
			default: '',
		},
		inputTheme: {
			type: String,
			default: 'primary',
		},
		inputTag: {
			type: String,
			default: 'input',
			validator: (value) => [
				'input',
				'textarea',
			].includes(value),
		},
		inputType: {
			type: String,
			default: '',
			validator: (value) => [
				'',
				'number',
			].includes(value),
		},
		suffix: {
			type: String,
			default: null,
		},
		focusOnMount: {
			type: Boolean,
			default: false,
		},
		padding: {
			type: String,
			default: '',
		},
		isMessageAbsolute: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-field-text {
	margin-bottom: 16px;

	&__input {
		width: 100%;
	}

	&__message {
		margin-top: 8px;
		color: $grey-800;

		&--error {
			color: $secondary;
		}

		&--absolute {
			position: absolute;
			margin: 0;
		}
	}

	&__label {
		margin: 8px 0;
		line-height: 16px;

		&--disabled {
			color: $grey-800;
		}
	}
}
</style>
