<template>
	<div
		class="zyro-field-shorthand"
		:class="{ 'zyro-field-shorthand--spinner-disabled' : spinnerDisabled }"
	>
		<ZyroLabel
			:bold="bold"
			:for="$options.inputId"
		>
			{{ $attrs.label }}
		</ZyroLabel>
		<div class="zyro-field-shorthand__controls">
			<div
				v-if="showVertical"
				class="zyro-field-shorthand__top-bottom"
			>
				<ZyroLabel
					:for="$options.inputId"
					class="zyro-field-shorthand__label"
					:class="{ 'zyro-field-shorthand__label--disabled': disabled }"
				>
					{{ titleVertical }}
				</ZyroLabel>
				<ZyroInput
					:id="$options.inputId"
					v-model="topBottom"
					v-bind="$attrs"
					class="zyro-field-shorthand__input"
					:disabled="disabled"
					type="number"
					:min-value="0"
					maxlength="3"
					:spinner-disabled="spinnerDisabled"
					:suffix="units"
					:input-type="inputType"
					v-on="$listeners"
				/>
			</div>
			<div
				v-if="showHorizontal"
				class="zyro-field-shorthand__left-right"
			>
				<ZyroLabel
					:for="$options.inputId + '2'"
					class="zyro-field-shorthand__label"
					:class="{ 'zyro-field-shorthand__label--disabled': disabled }"
				>
					{{ titleHorizontal }}
				</ZyroLabel>
				<ZyroInput
					:id="$options.inputId + '2'"
					v-model="leftRight"
					v-bind="$attrs"
					class="zyro-field-shorthand__input"
					:disabled="disabled"
					type="number"
					maxlength="3"
					:min-value="0"
					:spinner-disabled="spinnerDisabled"
					:suffix="units"
					:input-type="inputType"
					v-on="$listeners"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';

import i18n from '@/i18n/setup';
import { parseCSSSides } from '@/utils/parseCSSSides';

const inputId = nanoid();

export default {
	inputId,
	inheritAttrs: false,
	props: {
		bold: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		spinnerDisabled: {
			type: Boolean,
			default: true,
		},
		value: {
			type: String,
			required: true,
		},
		units: {
			type: String,
			validator: (value) => [
				'px',
				'',
			].includes(value),
			default: '',
		},
		showHorizontal: {
			type: Boolean,
			default: true,
		},
		showVertical: {
			type: Boolean,
			default: true,
		},
		titleVertical: {
			type: String,
			default: i18n.t('builder.cssShorthandControlRange.vertical'),
		},
		titleHorizontal: {
			type: String,
			default: i18n.t('builder.cssShorthandControlRange.horizontal'),
		},
		inputType: {
			type: String,
			default: '',
		},
	},
	data() {
		return { showSpinner: false };
	},
	computed: {
		parsedCSSSides() {
			return parseCSSSides(this.value);
		},
		topBottom: {
			get() {
				return Number.parseInt(this.parsedCSSSides.top, 10) || 0;
			},
			set(event) {
				const { value } = event.target;
				const parsedValue = value < 0 ? 0 : Number.parseInt(value, 10);
				const newValue = `${parsedValue || 0}px`;
				const fullPadding = parseCSSSides(this.value);

				fullPadding.top = newValue;
				fullPadding.bottom = newValue;
				this.$emit('update:value', Object.values(fullPadding).join(' '));
			},
		},
		leftRight: {
			get() {
				return Number.parseInt(this.parsedCSSSides.left, 10) || 0;
			},
			set(event) {
				const { value } = event.target;
				const parsedValue = value < 0 ? 0 : Number.parseInt(value, 10);
				const newValue = `${parsedValue || 0}px`;
				const fullPadding = parseCSSSides(this.value);

				fullPadding.left = newValue;
				fullPadding.right = newValue;
				this.$emit('update:value', Object.values(fullPadding).join(' '));
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-field-shorthand {
	padding-top: 12px;
	margin-bottom: 16px;

	&__controls {
		position: relative;
		display: flex;

		> * {
			flex: 0 0 96px;
			max-width: 96px;
			margin-right: 16px;

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button { margin-left: 20px; }
		}
	}

	&__input {
		width: 96px !important;
		padding-right: 8px !important;

		::v-deep {
			padding: 0;
		}
	}

	&__label {
		margin: 8px 0;
		line-height: 16px;
		cursor: pointer;

		&--disabled {
			color: $grey-800;
		}
	}
}
</style>
