var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ZyroButton',{staticClass:"color-button",class:[
		("color-button--" + _vm.type),
		( _obj = {}, _obj[("color-button--selected-" + _vm.type)] = _vm.isSelected, _obj['color-button--border-radius'] =  _vm.hasBorderRadius, _obj ),
		("color-button--hover-" + _vm.type)
	],style:(_vm.computedStyles),attrs:{"title":_vm.$options.convertColorToTitle(_vm.color)},on:{"click":function($event){return _vm.$emit('click', _vm.color)}}},[_c('div',{staticClass:"color-button__color",class:[
			("color-button__color--" + _vm.type),
			( _obj$1 = {}, _obj$1[("color-button__color--selected-" + _vm.type)] = _vm.isSelected, _obj$1 )
		]})])}
var staticRenderFns = []

export { render, staticRenderFns }