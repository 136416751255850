<template>
	<div
		ref="sentry"
		class="sentry"
	/>
</template>

<script>
export default {
	observer: null,
	mounted() {
		this.$options.observer = new IntersectionObserver((entries) => {
			// when sentry is not fully in the viewport:
			this.$emit('toggle-mobile', !entries[0].isIntersecting);
		}, { threshold: 1 });
		this.$options.observer.observe(this.$refs.sentry);
	},
	beforeDestroy() {
		this.$options.observer.unobserve(this.$refs.sentry);
	},
};
</script>

<style lang="scss" scoped>
.sentry {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: $media-grid;
	height: 1px;
	pointer-events: none;
}
</style>
