<template>
	<div>
		<button
			:class="[
				`trigger trigger--${size}`,
				{ 'trigger--seperator': !isVisible && hasSeparator }
			]"
			@click="handleTriggerClick"
		>
			<slot
				name="trigger"
				:isVisible="isVisible"
			/>
			<ZyroSvg
				v-if="collapseIndication === 'chevron'"
				class="trigger__icon"
				:class="isVisible ? 'trigger__icon--flip' : ''"
				name="chevron-down-small"
			/>
			<ZyroToggle
				v-if="collapseIndication === 'toggle'"
				:checked="isVisible"
			/>
		</button>
		<Transition :name="!isVisible ? 'slide-top-to-bottom' : 'slide-bottom-to-top'">
			<slot v-if="isVisible" />
		</Transition>
	</div>
</template>

<script>
export default {
	props: {
		isOpen: {
			type: Boolean,
			default: false,
		},
		collapseIndication: {
			type: String,
			default: 'chevron',
			validator(value) {
				return [
					'chevron',
					'toggle',
				].includes(value);
			},
		},
		size: {
			type: String,
			default: null,
			validator(value) {
				return [
					'large',
					'x-large',
				].includes(value);
			},
		},
		hasSeparator: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { isVisible: false };
	},
	watch: {
		isOpen(newValue) {
			this.isVisible = newValue;
		},
	},
	created() {
		this.isVisible = this.isOpen;
	},
	methods: {
		handleTriggerClick() {
			this.isVisible = !this.isVisible;
			this.$emit('toggle');
		},
	},
};
</script>

<style scoped lang="scss">
.trigger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 4px 0;
	cursor: pointer;

	&--large {
		padding: 12px 0;
	}

	&--x-large {
		padding: 16px 0;
	}

	&__icon {
		&--flip {
			transform: rotateX(180deg);
		}
	}

	&--seperator {
		border-bottom: 1px solid $grey-100;
	}

	&:hover,
	&:focus {
		color: $primary;
	}
}
</style>
