import { nanoid } from 'nanoid';
import Vue from 'vue';

import {
	NAVIGATION_GROUP_ROOT,
	NAVIGATION_GROUP_HIDDEN,
} from '@/use/navigation/constants';
import { merge } from '@/utils/object';

export default {
	namespaced: true,
	state: { navigationHeight: 0 },
	getters: {
		navigation: (state, getters, { website }) => website.navigation,
		items: (state, { navigation }) => navigation.items,
		itemsIds: (state, { navigation }) => navigation.itemsIds,
		hiddenItemsIds: (state, { navigation }) => navigation.hiddenItemsIds,
		getIsItemHidden: (state, { hiddenItemsIds }) => (itemId) => hiddenItemsIds.includes(itemId),
		getItemGroupName: (state, {
			itemsIds,
			hiddenItemsIds,
			items,
		}) => (itemId) => {
			if (itemsIds.includes(itemId)) {
				return NAVIGATION_GROUP_ROOT;
			}

			if (hiddenItemsIds.includes(itemId)) {
				return NAVIGATION_GROUP_HIDDEN;
			}

			const [groupId] = Object.entries(items).find(([, item]) => item.subItems.includes(itemId));

			return groupId;
		},
		getGroupItemsByGroupName: (state, {
			itemsIds,
			hiddenItemsIds,
			items,
		}) => (groupName) => {
			if (groupName === NAVIGATION_GROUP_ROOT) {
				return itemsIds;
			}

			if (groupName === NAVIGATION_GROUP_HIDDEN) {
				return hiddenItemsIds;
			}

			return items[groupName].subItems;
		},
		getItemDataByItemId: (state, {
			getItemGroupName,
			getGroupItemsByGroupName,
			getIsItemHidden,
			items,
		}) => (itemId) => {
			const groupName = getItemGroupName(itemId);

			return {
				groupId: groupName,
				index: getGroupItemsByGroupName(groupName).indexOf(itemId),
				item: items[itemId],
				isHidden: getIsItemHidden(itemId),
			};
		},
	},
	mutations: {
		// Navigation
		updateNavigationHeight: (state, value) => {
			state.navigationHeight = value;
		},
		setIsNavigationHidden: (state, {
			rootState,
			isHidden,
		}) => {
			const { navigation } = rootState.website;

			Vue.set(navigation, 'hidden', isHidden);
		},
		// Navigation items
		setItemsIdsData: (state, {
			rootState,
			data,
		}) => {
			const { navigation } = rootState.website;

			Vue.set(navigation, 'itemsIds', data);
		},
		setItemSubItemsIds: (state, {
			rootState,
			data,
			itemId,
		}) => {
			const { items } = rootState.website.navigation;

			Vue.set(items[itemId], 'subItems', data);
		},
		setHiddenItemsIdsData: (state, {
			rootState,
			data,
		}) => {
			const { navigation } = rootState.website;

			Vue.set(navigation, 'hiddenItemsIds', data);
		},
		addVisibleItem: (state, {
			rootState,
			itemId,
			item,
		}) => {
			const { navigation } = rootState.website;
			const {
				items,
				itemsIds,
			} = navigation;

			Vue.set(items, itemId, item);
			itemsIds.push(itemId);
		},
		addHiddenItem: (state, {
			rootState,
			itemId,
			item,
		}) => {
			const { navigation } = rootState.website;
			const {
				items,
				hiddenItemsIds,
			} = navigation;

			Vue.set(items, itemId, item);
			hiddenItemsIds.push(itemId);
		},
		setItemData: (state, {
			rootState,
			itemId,
			data,
		}) => {
			Vue.set(rootState.website.navigation.items, itemId, data);
		},
		removeItem: (state, {
			rootState,
			itemId,
			groupId,
		}) => {
			const {
				items,
				itemsIds,
				hiddenItemsIds,
			} = rootState.website.navigation;

			if (groupId === NAVIGATION_GROUP_ROOT) {
				Vue.delete(itemsIds, itemsIds.indexOf(itemId));
			} else if (groupId === NAVIGATION_GROUP_HIDDEN) {
				Vue.delete(hiddenItemsIds, hiddenItemsIds.indexOf(itemId));
			} else {
				Vue.delete(items[groupId].subItems, items[groupId].subItems.indexOf(itemId));
			}

			Vue.delete(items, itemId);
		},
	},
	actions: {
		setItemsIdsData: ({
			commit,
			rootState,
		}, { data }) => {
			commit('setItemsIdsData', {
				rootState,
				data,
			});
		},
		setItemSubItemsIds: ({
			commit,
			rootState,
		}, {
			data,
			itemId,
		}) => {
			commit('setItemSubItemsIds', {
				rootState,
				data,
				itemId,
			});
		},
		setHiddenItemsIdsData: ({
			commit,
			rootState,
		}, { data }) => {
			commit('setHiddenItemsIdsData', {
				rootState,
				data,
			});
		},
		updateNavigationVisibility: ({
			commit,
			rootState,
		}) => {
			const { itemsIds } = rootState.website.navigation;

			commit('setIsNavigationHidden', {
				rootState,
				isHidden: !itemsIds.length > 0,
			});
		},
		addItem: ({
			commit,
			dispatch,
			rootState,
		},
		{
			itemId = nanoid(),
			item,
			isHidden = false,
		}) => {
			if (isHidden) {
				commit('addHiddenItem', {
					rootState,
					itemId,
					item,
				});
			} else {
				commit('addVisibleItem', {
					rootState,
					itemId,
					item,
				});
			}

			dispatch('updateNavigationVisibility');
		},
		setItemData: ({
			commit,
			rootState,
		},
		{
			itemId = rootState.pages.currentPageId,
			data,
		}) => {
			const mergedData = merge(rootState.website.navigation.items[itemId], data);

			commit('setItemData', {
				rootState,
				itemId,
				data: mergedData,
			});
		},
		duplicateItem: ({
			dispatch,
			getters,
		}, { itemId }) => {
			const { getItemDataByItemId } = getters;
			const {
				item,
				isHidden,
			} = getItemDataByItemId(itemId);

			dispatch('addItem', {
				item: {
					...item,
					name: `${item.name}Copy`,
					subItems: [],
				},
				isHidden,
			});
		},
		removeItem: ({
			dispatch,
			commit,
			rootState,
			getters,
		}, { itemId }) => {
			// Remove parent item
			const {
				getItemDataByItemId,
				itemsIds,
			} = getters;
			const {
				groupId,
				index,
				item,
			} = getItemDataByItemId(itemId);

			commit('removeItem', {
				rootState,
				itemId,
				groupId,
			});

			// Move subItems to ROOT group, if item has them
			const subItems = [...item.subItems];

			if (item.subItems.length > 0) {
				const newRootItemsIds = [...itemsIds];

				newRootItemsIds.splice(index, 0, ...subItems);
				dispatch('setGroupData', {
					groupId: NAVIGATION_GROUP_ROOT,
					data: newRootItemsIds,
				});

				return;
			}

			dispatch('updateNavigationVisibility');
		},
		showItem: ({
			dispatch,
			getters,
		}, { itemId }) => {
			const {
				itemsIds,
				getIsItemHidden,
				hiddenItemsIds,
			} = getters;
			const isItemHidden = getIsItemHidden(itemId);

			if (!isItemHidden) {
				return;
			}

			dispatch('moveItem', {
				fromId: NAVIGATION_GROUP_HIDDEN,
				toId: NAVIGATION_GROUP_ROOT,
				oldIndex: hiddenItemsIds.indexOf(itemId),
				newIndex: itemsIds.length,
			});
		},
		hideItem: ({
			dispatch,
			getters,
		}, { itemId }) => {
			const {
				getItemDataByItemId,
				hiddenItemsIds,
			} = getters;
			const {
				groupId,
				index,
				item,
			} = getItemDataByItemId(itemId);

			if (item.subItems.length > 0) {
				dispatch('hideItemWithSubItems', {
					itemId,
					oldIndex: index,
					newIndex: hiddenItemsIds.length,
				});

				return;
			}

			dispatch('moveItem', {
				fromId: groupId,
				toId: NAVIGATION_GROUP_HIDDEN,
				oldIndex: index,
				newIndex: hiddenItemsIds.length,
			});
		},
		hideItemWithSubItems: ({
			dispatch,
			getters,
		}, {
			itemId,
			oldIndex,
			newIndex,
		}) => {
			const {
				items,
				hiddenItemsIds,
				itemsIds,
			} = getters;
			const { subItems } = items[itemId];

			const itemsToHide = [
				itemId,
				...subItems,
			];
			const newHiddenItemsIds = [...hiddenItemsIds];

			newHiddenItemsIds.splice(newIndex, 0, ...itemsToHide);

			const visibleItemsIds = [...itemsIds];

			visibleItemsIds.splice(oldIndex, 1);

			// Update hidden items data
			dispatch('setGroupData', {
				groupId: NAVIGATION_GROUP_HIDDEN,
				data: newHiddenItemsIds,
			});
			// Clear item subitems data
			dispatch('setGroupData', {
				groupId: itemId,
				data: [],
			});
			// Remove item from visible items array
			dispatch('setGroupData', {
				groupId: NAVIGATION_GROUP_ROOT,
				data: visibleItemsIds,
			});
		},
		moveItem: ({
			dispatch,
			getters,
		}, {
			fromId,
			toId,
			oldIndex,
			newIndex,
		}) => {
			const { getGroupItemsByGroupName } = getters;
			const fromGroup = getGroupItemsByGroupName(fromId);
			const toGroup = getGroupItemsByGroupName(toId);
			const item = fromGroup[oldIndex];

			fromGroup.splice(oldIndex, 1);
			toGroup.splice(newIndex, 0, item);

			dispatch('setGroupData', {
				groupId: fromId,
				data: fromGroup,
			});
			dispatch('setGroupData', {
				groupId: toId,
				data: toGroup,
			});
		},
		setGroupData: ({ dispatch }, {
			groupId,
			data,
		}) => {
			if (groupId === NAVIGATION_GROUP_ROOT) {
				dispatch('setItemsIdsData', { data });
			} else if (groupId === NAVIGATION_GROUP_HIDDEN) {
				dispatch('setHiddenItemsIdsData', { data });
			} else {
				dispatch('setItemSubItemsIds', {
					data,
					itemId: groupId,
				});
			}

			dispatch('updateNavigationVisibility');
		},
	},
};
