<template>
	<div
		ref="galleryLayoutRef"
		class="gallery-manager"
	>
		<ImagesControlBar
			v-if="!isEmpty"
			is-gallery-display
			@open-asset-manager="$emit('open-asset-manager')"
		/>
		<HorizontalMasonry
			v-if="!isEmpty"
			:images="imageObject"
			@remove-from-gallery="removeImageFromGallery"
			@move-image="moveImage"
			@cancel-upload="cancelUpload"
		/>
		<DragDropOverlay
			v-else
			is-gallery
			class="gallery-manager__empty"
			@open-asset-manager="$emit('open-asset-manager')"
		/>
	</div>
</template>
<script>
import {
	onMounted,
	ref,
} from '@vue/composition-api';
import {
	mapMutations,
	mapState,
} from 'vuex';

import HorizontalMasonry from '@/components/builder-modals/modals/asset-manager/gallery/HorizontalMasonry.vue';
import { useUploadImages } from '@/components/builder-modals/modals/asset-manager/useUploadImages';
import DragDropOverlay from '@/components/builder-modals/modals/asset-manager/user/DragDropOverlay.vue';
import { filterObject } from '@/utils/object';

import ImagesControlBar from './user/ImagesControlBar.vue';

export default {
	components: {
		ImagesControlBar,
		HorizontalMasonry,
		DragDropOverlay,
	},
	props: {
		galleryId: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const {
			cancelUpload,
			listenForDragAndDrop,
			removeImage,
			uploadedImages,
			uploadValidFiles,
		} = useUploadImages(props);
		const galleryLayoutReference = ref(null);

		onMounted(() => {
			listenForDragAndDrop(galleryLayoutReference.value);
		});

		return {
			cancelUpload,
			galleryLayoutRef: galleryLayoutReference,
			removeImage,
			uploadedImages,
			uploadValidFiles,
		};
	},
	computed: {
		...mapState(['website']),
		images() {
			return this.website.components[this.galleryId].images;
		},
		uploadedToThisGallery() {
			return filterObject(
				this.uploadedImages,
				({ value }) => value.galleryId === this.galleryId,
			);
		},
		imageObject() {
			return {
				...this.images.map((image) => ({ url: image })),
				...this.uploadedToThisGallery,
			};
		},
		isEmpty() {
			return Object.keys(this.imageObject).length === 0;
		},
	},
	watch: {
		// Push images to gallery after uploading them
		uploadedToThisGallery(newValue) {
			const images = [...this.images];

			Object.entries(newValue).forEach(([imageId, image]) => {
				if (image.url) {
					// Add image url to gallery and remove uploaded image
					images.push(image.url);
					this.removeImage(imageId);
					this.setElementData({
						data: { images },
						skipHistory: false,
					});
				}
			});
		},
	},
	methods: {
		...mapMutations(['setElementData']),
		moveImage(settings) {
			const images = [...this.images];

			// Swap places
			images.splice(settings.newIndex, 0, images.splice(settings.oldIndex, 1)[0]);
			this.setElementData({
				data: { images },
				skipHistory: false,
			});
		},
		removeImageFromGallery(imageIndex) {
			const images = [...this.images];

			images.splice(imageIndex, 1);
			this.setElementData({
				data: { images },
				skipHistory: false,
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.gallery-manager {
	height: 100%;
	padding: 0 20px;

	&__empty {
		height: 100%;
	}
}
</style>
