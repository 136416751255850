import { createNamespacedHelpers } from 'vuex';

import { getGoogleFonts } from '@/api/GoogleFontsApi';
import {
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
} from '@/constants/globalStyles';
import { WEB_SAFE_FONTS } from '@/constants/webSafeFonts';
import {
	NOTIFICATIONS_NAMESPACE,
	NOTIFY,
} from '@/store/builder/notifications';
import { isDevelopment } from '@/utils/flags';
import {
	constructMetaFont,
	convertWeightStringToNumber,
	filterAvailableFontWeightVariants,
	pickUsedFontWeights,
	websiteFontNames,
} from '@/utils/font';

const AVAILABLE_FONT_WEIGHTS = [
	100,
	200,
	300,
	400,
	500,
	600,
	700,
	800,
	900,
];

// namespacing to use with `createNamespacedHelpers`
export const FONTS_NAMESPACE = 'fonts';

// action type constants:
export const FETCH_GOOGLE_FONTS = 'FETCH_GOOGLE_FONTS';
export const UPDATE_FONT_STYLES = 'UPDATE_FONT_STYLES';
export const UPDATE_CURRENT_FONT_STYLES = 'UPDATE_CURRENT_FONT_STYLES';

// mutation type constants:
export const SET_LOADING_FONTS = 'SET_LOADING_FONTS';
export const SET_FONTS = 'SET_FONTS';
export const SET_FONT_STYLES = 'SET_FONT_STYLES';

export const { mapState: mapStateFonts } = createNamespacedHelpers(FONTS_NAMESPACE);
export const { mapActions: mapActionsFonts } = createNamespacedHelpers(FONTS_NAMESPACE);
export const { mapMutations: mapMutationsFonts } = createNamespacedHelpers(FONTS_NAMESPACE);

export default {
	namespaced: true,
	state: {
		fonts: [],
		isLoadingFonts: false,
		fontStyles: {
			primary: {
				subsets: [],
				variants: [],
				family: '',
				category: '',
			},
			secondary: {
				subsets: [],
				variants: [],
				family: '',
				category: '',
			},
		},
	},
	getters: {
		getAvailableFontWeights(state) {
			const { fontStyles } = state;
			const primaryWeights = fontStyles[PROPERTY_FONT_PRIMARY].variants
				.filter((variant) => AVAILABLE_FONT_WEIGHTS.includes(convertWeightStringToNumber(variant)));
			const secondaryWeights = fontStyles[PROPERTY_FONT_SECONDARY].variants
				.filter((variant) => AVAILABLE_FONT_WEIGHTS.includes(convertWeightStringToNumber(variant)));

			return {
				[PROPERTY_FONT_PRIMARY]: primaryWeights,
				[PROPERTY_FONT_SECONDARY]: secondaryWeights,
			};
		},
		getUsedFontWeights(state, getters, rootState) {
			return pickUsedFontWeights(rootState.website.styles);
		},
		getFontNames(state, getters, rootState) {
			return websiteFontNames(rootState.website.styles.font);
		},
		getMetaFont(state, getters, rootState, rootGetters) {
			const shouldUseAllFontWeights = rootState.user.isUserDesigner
				|| isDevelopment
				|| rootGetters['user/isZyroUser'];

			const fontNames = getters.getFontNames;
			const fontWeights = shouldUseAllFontWeights
				? {
					primary: AVAILABLE_FONT_WEIGHTS,
					secondary: AVAILABLE_FONT_WEIGHTS,
				}
				: getters.getUsedFontWeights;

			return constructMetaFont(fontNames, fontWeights);
		},
	},
	mutations: {
		[SET_LOADING_FONTS]: (state, value) => {
			state.isLoadingFonts = value;
		},
		[SET_FONTS]: (state, fonts) => {
			state.fonts = fonts;
		},
		[SET_FONT_STYLES]: (state, fontStyles) => {
			state.fontStyles = {
				...state.fontStyles,
				...fontStyles,
			};
		},
	},
	actions: {
		[FETCH_GOOGLE_FONTS]: async ({
			commit,
			dispatch,
			state,
		}) => {
			if (state.fonts.length > 0) {
				return;
			}

			commit(SET_LOADING_FONTS, true);

			try {
				const { items } = await getGoogleFonts();
				const fonts = items.map(({
					family,
					category,
					variants,
					subsets,
				}) => ({
					family,
					category,
					variants,
					subsets,
				}));
				const allFonts = [
					...fonts,
					...WEB_SAFE_FONTS,
				].sort((a, b) => a.family.localeCompare(b.family));

				commit(SET_FONTS, allFonts);
				dispatch(UPDATE_CURRENT_FONT_STYLES);
			} catch {
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					origin: 'Vuex fonts store',
					message: 'We couldn\'t load Google fonts at the moment. Please reload this page.',
				}, { root: true });
			} finally {
				commit(SET_LOADING_FONTS, false);
			}
		},
		[UPDATE_FONT_STYLES]: ({
			commit,
			state,
		}, {
			family: passedFamily,
			type,
		}) => {
			const fontStyles = state.fonts.find(({ family }) => family === passedFamily);

			commit(SET_FONT_STYLES, {
				[type]: {
					...fontStyles,
					variants: filterAvailableFontWeightVariants(fontStyles.variants),
				},
			});
		},
		[UPDATE_CURRENT_FONT_STYLES]: ({
			commit,
			state,
			getters,
		}) => {
			const { fonts } = state;
			const fontNames = getters.getFontNames;
			const primaryFontStyles = fonts
				.find(({ family }) => family === fontNames[PROPERTY_FONT_PRIMARY]);
			const secondaryFontStyles = fonts
				.find(({ family }) => family === fontNames[PROPERTY_FONT_SECONDARY]);

			commit(SET_FONT_STYLES, {
				primary: {
					...primaryFontStyles,
					variants: filterAvailableFontWeightVariants(primaryFontStyles.variants),
				},
				secondary: {
					...secondaryFontStyles,
					variants: filterAvailableFontWeightVariants(secondaryFontStyles.variants),
				},
			});
		},
	},
};
