import {
	ref,
	computed,
} from '@vue/composition-api';

import { getMedia } from '@/api/InstagramApi';
import { getRandom } from '@/api/UnsplashApi';

export const useGridInstagramFeed = (props, context, {
	getInstagramTokenHandler,
	cleanupCallback,
}) => {
	const limit = computed(() => props.data.settings['item-count']);
	const token = ref(null);
	const responseResult = ref([]);

	const media = computed(() => (responseResult.value.length > 0
		? responseResult.value.slice(0, limit.value)
		: new Array(limit.value).fill({})));

	const getInstagramMedia = async () => {
		const { data } = await getMedia('id,caption,media_url', token.value);

		responseResult.value = data.map((item) => ({
			id: item.id,
			src: item.media_url,
			alt: item.caption,
		}));
	};

	const getUnsplashMedia = async () => {
		const { randomImagesTheme } = props.data.settings;
		const { results } = await getRandom(randomImagesTheme ? { query: randomImagesTheme } : {});

		responseResult.value = results.map((item) => ({
			id: item.id,
			src: item.urls.thumb,
			alt: item.alt_description,
		}));
	};

	const init = async () => {
		try {
			token.value = await getInstagramTokenHandler(props.id);
			await getInstagramMedia();
		} catch {
			getUnsplashMedia();

			if (cleanupCallback) {
				cleanupCallback(token.value);
			}
		} finally {
			token.value = null;
		}
	};

	return {
		media,
		init,
		itemGap: computed(() => props.data.settings.styles['item-gap']),
	};
};

/**
 * TODO:
 * cache unsplash response
 * preconnect instagram to head
 * proper multi-step error handling
 * refactor overall logic - missign token should not fall back to unsplash by default
 */
