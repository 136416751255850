export default {
	namespaced: true,
	state: {
		history: [],
		redoHistory: [],
		customHistoryIndex: null,
		customHistoryRedoIndex: null,
	},
	getters: {
		isUndoDisabled: (state) => {
			let currentHistoryIndex = state.history.length;

			if (state.customHistoryIndex > 0) {
				currentHistoryIndex -= state.customHistoryIndex;
			}

			return currentHistoryIndex <= 0;
		},
		isRedoDisabled: (state) => {
			let currentHistoryIndex = state.redoHistory.length;

			if (state.customHistoryRedoIndex > 0) {
				currentHistoryIndex -= state.customHistoryRedoIndex;
			}

			return currentHistoryIndex <= 0;
		},
	},
	mutations: {
		undoWebsite: (state) => {
			if (state.history.length > 0) {
				const action = state.history.pop();

				action.undo();
				state.redoHistory.push(action);
			}
		},
		redoWebsite: (state) => {
			if (state.redoHistory.length > 0) {
				const action = state.redoHistory.pop();

				action.execute();
				state.history.push(action);
			}
		},
		lockCustomHistoryIndex: (state) => {
			state.customHistoryIndex = state.history.length;
			state.customHistoryRedoIndex = state.redoHistory.length;
		},
		unlockCustomHistoryIndex: (state) => {
			state.history.splice(state.customHistoryIndex);
			state.redoHistory.splice(state.customHistoryRedoIndex);
			state.customHistoryIndex = null;
			state.customHistoryRedoIndex = null;
		},
	},
};
