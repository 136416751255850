/* eslint-disable no-underscore-dangle */

import amplitudeApi from 'amplitude-js';
import axios from 'axios';

import eventList from '@/data/analyticEventList';

import { trackEvent } from './GoogleTagManagerApi';

if (process.env.VUE_APP_AMPLITUDE_API_KEY) {
	amplitudeApi.getInstance().init(process.env.VUE_APP_AMPLITUDE_API_KEY);
}

export default {
	logEvent({
		eventName,
		eventProperties = {},
		amplitude = true,
		googleTagManger = true,
	}) {
		const event = eventList.getEvent(eventName);

		if (!event || !event.amplitude) {
			console.error(`[EVENT LOGGING ERROR] \nEvent with name - ${eventName} not found in event list or wrong event object structure`);

			return;
		}

		try {
			if (amplitude) {
				if (!amplitudeApi.getInstance().options.deviceId) {
					amplitudeApi.getInstance().regenerateDeviceId();
				}

				const userDetails = amplitudeApi.getInstance();
				const updatedEventProperties = {
					...eventProperties,
					gtmBlocked: !window.google_tag_manager,
				};

				axios.post(`${process.env.VUE_APP_API_URL}/v3/events`, {
					eventName: 'amplitude',
					event: {
						event_type: event.amplitude,
						device_id: userDetails.options.deviceId,
						platform: userDetails.options.platform || '',
						os_name: userDetails._ua.browser.name || '',
						os_version: userDetails._ua.browser.version || '',
						device_manufacturer: userDetails._ua.device.vendor || '',
						device_model: userDetails._ua.os.name || '',
						language: userDetails.options.language || '',
						session_id: userDetails._sessionId || '',
						event_properties: updatedEventProperties,
					},
				});
			}

			if (googleTagManger) {
				trackEvent({
					...event.gtm,
					...eventProperties,
				});
			}
		} catch {
			console.error('Error while sending event to analytics 3rd party API');
		}
	},
};
