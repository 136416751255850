import axios from 'axios';

const ASSETS_API = `${process.env.VUE_APP_API_URL}/v1/assets/`;

const headers = { 'Content-Type': 'multipart/form-data' };

const handleMetaImage = (formData, endpoint) => axios.post(`${ASSETS_API}${endpoint}`, formData, { headers });

export const getAssets = async () => (await axios(ASSETS_API)).data;

export const deleteAssets = async (imageNameArray) => axios.delete(`${ASSETS_API}remove-images`, { data: { images: imageNameArray } });

export const uploadFavicon = (formData) => handleMetaImage(formData, 'favicon');

export const uploadOgImage = (formData) => handleMetaImage(formData, 'ogimage');
