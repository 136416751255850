<template>
	<GridTextBox
		@click="handleClick"
		v-html="data.content"
	/>
</template>

<script>
import { mapState } from 'vuex';

import GridTextBox from '@user/components/grid-components/textBox/GridTextBox.vue';
import { scrollToAnchorTag } from '@user/utils/anchorTag';

export default {
	name: 'GridTextBoxProviderUser',
	components: { GridTextBox },
	props: {
		data: {
			type: Object,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			required: false,
		},
	},
	computed: { ...mapState(['website']) },
	methods: {
		handleClick(event) {
			const href = event.target.getAttribute('href');

			scrollToAnchorTag(href, event);

			const internalLink = event.target.closest('[data-internal-link]');

			if (!internalLink) {
				return;
			}

			// If internal page link - use $router instead of default <a> tag behavior
			event.preventDefault();
			const { pages } = this.website;
			const pageId = internalLink.dataset.internalLink;
			const pagePath = pages[pageId]?.path;

			if (pagePath && !this.isPreviewMode) {
				this.$router.push(pagePath);
			}

			if (this.isPreviewMode) {
				event.preventDefault();
				this.$store.dispatch('pages/changePage', { pageId });
			}
		},
	},
};
</script>
