import Vue from 'vue';

import { EventBus } from '@/eventBus';
import {
	createObjectDiff,
	revertChangesByDiff,
} from '@/utils/jsondiffpatch';
import { cloneDeep } from '@/utils/object';

export default class {
	constructor(parameters) {
		this.type = 'REMOVE';
		this.params = parameters;
		this.pageId = parameters.state.pages.currentPageId;
		this.diff = null;
	}

	execute() {
		const {
			state,
			blockId,
		} = this.params;
		const websiteCloneBeforeChanges = cloneDeep(state.website);

		// Get current block page
		const blockPage = state.website.pages[this.pageId];
		// Slice shallow copies the array
		const currentPageBlocks = blockPage.blocks.slice();

		// Make array of blocks without removed block
		const newPageBlocks = currentPageBlocks.filter((id) => id !== blockId);

		// Remove block from current page blocks
		Vue.set(blockPage, 'blocks', newPageBlocks);

		// Delete slide components and slides blocks
		state.website.blocks[blockId].slides.forEach((slide) => {
			state.website.blocks[slide.blockId].components.forEach((componentId) => {
				Vue.delete(state.website.components, componentId);
			});

			Vue.delete(state.website.blocks, slide.blockId);
		});

		Vue.delete(state.website.blocks, blockId);

		this.diff = createObjectDiff(websiteCloneBeforeChanges, state.website);
	}

	undo() {
		EventBus.$emit('before-undo');
		const { state } = this.params;

		state.website = revertChangesByDiff(state.website, this.diff);

		EventBus.$emit('after-undo');
	}
}
