<template>
	<div
		ref="colorButton"
		class="color-picker"
	>
		<ColorButton
			v-if="!isIntegrated && !isPopup"
			ref="colorButton"
			:color="value"
			:color-dimensions="colorDimensions"
			has-border-radius
			data-qa="builder-colorpicker-toggle-btn"
			@click="toggleColorPicker"
		/>
		<slot
			name="trigger"
			:toggle-color-picker="toggleColorPicker"
		/>
		<Transition name="popup">
			<Component
				:is="isPortal ? 'Portal' : 'div'"
				to="color-picker"
			>
				<!-- :key=$vnode.key takes :key passed when mapping color picker buttons for Portal use case
				this is needed because when portal is used there is a slight delay
				when 2 color pickers are in DOM so events would still be passed
				and outside click would trigger
				-->
				<Component
					:is="$options.COLOR_PICKER_COMPONENT[mode]"
					ref="colorPickerPopup"
					:key="$vnode.key"
					v-click-outside="{
						handler: closeColorPicker,
						middlware: beforeClose,
						events: ['click'],
					}"
					class="color-picker__mode"
					:class="computedColorPickerClasses"
					:style="computedStyles"
					:selected-color="currentColor"
					:is-integrated="isIntegrated"
					:default-mode="defaultMode"
					:show-footer="showFooter"
					:is-full="isFull"
					:disable-alpha="disableAlpha"
					is-absolute
					@save="onSave"
					@select-color="$emit('input', $event), currentColor = $event"
					@change-mode="changeMode($event)"
					@hook:mounted="setPopupPosition"
				/>
			</Component>
		</Transition>
	</div>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';

import {
	COLOR_PICKER_MODE_MINIMAL,
	COLOR_PICKER_MODE_FULL,
	COLOR_PICKER_MODE_INTEGRATED,
	COLOR_PICKER_MODE_CUSTOM_COLOR,
	COLOR_PICKER_COMPONENT,
} from '@/components/global/zyro-color-picker/constants';
import ColorButton from '@/components/global/zyro-color-picker/misc/ColorButton.vue';
import positioning, { isWithinScreen } from '@/utils/positioning';

const POPUP_POSITION_BOTTOM = 'bottom';
const POPUP_POSITION_TOP = 'top';

export default {
	COLOR_PICKER_COMPONENT,
	components: {
		ColorButton,
		Full: () => import('@/components/global/zyro-color-picker/Full.vue'),
		Minimal: () => import('@/components/global/zyro-color-picker/Minimal.vue'),
		ColorPicker: () => import('@/components/global/zyro-color-picker/ColorPicker.vue'),
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		defaultMode: {
			type: String,
			default: COLOR_PICKER_MODE_MINIMAL,
			validator(value) {
				return [
					COLOR_PICKER_MODE_MINIMAL,
					COLOR_PICKER_MODE_FULL,
					COLOR_PICKER_MODE_INTEGRATED,
					COLOR_PICKER_MODE_CUSTOM_COLOR,
				].includes(value);
			},
		},
		colorDimensions: {
			type: String,
			default: '16px',
		},
		isPortal: {
			type: Boolean,
			default: false,
		},
		isPopup: {
			type: Boolean,
			default: false,
		},
		disableAlpha: {
			type: Boolean,
			default: false,
		},
		showFooter: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mode: null,
			currentColor: this.value,
			startingColor: this.value,
			previousMode: null,
			popupPosition: POPUP_POSITION_BOTTOM,
			manualPopupPosition: {
				top: 0,
				left: 0,
			},
		};
	},
	computed: {
		...mapState('gui', [
			'mobilePreviewRef',
			'desktopPreviewRef',
			'isMobileView',
		]),
		computedColorPickerClasses() {
			if (this.isIntegrated) {
				return '';
			}

			if (this.isPortal) {
				return 'color-picker__mode--manual-position';
			}

			return [
				'color-picker__mode--position',
				`color-picker__mode--position-${this.popupPosition}`,
				{ 'color-picker__mode--position-full': this.isFull },
			];
		},
		isFull: ({ defaultMode }) => defaultMode === COLOR_PICKER_MODE_FULL,
		isMinimal: ({ defaultMode }) => defaultMode === COLOR_PICKER_MODE_MINIMAL,
		isCustomColorPicker: ({ defaultMode }) => defaultMode === COLOR_PICKER_MODE_CUSTOM_COLOR,
		isIntegrated: ({ defaultMode }) => defaultMode === COLOR_PICKER_MODE_INTEGRATED,
		computedStyles() {
			return {
				'--manual-popup-top': this.manualPopupPosition.top,
				'--manual-popup-left': this.manualPopupPosition.left,
			};
		},
	},
	mounted() {
		if (this.isIntegrated) {
			this.mode = COLOR_PICKER_MODE_FULL;
		}
	},
	methods: {
		...mapActions('colors', ['addRecentColor']),
		toggleColorPicker() {
			this.mode = this.mode ? null : this.defaultMode;
		},
		toggleColorPickerIntegrated() {
			this.mode = this.mode === COLOR_PICKER_MODE_CUSTOM_COLOR
				? COLOR_PICKER_MODE_FULL : COLOR_PICKER_MODE_CUSTOM_COLOR;
		},
		closeColorPicker() {
			if (this.isIntegrated) {
				return;
			}

			this.mode = null;

			this.$emit('close', this.currentColor);
		},
		beforeClose() {
			this.popupPosition = POPUP_POSITION_BOTTOM;
			this.closeColorPicker();
		},
		onSave() {
			this.mode = null;

			if (this.startingColor !== this.currentColor) {
				this.$emit('save', this.currentColor);
			}
		},
		changeMode(newMode) {
			this.popupPosition = POPUP_POSITION_BOTTOM;

			if (this.isIntegraded) {
				this.toggleColorPickerIntegrated();

				return;
			}

			if (this.isFull) {
				this.mode = newMode;

				return;
			}

			if (this.isCustomColorPicker) {
				this.closeColorPicker();

				return;
			}

			const isSwitchingFromFullToMinimal = this.mode === COLOR_PICKER_MODE_CUSTOM_COLOR
				&& this.previousMode === COLOR_PICKER_MODE_MINIMAL;

			if (isSwitchingFromFullToMinimal) {
				this.previousMode = null;
				this.mode = COLOR_PICKER_MODE_MINIMAL;

				return;
			}

			this.previousMode = this.mode;
			this.mode = newMode;
		},
		setPopupPosition() {
			if (this.mode === COLOR_PICKER_MODE_MINIMAL || !this.mode) {
				return;
			}

			if (this.isPortal) {
				this.manualPopupPosition = positioning.positionElemPopupDesktop(
					this.$refs.colorButton,
					this.desktopPreviewRef,
					this.$refs.colorPickerPopup.$el,
				);
			}

			const currentPreviewReference = this.isMobileView ? this.mobilePreviewRef : this.desktopPreviewRef;
			const elementOffsetInViewPort = positioning
				.getElementOffset(this.$refs.colorPickerPopup.$el, currentPreviewReference);
			const isEnoughSpaceTop = elementOffsetInViewPort.top < elementOffsetInViewPort.height;

			if (isEnoughSpaceTop) {
				this.popupPosition = POPUP_POSITION_BOTTOM;

				return;
			}

			const isPopupWithinScreen = isWithinScreen(this.$refs.colorPickerPopup.$el);

			this.popupPosition = isPopupWithinScreen ? POPUP_POSITION_BOTTOM : POPUP_POSITION_TOP;
		},
	},
};
</script>

<style lang="scss" scoped>
.color-picker {
	position: relative;

	&__mode {
		&--position {
			position: absolute;
			left: 50%;
			transform: translate(-50%);
		}

		&--position-top {
			bottom: calc(100% + 1px);
		}

		&--position-bottom {
			top: calc(100% + 1px);
		}

		&--position-full {
			right: 0;
			left: unset;
			transform: none;
		}

		&--manual-position:not(.s) {
			top: var(--manual-popup-top);
			left: var(--manual-popup-left);
			z-index: z-index(layout--sidebar-popup);
		}
	}
}
</style>
