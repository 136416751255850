<template>
	<ZyroModal
		max-width="1200px"
		max-height="90vh"
		:title="isGalleryManagerOpen ?
			$t('builder.assetManager.gallery.gallery') :
			$t('builder.assetManager.chooseImage')"
		content-padding="0"
		no-overflow
		use-flex-for-content
		@close-modal="closeModal(), $emit('close')"
	>
		<GalleryManager
			v-if="isGalleryManagerOpen"
			:gallery-id="galleryId"
			@open-asset-manager="isGalleryManagerOpen = false"
		/>
		<template v-else>
			<div
				class="asset-manager-navigation"
			>
				<ZyroButton
					v-if="showBackButton"
					class="asset-manager-navigation-back"
					theme="plain"
					color="black"
					icon-left="chevron-left"
					@click="showBackButton = false"
				>
					{{ $t('builder.assetManager.backToList') }}
				</ZyroButton>
				<ZyroTabs
					v-else
					:tabs="$options.TABS"
					:current-tab="currentTab"
					@change="currentTab = $event"
				/>
			</div>
			<UnsplashLayout
				v-show="currentTab.id === $options.UNSPLASH_TAB_ID"
				:is-preview-open="showBackButton"
				:is-gallery="isGallery"
				@toggle-preview="showBackButton = $event"
				@select-image="selectImage"
			/>
			<UserLayout
				v-show="currentTab.id === $options.USER_TAB_ID"
				:is-gallery="isGallery"
				@select-image="selectImage"
				@select-images="selectImages"
			/>
		</template>
	</ZyroModal>
</template>

<script>
import { mapMutations } from 'vuex';

import { logDownload } from '@/api/UnsplashApi';
import i18n from '@/i18n/setup';

import GalleryManager from './asset-manager/GalleryManager.vue';
import UnsplashLayout from './asset-manager/unsplash/UnsplashLayout.vue';
import UserLayout from './asset-manager/user/UserLayout.vue';

const UNSPLASH_TAB_ID = 'unsplash';
const USER_TAB_ID = 'userImages';
const TABS = [
	{
		title: i18n.t('builder.assetManager.tabs.sitePhotos'),
		id: USER_TAB_ID,
	},
	{
		title: i18n.t('builder.assetManager.tabs.freePhotos'),
		id: UNSPLASH_TAB_ID,
	},
];

export default {
	UNSPLASH_TAB_ID,
	USER_TAB_ID,
	TABS,
	components: {
		GalleryManager,
		UnsplashLayout,
		UserLayout,
	},
	props: {
		isGallery: {
			type: Boolean,
			default: false,
		},
		galleryId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			showBackButton: false,
			currentTab: TABS[0],
			isGalleryManagerOpen: false,
		};
	},
	created() {
		this.isGalleryManagerOpen = this.isGallery;
	},
	methods: {
		...mapMutations('gui', ['closeModal']),
		selectImage(image) {
			const isUnsplashCurrent = this.currentTab.id === UNSPLASH_TAB_ID;

			if (isUnsplashCurrent) {
				// Needed by Unsplash TOS whenever image is selected
				logDownload({ imageJson: image });
			}

			// https://unsplash.com/documentation#supported-parameters
			const UNSPLASH_QUALITY_SETTINGS = '&w=1366&q=70&auto=format';

			// Get image url
			const imageUrl = isUnsplashCurrent
				? `${image.urls.raw}${UNSPLASH_QUALITY_SETTINGS}`
				: image;

			// reset alt for user image, set alt for unsplash image
			const alt = isUnsplashCurrent ? image.alt_description : '';

			if (this.isGallery) {
				// Go back to gallery manager if in gallery mode
				this.isGalleryManagerOpen = true;
				this.resetState();
			}

			this.$emit('select-image', {
				url: imageUrl,
				alt,
			});
		},
		// No need to handle unsplash here
		selectImages(images) {
			const imageObjects = images.map((image) => ({
				url: image.url,
				alt: '',
			}));

			this.$emit('select-images', imageObjects);
			this.isGalleryManagerOpen = true;
			this.resetState();
		},
		resetState() {
			[this.currentTab] = TABS;
			this.showBackButton = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.asset-manager-navigation {
	padding: 0 24px;

	&-back {
		margin-bottom: 12px;
	}
}
</style>
