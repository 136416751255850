import {
	getPlan as getPlanChargeBeeApi,
	getProducts as getProductsChargeBeeApi,
	getCurrentSiteSubscription as getCurrentSiteSubscriptionChargeBee,
	getAllSubscriptions as getAllSubscriptionsChargeBeeApi,
	activatePlan as activatePlanChargeBeeApi,
} from '@/api/BillingApi';
import EventLogApi from '@/api/EventLogApi';
import { getProducts as getProductsApi } from '@/api/PricingApi';
import {
	getPlan as getPlanApi,
	getAllSubscriptions as getAllSubscriptionsApi,
	getCurrentSiteSubscription,
	activatePlan as activatePlanApi,
} from '@/api/SubscriptionsApi';
import { getLocaleObjectFromLocalStorage } from '@/utils/i18n/localStorageLocale';
import { getPriceFormatter } from '@/utils/i18n/priceFormatter';
// TODO: all plan data, including groups, ID's etc should be global throughout all the projects
const UNUSED_PLAN = 'UNUSED';
const BASIC_PLAN = 'BASIC';
const ECOMMERCE_PLAN = 'ECOMMERCE';

const ACTIVE_SUBSCRIPTION_STATUS = 'ACTIVE';

const getLowestMonthlyPrice = (products, typeGroup) => {
	const lowestProduct = products
		.filter(({ group }) => group === typeGroup)
		.sort((a, b) => (a.monthlyPrice > b.monthlyPrice ? 1 : -1))[0];

	return lowestProduct?.formattedMonthlyPrice;
};

export default {
	namespaced: true,
	state: {
		allSubscriptions: [],
		plan: null,
		products: [],
		subscription: {},
		loading: true,
	},
	getters: {
		currencyCode: (state, getters, rootState) => rootState.user?.user?.currency || getLocaleObjectFromLocalStorage()?.defaultCurrency || 'USD',
		hasActiveSubscriptions: (state) => !!state.allSubscriptions
			?.some((subscription) => subscription.status === ACTIVE_SUBSCRIPTION_STATUS),
		hasPlan: ({ plan }) => !!plan && plan !== UNUSED_PLAN.toLowerCase(),
		hasEcommercePlan: (state) => state.plan?.includes(ECOMMERCE_PLAN),
		lowestBasicPlanPrice: ({ products }) => getLowestMonthlyPrice(products, BASIC_PLAN),
		lowestEcommercePlanPrice: ({ products }) => getLowestMonthlyPrice(products, ECOMMERCE_PLAN),
		isUserWithSingleUnusedSubscription: ({ allSubscriptions }, { hasPlan }) => (
			!hasPlan && allSubscriptions.length === 1 && !allSubscriptions[0].siteId
		),
	},
	mutations: {
		setPlan: (state, plan) => {
			state.plan = plan;
		},
		setProducts: (state, products) => {
			state.products = products;
		},
		setAllSubscriptions: (state, allSubscriptions = []) => {
			state.allSubscriptions = allSubscriptions;
		},
		setSubscription: (state, subscription) => {
			state.subscription = subscription;
			state.loading = false;
		},
		setLoading: (state, loading) => {
			state.loading = loading;
		},
	},
	actions: {
		getPlan: async ({
			commit,
			rootGetters,
		}) => {
			const data = rootGetters['user/isChargeBeeUser']
				? await getPlanChargeBeeApi()
				: await getPlanApi();

			if (data?.plan) {
				commit('setPlan', data.plan);
			}
		},
		getProducts: async ({
			commit,
			getters,
			rootGetters,
		}) => {
			const data = rootGetters['user/isChargeBeeUser']
				? await getProductsChargeBeeApi(getters.currencyCode)
				: await getProductsApi(getters.currencyCode);
			const formatPrice = getPriceFormatter(
				getLocaleObjectFromLocalStorage().iso,
				getters.currencyCode,
			);

			const productsWithFormattedPrice = data.products.map(
				(product) => ({
					...product,
					formattedMonthlyPrice: formatPrice(product.monthlyPrice),
					formattedDiscountedMonthlyPrice: formatPrice(product.discountedMonthlyPrice),
					formattedPrice: formatPrice(product.price),
					formattedDiscountedPrice: formatPrice(product.discountedPrice),
				}),
			);

			commit('setProducts', productsWithFormattedPrice);
		},
		getAllSubscriptions: async ({
			commit,
			rootGetters,
		}) => {
			const allSubscriptions = rootGetters['user/isChargeBeeUser']
				? await getAllSubscriptionsChargeBeeApi()
				: await getAllSubscriptionsApi();

			commit('setAllSubscriptions', allSubscriptions);
		},
		getSubscription: async ({
			commit,
			rootGetters,
			getters,
			rootState,
		}) => {
			commit('setLoading', true);
			const response = rootGetters['user/isChargeBeeUser']
				? await getCurrentSiteSubscriptionChargeBee()
				: await getCurrentSiteSubscription();

			commit('setSubscription', response.data.subscription);

			if (rootGetters['onlineStore/isSiteWithEcwid'] && !getters.hasEcommercePlan) {
				EventLogApi.logEvent({
					amplitude: false,
					eventName: 'eshop.trigger_upgrade',
				});
			}

			if (!rootState.website.meta.ecwidStoreId && getters.hasEcommercePlan) {
				EventLogApi.logEvent({
					amplitude: false,
					eventName: 'eshop.trigger_setup',
				});
			}
		},
		autoConnectPlan: async ({
			state,
			commit,
			dispatch,
			getters,
			rootGetters,
		}, { ecommerceOnly = false } = {}) => {
			if (!getters.isUserWithSingleUnusedSubscription) {
				return;
			}

			const singleUnusedSubscription = state.allSubscriptions[0];

			if (ecommerceOnly && !singleUnusedSubscription.plan.includes(ECOMMERCE_PLAN)) {
				return;
			}

			if (rootGetters['user/isChargeBeeUser']) {
				await activatePlanChargeBeeApi();
			} else {
				await activatePlanApi();
			}

			// Re-init subscription and plan data
			await dispatch('getPlan');
			await dispatch('getSubscription');
			commit('setHasActivePlan', !!state.plan, { root: true });
		},
	},
};
