import { nanoid } from 'nanoid';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		isBlogEditMode: false,
		websiteBeforeEdit: null,
		isChangesMade: false,
	},
	getters: {
		categoryIdByName: (state, getters, rootState) => (categoryName) => Object
			.entries(rootState.website.blogCategories)
			.find(([, category]) => category.name === categoryName)[0],

		categoryNameById: (state, getters, rootState) => (categoryId) => rootState
			.website.blogCategories[categoryId].name,

		categoriesNames: (state, getters, rootState) => Object.values(rootState.website.blogCategories)
			.map((value) => value.name),

		postCategoriesNames: (state, getters, rootState) => (postId) => rootState
			.website.pages[postId].customData.categories
			.map((categoryId) => rootState.website.blogCategories[categoryId].name),

		postCategories: (state, getters, rootState) => (postId) => rootState
			.website.pages[postId].customData.categories,

		blogListCategoriesNames: (state, getters, rootState) => (blockId) => rootState
			.website.blocks[blockId].settings.categories
			.map((categoryId) => rootState.website.blogCategories[categoryId].name),

		isWebsiteWithBlogBlock: (state, getters, rootState) => Object.values(rootState.website.blocks)
			.some((block) => block.type === 'BlockBlogList'),
	},
	mutations: {
		setBlogEditMode: (state, value) => {
			state.isBlogEditMode = value;
		},
		setWebsiteBeforeEdit: (state, website) => {
			state.websiteBeforeEdit = website;
		},
		setIsChangesMade: (state, isChangesMade) => {
			state.isChangesMade = isChangesMade;
		},
		toggleBlogPostVisibility: (state, {
			rootState,
			pageId,
		}) => {
			const blogData = rootState.website.pages[pageId].customData;

			Vue.set(blogData, 'hidden', !blogData.hidden);
		},

		// Categories CRUD
		addPostCategory: (state, {
			rootState,
			postId,
			categoryId,
		}) => {
			rootState.website.pages[postId].customData.categories.push(categoryId);
		},
		addBlogListCategory: (state, {
			rootState,
			blockId,
			categoryId,
		}) => {
			rootState.website.blocks[blockId].settings.categories.push(categoryId);
		},
		addCategory: (state, {
			rootState,
			id = nanoid(),
			name,
		}) => {
			Vue.set(rootState.website.blogCategories, id, { name });
		},
		editCategory: (state, {
			rootState,
			id,
			name,
		}) => {
			Vue.set(rootState.website.blogCategories[id], 'name', name);
		},
		removeCategory: (state, {
			rootState,
			id,
		}) => {
			Vue.delete(rootState.website.blogCategories, id);
		},
		setCategories: (state, {
			rootState,
			categories,
		}) => {
			Vue.set(rootState.website, 'blogCategories', categories);
		},
	},
	actions: {
		addCategory: ({
			commit,
			rootState,
		}, {
			id = nanoid(),
			name,
		}) => {
			commit('addCategory', {
				rootState,
				id,
				name,
			});
		},

		duplicateCategory: ({
			commit,
			rootState,
		}, {
			id = nanoid(),
			name,
		}) => {
			commit('addCategory', {
				rootState,
				name: `${name}-${id}`,
			});
		},

		editCategory: ({
			commit,
			getters,
			rootState,
		}, {
			oldValue,
			newValue,
		}) => {
			const id = getters.categoryIdByName(oldValue);

			commit('editCategory', {
				rootState,
				id,
				name: newValue,
			});
		},

		removeCategory: ({
			commit,
			getters,
			rootState,
			rootGetters,
			dispatch,
		}, category) => {
			// Remove category in all posts, bloglists and main categories.
			const blogPages = rootGetters['pages/blogPages'];
			const categoryId = getters.categoryIdByName(category);

			const getEditedArray = (categoryIdsArray) => categoryIdsArray
				.filter((idToRemove) => idToRemove !== categoryId);

			// Remove in main category array
			commit('removeCategory', {
				rootState,
				id: categoryId,
			});

			//* Remove in all blog posts.
			Object.entries(blogPages).forEach(([pageId, post]) => dispatch('pages/setPageData',
				{
					type: 'blog',
					payload: {
						pageId,
						data: { customData: { categories: getEditedArray(post.customData.categories) } },
					},
				},
				{ root: true }));

			//* Remove in all blog lists.
			Object.entries(rootState.website.blocks).forEach(([blockId, block]) => {
				if (block.type !== 'BlockBlogList') {
					return;
				}

				commit('setBlockData',
					{
						blockId,
						data: { settings: { categories: getEditedArray(block.settings.categories) } },
					},
					{ root: true });
			});
		},

		addPostCategory: ({
			commit,
			dispatch,
			rootState,
		},
		{
			categoryId = nanoid(),
			postId,
			name,
		}) => {
			dispatch('addCategory', {
				id: categoryId,
				name,
			});
			commit('addPostCategory', {
				rootState,
				postId,
				categoryId,
			});
		},

		addBlogListCategory: ({
			commit,
			dispatch,
			rootState,
		},
		{
			categoryId = nanoid(),
			blockId,
			name,
		}) => {
			dispatch('addCategory', {
				id: categoryId,
				name,
			});
			commit('addBlogListCategory', {
				rootState,
				blockId,
				categoryId,
			});
		},

		setCategories: ({
			commit,
			rootState,
		}, { categories }) => {
			commit('setCategories', {
				rootState,
				categories,
			});
		},

		toggleBlogPostVisibility: ({
			commit,
			rootState,
		}, pageId) => {
			commit('toggleBlogPostVisibility', {
				rootState,
				pageId,
			});
		},
		/*
		 * Based on how Medium calculates read time.
		 * wordReadDuration - 1 word takes to read 0.22s for an average person.
		 * imageBaseDuration - 1st image takes 12 seconds, 2nd - 11 seconds, ..., 10th image - 3 seconds.
		 * The rest of the images take also 3 seconds.
		 */
		calculateReadTime: ({
			dispatch,
			rootState,
		}, { pageId }) => {
			const { website } = rootState;
			// Collect all components that are inside the blog post.
			const pageBlocksIds = website.pages[pageId].blocks;
			const excludedBlockTypes = ['BlockBlogHeader'];

			const filteredPageBlockIds = pageBlocksIds
				.filter((blockId) => !excludedBlockTypes.includes(website.blocks[blockId].type));
			const filteredComponentIds = filteredPageBlockIds
				.flatMap((blockId) => website.blocks[blockId].components);

			const minImageReadTime = 3;
			const wordReadTime = 0.22;
			let imageReadTime = 12;
			let readTime = 0;

			filteredComponentIds.forEach((componentId) => {
				// Clean up TextBox content from HTML, count amount of words and get read time.
				const component = website.components[componentId];

				if (component.type === 'GridTextBox') {
					const cleanContent = component.content.replace(/<\/?[^>]+(>|$)/g, ' ');
					const wordsCount = cleanContent.match(/\b[\w()+?-]+\b/gi)?.length;

					readTime += wordsCount ? wordsCount * wordReadTime : 0;
				}

				/*
				 * Add imageReadTime for each image. After each image addition, lower the reading time by 1.
				 * When the imageReadTime reaches min reading time, stop reducing and use the min value.
				 */
				if (component.type === 'GridImage') {
					readTime += imageReadTime === minImageReadTime ? minImageReadTime : imageReadTime;
					imageReadTime = imageReadTime === minImageReadTime ? minImageReadTime : imageReadTime - 1;
				}
			});
			/*
			 * Normalize the read time. If it is less than 60 seconds, treat like 1 minute.
			 * Else, just convert seconds into minutes and use that number as the final value.
			 */
			const readTimeInMinutes = readTime / 60;
			const normalizedReadTime = readTimeInMinutes < 1 ? 1 : Math.round(readTimeInMinutes);

			dispatch('pages/setPageData', {
				type: 'blog',
				payload: {
					pageId,
					data: { customData: { minutesToRead: `${normalizedReadTime}` } },
				},
			}, { root: true });
		},
		/*
		 * If no blog list and blog posts are present, add two predefined posts.
		 * This can't be done via mapper, because these posts need to have the same date
		 * the time list was added. With mapper, the 'mapped' date would be added, which
		 * would cause newly loaded templates have posts with wrong date generated.
		 */
		async addPredefinedPages({
			getters,
			rootGetters,
			dispatch,
		}) {
			if (getters.isWebsiteWithBlogBlock || Object.keys(rootGetters['pages/blogPages']).length > 0) {
				return;
			}

			const payload = {
				hidden: false,
				changePage: false,
			};

			await dispatch('pages/addPage', {
				type: 'blog',
				payload,
			}, { root: true });
			await dispatch('pages/addPage', {
				type: 'blog',
				payload,
			}, { root: true });
		},
	},
};
