<template>
	<div
		class="zyro-icon-controls"
		:class="`zyro-icon-controls--${theme}`"
	>
		<div
			v-for="icon in icons"
			:key="icon.value"
			v-qa="`iconcontrols-${icon.value}`"
			class="zyro-icon-controls__icon"
			:class="{ 'zyro-icon-controls__icon--active': icon.value === current }"
			@click="setNewValue(icon.value)"
		>
			<ZyroSvg
				:name="icon.icon"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		current: {
			type: String,
			default: '',
		},
		icons: {
			type: Array,
			required: true,
		},
		theme: {
			type: String,
			validator: (value) => [
				'rounded',
				'bordered',
			].includes(value),
			default: 'bordered',
		},
		toggleable: {
			type: Boolean,
			default: true,
		},
		defaultValue: {
			type: String,
			default: '',
		},
	},
	methods: {
		setNewValue(value) {
			const newValue = (value === this.current && this.toggleable)
				? this.defaultValue : value;

			this.$emit('update:current', newValue);
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-icon-controls {
	display: inline-flex;
	padding: 0;
	margin: 8px 0 16px;

	$this: &;

	&--bordered {
		border: 1px solid $grey-100;
		border-radius: $border-radius-tiny;
	}

	&--rounded {
		#{$this}__icon {
			&:first-child {
				border-radius: 5px 0 0 5px;
			}

			&:last-child {
				border-radius: 0 5px 5px 0;
			}
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		padding: 5px;

		&--active,
		&:hover,
		&:focus {
			cursor: pointer;
			background-color: $grey-100;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}
}
</style>
