<template>
	<ZyroModal
		max-width="470px"
		max-height="auto"
		height="auto"
		width="auto"
		class="choose-placement-modal"
		:title="title"
		:style="computedStyles"
		@close-modal="$emit('close')"
	>
		<div
			class="choose-placement-modal__content"
			:class="{ 'choose-placement-modal__content--has-title': title }"
		>
			<slot />
		</div>
		<template slot="footer">
			<ZyroButton
				v-qa="'systemdialogmodal-primary-btn'"
				class="choose-placement-modal__primary-button"
				@click="$emit('click-primary')"
			>
				{{ primaryButtonText }}
			</ZyroButton>
			<ZyroButton
				v-qa="'systemdialogmodal-seconday-btn'"
				theme="primary"
				@click="$emit('click-secondary')"
			>
				{{ secondaryButtonText }}
			</ZyroButton>
		</template>
	</ZyroModal>
</template>

<script>
export default {
	props: {
		primaryButtonText: {
			type: String,
			default: '',
		},
		secondaryButtonText: {
			type: String,
			default: '',
		},
		primaryButtonMargin: {
			type: String,
			default: '0 16px 0 0',
		},
		primaryButtonTheme: {
			type: String,
			default: 'outline',
		},
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		computedStyles() {
			return { '--primary-button-margin': this.primaryButtonMargin };
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal .modal__content {
	padding: 0;
	overflow: visible;
}

.choose-placement-modal {
	&__content {
		padding: 24px;

		&--has-title {
			padding-top: 0;
		}
	}

	&__primary-button {
		margin: var(--primary-button-margin);
	}
}
</style>
