<template>
	<div
		class="zyro-field-toggle"
	>
		<div class="zyro-field-toggle__group">
			<ZyroLabel
				:for="$attrs.id"
				:bold="bold"
				class="zyro-field-toggle__label"
				:class="{ 'zyro-field-toggle__label--disabled': disabled }"
			>
				{{ $attrs.label }}
			</ZyroLabel>
			<ZyroToggle
				v-bind="$attrs"
				class="zyro-field-toggle__switch"
				:checked="checked"
				:disabled="disabled"
				v-on="$listeners"
			/>
		</div>
		<div
			v-if="$attrs.message"
			class="zyro-field-toggle__message z-body-small"
		>
			{{ $attrs.message }}
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		bold: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-field-toggle {
	width: 100%;
	padding: 16px 0;

	&__group {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 24px;
	}

	&__message {
		margin-top: 8px;
		color: $grey-800;
	}

	&__label {
		line-height: 16px;
		cursor: pointer;

		&--disabled {
			color: $grey-800;
		}
	}

	&__switch {
		margin-left: auto;
	}
}
</style>
