<template>
	<div
		class="horizontal-masonry"
		@click="$emit('reset-selected-images')"
	>
		<UserImage
			v-for="(image, key) in images"
			:id="key"
			:key="`image-${key}`"
			v-qa="`assetmanager-image-${isImageSelected(key) ? 'selected' : 'notselected'}`"
			class="horizontal-masonry__image-container"
			:class="{ 'horizontal-masonry__image-container--is-selected' : isImageSelected(key) }"
			:image="image"
			@click-image="$emit('select-image', $event)"
			@cancel-upload="$emit('cancel-upload', $event)"
		/>
	</div>
</template>

<script>
import UserImage from '@/components/builder-modals/modals/asset-manager/UserImage.vue';

export default {
	components: { UserImage },
	props: {
		images: {
			type: Object,
			required: true,
		},
		selectedImageKeys: {
			type: Array,
			required: true,
		},
	},
	methods: {
		isImageSelected(key) {
			return this.selectedImageKeys.includes(key);
		},
	},
};
</script>

<style lang="scss" scoped>
.horizontal-masonry {
	$this: &;

	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 5px;
	align-content: flex-start;
	// Makes selected image top outline visible
	padding: 2px;
	overflow-x: hidden;
	overflow-y: auto;

	&__image-container {
		--object-fit: contain;

		&--is-selected {
			outline: 2px solid $accent-two;
		}
	}
}
</style>
