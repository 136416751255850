import { createNamespacedHelpers } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import {
	DRAWER_PAGES,
	DRAWER_PAGES_DEFAULT_STATE,
	MAIN_PAGE_KEY,
} from '@/store/builder/constants/drawerPages';
import {
	USER_STYLES_DRAWER,
	BLOG_DRAWER,
} from '@/store/builder/constants/drawers';
import { cloneDeep } from '@/utils/object';
import { USER_MOBILE_BREAKPOINT } from '@user/constants';

// namespacing to use with `createNamespacedHelpers`
export const GUI_NAMESPACE = 'gui';

// action type constants:
export const UPDATE_IS_MOBILE_SCREEN = 'UPDATE_IS_MOBILE_SCREEN';
export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const OPEN_HEATMAP = 'OPEN_HEATMAP';
export const CLOSE_HEATMAP = 'CLOSE_HEATMAP';
export const TOGGLE_HEATMAP = 'TOGGLE_HEATMAP';
export const CHANGE_DRAWER_PAGE = 'CHANGE_DRAWER_PAGE';
export const CHANGE_DRAWER_OPTIONS = 'CHANGE_DRAWER_OPTIONS';
export const CHANGE_PREVIOUS_DRAWER_PAGE = 'CHANGE_PREVIOUS_DRAWER_PAGE';

// mutation type constants:
export const SET_IS_MOBILE_SCREEN = 'SET_IS_MOBILE_SCREEN';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_ACTIVE_DRAWER = 'SET_ACTIVE_DRAWER';
export const SET_HEATMAP_OPEN = 'SET_HEATMAP_OPEN';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_DRAWER_PAGE = 'SET_DRAWER_PAGE';

/**
 * TODO: each namespacedHelper export should go to corresponding module state/action/mutation files
 *
 * Considering it's our own pattern, we should document this after refactoring. Smth. like:
 * 'Exported namespaced state/getters/actions/mutations map. Import it together with constants.'
 */
export const { mapState: mapStateGui } = createNamespacedHelpers(GUI_NAMESPACE);
export const { mapActions: mapActionsGui } = createNamespacedHelpers(GUI_NAMESPACE);
export const { mapMutations: mapMutationsGui } = createNamespacedHelpers(GUI_NAMESPACE);

export default {
	namespaced: true,
	state: {
		isMobileView: false,
		isMobileScreen: window.innerWidth < USER_MOBILE_BREAKPOINT,
		isSidebarOpen: true,
		activeModalName: null,
		activeModalSettings: {},
		activeDrawer: null,
		isEditMode: false,
		mobilePreviewRef: null,
		desktopPreviewRef: null,
		isSiteBeingPublished: false,
		headerHeight: null,
		isHeatmapOpen: false,
		drawerPage: DRAWER_PAGES_DEFAULT_STATE,
	},
	getters: {
		isMobileMode: (state) => state.isMobileScreen || state.isMobileView,
		isNavigationVisible: (state, getters, rootState) => (
			!rootState.website.navigation.hidden && !rootState.blog.isBlogEditMode
		),
	},
	mutations: {
		toggleMobileView: (state) => {
			state.isMobileView = !state.isMobileView;
		},
		setEditMode: (state, value) => {
			state.isEditMode = value;
		},
		toggleSiteBeingPublished: (state) => {
			state.isSiteBeingPublished = !state.isSiteBeingPublished;
		},
		setRef: (state, {
			el,
			ref,
		}) => {
			state[el] = ref;
		},
		[SET_HEADER_HEIGHT]: (state, height) => {
			state.headerHeight = height;
		},
		[SET_ACTIVE_MODAL]: (state, {
			name,
			settings,
		}) => {
			state.activeModalName = name;
			state.activeModalSettings = settings;
		},
		[SET_SIDEBAR_OPEN]: (state, isOpenState) => {
			state.isSidebarOpen = isOpenState;
		},
		[SET_HEATMAP_OPEN]: (state, isOpenState) => {
			state.isHeatmapOpen = isOpenState;
		},
		[SET_ACTIVE_DRAWER]: (state, activeDrawer) => {
			state.activeDrawer = activeDrawer;
		},
		[SET_IS_MOBILE_SCREEN]: (state, isMobileScreen) => {
			state.isMobileScreen = isMobileScreen;
		},
		[SET_DRAWER_PAGE]: (state, {
			drawerPage,
			drawerKey,
		}) => {
			state.drawerPage[drawerKey] = drawerPage;
		},
	},
	actions: {
		// TODO: setEditMde sets undefined if no args are passed
		setEditMode: ({ commit }, value) => {
			if (!value) {
				commit('unsetCurrentElement', null, { root: true });
			}

			commit('setEditMode', value);
		},
		toggleMobileView: ({ commit }) => {
			commit('unsetCurrentElement', null, { root: true });
			commit('toggleMobileView');
		},

		[UPDATE_HEADER_HEIGHT]: ({
			state,
			commit,
		}, height) => {
			if (!state.headerHeight && height) {
				commit(SET_HEADER_HEIGHT, height);
			}
		},

		// TODO: Test these three actions for @matasmazeikaa. Dunno how to test these properly
		[CHANGE_DRAWER_PAGE]: ({ commit }, {
			drawerKey,
			pageKey,
			options = {},
		}) => {
			const drawerPageData = DRAWER_PAGES[drawerKey][pageKey];
			const drawerPage = {
				...drawerPageData,
				options: {
					...drawerPageData.options,
					...options,
				},
			};

			commit(SET_DRAWER_PAGE, {
				drawerKey,
				drawerPage,
			});
		},

		[CHANGE_DRAWER_OPTIONS]: ({
			commit,
			state,
		}, {
			drawerKey,
			options = {},
		}) => {
			const drawerPageData = state.drawerPage[drawerKey];
			const drawerPage = {
				...drawerPageData,
				options: {
					...drawerPageData.options,
					...options,
				},
			};

			commit(SET_DRAWER_PAGE, {
				drawerKey,
				drawerPage,
			});
		},

		[CHANGE_PREVIOUS_DRAWER_PAGE]: ({
			commit,
			state,
		}, drawerKey) => {
			const pageKey = state.drawerPage[drawerKey].previousPage
				?? MAIN_PAGE_KEY;
			const drawerPage = cloneDeep(DRAWER_PAGES[drawerKey][pageKey]);

			commit(SET_DRAWER_PAGE, {
				drawerPage,
				drawerKey,
			});
		},

		[OPEN_SIDEBAR]: ({ commit }) => commit(SET_SIDEBAR_OPEN, true),

		[OPEN_MODAL]: ({ commit }, {
			name,
			settings,
		}) => {
			commit(SET_ACTIVE_MODAL, {
				name,
				settings,
			});
		},

		[CLOSE_MODAL]: ({ commit }) => {
			commit(SET_ACTIVE_MODAL, {
				name: null,
				settings: {},
			});
		},

		[CLOSE_SIDEBAR]: ({
			commit,
			dispatch,
		}) => {
			commit(SET_SIDEBAR_OPEN, false);
			dispatch(CLOSE_HEATMAP);
		},

		[TOGGLE_SIDEBAR]: ({
			state,
			dispatch,
		}) => {
			dispatch(state.isSidebarOpen ? CLOSE_SIDEBAR : OPEN_SIDEBAR);
		},

		[OPEN_HEATMAP]: ({
			state,
			commit,
			dispatch,
		}) => {
			dispatch(CLOSE_DRAWER);

			if (!state.isHeatmapOpen) {
				commit(SET_HEATMAP_OPEN, true);
				EventLogApi.logEvent({ eventName: 'builder.ai_heatmap.generate_heatmap' });
			}
		},

		[CLOSE_HEATMAP]: ({
			state,
			commit,
		}) => {
			if (state.isHeatmapOpen) {
				commit(SET_HEATMAP_OPEN, false);
				EventLogApi.logEvent({ eventName: 'builder.ai_heatmap.close' });
			}
		},

		[TOGGLE_HEATMAP]: ({
			state,
			dispatch,
		}) => {
			dispatch(state.isHeatmapOpen ? CLOSE_HEATMAP : OPEN_HEATMAP);
		},

		[OPEN_DRAWER]: ({
			rootState,
			commit,
			dispatch,
		}, name) => {
			if (name === USER_STYLES_DRAWER) {
				EventLogApi.logEvent({ eventName: 'builder.styles.open' });
			}

			if (name === BLOG_DRAWER) {
				EventLogApi.logEvent({ eventName: 'builder.blog.open_sidebar' });
				if (rootState.user?.id) {
					window.hj('identify', rootState.user.id, { 'builder.blog.open_sidebar': true });
				}
			}

			dispatch(CLOSE_HEATMAP);
			commit(SET_ACTIVE_DRAWER, name);
		},

		[CLOSE_DRAWER]: ({ commit }) => commit(SET_ACTIVE_DRAWER, null),

		[TOGGLE_DRAWER]: ({
			state,
			dispatch,
		}, name) => {
			dispatch(state.activeDrawer === name ? CLOSE_DRAWER : OPEN_DRAWER, name);
		},

		[UPDATE_IS_MOBILE_SCREEN]: ({ commit }, isMobile) => commit(SET_IS_MOBILE_SCREEN, isMobile),
	},
};
