<template>
	<div
		ref="userLayoutRef"
		class="user-layout"
	>
		<ZyroLoader
			v-if="!haveUserImagesLoaded"
			class="user-layout__loader"
		/>
		<template v-else-if="!areAllImagesEmpty">
			<ImagesControlBar
				:is-gallery="isGallery"
				:selected-count="selectedImageKeys.length"
				class="user-layout__control-bar"
				@open-file-dialog="$refs.fileInput.click()"
				@select-all="selectAllImages"
				@deselect-all="resetSelectedImages"
			/>
			<HorizontalMasonry
				:images="allImages"
				:selected-image-keys="selectedImageKeys"
				:class="{
					'user-layout__images--full-width': !selectedImageKeys.length,
					'user-layout__images--uploading': inProgressImageCount,
				}"
				class="user-layout__images"
				@select-image="toggleSelectImage"
				@cancel-upload="cancelUpload"
				@reset-selected-images="resetSelectedImages"
			/>
			<ImagePreview
				v-if="selectedImageKeys.length"
				class="user-layout__preview"
				:class="{ 'user-layout__preview--uploading': inProgressImageCount }"
				:selected-image-values="selectedImageValues"
				:is-gallery="isGallery"
				@select-image="$emit('select-image', $event)"
				@select-images="$emit('select-images', $event)"
				@delete-image="deleteImages"
			/>
			<ImageProgress
				v-if="inProgressImageCount"
				:in-progress-image-count="inProgressImageCount"
				@cancel-all-uploads="showCancelAllUploadsDialog = true"
			/>
		</template>

		<DragDropOverlay
			v-if="(areAllImagesEmpty || isDraggedOver) && haveUserImagesLoaded"
			class="user-layout__drag-and-drop-overlay"
			:is-dragged-over="isDraggedOver"
			@open-file-dialog="$refs.fileInput.click()"
		/>
		<!-- Dialogs -->
		<SystemDialogModal
			v-if="uploadHasUnsupportedFiles"
			:title="$t('builder.assetManager.dialogs.unsupportedFile')"
			:primary-button-text="$t('builder.assetManager.dialogs.cancel')"
			:secondary-button-text="$t('builder.assetManager.dialogs.skipUnsupported')"
			@close="uploadHasUnsupportedFiles = false, uploadHasImagesTooLarge = false"
			@click-primary="uploadHasUnsupportedFiles = false, uploadHasImagesTooLarge = false"
			@click-secondary="uploadValidFiles"
		>
			{{ $t('builder.assetManager.dialogs.fileSupport') }}
		</SystemDialogModal>
		<SystemDialogModal
			v-else-if="uploadHasImagesTooLarge"
			:title="$t('builder.assetManager.dialogs.tooBig')"
			:primary-button-text="$t('builder.assetManager.dialogs.cancel')"
			:secondary-button-text="$t('builder.assetManager.dialogs.skipUnsupported')"
			@close="uploadHasImagesTooLarge = false"
			@click-primary="uploadHasImagesTooLarge = false"
			@click-secondary="uploadValidFiles"
		>
			{{ $t('builder.assetManager.dialogs.tooLarge') }}
		</SystemDialogModal>
		<SystemDialogModal
			v-else-if="showCancelAllUploadsDialog && inProgressImageCount"
			title="Stop uploads"
			:primary-button-text="$t('builder.assetManager.tabUser.cancelUpload')"
			:secondary-button-text="$t('builder.assetManager.dialogs.continue')"
			@close="showCancelAllUploadsDialog = false"
			@click-primary="cancelAllUploads(), showCancelAllUploadsDialog = false"
			@click-secondary="showCancelAllUploadsDialog = false"
		>
			{{ $t('builder.assetManager.dialogs.cancelAll') }}
		</SystemDialogModal>
		<SystemDialogModal
			v-else-if="hasUploadFinishedWithFailedImages"
			title="Something went wrong"
			:primary-button-text="$t('builder.assetManager.dialogs.skipUnsupported')"
			:secondary-button-text="$t('builder.assetManager.dialogs.retry')"
			@close="removeFailedImages"
			@click-primary="removeFailedImages"
			@click-secondary="retryFailedImages"
		>
			{{ $t('builder.assetManager.dialogs.someFailed') }}
		</SystemDialogModal>
		<!-- Hidden input to call file dialog  -->
		<input
			v-show="false"
			ref="fileInput"
			type="file"
			data-qa="builder-input-image"
			name="images"
			accept="image/*"
			multiple
			@change="onSelectFiles($event)"
		>
	</div>
</template>

<script>
import {
	ref,
	onMounted,
} from '@vue/composition-api';

import { deleteAssets } from '@/api/AssetsApi';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import { useUploadImages } from '@/components/builder-modals/modals/asset-manager/useUploadImages';
import { useUserImages } from '@/components/builder-modals/modals/asset-manager/useUserImages';
import ZyroLoader from '@/components/global/ZyroLoader.vue';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';
import { getFileNameFromURL } from '@/utils/modifyString';
import { filterObject } from '@/utils/object';

import DragDropOverlay from './DragDropOverlay.vue';
import HorizontalMasonry from './HorizontalMasonry.vue';
import ImagePreview from './ImagePreview.vue';
import ImageProgress from './ImageProgress.vue';
import ImagesControlBar from './ImagesControlBar.vue';

export default {
	components: {
		DragDropOverlay,
		SystemDialogModal,
		HorizontalMasonry,
		ImagePreview,
		ImageProgress,
		ImagesControlBar,
		ZyroLoader,
	},
	props: {
		isGallery: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const {
			cancelAllUploads,
			cancelUpload,
			isDraggedOver,
			listenForDragAndDrop,
			onSelectFiles,
			removeFailedImages,
			removeImage,
			retryFailedImages,
			uploadedImages,
			uploadHasImagesTooLarge,
			uploadHasUnsupportedFiles,
			uploadValidFiles,
		} = useUploadImages();
		const userLayoutReference = ref(null);

		onMounted(() => {
			listenForDragAndDrop(userLayoutReference.value);
		});

		const {
			userResults,
			haveUserImagesLoaded,
			removeUserImage,
		} = useUserImages();

		return {
			cancelAllUploads,
			cancelUpload,
			isDraggedOver,
			onSelectFiles,
			removeFailedImages,
			removeImage,
			retryFailedImages,
			uploadedImages,
			uploadHasImagesTooLarge,
			uploadHasUnsupportedFiles,
			uploadValidFiles,
			userLayoutRef: userLayoutReference,
			userResults,
			haveUserImagesLoaded,
			removeUserImage,
		};
	},
	data() {
		return {
			selectedImageKeys: [],
			showCancelAllUploadsDialog: false,
		};
	},
	computed: {
		selectedImageValues() {
			return filterObject(
				this.allImages, ({ key }) => this.selectedImageKeys.includes(key),
			);
		},
		allImages() {
			return {
				...this.userResults,
				...this.uploadedImages,
			};
		},
		areAllImagesEmpty() {
			return Object.keys(this.allImages).length === 0;
		},
		/**
		 * When doing this in composition api with it doesnt work
		 * TODO: recheck when we move to vue3
		 */
		uploadedImageValues() {
			return Object.values(this.uploadedImages);
		},
		inProgressImageCount() {
			return this.uploadedImageValues
				.filter((image) => image.transferProgress !== 100).length;
		},
		hasUploadFinishedWithFailedImages() {
			const failedImages = this.uploadedImageValues
				.filter((image) => image.hasFailed);
			// How many left to upload
			const nonUploadedImageCount = this.uploadedImageValues.length
			- (this.uploadedImageValues.length - this.inProgressImageCount);
			/**
			 * In human words:
			 * There are images left to upload
			 * And all left images have failed
			 */

			return nonUploadedImageCount > 0
					&& (nonUploadedImageCount - failedImages.length) === 0;
		},
	},
	created() {
		/**
		 * When uploading in background the animation-finished
		 * event doesnt fire and the loader stays visible
		 */
		Object.keys(this.uploadedImages).forEach((imageId) => {
			const image = this.uploadedImages[imageId];

			if (image.transferProgress === 100) {
				image.animationFinished = true;
			}
		});
	},
	methods: {
		...mapActionsNotifications({ notify: NOTIFY }),
		selectAllImages() {
			this.selectedImageKeys = Object.keys(this.allImages).filter(this.notInProgressFilter);
		},
		async deleteImages(ids) {
			const imageNames = ids.map((id) => getFileNameFromURL(this.allImages[id].url));

			try {
				await deleteAssets(imageNames);
			} catch {
				this.notify({
					origin: 'UserLayout.vue',
					message: 'Failed to delete image(s)',
				});

				return;
			}

			this.resetSelectedImages();
			ids.forEach((id) => {
				if (id in this.uploadedImages) {
					// Removes image uploaded in this session, they use nanoid
					this.removeImage(id);
				} else {
					// Removes user image, user ids are their index in array
					this.removeUserImage(id);
				}
			});
		},
		notInProgressFilter(imageId) {
			return 'url' in this.allImages[imageId];
		},
		toggleSelectImage(options) {
			const {
				e,
				imageKey,
			} = options;
			// Deselect image
			const imageIndex = this.selectedImageKeys.indexOf(imageKey);

			if (imageIndex !== -1) {
				this.selectedImageKeys.splice(imageIndex, 1);

				return;
			}

			// Shift select multiple
			if (e.shiftKey && this.isGallery) {
				const keys = Object.keys(this.allImages);

				let pushLeftToRight = false;
				let pushRightToLeft = false;

				const leftToRightKeys = [imageKey];
				const rightToLeftKeys = [imageKey];

				let previousImagePosition = -1;
				let currentImagePosition = -1;

				keys.forEach((key, keyIndex) => {
					if (pushLeftToRight) {
						leftToRightKeys.push(key);
					}

					if (pushRightToLeft) {
						rightToLeftKeys.push(key);
					}

					if (key === this.selectedImageKeys[this.selectedImageKeys.length - 1]) {
						previousImagePosition = keyIndex;
						pushLeftToRight = true;
						pushRightToLeft = false;
					}

					if (key === imageKey) {
						currentImagePosition = keyIndex;
						pushRightToLeft = true;
						pushLeftToRight = false;
					}
				});

				const onlyUnique = (imageId, index, imageArray) => imageArray.indexOf(imageId) === index;

				if (currentImagePosition > previousImagePosition) {
					this.selectedImageKeys = [
						...this.selectedImageKeys,
						...leftToRightKeys,
					]
						.filter(onlyUnique).filter(this.notInProgressFilter);
				} else {
					this.selectedImageKeys = [
						...this.selectedImageKeys,
						...rightToLeftKeys,
					]
						.filter(onlyUnique).filter(this.notInProgressFilter);
				}

				return;
			}

			if (this.isGallery) {
				this.selectedImageKeys.push(imageKey);

				return;
			}

			// Handles single image
			this.selectedImageKeys = [imageKey];
		},
		resetSelectedImages() {
			this.selectedImageKeys = [];
		},
	},
};
</script>

<style lang="scss" scoped>
.user-layout {
	$spacing: 22px;

	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 1fr 320px;
	// Account for navigation height
	height: calc(100% - 34px);

	&__loader {
		grid-area: 1/1/-1/-1;
	}

	&__images {
		grid-area: 2/1/2/2;
		margin: 0 $spacing $spacing $spacing;

		&--uploading {
			margin-bottom: 8px;
		}

		&--full-width {
			grid-area: 2/1/2/3;
		}
	}

	&__control-bar {
		grid-area: 1/1/2/3;
		padding: 0 $spacing;
		margin-top: 14px;
	}

	&__preview {
		grid-area: 2/2/3/3;
		margin: 0 $spacing $spacing 0;

		&--uploading {
			margin-bottom: 8px;
		}
	}

	&__status-bar {
		grid-area: 3/1/3/3;
	}

	&__drag-and-drop-overlay {
		// Fix zindexing in relation to other elements that have position: relative
		position: relative;
		grid-area: 1/1/-1/-1;
		margin: 0 $spacing $spacing;
	}
}
</style>
