import axios from 'axios';

const BILLING_API = `${process.env.VUE_APP_API_URL}/billing`;

/**
 * OLD: PricingApi.js -> getProducts -> v1/pricing/products
 * ChargeBee: billing/products
 */
export const getProducts = async (currency) => (await axios.get(`${BILLING_API}/products`, { params: { currency } })).data;

/**
 * OLD: SubscriptionsApi.js -> getPlan -> v3/subscriptions/plan
 * ChargeBee: billing/subscriptions/plan
 */
export const getPlan = async () => (await axios.get(`${BILLING_API}/subscriptions/plan`)).data;

/**
 * OLD: SubscriptionsApi.js -> getCurrentSiteSubscription -> v3/subscriptions/current-site
 * ChargeBee: billing/subscriptions/current-site
 */
export const getCurrentSiteSubscription = () => axios.get(`${BILLING_API}/subscriptions/current-site`);

/**
 * OLD: SubscriptionsApi.js -> cancelSubscription -> v3/subscriptions/cancel/${siteId}
 * ChargeBee: billing/subscriptions/cancel-site
 */
export const cancelSubscription = (siteId) => axios.patch(`${BILLING_API}/subscriptions/cancel-site`, { siteId });

/**
 * OLD: SubscriptionsApi.js -> getAllSubscriptions -> v3/subscriptions
 * ChargeBee: billing/subscriptions
 */
export const getAllSubscriptions = async () => (await axios.get(`${BILLING_API}/subscriptions`)).data;

/**
 * OLD: SitesApi.js -> /v3/sites -> getSites
 * ChargeBee: billing/sites
 */
export const getSites = async () => (await axios.get(`${BILLING_API}/sites`)).data;

/**
 * Old: GET /v3/subscriptions/activateplan?site-id=SITE_ID
 * ChargeBee: billing/subscriptions/activate-plan
 */
export const activatePlan = (siteId) => {
	let query = '';

	if (siteId) {
		query = `?site-id=${encodeURIComponent(siteId)}`;
	}

	return axios.get(`${BILLING_API}/subscriptions/activate-plan${query}`);
};
