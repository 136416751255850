import Vue from 'vue';

import { cloneDeep } from '@/utils/object';

export default class {
	constructor(parameters) {
		this.type = 'SET';
		this.params = parameters;
		this.oldData = undefined;
		this.redoData = undefined;
	}

	execute() {
		const {
			state,
			blockId,
			oldData,
		} = this.params;

		if (typeof this.redoData === 'undefined') {
			if (JSON.stringify(state.website.blocks[blockId]) === JSON.stringify(oldData)) {
				return false;
			}

			this.redoData = cloneDeep(state.website.blocks[blockId]);
		}

		this.oldData = oldData;

		Vue.set(state.website.blocks, blockId, this.redoData);

		return true;
	}

	undo() {
		const {
			state,
			blockId,
		} = this.params;

		Vue.set(state.website.blocks, blockId, this.oldData);
	}
}
