<template>
	<section
		class="block"
		:style="computedStyle"
	>
		<BlockBackground
			:background="data.background"
			:is-next-to-transparent-header="isNextToTransparentHeader"
			:is-fixed="data.settings.styles.attachment === 'fixed'"
			is-lazy
		/>
		<Component
			:is="data.type"
			:block-id="id"
			:data="data"
			:is-preview-mode="isPreviewMode"
			:is-mobile-view="isMobileView"
			:is-header-sticky="data.settings.isSticky"
			:is-next-to-transparent-header="isNextToTransparentHeader"
			v-bind="{
				...$attrs,
				...$props
			}"
			:components="components"
			:blocks="blocks"
			v-on="$listeners"
			@hook:mounted="$emit('child-component-mounted')"
		/>
	</section>
</template>

<script>
import { computed } from '@vue/composition-api';

import { useInlineCSSVariables } from '@/use/useInlineCSSVariables';
import BlockBlogHeader from '@user/components/block-blog/block-blog-header/BlockBlogHeaderUser.vue';
import BlockBlogList from '@user/components/block-blog/block-blog-list/BlockBlogListUser.vue';
import BlockEcwidStore from '@user/components/block-ecwid-store/BlockEcwidStoreUser.vue';
import BlockGrid from '@user/components/block-grid/BlockGridUser.vue';
import BlockSlideshow from '@user/components/block-slideshow/BlockSlideshowUser.vue';
import BlockBackground from '@user/components/block/BlockBackground.vue';

export default {
	name: 'BlockUser',
	components: {
		BlockGrid,
		BlockBackground,
		BlockBlogList,
		BlockBlogHeader,
		BlockEcwidStore,
		BlockSlideshow,
	},
	inheritAttrs: false,
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		components: {
			type: Object,
			default: () => ({}),
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
		isNextToTransparentHeader: {
			type: Boolean,
			required: false,
		},
		blocks: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const { variablesString } = useInlineCSSVariables(props);

		return {
			computedStyle: computed(() => {
				/**
				 * 'content-visbility': auto (==lazy) defers rendering of blocks outside viewport.
				 * 'contain-intrinsic-size: Wpx Hpx' defines expected content size inside block.
				 * We don't care about width, so we leave it 1px.
				 * It's informational, so does not limit content itself (like max-height). Content can be larger or smaller.
				 *
				 * This is not supposed to be implemented with absolute/fixed/sticky positioned elements, so we exclude navigation.
				 * It should be not as graceful on mobile, but we cannot predict block height there.
				 * More info: https://www.youtube.com/watch?v=FFA-v-CIxJQ
				 */

				// Do not add 'content-visibility' for block next to transparent header to avoid clipping
				if (props.isNextToTransparentHeader) {
					return `
					${variablesString.value};
					margin-top:${-60}px;
					padding-top:${60}px
				`;
				}

				// destructuring props breaks reactivity, but we don't need in user-app
				const { styles } = props.data.settings ?? {};
				const rowGap = Number.parseInt(styles['row-gap'], 10);
				const rowSize = Number.parseInt(styles['row-size'], 10);
				const rowCount = Number.parseInt(styles.rows, 10);

				// we don't add block-padding, as this should only calculate _inner_ content
				const blockInnerHeight = (rowGap + rowSize) * rowCount - rowGap;

				return `
					${variablesString.value};
					content-visibility:auto;
					contain-intrinsic-size:1px ${blockInnerHeight}px;
					margin-top:${-60}px;
					padding-top:${60}px
				`;
			}),
		};
	},
	created() {
		// eslint-disable-next-line no-console
		console.log('BlockUser created');
	},
	mounted() {
		// eslint-disable-next-line no-console
		console.log('BlockUser mounted');
	},
};
</script>

<style lang="scss" scoped>
@import 'Block.scss';
</style>
