/**
 * Based on @see https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
 *
 * @todo pluralization
 * @todo number/datetime/currency formatting
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import loadBaseMessages from '@/i18n/helpers/loadBaseMessages';
import mergeMessageTranslations from '@/i18n/helpers/mergeMessageTranslations';
import getLocaleFromBrowser from '@/utils/i18n/getBrowserLocale';
import getLocaleFromQuery from '@/utils/i18n/getLocaleFromQuery';
import {
	getLocaleFromLocalStorage,
	setLocaleToLocalStorage,
} from '@/utils/i18n/localStorageLocale';
import {
	getLocale,
	DEFAULT_LOCALE,
} from '@/utils/i18n/supportedLocales';

Vue.use(VueI18n);

/**
 * Gets the starting locale code, of the locale that should be used on page load.
 * Priority flow, of initial locale decision making:
 * `query param ("?lang=")` > `cookie/localStorage` > `browser preferences` > default
 *
 * @returns {string} - starting locale code
 */
function getStartingLocale() {
	const startingLocale = getLocale(getLocaleFromQuery())
		|| getLocale(getLocaleFromLocalStorage())
		|| getLocale(getLocaleFromBrowser());

	return startingLocale?.code || DEFAULT_LOCALE.code;
}

const startingLocale = getStartingLocale();

setLocaleToLocalStorage(startingLocale);

/** The VueI18n instance */
const i18n = new VueI18n({
	locale: startingLocale,
	fallbackLocale: DEFAULT_LOCALE.code,
	messages: {
		// because of our message merging logic, default messages are loaded regardless
		[DEFAULT_LOCALE.code]: loadBaseMessages(),
	},
	silentTranslationWarn: process.env.VUE_APP_ENV_NAME !== 'local',
});

/**
 * Array of locale codes, that are already loaded
 * @type {Array.<string>}
 */
const loadedLanguages = [DEFAULT_LOCALE.code];

/**
 * Sets the current locale to a given one
 * @param {string} locale - locale code
 */
function setLocale(locale) {
	i18n.locale = locale;
	setLocaleToLocalStorage(locale);
}

/**
 * This function sets the i18n to a given locale,
 * and loads the given locale's messages if needed
 * @export
 * @param {string} locale - locale code
 * @returns {Promise.<string>} Promise that resolves to the set locale code
 */
export function loadLocaleMessagesAsync(locale) {
	const localeInfo = getLocale(locale);

	if (!localeInfo) {
		setLocale(i18n.fallbackLocale);

		return Promise.resolve(i18n.fallbackLocale);
	}

	// If the language was already loaded
	if (loadedLanguages.includes(locale)) {
		// If locale is loaded, is not yet set as current one
		if (i18n.locale !== locale) {
			setLocale(locale);
		}

		return Promise.resolve(locale);
	}

	// If the language hasn't been loaded yet
	return mergeMessageTranslations(localeInfo.localeId).then((messages) => {
		i18n.setLocaleMessage(locale, messages);
		loadedLanguages.push(locale);
		setLocale(locale);

		return Promise.resolve(locale);
	});
}

export default i18n;
