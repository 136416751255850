<template>
	<Transition
		name="fade"
		mode="out-in"
		appear
	>
		<div
			v-if="!isAppLoading"
			key="builder"
			:style="siteCSSVariables"
		>
			<Transition
				:name="$route.meta.transitionName"
				mode="out-in"
				appear
			>
				<RouterView />
			</Transition>
			<PortalTarget
				class="tooltip-portal"
				name="tooltip"
			/>
			<!-- Update v-click-outside middleware in BuilderPreview if class changes -->
			<PortalTarget
				class="color-picker"
				name="color-picker"
				multiple
			/>
			<PortalTarget
				class="app-portal"
				name="app"
			/>
			<!-- When user app is decoupled to iframe we
			can remove lightbox from Builder and not use portal at all -->
			<PortalTarget name="lightbox" />
			<BuilderModals />
			<BuilderToasts />
			<MobileObserver @toggle-mobile="updateIsMobileScreen" />
			<BuilderNps v-if="isNpsVisible && !isMobileScreen" />
		</div>
		<div
			v-else
			key="loader"
			class="loader-wrapper"
		>
			<ZyroLoader
				:style="{
					'--size': '100px',
					'--weight': '4px',
					'--border-color': 'var(--accent-two-light)',
					'--color': 'var(--light)'
				}"
			/>
		</div>
	</Transition>
</template>

<script>
import {
	mapState,
	mapActions,
	mapMutations,
	mapGetters,
} from 'vuex';

import typographyStyles from '@/assets/data/typography-styles-library.json';
import BuilderModals from '@/components/builder-modals/BuilderModals.vue';
import BuilderNps from '@/components/builder-nps/BuilderNps.vue';
import BuilderToasts from '@/components/builder-toasts/BuilderToasts.vue';
import MobileObserver from '@/components/ui/MobileObserver.vue';
import { _000_WEBHOST } from '@/constants';
import { PREVIEW_ROUTE } from '@/router';
import {
	mapActionsGui,
	UPDATE_IS_MOBILE_SCREEN,
} from '@/store/builder/gui';
import { useSiteStyles } from '@/use/useSiteStyles';
import { constructTypographyStylesGoogleFontQuery } from '@/utils/constructTypographyStylesGoogleFontQuery';
import { injectCustomScripts } from '@/utils/injectCustomScripts';

export default {
	name: 'App',
	components: {
		BuilderModals,
		MobileObserver,
		BuilderToasts,
		BuilderNps,
	},
	setup() {
		const { siteCSSVariables } = useSiteStyles();

		return { siteCSSVariables };
	},
	data() {
		return {
			isWebsiteLoaded: false,
			headElementRefs: [],
		};
	},
	metaInfo() {
		const fontQuery = this.website?.styles?.font && this.getMetaFont;
		const {
			texts,
			fonts,
		} = constructTypographyStylesGoogleFontQuery(typographyStyles);
		// TODO: Move to a separate component, and maybe store in env?
		const googleTagManagerId = 'GTM-KDF9B7B';
		const googleTagManagerScript = googleTagManagerId ? {
			innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${googleTagManagerId}');`,
			type: 'text/javascript',
			async: false,
		} : {};

		return {
			title: this.currentPage?.meta?.title || this.currentPage?.name,
			description: this.currentPage?.meta?.description,
			link: [
				fontQuery
					? {
						rel: 'stylesheet',
						href: `https://fonts.googleapis.com/css?family=${fontQuery}&display=swap`,
					}
					: {},
				{
					rel: 'stylesheet',
					href: `https://fonts.googleapis.com/css?family=${fonts}&texts=${texts}`,
				},
			],
			script: [googleTagManagerScript],
		};
	},
	computed: {
		...mapState([
			'isAppLoading',
			'website',
			'websiteId',
		]),
		...mapState('user', [
			'user',
			'beingRedirectedToSignup',
		]),
		...mapState('gui', ['isMobileScreen']),
		...mapState('nps', ['isNpsVisible']),
		...mapGetters('pages', ['currentPage']),
		...mapGetters('fonts', ['getMetaFont']),
	},
	watch: {
		website: {
			handler(newValue) {
				/*
				 * Save website to local storage only from currently opened and edited tab/window
				 * Not opened same site tabs/windows get updated automaticly by window.storage event
				 */
				if (!document.hidden) {
					this.saveWebsiteToLocalStorage({ websiteData: newValue });
				}
			},
			deep: true,
		},
		user: {
			handler(user) {
				setTimeout(() => {
					const noIntercomReferences = [_000_WEBHOST];

					if (!user || (!user.paid && noIntercomReferences.includes(user.ref))) {
						return;
					}

					this.$intercom.boot({
						user_id: user.id,
						user_hash: user.intercomHash,
						name: user.fullName,
						email: user.email,
					});
				},
				process.env.VUE_APP_INTERCOM_DELAY);
			},
		},
		$route(to, from) {
			if (to.name === PREVIEW_ROUTE) {
				// add custom meta code inside <HEAD> on preview page
				const { customMeta } = this.website.meta;

				this.headElementRefs = injectCustomScripts(customMeta);
			}

			if (from.name === PREVIEW_ROUTE) {
				// remove custom meta code from <HEAD> when leaving preview
				this.headElementRefs.forEach((item) => {
					item.remove();
				});

				this.headElementRefs = [];
			}
		},
	},
	created() {
		this.syncSameWebsiteTabs();
		this.handleTabClose();
		this.saveWebsiteInterval();
		this.initHotjar();
	},
	methods: {
		...mapActions([
			'saveWebsiteToLocalStorage',
			'saveWebsite',
		]),
		...mapMutations([
			'setWebsite',
			'unsetCurrentElement',
		]),
		...mapActionsGui({ updateIsMobileScreen: UPDATE_IS_MOBILE_SCREEN }),
		handleTabClose() {
			// Open confirmation dialog for guest user
			let timeout = null;

			window.addEventListener('beforeunload', (event) => {
				if (this.user || this.beingRedirectedToSignup || process.env.NODE_ENV === 'development') {
					return;
				}

				// Show tab close confirmation dialog
				// eslint-disable-next-line no-param-reassign
				event.returnValue = 'Changes will be lost. Login or signup and save your work';

				/*
				 * Code below will run in both cases - if user accepts or deny closing tab
				 * If cancel tab close - show "save website" tooltip after a small delay
				 * If accept tab close - first setTimeout prevents tooltip from blinking before unload event
				 */
				timeout = setTimeout(() => {
					timeout = setTimeout(() => {
						document.querySelector('#saveWebsiteButton').click();
					}, 500);
				}, 1);
			});

			// Save changes to DB
			window.addEventListener('unload', () => {
				this.saveWebsite({ sendBeacon: true });
				clearTimeout(timeout);
			});
		},
		syncSameWebsiteTabs() {
			window.addEventListener('storage', () => {
				if (!this.websiteId) {
					return;
				}

				const localStorageWebsite = JSON.parse(window.localStorage.getItem(`zyro_website_${this.websiteId}`));

				if (!localStorageWebsite) {
					return;
				}

				this.unsetCurrentElement();
				this.setWebsite({ website: localStorageWebsite.data });
			});
		},
		saveWebsiteInterval() {
			// Save website every 5 mins (300000ms)
			setInterval(() => {
				this.saveWebsite().catch(() => {
					// TODO: error handling
				});
			}, 300000);
		},
		initHotjar() {
		// https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#best-practices
		// eslint-disable-next-line func-names, no-undef, prefer-rest-params
			window.hj = window.hj || function () { (hj.q = hj.q || []).push(arguments); };
		},
	},
};
</script>

<style lang="scss" scoped>
.app-portal {
	position: fixed;
	z-index: z-index(modal--confirm);
}

.loader-wrapper {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
</style>
