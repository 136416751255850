import Vue from 'vue';

import {
	createToken,
	getDataList,
	removeDataEntry,
	removeDataEntries,
} from '@/api/UserDataApi';
import {
	NOTIFICATIONS_NAMESPACE,
	NOTIFY,
} from '@/store/builder/notifications';

export default {
	namespaced: true,
	state: { formEntries: {} },
	mutations: {
		// TODO: use setFormData instead of these mutations
		setFormToken: (state, {
			rootState,
			formId,
			tokenId,
		}) => {
			if (!rootState.website.forms[formId]) {
				Vue.set(rootState.website.forms, formId, {});
			}

			Vue.set(rootState.website.forms[formId], 'token', tokenId);
		},
		setFormEmail: (state, {
			formId,
			email,
			rootState,
		}) => {
			const { forms } = rootState.website;

			if (!forms[formId]) {
				return;
			}

			Vue.set(forms[formId], 'email', email);
		},
		setFormEmailNotifications: (state, {
			rootState,
			formId,
			emailNotifications,
		}) => {
			const { forms } = rootState.website;

			if (!forms[formId]) {
				return;
			}

			Vue.set(forms[formId], 'emailNotifications', emailNotifications);
		},
		setFormName: (state, {
			rootState,
			formId,
			name,
		}) => {
			const { forms } = rootState.website;

			if (!forms[formId]) {
				return;
			}

			Vue.set(forms[formId], 'name', name);
		},
		setFormEntries(state, {
			formId,
			formEntries,
		}) {
			Vue.set(state.formEntries, formId, formEntries);
		},
	},
	// TODO: use getFormData instead of these 5 getters
	getters: {
		getForms: (state, getters, rootState) => Object.values(rootState.website.components).filter(({ type }) => type === 'GridForm'),
		getFormEmail: (state, getters, rootState) => (formId) => {
			const { forms } = rootState.website;

			return forms[formId].email || '';
		},
		getFormEmailNotifications: (state, getters, rootState) => (formId) => {
			const { forms } = rootState.website;

			return forms[formId].emailNotifications || false;
		},
		getFormName: (state, getters, rootState) => (formId) => {
			const { forms } = rootState.website;

			return forms[formId].name || '';
		},
		getActiveFormIds(state, getters, rootState) {
			const formIds = [
				'contactForm',
				'subscribeForm',
			];

			return formIds.filter((formId) => rootState.forms[formId]);
		},
		getFormEntries: (state) => (formId) => state.formEntries[formId] || [],
		isWebsiteWithForms: (state, getters) => getters.getForms.length > 0,
	},
	actions: {
		// TODO: use setFormData instead of these actions
		generateFormToken: async ({
			getters,
			dispatch,
			rootState,
		}) => {
			getters.getForms.forEach((form) => {
				if (!rootState.website.forms[form.formId]?.token) {
					dispatch('setFormToken', { formId: form.formId });
				}
			});
		},
		setFormToken: async ({
			commit,
			dispatch,
			rootState,
		}, { formId }) => {
			try {
				const { data } = await createToken(formId);

				commit('setFormToken', {
					rootState,
					formId,
					tokenId: data.token,
				});
			} catch {
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					origin: 'Vuex forms store',
					message: 'Error adding the form. Please contact customer success team.',
					submitLabel: 'Retry',
					submitCallback: () => dispatch('setFormToken', { formId }),
				}, { root: true });
			}
		},
		setFormEmail: ({
			commit,
			rootState,
		}, {
			formId,
			email,
		}) => {
			commit('setFormEmail', {
				rootState,
				formId,
				email,
			});
		},
		setFormEmailNotifications: ({
			commit,
			rootState,
		}, {
			formId,
			emailNotifications,
		}) => {
			commit('setFormEmailNotifications', {
				rootState,
				formId,
				emailNotifications,
			});
		},
		setFormName: ({
			commit,
			rootState,
		}, {
			formId,
			name,
		}) => {
			commit('setFormName', {
				rootState,
				formId,
				name,
			});
		},
		fetchFormEntries: async ({
			commit,
			rootState,
		}, { formId }) => {
			const { data: formEntries } = await getDataList(rootState.website.forms[formId].token);

			commit('setFormEntries', {
				formId,
				formEntries,
			});
		},
		deleteEntry: async ({
			state,
			rootState,
			dispatch,
		}, {
			formId,
			dataId,
		}) => {
			try {
				await removeDataEntry(rootState.website.forms[formId].token, dataId);
				const formEntries = state.formEntries[formId];
				const formEntriesFiltered = formEntries.filter((formEntry) => formEntry.id !== dataId);

				state.formEntries[formId] = formEntriesFiltered;
			} catch {
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					origin: 'Vuex forms store',
					message: 'Couldn\t delete the record at the moment.',
				}, { root: true });
			}
		},
		deleteEntries: async ({
			state,
			rootState,
			dispatch,
		}, {
			checkboxes,
			formId,
		}) => {
			try {
				await removeDataEntries(
					rootState.website.forms[formId].token,
					checkboxes.checked,
				);
				const stateCopy = state.formEntries[formId]
					.filter((item) => !checkboxes.checked.includes(item.id));

				state.formEntries[formId] = stateCopy;
			} catch {
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					origin: 'Vuex forms store',
					message: 'Couldn\t delete the selected records at the moment.',
				}, { root: true });
			}
		},
	},
};
