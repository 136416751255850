import api from './requestApp';

const TRANSLATIONS_API = '/v1/translations';

export const getLocaleMessages = async (language) => {
	const { data } = await api.get(
		`${TRANSLATIONS_API}/${process.env.VUE_APP_TRANSLATIONS_BRAND}/${language}`,
	);

	return data;
};
