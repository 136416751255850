import { captureMessage } from '@sentry/browser';
import axios from 'axios';

import {
	getLocalDevelopmentTemplate,
	getLocalDevelopmentTemplateName,
} from '@/utils/localDevelopment';

const SITES_API = `${process.env.VUE_APP_API_URL}/v3/sites`;

export const saveCurrentSite = (data, clientTimestamp) => axios.patch(`${SITES_API}/current`, {
	data,
	clientTimestamp,
});

/**
 * sendBeacon is used to save website on tab/browser close
 * !!! sendBeacon is suitable only for small amounts  of data, and might not save bigger website json's
 * TODO: ASAP consider autosaving with websockets
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
 */
export const saveSiteSendBeacon = (data, siteId, clientTimestamp) => {
	navigator.sendBeacon(`${SITES_API}/site`, JSON.stringify({
		data,
		siteId,
		clientTimestamp,
	}));
};

export const getCurrentSite = async () => {
	const localTemplateName = getLocalDevelopmentTemplateName();

	// Local development setup
	if (process.env.NODE_ENV === 'development' && localTemplateName) {
		return getLocalDevelopmentTemplate(localTemplateName);
	}

	/*
	 * Get website from DB, check for same website in local storage
	 * If locally stored website is newer, use its data.
	 */
	let website = await axios(`${SITES_API}/current`).then((result) => result.data);
	const locallyStoredWebsite = JSON.parse(window.localStorage.getItem(`zyro_website_${website.id}`));

	if (
		locallyStoredWebsite
		&& website.id === locallyStoredWebsite.id
		&& locallyStoredWebsite.clientTimestamp > website.clientTimestamp
	) {
		const localVersion = Number.parseFloat(locallyStoredWebsite.data.meta.version, 10);
		const currentVersion = Number.parseFloat(website.data.meta.version, 10);

		/*
		 * Check if newer website in local storage has older data.json structure
		 * If data.json structure older - save it to DB and request from backend again, this way:
		 *   1. The newest data.json (with outdated structure) is saved to DB
		 *   2. On get request its mapped to the newest current version
		 */
		if (localVersion < currentVersion) {
			await saveCurrentSite(
				locallyStoredWebsite.data, locallyStoredWebsite.clientTimestamp,
			);
			website = await axios(`${SITES_API}/current`).then((result) => result.data);
		} else {
			website.data = locallyStoredWebsite.data;
		}

		const outdatedRemoteWebsiteDataMessage = 'Website data in DB was outdated. Local data used instead.';

		console.error(outdatedRemoteWebsiteDataMessage);
		captureMessage(outdatedRemoteWebsiteDataMessage);
	}

	return website;
};

export const createSite = ({
	data,
	siteName,
	templateId,
}) => axios.post(`${SITES_API}/`, {
	data,
	siteName,
	templateId,
});

export const duplicateSite = async (siteId, newName) => {
	const { data } = await axios.put(`${SITES_API}/duplicate/${siteId}/`, { newName });

	return data.newSite;
};

export const deleteSite = async (siteId) => axios.delete(`${SITES_API}/${siteId}`);

export const selectSite = (siteId) => axios(`${SITES_API}/select/${siteId}`);

export const renameSite = (siteId, name) => axios.patch(`${SITES_API}/${siteId}`, { name });

export const getSites = async () => {
	const { data } = await axios.get(SITES_API);

	return data;
};
