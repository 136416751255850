import axios from 'axios';

import { getTemplateById } from '@/api/TemplateApi';

export const LOCAL_TEMPLATES_LIST = [
	'local',
	'playground',
];
export const LOCAL_ASSETS_PATH = '/src/assets/templates/';

/**
 * Set the provided template name to session storage and return the template data,
 * either from database or from local assets (depends on the template name).
 */
export const getLocalDevelopmentTemplate = (templateName) => {
	sessionStorage.setItem('localTemplateName', templateName);
	if (LOCAL_TEMPLATES_LIST.includes(templateName)) {
		return axios(`${LOCAL_ASSETS_PATH}${sessionStorage.getItem('localTemplateName')}.json`);
	}

	return getTemplateById(templateName);
};

// Returns template name from session storage of from database
export const getLocalDevelopmentTemplateName = () => new URLSearchParams(window.location.search.slice(1)).get('template') || sessionStorage.getItem('localTemplateName') || null;
