var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Transition',{attrs:{"name":_vm.direction === 'right' ? 'drawer-slide-right' : 'drawer-slide-left'}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
			handler: _vm.closeDrawer,
			middleware: _vm.beforeClose,
			events: ['click'],
		}),expression:"{\n\t\t\thandler: closeDrawer,\n\t\t\tmiddleware: beforeClose,\n\t\t\tevents: ['click'],\n\t\t}"}],staticClass:"app-drawer",class:{
			'app-drawer--sidebar-open': _vm.isSidebarOpen,
			'app-drawer--left': _vm.direction === 'left',
			'app-drawer--right': _vm.direction === 'right',
		},style:({ width: _vm.width })},[(_vm.showCloseButton)?_c('ZyroButton',{staticClass:"app-drawer__close",attrs:{"data-qa":"builder-sidemenu-btn-close","icon":"x","title":_vm.$t('common.close')},on:{"click":_vm.closeDrawer}}):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }