<template>
	<div class="selected-image">
		<template v-if="isGallery">
			<div class="selected-image__placeholder">
				<ZyroSvg name="image-multiple" />
			</div>
			<p class="selected-image__selected-count z-body-small">
				{{ `${imageCount} ${$t('builder.assetManager.tabUser.itemsSelected')}` }}
			</p>
		</template>
		<template v-else>
			<img
				ref="selectedImage"
				:src="firstImageValue.base64Thumbnail || firstImageValue.url"
				class="selected-image__image"
				@load="updateSelectedImageInfo"
			>
			<p
				class="selected-image__name z-body z-body--strong"
				data-qa="chooseimage-label-img"
			>
				{{ imageName }}
			</p>
			<p class="selected-image__info z-body-small">
				{{ $t('builder.assetManager.tabUser.resolution') }}
				{{ imageInfo.width }} &times; {{ imageInfo.height }}<br>
				{{ $t('builder.assetManager.tabUser.type') }} {{ imageFormat }}<br>
			</p>
		</template>
		<ZyroSeparator class="selected-image__separator" />
		<ZyroButton
			theme="primary"
			size="xs"
			color="black"
			class="selected-image__add-button"
			data-qa="chooseimage-btn-addtopage"
			@click="isGallery ?
				$emit('select-images', Object.values(selectedImageValues)) :
				$emit('select-image', firstImageValue.url), logSelectEvent"
		>
			{{ isGallery
				? $t('builder.assetManager.addToGallery') : $t('builder.assetManager.addToPage') }}
		</ZyroButton>
		<ZyroButton
			theme="plain"
			size="xs"
			icon-left="trash"
			class="selected-image__delete-button"
			data-qa="chooseimage-btn-deleteimage"
			@click="$emit('delete-image', Object.keys(selectedImageValues))"
		>
			<template v-if="isGallery">
				{{ `${$t('builder.assetManager.tabUser.deleteSelected')} (${imageCount})` }}
			</template>
			<template v-else>
				{{ $t('builder.assetManager.tabUser.delete') }}
			</template>
		</ZyroButton>
	</div>
</template>

<script>
import EventLogApi from '@/api/EventLogApi';
import {
	getExtension,
	getFileNameFromURL,
} from '@/utils/modifyString';

export default {
	props: {
		// Object because this will have to support multiple images
		selectedImageValues: {
			type: Object,
			required: true,
		},
		isGallery: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { selectedImageInfo: {} };
	},
	computed: {
		firstImagekey() {
			return Object.keys(this.selectedImageValues)[0];
		},
		firstImageValue() {
			return this.selectedImageValues[this.firstImagekey];
		},
		imageName() {
			// Backend changes/may change the image name, prioritise image name from that url
			return getFileNameFromURL(this.firstImageValue?.url) || this.firstImageValue.name;
		},
		imageInfo() {
			return {
				height: this.selectedImageInfo.height || '-',
				width: this.selectedImageInfo.width || '-',
				type: this.selectedImageInfo.type || '-',
			};
		},
		imageFormat() {
			return getExtension(this.imageName);
		},
		imageCount() {
			return Object.keys(this.selectedImageValues).length;
		},
	},
	watch: {
		selectedImageValues() {
			this.selectedImageInfo = {};
		},
	},
	methods: {
		logSelectEvent() {
			EventLogApi.logEvent({
				eventName: 'builder.image_element.choose_image',
				eventProperties: { tab: 'uploaded' },
			});
		},
		/**
		 * TODO: Backend should probably return full image data like unsplash does
		 * so we dont have to parse everything in front end
		 * we also cant load small thumbnails in preview
		 * for better perfromance, because we need full image
		 * to get real resolution and size
		 */
		async updateSelectedImageInfo() {
			this.selectedImageInfo = {
				width: this.firstImageValue?.height || this.$refs.selectedImage?.naturalWidth,
				height: this.firstImageValue?.height || this.$refs.selectedImage?.naturalHeight,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.selected-image {
	display: flex;
	flex-direction: column;
	grid-area: 1/2/2/3;
	align-items: center;
	padding: 24px;
	overflow-x: hidden;
	overflow-y: auto;
	background: $grey-100;

	&__image {
		height: 100%;
		min-height: 100px;
		max-height: 200px;
		object-fit: contain;
	}

	&__placeholder {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 200px;
		border: 1px solid $grey-300;
	}

	&__selected-count {
		margin: 24px auto;
	}

	&__image,
	&__name {
		width: 100%;
		margin-bottom: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__info,
	&__separator,
	&__add-button {
		margin-bottom: 24px;
	}

	&__add-button,
	&__delete-button {
		// Not hiding overflow causes container to expand, idk why
		overflow: hidden;
	}

	&__name {
		// Box doesnt expand with text without this
		overflow: visible;
	}

	&__name,
	&__info {
		width: 100%;
	}
}
</style>
