import Vue from 'vue';

import { EventBus } from '@/eventBus';
import {
	createObjectDiff,
	revertChangesByDiff,
} from '@/utils/jsondiffpatch';
import { cloneDeep } from '@/utils/object';

export default class {
	constructor(parameters) {
		this.type = 'ADD';
		this.params = parameters;
	}

	execute() {
		const {
			state,
			newBlockId,
			newBlock,
			newComponents,
			slideshowBlockId,
			newSlide,
		} = this.params;

		const websiteCloneBeforeChanges = cloneDeep(state.website);

		const newBlockSlideshowSlides = [
			...state.website.blocks[slideshowBlockId].slides,
			newSlide,
		];

		// Add new slide to block slideshow slides list
		Vue.set(state.website.blocks[slideshowBlockId], 'slides', newBlockSlideshowSlides);

		Object.entries(newComponents).forEach(([componentId, componentValue]) => {
			Vue.set(state.website.components, componentId, componentValue);
		});
		// Add block to blocks in all cases
		Vue.set(state.website.blocks, newBlockId, newBlock);

		this.diff = createObjectDiff(websiteCloneBeforeChanges, state.website);
	}

	undo() {
		EventBus.$emit('before-undo');
		const { state } = this.params;

		state.website = revertChangesByDiff(state.website, this.diff);

		EventBus.$emit('after-undo');
	}
}
