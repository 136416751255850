import Vue from 'vue';

export default class {
	constructor(parameters) {
		this.type = 'SET';
		this.params = parameters;
		this.oldData = undefined;
	}

	execute() {
		const {
			state,
			elementId,
			data,
		} = this.params;

		if (JSON.stringify(state.website.components[elementId]) === JSON.stringify(data)) {
			return false;
		}

		this.oldData = state.website.components[elementId];

		Vue.set(state.website.components, elementId, data);

		return true;
	}

	undo() {
		const {
			state,
			elementId,
		} = this.params;

		Vue.set(state.website.components, elementId, this.oldData);
	}
}
