var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
		handler: _vm.close,
		middleware: _vm.beforeClose,
		events: ['mousedown'],
	}),expression:"{\n\t\thandler: close,\n\t\tmiddleware: beforeClose,\n\t\tevents: ['mousedown'],\n\t}"}],staticClass:"tooltip"},[_c('div',_vm._g({ref:"trigger",staticClass:"trigger"},_vm.triggerAction()),[_vm._t("trigger")],2),(_vm.show)?_c(_vm.usePortal ? 'portal' : 'div',{tag:"Component",attrs:{"to":"tooltip"}},[_c('Transition',{attrs:{"name":"fade","appear":""}},[_c('div',{ref:"tooltip",class:[
					("tooltip__content\n\t\t\t\t\ttooltip__content--" + _vm.mode + "\n\t\t\t\t\ttooltip__content--" + _vm.size + "\n\t\t\t\t\ttooltip__content--position-" + _vm.contentPosition + "\n\t\t\t\t\tbody-small"),
					{
						'tooltip__content--modal' : _vm.isOnModal,
						'tooltip__content--has-close-icon' : _vm.hasCloseIcon
					}
				],style:(_vm.forcedPosition || _vm.tooltipPosition)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.triangle),expression:"triangle"}],staticClass:"tooltip__triangle",class:[
						("tooltip__triangle\n\t\t\t\t\t\ttooltip__triangle--" + _vm.mode),
						{
							'tooltip__triangle--top': _vm.position === 'bottom',
							'tooltip__triangle--bottom': _vm.position === 'top',
							'tooltip__triangle--left': _vm.position === 'right',
							'tooltip__triangle--right': _vm.position === 'left'
						}
					]}),_vm._t("default"),(_vm.hasCloseIcon)?_c('ZyroSvg',{class:("icon-close-sm\n\t\t\t\t\t\ttooltip__close-icon\n\t\t\t\t\t\ttooltip__close-icon--" + _vm.mode + "\n\t\t\t\t\t\ttooltip__close-icon--" + _vm.size + "\n\t\t\t\t\t\ttooltip__close-icon--position-" + _vm.contentPosition),attrs:{"data-qa":"tooltip-btn-close","name":"close-sm"},on:{"click":_vm.close}}):_vm._e()],2)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }