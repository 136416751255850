import Vue from 'vue';

export const EventBus = new Vue();

if (process.env.NODE_ENV === 'development') {
	const logEvent = (eventName) => {
		EventBus.$on(eventName, () => {
			// console.log(`EventBus emitted %c${eventName}`, 'color:white; background:black;');
		});
	};

	logEvent('before-undo');
	logEvent('after-undo');
}
