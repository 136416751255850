<template>
	<div
		:id="blockId"
		ref="blogList"
		class="block-blog-list"
	>
		<Transition name="fade">
			<div
				v-if="filteredCategoryId"
				class="block-blog-list__filter"
			>
				{{ website.blogCategories[filteredCategoryId].name }}
				<!-- TODO: Style this button before releasing blog feature live -->
				<button
					class="block-blog-list__filter-button"
					@click="filteredCategoryId = null"
				>
					Show all posts
				</button>
			</div>
		</Transition>
		<div
			v-if="currentPageItems.length"
			class="block-blog-list__list"
			:class="{ 'block-blog-list__list--one-col': postColumnCount === 1 }"
		>
			<BlockBlogListItemUser
				v-for="(post, index) in currentPageItems"
				:key="`post-${index}`"
				data-qa="blog-list-item"
				:post="post"
				:author-full-name="userFullName"
				:cover-object-fit="data.settings.styles['cover-object-fit']"
				:shown-items="data.settings.shownItems"
				@filter-category="filteredCategoryId = $event"
				@post-click="handleClick(post)"
			/>
		</div>
		<BlockBlogListEmptyBlockUser v-else />
		<ZyroPagination
			class="block-blog-list__pagination"
			:current-page="currentPage"
			:page-count="pageCount"
			@change-page="changePage"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import {
	mapState,
	mapGetters,
	mapActions,
} from 'vuex';

import ZyroPagination from '@/components/global/ZyroPagination.vue';
import BlockBlogListEmptyBlockUser from '@user/components/block-blog/block-blog-list-empty-block/BlockBlogListEmptyBlockUser.vue';
import BlockBlogListItemUser from '@user/components/block-blog/block-blog-list-item/BlockBlogListItemUser.vue';
import { mockPosts } from '@user/components/block-blog/mockPosts';

export default {
	components: {
		BlockBlogListItemUser,
		BlockBlogListEmptyBlockUser,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			currentPage: 1,
			filteredCategoryId: null,
		};
	},
	computed: {
		...mapState(['website']),
		...mapState('user', ['user']),
		...mapState('navigation', ['navigationHeight']),
		...mapGetters('pages', ['publishedBlogPages']),
		postColumnCount() {
			return Number.parseInt(this.data.settings.styles['post-column-count'], 10);
		},
		userFullName() {
			return this.user?.fullName;
		},
		/*
		 * Create a posts object from original one, but exclude posts that don't have shown categories.
		 * or, if filtered category is set, show only filtered posts.
		 * If the main categories array is empty, show all posts.
		 */
		posts() {
			const {
				categories,
				showAllPosts,
				showWithoutCategories,
				mockCategories,
			} = this.data.settings;

			if (mockCategories) {
				return mockPosts;
			}

			const posts = showAllPosts ? this.publishedBlogPages : Object.fromEntries(
				Object.entries(this.publishedBlogPages)
					.filter(([, page]) => {
						const isPostIncludedInList = categories.some(
							(listCategory) => page.customData.categories.includes(listCategory),
						);
						const showUncategorized = showWithoutCategories && page.customData.categories.length === 0;

						return showUncategorized || isPostIncludedInList;
					}),
			);

			if (this.filteredCategoryId) {
				return Object.fromEntries(Object.entries(posts)
					.filter(([, post]) => post.customData.categories.includes(this.filteredCategoryId)));
			}

			return posts;
		},
		sortedPosts() {
			return Object.values(this.posts).sort(
				(a, b) => Date.parse(b.customData.date) - Date.parse(a.customData.date),
			);
		},
		currentPageItems() {
			return this.sortedPosts.slice(
				(this.currentPage - 1) * this.data.settings.postsPerPage,
				this.currentPage * this.data.settings.postsPerPage,
			);
		},
		pageCount() {
			return Math.ceil(Object.keys(this.posts).length / this.data.settings.postsPerPage);
		},
	},
	watch: {
		// If current page is not the first page and there are no items to show, go back one page
		currentPageItems(value) {
			if (value.length === 0 && this.currentPage > 1) {
				this.currentPage -= 1;
			}
		},
	},
	beforeMount() {
		Vue.component('ZyroPagination', ZyroPagination);
	},
	methods: {
		...mapActions('pages', [
			'setCurrentPage',
			'addPage',
		]),
		handleClick(post) {
			// Exception for preview mode
			if (process.env.VUE_APP_MODE !== 'user') {
				const pageId = Object.entries(this.publishedBlogPages)
					.find(([, page]) => page.path === post.path)[0];

				this.setCurrentPage({
					type: 'blog',
					payload: { pageId },
				});
			} else {
				this.$router.push({ path: `${post.path}` });
			}
		},
		changePage(pageNumber) {
			this.currentPage = pageNumber;
			const containerTopCoordinate = this.$refs.blogList.getBoundingClientRect().top;

			// If block is not fully visible, scroll to the top of it.
			if (containerTopCoordinate > 0) {
				return;
			}

			window.scrollBy({
				top: containerTopCoordinate - this.navigationHeight,
				behavior: 'smooth',
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import './BlockBlogList';
</style>
