// var(--colors-primary) => primary
import { capitalizeFirstLetter } from '@/utils/modifyString';

export const stripColorFromVariable = (variable) => variable.replace(/var\(--colors-|\)/g, '');

export const transformColorToVariable = (color) => `var(--colors-${color})`;

// var(--colors-primary-accent) => Primary accent
export const convertColorToTitle = (color) => capitalizeFirstLetter(stripColorFromVariable(color)).replace(/-/g, ' ');

export const isCustomColor = (color) => !color.includes('var');
