export const MAIN_WEBSITE_COLORS = [
	'primary',
	'secondary',
	'accent-1',
	'accent-2',
	'light',
];

export const ALL_WEBSITE_COLORS = [
	'primary-light',
	'secondary-light',
	'accent-2-light',
	'accent-1-light',
	'light',
	'primary-accent',
	'secondary-accent',
	'accent-2-accent',
	'accent-1-accent',
	'grey-200',
	'primary',
	'secondary',
	'accent-2',
	'accent-1',
	'grey-800',
	'primary-dark',
	'secondary-dark',
	'accent-2-dark',
	'accent-1-dark',
	'dark',
];

export const COLOR_PICKER_MODE_MINIMAL = 'minimal';
export const COLOR_PICKER_MODE_FULL = 'full';
export const COLOR_PICKER_MODE_CUSTOM_COLOR = 'color-picker';
export const COLOR_PICKER_MODE_INTEGRATED = 'integrated';

export const COLOR_PICKER_COMPONENT = {
	[COLOR_PICKER_MODE_MINIMAL]: 'Minimal',
	[COLOR_PICKER_MODE_FULL]: 'Full',
	[COLOR_PICKER_MODE_CUSTOM_COLOR]: 'ColorPicker',
};

export const CUSTOM_COLOR_ICON = 'droplet';
