import '@/assets/scss/global.scss';

import VueFormulate from '@braid/vue-formulate';
import * as Sentry from '@sentry/browser';
import {
	Vue as VueIntegration,
	Dedupe as SentryDedupe,
} from '@sentry/integrations';
import VueCompositionApi from '@vue/composition-api';
import axios from 'axios';
import { nanoid } from 'nanoid';
import PortalVue from 'portal-vue';
import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import VueIntercom from 'vue-intercom';
import JsonExcel from 'vue-json-excel';
import VueMasonry from 'vue-masonry-css';
import VueMeta from 'vue-meta';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Router from 'vue-router';
import VueSanitize from 'vue-sanitize';
import Vuelidate from 'vuelidate';
import 'lazysizes';

import i18n from '@/i18n/setup';
import GridInput from '@user/components/grid-components/input/GridInput.vue';

import App from './App.vue';
import {
	dataQADirective,
	DIRECTIVE_NAME,
} from './plugins/qaAttributeDirective';
import Recomputable from './plugins/recomputable';
import router from './router';
import store from './store/builder';

(async () => {
	if ('ResizeObserver' in window === false) {
		// Loads polyfill asynchronously, only if required.
		const observer = await import('@juggle/resize-observer');

		window.ResizeObserver = observer.ResizeObserver;
	}
})();

Sentry.init({
	release: process.env.VUE_APP_SENTRY_ENV === 'PROD' ? `${process.env.VUE_APP_BUILD_NUMBER}` : 'builder@dev',
	dsn: process.env.VUE_APP_SENTRY_DSN,
	environment: process.env.VUE_APP_SENTRY_ENV || 'local',
	integrations: [
		new VueIntegration({
			Vue,
			tracing: true,
		}),
		new SentryDedupe(),
	],
	tracesSampleRate: 1,
	// https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
	ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

// Send cookies with every request
axios.defaults.withCredentials = true;
// Add window id to every request headers
axios.interceptors.request.use((config) => {
	let windowId = sessionStorage.getItem('z-window-id');

	if (!windowId) {
		windowId = nanoid();
		sessionStorage.setItem('z-window-id', windowId);
	}

	return {
		...config,
		headers: {
			...config.headers,
			'z-app': 'builder',
			'z-window-id': windowId,
		},
	};
});

Vue.use(Router);
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });
Vue.use(
	VueReCaptcha,
	{
		siteKey: process.env.VUE_APP_RE_CAPTCHA_SITE_KEY,
		loaderOptions: { autoHideBadge: true },
	},
);
Vue.use(VueMeta);
Vue.use(vClickOutside);
Vue.use(VueMasonry);
Vue.use(PortalVue);
Vue.use(Recomputable);
Vue.use(VueCompositionApi);
Vue.use(Vuelidate);
Vue.use(VueSanitize);
// Register GridInput globally, else it's unavailable in VueFormulate
Vue.component('GridInput', GridInput);
Vue.use(VueFormulate, {
	library: {
		GridInput: {
			classification: 'text',
			component: 'GridInput',
		},
	},
});
Vue.component('DownloadExcel', JsonExcel);

Vue.directive(DIRECTIVE_NAME, dataQADirective);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

// Define user mode globally, used for switching context in user facing components
const globalComponents = require.context('./components/global', false, /.(vue|js)$/);

globalComponents.keys().forEach((fileName) => {
	const componentName = fileName.replace(/.\/|.vue/g, '');

	Vue.component(componentName, globalComponents(fileName).default);
});

new Vue({
	i18n,
	store,
	router,
	mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
	render: (h) => h(App),
}).$mount('#app');
