export const DEFAULT_GRID_STYLES = {
	'row-gap': '16px',
	'row-size': '48px',
	'column-gap': '24px',
	'grid-width': '1224px',
	'column-count': '12',
	'content-width': '1600px',
	'content-padding': '16px',
	'block-padding': '16px 0',
	'm-block-padding': '56px 16px',
};

export const getBlogListBlock = ({ mockCategories }) => ({
	type: 'BlockBlogList',
	settings: {
		postsPerPage: '2',
		categories: [],
		showAllPosts: true,
		showWithoutCategories: false,
		fullWidth: false,
		mockCategories,
		shownItems: {
			coverImage: true,
			title: true,
			description: true,
			date: true,
			categories: true,
			authorFullName: true,
			avatar: true,
			minutesToRead: true,
		},
		styles: {
			'post-column-count': '2',
			'grid-gap-size': DEFAULT_GRID_STYLES['column-gap'],
			'block-padding': '30px',
			'content-width': DEFAULT_GRID_STYLES['grid-width'],
			'cover-object-fit': 'contain',
			// Styles below are not used for the list appearance - they are for AddBlockModal support
			rows: 10,
			cols: 10,
			'row-size': DEFAULT_GRID_STYLES['row-size'],
			'row-gap': DEFAULT_GRID_STYLES['row-gap'],
		},
	},
	components: [],
	zindexes: [],
	background: {},
});

export const getEcwidBlock = () => ({
	type: 'BlockEcwidStore',
	settings: {
		styles: {
			'content-width': DEFAULT_GRID_STYLES['grid-width'],
			'block-padding': '16px 46px',
			'm-block-padding': DEFAULT_GRID_STYLES['m-block-padding'],
		},
	},
	background: {
		current: 'color',
		color: 'var(--light)',
	},
	components: [],
	zindexes: [],
});

export const getGridBlock = () => ({
	type: 'BlockGrid',
	settings: {
		styles: {
			rows: 5,
			...DEFAULT_GRID_STYLES,
		},
	},
	components: [],
	background: {},
	zindexes: [],
});

export const getBlogHeaderBlock = () => ({
	type: 'BlockBlogHeader',
	hidden: false,
	settings: {
		shownItems: {
			date: true,
			categories: true,
			authorFullName: true,
			avatar: true,
			minutesToRead: true,
			description: true,
		},
		styles: {
			'block-padding': '45px 30px 45px 30px',
			'm-block-padding': '16px 16px 16px 16px',
			'content-width': '954px',
			align: 'flex-start',
		},
	},
	components: [],
	background: {},
});

export const getBlogPostGridBlock = () => ({
	type: 'BlockGrid',
	settings: {
		styles: {
			rows: '9',
			...DEFAULT_GRID_STYLES,
		},
	},
	components: [],
	background: {},
	zindexes: [],
});
