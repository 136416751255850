var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('textarea',_vm._g(_vm._b({ref:"textareaRef",staticClass:"zyro-textarea",class:[
		( _obj = {}, _obj[("zyro-textarea--" + _vm.theme)] = _vm.theme, _obj ),
		{ 'z-body-small': _vm.theme === 'primary' },
		{ 'z-body': _vm.theme === 'secondary' },
		{ 'zyro-textarea--light' : _vm.color },
		{ 'zyro-textarea--error' : _vm.error } ],attrs:{"autocomplete":"off"}},'textarea',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }