import { nanoid } from 'nanoid';
import Vue from 'vue';
import Vuex from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import {
	publishCurrentSite,
	updateCurrentSite,
} from '@/api/PublishApi';
import {
	getCurrentSite,
	saveCurrentSite,
	saveSiteSendBeacon,
} from '@/api/SitesApi';
import { getCurrentTemplate } from '@/api/TemplateApi';
import { getGridBlock } from '@/components/block/blocks';
import { GENERATED_TEMPLATE_ID } from '@/constants';
import i18n from '@/i18n/setup';
import BlockSlideshowAddCommand from '@/store/builder/commands/BlockSlideshowAddCommand';
import BlockSlideshowAddSlideCommand from '@/store/builder/commands/BlockSlideshowAddSlideCommand';
import BlockSlideshowRemoveCommand from '@/store/builder/commands/BlockSlideshowRemoveCommand';
import BlockSlideshowRemoveSlideCommand from '@/store/builder/commands/BlockSlideshowRemoveSlideCommand';
import { PUBLISH_ERROR_MODAL } from '@/store/builder/constants/modals';
import { useRedirects } from '@/use/useRedirects';
import {
	cloneDeep,
	merge,
} from '@/utils/object';

import blog from './blog';
import colors from './colors';
import BlockAddCommand from './commands/BlockAddCommand';
import BlockRemoveCommand from './commands/BlockRemoveCommand';
import BlockSetDataCommand from './commands/BlockSetDataCommand';
import BlockSetDataCommandReversed from './commands/BlockSetDataCommandReversed';
import ElementAddCommand from './commands/ElementAddCommand';
import ElementRemoveCommand from './commands/ElementRemoveCommand';
import ElementSetDataCommand from './commands/ElementSetDataCommand';
import ElementSetDataCommandReversed from './commands/ElementSetDataCommandReversed';
import experiment from './experiment';
import fonts from './fonts';
import forms from './forms';
import gui, { OPEN_MODAL } from './gui';
import navigation from './navigation';
import notifications, {
	NOTIFICATION_TYPE_MODAL,
	NOTIFICATIONS_NAMESPACE,
	NOTIFY,
} from './notifications';
import nps from './nps';
import onlineStore from './onlineStore';
import pages from './pages';
import subscription from './subscription';
import undoRedo from './undoRedo';
import user from './user';

const { redirectToWWW } = useRedirects();

Vue.use(Vuex);

export const storeConfig = {
	modules: {
		gui,
		undoRedo,
		user,
		subscription,
		navigation,
		notifications,
		nps,
		forms,
		blog,
		onlineStore,
		pages,
		experiment,
		colors,
		fonts,
	},
	state: {
		website: null,
		websiteId: null,
		websiteName: null,
		domain: '',
		customDomain: '',
		hasActivePlan: '',
		currentBlockId: '',
		currentBlockRef: '',
		currentElementId: '',
		currentElementRef: null,
		currentElementBoxRef: null,
		templateId: null,
		template: null,
		isAppLoading: false,
		hasBuilderInitialized: false,
		templateAuthorSlug: '',
		published: null,
	},
	getters: {
		currentBlock: (state) => {
			if (state.currentBlockId) {
				return state.website.blocks[state.currentBlockId];
			}

			return null;
		},
		currentElement: (state) => {
			if (state.currentElementId) {
				return state.website.components[state.currentElementId];
			}

			return null;
		},
		currentElementSettings: (state, getters) => getters.currentElement?.settings,
		currentElementStyle: (state, getters) => getters.currentElementSettings?.style,
		getCurrentElementBlockId: (state) => Object
			.keys(state.website.blocks)
			.find((blockId) => state.website.blocks[blockId].components
				?.includes(state.currentElementId)),
		getBlockDuplicateById: (state) => (blockId) => {
			const blockById = state.website.blocks[blockId];
			const newComponents = {};
			const newBlockComponentIds = [];
			const newZindexIds = [...blockById.zindexes];
			const newBlockId = nanoid();

			// TODO: https://github.com/zyro-inc/zyro/issues/2934
			blockById.components.forEach((componentId) => {
				const newComponentId = nanoid();

				newComponents[newComponentId] = JSON.parse(
					JSON.stringify(state.website.components[componentId]),
				);
				newBlockComponentIds.push(newComponentId);
				// Keep zindex order, switch ids
				newZindexIds[newZindexIds.indexOf(componentId)] = newComponentId;
			});

			const newBlock = {
				...blockById,
				components: newBlockComponentIds,
				zindexes: newZindexIds,
			};

			return {
				newBlockId,
				newBlock,
				newComponents,
			};
		},
		getBlockSlideshowDuplicateBySlides: (state, getters) => (
			slides,
			blockId,
			newBlockIdToAdd = null,
		) => {
			const blockDuplicates = slides
				.map((slide) => getters.getBlockDuplicateById(slide.blockId));
			const newBlockId = newBlockIdToAdd ?? nanoid();
			const blockSlideshow = cloneDeep(state.website.blocks[blockId]);
			const {
				newComponents,
				newSlides,
				newSlidesArray,
			} = blockDuplicates
				.reduce((acc, curr, index) => ({
					newComponents: {
						...acc.newComponents,
						...curr.newComponents,
					},
					newSlides: {
						...acc.newSlides,
						[curr.newBlockId]: curr.newBlock,
					},
					newSlidesArray: [
						...acc.newSlidesArray,
						{
							blockId: curr.newBlockId,
							name: `${i18n.t('common.slide')} ${index + 1}`,
						},
					],
				}), {
					newComponents: {},
					newSlides: {},
					newSlidesArray: [],
				});

			return {
				previousBlockId: blockId,
				newBlockId,
				newBlock: {
					...blockSlideshow,
					slides: newSlidesArray,
				},
				newComponents,
				newSlides,
			};
		},
		websiteMeta: (state) => state.website?.meta ?? {},
		doesFooterExist: (state) => Object.values(state.website.blocks)
			.some((block) => block.slot === 'footer'),
		siteUrl: (state) => {
			// Free zyro domain sometimes is return without the publish suffix, so a check is needed
			const {
				domain,
				customDomain,
				hasActivePlan,
			} = state;
			const defaultDomain = domain || i18n.t('builder.domain.defaultDomain');
			const zyroDomain = defaultDomain
				.includes(process.env.VUE_APP_PUBLISH_DOMAIN) ? defaultDomain : `${defaultDomain}${process.env.VUE_APP_PUBLISH_DOMAIN}`;

			return `https://${hasActivePlan && customDomain ? customDomain : zyroDomain}`;
		},
		doesWebsiteHaveTemplate: (state) => (state.templateId !== GENERATED_TEMPLATE_ID),
	},
	mutations: {
		// App
		setIsAppLoading: (state, value) => {
			state.isAppLoading = value;
		},
		setHasBuilderInitialized: (state, value) => {
			state.hasBuilderInitialized = value;
		},
		// Website
		setWebsite: (state, {
			website,
			websiteId,
		}) => {
			state.website = website;
			if (websiteId) {
				state.websiteId = websiteId;
			}
		},
		setWebsiteName: (state, name) => {
			state.websiteName = name;
		},
		setDomain: (state, domain) => {
			state.domain = domain;
		},
		setCustomDomain: (state, customDomain) => {
			state.customDomain = customDomain;
		},
		setHasActivePlan: (state, hasActivePlan) => {
			state.hasActivePlan = !!hasActivePlan;
		},
		setWebsiteMeta: (state, payload) => {
			const {
				key,
				value,
			} = payload;

			Vue.set(state.website.meta, key, value);
		},
		setTemplateId: (state, payload) => {
			state.templateId = payload;
		},
		setTemplate: (state, payload) => {
			state.template = payload;
		},
		setTemplateAuthorSlug: (state, slug) => {
			state.templateAuthorSlug = slug;
		},
		setWebsitePublishDate: (state, published) => {
			state.published = published;
		},
		// Styles
		setStyleProperty: (state, {
			element,
			property,
			value,
		}) => {
			Vue.set(state.website.styles[element], property, value);
		},
		setStyleProperties: (state, {
			element,
			value,
		}) => {
			Vue.set(state.website.styles, element, {
				...state.website.styles[element],
				...value,
			});
		},
		// Block
		setCurrentBlock: (state, {
			blockRef,
			blockId,
		}) => {
			state.currentBlockId = blockId;
			state.currentBlockRef = blockRef;
		},
		addBlock: (state, {
			pageId,
			blockId,
			block,
		}) => {
			Vue.set(state.website.blocks, blockId, block);
			if (pageId) {
				state.website.pages[pageId].blocks.push(blockId);
			}
		},
		removeBlock: (state, {
			blockId,
			skipHistory = false,
		}) => {
			const action = new BlockRemoveCommand({
				state,
				blockId,
			});

			action.execute();
			if (!skipHistory) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},
		executeRemoveBlockSlideshow: (state, {
			blockId,
			skipHistory = false,
		}) => {
			const action = new BlockSlideshowRemoveCommand({
				state,
				blockId,
			});

			action.execute();
			if (!skipHistory) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},
		duplicateBlock: (state, {
			newComponents,
			newBlockId,
			newBlock,
			addToPage = true,
		}) => {
			const action = new BlockAddCommand({
				state,
				newComponents,
				newBlockId,
				newBlock,
				previousBlockId: state.currentBlockId,
				addToPage,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
		duplicateBlockSlideshow: (state, {
			newComponents,
			newSlides,
			newBlockId,
			newBlock,
			previousBlockId,
			addToPage = true,
		}) => {
			const action = new BlockSlideshowAddCommand({
				state,
				newComponents,
				newSlides,
				newBlockId,
				newBlock,
				previousBlockId,
				addToPage,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
		setBlockData: (state, {
			blockId = state.currentBlockId,
			data,
			skipHistory = true,
		}) => {
			const mergedData = merge(state.website.blocks[blockId], data);
			const action = new BlockSetDataCommand({
				state,
				blockId,
				data: mergedData,
			});
			const isApplied = action.execute();

			if (isApplied && !skipHistory) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},
		pushBlockDataToHistory: (state, {
			blockId = state.currentBlockId,
			oldData,
		}) => {
			const action = new BlockSetDataCommandReversed({
				state,
				blockId,
				oldData,
			});
			const isApplied = action.execute();

			if (isApplied) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},

		// Component
		setCurrentElement: (state, {
			elementId,
			elementBoxRef,
			elementRef,
		}) => {
			state.currentElementId = elementId;
			state.currentElementRef = elementRef;
			state.currentElementBoxRef = elementBoxRef;
		},
		unsetCurrentElement: (state) => {
			state.currentElementId = '';
			state.currentElementRef = null;
			state.currentElementBoxRef = null;
		},

		setElementData: (state, {
			elementId = state.currentElementId,
			data,
			skipHistory = true,
		}) => {
			const mergedData = merge(state.website.components[elementId], data);
			const action = new ElementSetDataCommand({
				state,
				elementId,
				data: mergedData,
			});
			const isApplied = action.execute();

			if (isApplied && !skipHistory) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},
		pushElementDataToHistory: (state, {
			elementId,
			oldData,
		}) => {
			const action = new ElementSetDataCommandReversed({
				state,
				elementId,
				oldData,
			});
			const isApplied = action.execute();

			if (isApplied) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},
		addElement: (state, {
			blockId,
			elementId,
			element,
			mobilePosition,
			previousElementId = state.currentElementId,
			skipHistory = false,
		}) => {
			const action = new ElementAddCommand({
				state,
				blockId,
				elementId,
				previousElementId,
				element,
				mobilePosition,
			});

			action.execute();

			if (!skipHistory) {
				state.undoRedo.history.push(action);
				state.undoRedo.redoHistory = [];
			}
		},
		removeElement: (state, {
			blockId,
			elementId,
		}) => {
			const action = new ElementRemoveCommand({
				state,
				blockId,
				elementId,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
		executeAddBlockSlideshow: (state, {
			newComponents,
			newSlides,
			newBlockId,
			newBlock,
			previousBlockId,
			addToPage,
		}) => {
			const action = new BlockSlideshowAddCommand({
				state,
				newComponents,
				newSlides,
				newBlockId,
				newBlock,
				previousBlockId,
				addToPage,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
		executeBlockSlideshowAddSlide: (state, {
			newComponents,
			newBlockId,
			newBlock,
			newSlide,
			slideshowBlockId,
		}) => {
			const action = new BlockSlideshowAddSlideCommand({
				state,
				newComponents,
				newBlockId,
				newBlock,
				newSlide,
				slideshowBlockId,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
		executeBlockSlideshowRemoveSlide: (state, {
			blockId,
			slideshowBlockId,
		}) => {
			const action = new BlockSlideshowRemoveSlideCommand({
				state,
				blockId,
				slideshowBlockId,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
	},
	actions: {
		setCurrentElement: ({ commit }, {
			elementId,
			elementBoxRef,
			elementRef,
		}) => {
			commit('setCurrentElement', {
				elementId,
				elementBoxRef,
				elementRef,
			});
		},
		initBuilder: async ({
			state,
			commit,
			dispatch,
			getters,
		}) => {
			commit('setIsAppLoading', true);
			const website = await getCurrentSite().catch(redirectToWWW);

			/*
			 * Short term hack to fix pages without types until the real cause is found.
			 * TODO: Remove this once long term solution is added.
			 */
			Object.entries(website.data.pages).forEach(([key, value]) => {
				if (!('type' in value)) {
					website.data.pages[key] = {
						...value,
						type: 'default',
					};
				}
			});

			commit('setWebsite', {
				website: website.data,
				websiteId: website.id,
			});
			commit('setTemplateId', website.templateId);
			commit('setTemplateAuthorSlug', website.templateAuthorSlug);
			commit('setWebsitePublishDate', website.published);

			// Load products to get pricing (dont't block it with await, it can by asynchronuos)

			/**
			 * Persisted state keeps page id.
			 * If this check is not present, then it gets reset to homepage.
			 */
			if (!state.pages.currentPageId) {
				dispatch('pages/setCurrentPage', { type: 'default' });
			}

			if (!(process.env.NODE_ENV === 'development' && sessionStorage.getItem('localTemplateName'))) {
				commit('setWebsiteMeta', {
					key: 'isFirstLoad',
					value: !!website.isFirstLoad,
				});

				await dispatch('user/getUser');
				dispatch('subscription/getProducts');
				dispatch('subscription/getPlan');
				dispatch('subscription/getAllSubscriptions');
				dispatch('subscription/getSubscription');

				// Domain is treated as a string in most places, but from DB often returned as 'null'.
				commit('setDomain', website.domain ?? '');
				commit('setCustomDomain', website.customDomain);
				commit('setHasActivePlan', website.hasActivePlan);
				commit('setWebsiteName', website.name);

				EventLogApi.logEvent({
					eventName: 'template.load',
					eventProperties: { templateId: website.templateId },
				});

				if (!getters['onlineStore/isSiteWithEcwid']) {
					EventLogApi.logEvent({
						amplitude: false,
						eventName: 'site.eshopDoNotContainBlock',
					});
				}
			}

			commit('setHasBuilderInitialized', true);
			commit('setIsAppLoading', false);
			dispatch('forms/generateFormToken');
		},
		getTemplate: async ({
			dispatch,
			commit,
		}) => {
			try {
				const template = await getCurrentTemplate();

				commit('setTemplate', template);
			} catch {
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					message: 'Error while loading template structure',
					origin: 'Vuex index store, getTemplate',
				});
			}
		},
		saveWebsiteToLocalStorage: (
			{ state },
			{
				websiteId = state.websiteId,
				websiteData,
				clientTimestamp = Math.floor(Date.now() / 1000),
			},
		) => {
			try {
				window.localStorage.setItem(
					`zyro_website_${websiteId}`,
					JSON.stringify({
						id: websiteId,
						data: websiteData,
						clientTimestamp,
					}),
				);
			} catch (error) {
				// Clear websites from Local storage if its full
				const localStorageKeys = Object.keys(window.localStorage);

				localStorageKeys.forEach((key) => {
					if (key.includes('zyro_website_')) {
						window.localStorage.removeItem(key);
					}
				});
				window.localStorage.setItem(
					`zyro_website_${websiteId}`,
					JSON.stringify({
						id: websiteId,
						data: websiteData,
						clientTimestamp,
					}),
				);
			}
		},
		publishWebsite: async ({
			commit,
			dispatch,
			state,
		}, domain) => {
			const newDomain = `${domain}${process.env.VUE_APP_PUBLISH_DOMAIN}`;
			const { websiteId } = state;
			const { template } = state.website.meta;

			try {
				await publishCurrentSite(newDomain, websiteId, template);
				commit('setDomain', newDomain);
				dispatch(`gui/${OPEN_MODAL}`, { name: 'PublishedModal' });
			} catch (error) {
				/**
				 * TODO: Proper error handling, should consult with ux,
				 * maybe try again button that would try to republish again
				 * without the need to open publish modal
				 */
				commit('setDomain', '');
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					type: NOTIFICATION_TYPE_MODAL,
					origin: 'Vuex index store, publishWebsite',
					props: { modalName: PUBLISH_ERROR_MODAL },
				});
			}
		},
		updateWebsite: async ({ dispatch }, payload = { showModal: true }) => {
			try {
				await updateCurrentSite();
				if (payload.showModal) {
					dispatch(`gui/${OPEN_MODAL}`, { name: 'PublishedChangesModal' });
				}
			} catch (error) {
				/**
				 * TODO: Proper error handling, should consult with ux,
				 * maybe try again button that would try to republish again
				 * without the need to open republish modal
				 */
				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					type: NOTIFICATION_TYPE_MODAL,
					origin: 'Vuex index store, updateWebsite',
					props: { modalName: PUBLISH_ERROR_MODAL },
				});
			}
		},
		saveWebsite: ({
			state,
			dispatch,
		}, payload) => {
			const { sendBeacon } = payload ?? {};
			const {
				website,
				websiteId,
			} = state;

			if (!websiteId) {
				return null;
			}

			const clientTimestamp = Math.floor(Date.now() / 1000);

			dispatch('saveWebsiteToLocalStorage', {
				websiteData: website,
				clientTimestamp,
			});

			return sendBeacon ? saveSiteSendBeacon(website, websiteId, clientTimestamp) : saveCurrentSite(website, clientTimestamp);
		},
		// Element
		duplicateCurrentElement: ({
			state,
			getters,
			commit,
			dispatch,
		}) => {
			// We should use a better way to deep clone
			const newComponent = cloneDeep(getters.currentElement);
			const newComponentId = nanoid();
			const newComponentPositions = newComponent.settings.styles.position.split('/');
			const BUILDER_HORIZONTAL_LIMIT = 15;
			const newComponentMobilePosition = state.website.blocks[getters.getCurrentElementBlockId]
				.components.findIndex((el) => el === state.currentElementId) + 1;

			newComponent.settings.styles.position = newComponentPositions.map((positionPoint, index) => {
				const positionPointNumber = Number.parseInt(positionPoint, 10);
				const isOutsideGrid = (index === 1 || index === 3)
					&& Number(newComponentPositions[3]) === BUILDER_HORIZONTAL_LIMIT;

				if (isOutsideGrid) {
					return positionPointNumber;
				}

				return positionPointNumber + 1;
			})
				.join('/');

			commit('addElement', {
				blockId: getters.getCurrentElementBlockId,
				elementId: newComponentId,
				element: newComponent,
				mobilePosition: newComponentMobilePosition,
			});
			dispatch('setCurrentElement', newComponentId);
		},
		removeCurrentElement: ({
			state,
			commit,
			dispatch,
			getters,
		}) => {
			if (state.currentElementId) {
				commit('removeElement', {
					blockId: getters.getCurrentElementBlockId,
					elementId: state.currentElementId,
				});
				dispatch('setCurrentElement', '');
			}
		},
		removeCurrentBlock: ({
			state,
			commit,
		}) => {
			commit('removeBlock', { blockId: state.currentBlockId });
			commit('setCurrentBlock', '');
		},
		removeBlock: ({
			commit,
			state,
		}, {
			blockId,
			skipHistory = false,
		}) => {
			if (state.website.blocks[blockId].type === 'BlockSlideshow') {
				commit('executeRemoveBlockSlideshow', {
					blockId,
					skipHistory,
				});
			} else {
				commit('removeBlock', {
					blockId,
					skipHistory,
				});
			}
		},
		duplicateBlock: ({
			state,
			getters,
			commit,
		}, {
			addToPage = true,
			blockId = null,
		}) => {
			const blockIdToDuplicate = blockId ?? state.currentBlockId;

			if (getters.currentBlock.type === 'BlockSlideshow') {
				commit('duplicateBlockSlideshow', {
					...getters
						.getBlockSlideshowDuplicateBySlides(getters.currentBlock.slides, blockIdToDuplicate),
					addToPage,
				});
			} else {
				commit('duplicateBlock', getters.getBlockDuplicateById(blockIdToDuplicate));
			}
		},
		addBlock: ({ state }, {
			blockId = nanoid(),
			block,
			previousBlockId = state.currentBlockId,
			components = {},
			addToPage = true,
		}) => {
			let newBlock = block;

			if (!newBlock) {
				newBlock = getGridBlock();
			}

			const action = new BlockAddCommand({
				state,
				newComponents: components,
				newBlockId: blockId,
				newBlock,
				previousBlockId,
				addToPage,
			});

			action.execute();
			state.undoRedo.history.push(action);
			state.undoRedo.redoHistory = [];
		},
		addBlockSlideshow: ({
			state,
			commit,
		}, {
			blockId,
			block,
			previousBlockId = state.currentBlockId,
			components = {},
			slides = {},
			addToPage = true,
		}) => {
			commit('executeAddBlockSlideshow', {
				newComponents: components,
				newSlides: slides,
				newBlockId: blockId,
				newBlock: block,
				previousBlockId,
				addToPage,
			});
		},
		addBlockSlideshowSlide: ({ commit }, {
			blockId,
			slideshowBlockId,
			block,
			newSlide,
			components = {},
		}) => {
			commit('executeBlockSlideshowAddSlide', {
				newComponents: components,
				newBlockId: blockId,
				newBlock: block,
				newSlide,
				slideshowBlockId,
			});

			EventLogApi.logEvent({ eventName: 'builder.slideshow.add_new_slide' });
		},
		removeBlockSlideshowSlide: ({ commit }, {
			blockId,
			slideshowBlockId,
		}) => {
			commit('executeBlockSlideshowRemoveSlide', {
				blockId,
				slideshowBlockId,
			});

			EventLogApi.logEvent({ eventName: 'builder.slideshow.remove_slide' });
		},
		duplicateBlockSlideshowSlide: ({ commit }, {
			newBlockId,
			newBlock,
			newComponents,
			newSlide,
			slideshowBlockId,
		}) => {
			commit('executeBlockSlideshowAddSlide', {
				newBlockId,
				newBlock,
				newComponents,
				newSlide,
				slideshowBlockId,
			});

			EventLogApi.logEvent({ eventName: 'builder.slideshow.duplicate_slide' });
		},
	},
};

export default new Vuex.Store(storeConfig);
