<template>
	<div
		:id="blockId"
		class="block-ecwid-store"
	>
		<div
			v-if="showStore"
			:id="`my-store-${storeId}`"
			ref="store"
		/>
	</div>
</template>

<script>
/**
 * TODO: IMPROVEMENTS
 * Store page gets cached
 * Move out constant to a global one
 * Filter modal in preview mode goes full screen (test in live site if does not overlay)
 * Mobile modee not really responsive - https://github.com/zyro-inc/zyro/issues/1840
 * Loaded until store is showed
 * https://github.com/zyro-inc/zyro/issues/1900
 * https://github.com/zyro-inc/zyro/issues/1997 ?
 * https://github.com/zyro-inc/zyro/issues/2031
 * https://github.com/zyro-inc/zyro/issues/2589
 * https://github.com/zyro-inc/zyro/issues/2851
 * https://github.com/zyro-inc/zyro/issues/2865
 * https://github.com/zyro-inc/zyro/issues/2866
 * https://github.com/zyro-inc/zyro/issues/2895
 * https://github.com/zyro-inc/zyro/issues/2982
 */

import { mapState } from 'vuex';

import ecwidScript from '@/components/block-ecwid-store/ecwidScript';
import { isAppPrerendering } from '@user/utils/isAppPrerendering';

const DEFAULT_STORE_ID = '26103239';

/**
 * Taken from builderRoot.vue .builder-root__main transition
 * TODO: For all common CSS/SASS/JS variables - https://www.bluematador.com/blog/how-to-share-variables-between-js-and-sass
 */

const ANIMATION_TIME = 400;

export default {
	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return { showStore: true };
	},
	computed: {
		...mapState(['website']),
		...mapState('gui', ['isMobileView']),
		storeId() {
			const {
				ecwidStoreId,
				demoEcwidStoreId,
			} = this.website.meta;

			return ecwidStoreId || demoEcwidStoreId || DEFAULT_STORE_ID;
		},
	},
	watch: {
		async storeId(newId) {
			if (this.website.meta.demoEcwidStoreId === newId) {
				return;
			}

			// Delete previous store
			this.$refs.store.innerHTML = '';
			// Wait until template id changes
			await this.$nextTick();
			this.startLoadingEcwidScript();
		},
		isMobileView() {
			this.showStore = false;

			setTimeout(() => {
				this.showStore = true;
				ecwidScript.load(this.storeId);
			}, ANIMATION_TIME);
		},
	},
	mounted() {
		this.startLoadingEcwidScript();
	},
	methods: {
		startLoadingEcwidScript() {
			if (isAppPrerendering) {
				ecwidScript.preload(this.storeId);
			} else {
				ecwidScript.load(this.storeId);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./BlockEcwidStore";
</style>

<style lang="scss">
@import "./BlockEcwidStoreGlobal";

#ecwid-snapshot-html {
	pointer-events: none;

	.grid-product__image,
	.grid-category__card,
	.form-control__button {
		@include skeletonPlaceholder;
	}
}
</style>
