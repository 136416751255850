<template>
	<Transition
		name="fade"
		mode="out-in"
		appear
	>
		<div
			v-if="currentStep"
			class="nps"
			data-qa="nps-modal"
		>
			<ZyroSvg
				name="close-sm"
				class="nps__close-button"
				data-qa="nps-modal-button-close"
				@click="closeNPS"
			/>
			<div
				v-if="currentStep === $options.SCORE_STEP"
				class="nps__score"
			>
				<h5 class="nps__heading z-h5">
					{{ $t('builder.nps.scoreTitle') }}
				</h5>
				<div class="nps__score-buttons">
					<button
						v-for="score in 10"
						:key="score"
						v-qa="`nps-modal-score-${score}`"
						:title="$t('builder.nps.scoreButtonTitle', [score])"
						class="nps__score-button z-body z-body--strong"
						theme="outline"
						size="xxs"
						:disabled="isAlreadyClicked"
						@click="handleScoreSelect(score)"
					>
						{{ score }}
					</button>
				</div>
				<div class="nps__score-labels z-body">
					<div>
						👎 <span>{{ $t('builder.nps.notLikely') }}</span>
					</div>
					<div>
						<span>{{ $t('builder.nps.extremelyLikely') }}</span> 👍
					</div>
				</div>
				<div />
			</div>
			<div
				v-if="currentStep === $options.COMMENT_STEP"
				class="nps__comment"
			>
				<div class="nps__comment-body">
					<ZyroLabel class="nps__comment-label">
						{{ $t('builder.nps.commentTitle') }}
					</ZyroLabel>
					<ZyroTextArea
						v-model="commentModel"
						class="nps__comment-input"
						data-qa="nps-modal-textarea-feedback"
					/>
				</div>
				<div class="nps__comment-footer">
					<ZyroButton
						theme="outline"
						:title="$t('common.cancel')"
						data-qa="nps-modal-button-cancel"
						@click="closeNPS"
					>
						{{ $t('common.cancel') }}
					</ZyroButton>
					<ZyroButton
						theme="primary"
						:title="$t('common.send')"
						data-qa="nps-modal-button-send"
						@click="sendComment"
					>
						{{ $t('common.send') }}
					</ZyroButton>
				</div>
			</div>
			<div
				v-if="currentStep === $options.THANKS_STEP"
				class="nps__thanks-section"
				data-qa="nps-modal-section-thanks"
			>
				<h5 class="z-h5 nps__heading">
					{{ $t('builder.nps.thanks') }}
				</h5>
			</div>
		</div>
	</Transition>
</template>

<script>
import { mapActions } from 'vuex';

import {
	sendNpsScore,
	sendComment,
} from '@/api/NpsApi';

const SCORE_STEP = 'score';
const COMMENT_STEP = 'comment';
const THANKS_STEP = 'thanks';
const THANKS_SECTION_TIMEOUT = 2500;

export default {
	SCORE_STEP,
	COMMENT_STEP,
	THANKS_STEP,
	data() {
		return {
			currentStep: SCORE_STEP,
			comment: '',
			scoreId: null,
			isAlreadyClicked: false,
		};
	},
	computed: {
		commentModel: {
			get() {
				return this.comment;
			},
			set(event) {
				this.comment = event.target.value;
			},
		},
	},
	methods: {
		...mapActions('nps', ['setNpsVisibility']),
		async handleScoreSelect(score) {
			this.isAlreadyClicked = true;
			this.scoreId = await sendNpsScore(score);
			this.currentStep = COMMENT_STEP;
		},
		sendComment() {
			sendComment(this.scoreId, this.comment);
			this.currentStep = THANKS_STEP;
			setTimeout(() => { this.closeNPS(); }, THANKS_SECTION_TIMEOUT);
		},
		closeNPS() {
			this.setNpsVisibility(false);
			this.currentStep = SCORE_STEP;
		},
	},
};
</script>

<style lang="scss" scoped>
.nps {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: z-index(modal);
	width: 758px;
	margin-top: -150px;
	margin-left: -379px;
	background: $light;
	border-radius: 15px;
	box-shadow: 0 6px 14px rgba($dark, 0.35);

	&__close-button {
		position: absolute;
		top: 14px;
		right: 14px;
		cursor: pointer;
	}

	&__score {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 64px 24px;
	}

	&__comment {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: 46px 0 14px;
	}

	&__heading {
		margin-bottom: 32px;
		color: $grey-800;
		text-align: center;
	}

	&__score-buttons {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 16px;
	}

	&__score-button {
		width: 56px;
		height: 56px;
		cursor: pointer;
		border: 1px solid $grey-400;
		border-radius: 50%;
		transition: all 0.2s $easing-standard;

		&:focus {
			background: $primary-lighter;
			border: 2px solid $primary;
		}

		&:hover {
			background: $primary-lighter;
		}
	}

	&__score-labels {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		color: $grey-800;

		span {
			font-size: 14px;
		}
	}

	&__comment-body {
		padding: 0 24px 0;
	}

	&__comment-label {
		margin-bottom: 6px;
	}

	&__comment-input {
		height: 96px;
	}

	&__comment-footer {
		display: flex;
		justify-content: space-between;
		padding: 13px 24px 0;
		margin-top: 10px;
		border-top: 1px solid $grey-300;
	}

	&__thanks-section {
		padding: 100px 0 68px;
	}
}
</style>
