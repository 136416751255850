// This is a tweaked clone of: https://github.com/nuxt-community/google-optimize-module/blob/master/lib/plugin.js
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import {
	parse as parseCookie,
	serialize as serializeCookie,
} from 'cookie';
import weightedRandom from 'weighted-random';

const MAX_AGE = 24 * 60 * 60;
const COOKIE_NAME = 'exp';

// import all experiments from files
const experiments = [];
const importAll = (r) => r.keys().forEach((key) => {
	if (key === './constants.js') {
		return;
	}

	experiments.push(r(key).default);
});

importAll(require.context('@/experiments', false, /.(js)$/));

export const assignExperiment = (routeTo) => {
	// Choose experiment and variant
	let experimentIndex = -1;
	let experiment = {};
	let variantIndexes = [];
	let classes = [];

	// Try to restore from cookie
	const cookie = parseCookie(document.cookie)[COOKIE_NAME] || '';
	const [cookieExp, cookieVariables] = cookie.split('.');

	if (cookieExp && cookieVariables) {
		// Try to find experiment with that id
		experimentIndex = experiments.findIndex((exp) => exp.experimentID === cookieExp);
		experiment = experiments[experimentIndex];

		// Variant indexes
		variantIndexes = cookieVariables.split('-').map((v) => Number.parseInt(v, 10));
	}

	// Choose one experiment
	const experimentWeights = experiments.map((exp) => exp.weight ?? 1);
	let retries = experiments.length;

	while (experimentIndex === -1 && retries > 0) {
		experimentIndex = weightedRandom(experimentWeights);
		experiment = experiments[experimentIndex];

		// Check if current user is eligible for experiment
		if (typeof experiment.isEligible === 'function' && !experiment.isEligible(routeTo)) {
			// Try another one
			experimentWeights[experimentIndex] = 0;
			experimentIndex = -1;
		}

		retries -= 1;
	}

	if (experimentIndex !== -1) {
		// Validate variantIndexes against experiment (coming from cookie)
		variantIndexes = variantIndexes.filter((index) => experiment.variants[index]);

		// Choose enough variants
		const variantWeights = experiment.variants.map((variant) => variant.weight ?? 1);

		while (variantIndexes.length < (experiment.sections || 1)) {
			const index = weightedRandom(variantWeights);

			variantWeights[index] = 0;
			variantIndexes.push(index);
		}

		// Write exp cookie if changed
		const expCookie = `${experiment.experimentID}.${variantIndexes.join('-')}`;

		if (cookie !== expCookie) {
			document.cookie = serializeCookie(COOKIE_NAME, expCookie, {
				path: '/',
				maxAge: experiment.maxAge ?? MAX_AGE,
				domain: process.env.COOKIE_DOMAIN,
			});
		}

		// Compute global classes to be injected
		classes = variantIndexes.map((index) => `exp-${experiment.name}-${index}`);
	} else {
		// No active experiment
		experiment = {};
		variantIndexes = [];
		classes = [];
	}

	return {
		...experiment,
		$experimentIndex: experimentIndex,
		$variantIndexes: variantIndexes,
		$activeVariants: variantIndexes.map((index) => experiment.variants[index]),
		$classes: classes,
	};
};
