import VueCompositionApi, {
	ref,
	computed,
} from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionApi);

// Use one instance for all buttons
const stripeInstance = ref(null);

/*
 * One time payment with checkout docs:
 * https://stripe.com/docs/payments/checkout/client
 */

export const useStripeGridButton = (props) => {
	const priceId = computed(() => props.data.settings?.priceId || '');
	const successPageUrl = computed(() => props.data.settings?.successPageUrl || '/');
	const cancellationPageUrl = computed(() => props.data.settings?.cancellationPageUrl || '/');
	const paymentType = computed(() => props.data.settings?.paymentType || 'payment');

	return {
		stripeInstance,
		priceId,
		paymentType,
		successPageUrl,
		cancellationPageUrl,
	};
};
