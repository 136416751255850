import Vue from 'vue';

import { EventBus } from '@/eventBus';

export default class {
	constructor(parameters) {
		this.type = 'REMOVE';
		this.params = parameters;
		this.oldBlock = undefined;
		this.oldPageBlocks = undefined;
		this.oldComponents = {};
		this.pageId = parameters.state.pages.currentPageId;
	}

	execute() {
		const {
			state,
			blockId,
		} = this.params;
		// Get current block page
		const blockPage = state.website.pages[this.pageId];
		// Slice shallow copies the array
		const currentPageBlocks = blockPage.blocks.slice();
		const currentPageComponents = state.website.blocks[blockId].components;

		// Make array of blocks without removed block
		const newPageBlocks = currentPageBlocks.filter((id) => id !== blockId);

		// Save old blocks for undo
		this.oldPageBlocks = currentPageBlocks;
		// Save old block info for undo
		this.oldBlock = state.website.blocks[blockId];

		// Remove block from current page blocks
		Vue.set(blockPage, 'blocks', newPageBlocks);
		// Remove block from blocks
		Vue.delete(state.website.blocks, blockId);
		// Remove unused components
		currentPageComponents.forEach((componentId) => {
			this.oldComponents[componentId] = state.website.components[componentId];
			Vue.delete(state.website.components, componentId);
		});
	}

	undo() {
		EventBus.$emit('before-undo');
		const {
			state,
			blockId,
		} = this.params;

		const blockPage = state.website.pages[this.pageId];

		// Re-add old components to instance
		Object.entries(this.oldComponents).forEach(([componentId, componentValue]) => {
			Vue.set(state.website.components, componentId, componentValue);
		});
		Vue.set(state.website.blocks, blockId, this.oldBlock);
		Vue.set(blockPage, 'blocks', this.oldPageBlocks);

		EventBus.$emit('after-undo');
	}
}
