import axios from 'axios';

const SUBSCRIPTIONS_API = `${process.env.VUE_APP_API_URL}/v3/subscriptions`;

export const getPlan = () => axios.get(`${SUBSCRIPTIONS_API}/plan`).then((result) => result.data);
export const getCurrentSiteSubscription = () => axios.get(`${SUBSCRIPTIONS_API}/current-site`);
export const cancelSubscription = (siteId) => axios.patch(`${SUBSCRIPTIONS_API}/cancel/${siteId}`);
export const getAllSubscriptions = () => axios.get(`${SUBSCRIPTIONS_API}`).then((res) => res.data);
export const activatePlan = (siteId) => {
	let query = '';

	if (siteId) {
		query = `?site-id=${encodeURIComponent(siteId)}`;
	}

	return axios.get(`${SUBSCRIPTIONS_API}/activateplan${query}`);
};
