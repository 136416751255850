<template>
	<div
		ref="popupCard"
		v-qa="dataQa"
		class="popup-card"
		:class="{
			'popup-card--no-padding': type === 'no-padding',
			'popup-card--editor': type === 'editor',
			'popup-card--absolute': isAbsolute,
		}"
	>
		<div
			v-if="showCloseButton || title || tabs.length"
			class="popup-card__head"
		>
			<h4
				v-if="title"
				class="popup-card__title z-h5"
				:class="{ 'popup-card__title--no-margin-bottom': !tabs.length }"
			>
				<!-- TODO: different prop name. It looks like native HTML "title" attribute -->
				{{ title }}
				<slot name="title-suffix" />
			</h4>
			<ZyroButton
				v-if="showCloseButton"
				class="popup-card__close"
				data-qa="builder-popupcard-btn-close"
				icon="x"
				:title="$t('common.close')"
				@click="$emit('close')"
			/>
			<ZyroTabs
				v-if="tabs.length"
				:tabs="tabs"
				:current-tab="currentTab"
				class="popup-card__tabs"
				@change="$emit('change', $event)"
			/>
		</div>
		<div
			class="popup-card__content"
			:class="{ 'popup-card__content--align-left': isLeftAligned }"
		>
			<slot />
		</div>
		<div
			v-if="showFooter"
			class="popup-card__footer"
		>
			<ZyroButton
				v-qa="'builder-popupcard-cancelbtn'"
				@click="$emit('close')"
			>
				{{ $t('common.cancel') }}
			</ZyroButton>
			<ZyroButton
				v-qa="'builder-popupcard-savebtn'"
				:disabled="error"
				theme="primary"
				@click="$emit('save')"
			>
				{{ $t('common.save') }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
import {
	disableBodyScroll,
	enableBodyScroll,
} from 'body-scroll-lock';

export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
			validator: (type) => [
				'no-padding',
				'editor',
				'',
			].includes(type),
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: false,
		},
		tabs: {
			type: Array,
			default: () => [],
		},
		currentTab: {
			type: Object,
			default: () => {},
		},
		error: {
			type: Boolean,
			default: false,
		},
		dataQa: {
			type: String,
			default: '',
		},
		isLeftAligned: {
			type: Boolean,
			default: false,
		},
		isAbsolute: {
			type: Boolean,
			default: false,
		},
		disableBodyScroll: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		if (this.disableBodyScroll) {
			disableBodyScroll(this.$refs.popupCard);
		}
	},
	beforeDestroy() {
		if (this.disableBodyScroll) {
			enableBodyScroll(this.$refs.popupCard);
		}
	},
};
</script>

<style lang="scss" scoped>
.popup-card {
	$this: &;

	position: relative;
	z-index: z-index(controls--popup-card);
	max-width: 400px;
	padding: 24px;
	user-select: none;
	background: $light;
	border-radius: $border-radius-large;
	box-shadow: $box-shadow;

	&--no-padding {
		padding: 0;
		border-radius: $border-radius-small;
	}

	&--editor {
		width: 360px;
		padding: 0;
		border-radius: $border-radius-large;

		#{$this}__head {
			padding: 24px 24px 0 24px;
		}

		#{$this}__content {
			padding: 14px 24px 14px 24px;
		}
	}

	&--absolute {
		position: absolute;
	}

	&__head {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}

	&__content {
		&--align-left {
			text-align: left;
		}
	}

	&__title {
		margin-right: 1em;
		margin-bottom: 24px;

		&--no-margin-bottom {
			margin-bottom: 0;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 12px 24px 14px 24px;
		border-top: 1px solid $grey-300;
	}

	&__close {
		position: absolute;
		top: 14px;
		right: 14px;
		box-sizing: content-box;
		cursor: pointer;
		background-color: transparent;
		border: 0;
	}
}
</style>
