import axios from 'axios';

import EventLogApi from '@/api/EventLogApi';

const PUBLISH_API_V3 = `${process.env.VUE_APP_API_URL}/v3/publish`;

export const isDomainAvailable = async (domain) => {
	const { data } = await axios.get(`${PUBLISH_API_V3}/isavailable/${domain}`);

	EventLogApi.logEvent({
		eventName: 'user.domain_check',
		eventProperties: { domainName: domain },
	});

	return data.ok;
};

export const publishCurrentSite = async (domain, websiteId, template) => {
	const { data } = await axios.post(`${PUBLISH_API_V3}/current`, { newDomain: domain });

	EventLogApi.logEvent({
		eventName: 'site.published',
		eventProperties: {
			domainName: data.newDomain,
			websiteId,
			template,
		},
	});

	return data.newDomain;
};

export const publishDesignerTemplate = async () => {
	const { data } = await axios.post(`${PUBLISH_API_V3}/current`);

	EventLogApi.logEvent({
		eventName: 'site.published',
		eventProperties: { domainName: data.newDomain },
	});

	return data.newDomain;
};

export const updateCurrentSite = () => axios.post(`${PUBLISH_API_V3}/current`);
