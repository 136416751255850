import localesTarget from '@/i18n/localesTarget.json';

/**
 * Value of the local storage key, by which to get/set current locale code
 * @constant
 */
const LOCAL_STORAGE_KEY = 'locale';

/**
 * This gets the current locale from local storage
 * @export
 * @returns {string} - current locale code
 */
export function getLocaleFromLocalStorage() {
	return window.localStorage.getItem(LOCAL_STORAGE_KEY);
}

/**
 * This sets the current locale to local storage
 * @export
 * @param {string} locale - locale which to set into browser local storage
 */
export function setLocaleToLocalStorage(locale) {
	window.localStorage.setItem(LOCAL_STORAGE_KEY, locale);
}

/**
 * This gets the current locale from local storage
 * @export
 * @returns {object} - current locale object
 */
export function getLocaleObjectFromLocalStorage() {
	const localeCode = window.localStorage.getItem(LOCAL_STORAGE_KEY);

	return localesTarget.find((locale) => locale.code === localeCode);
}
