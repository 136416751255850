import { nanoid } from 'nanoid';
import Vue from 'vue';

import EventLogApi from '@/api/EventLogApi';
import { getRandom } from '@/api/UnsplashApi';
import {
	getEcwidBlock,
	getBlogPostGridBlock,
	getBlogHeaderBlock,
	getBlogListBlock,
} from '@/components/block/blocks';
import { blogPageElements } from '@/components/builder-controls/add-element/elements';
import { predefinedImages } from '@/data/images';
import i18n from '@/i18n/setup';
import { NAVIGATION_PAGE_TYPE } from '@/use/navigation/constants';
import { capitalizeFirstLetter } from '@/utils/modifyString';
import {
	merge,
	cloneDeep,
	filterObject,
	kebabCase,
} from '@/utils/object';

import PushPageDataToHistoryCommand from './commands/PushPageDataToHistoryCommand';

export default {
	namespaced: true,
	state: {
		currentPageId: '',
		mostRecentBuilderPageId: '',
	},
	getters: {
		currentPage: (state, getters, rootState) => (
			state.currentPageId ? rootState?.website?.pages[state.currentPageId] : null
		),

		isPageUrlUnique: (state, getters, rootState) => (url) => !Object.keys(rootState.website.pages)
			.some((page) => rootState.website.pages[page].path === url),

		defaultPages: (state, getters, rootState) => filterObject(rootState.website.pages, ({ value }) => value.type === 'default'),

		blogPages: (state, getters, rootState) => filterObject(rootState.website.pages, ({ value }) => value.type === 'blog'),

		ecommercePages: (state, getters, rootState) => {
			const {
				pages,
				blocks: siteBlocks,
			} = rootState.website;

			return filterObject(pages, ({ value }) => value.blocks.some((key) => siteBlocks[key].type === 'BlockEcwidStore'));
		},

		draftBlogPages: (state, getters) => filterObject(
			getters.blogPages,
			({ value }) => value.customData.hidden,
		),

		publishedBlogPages: (state, getters) => filterObject(
			getters.blogPages,
			({ value }) => !value.customData.hidden,
		),

		isEmptyPageBlockShown: (state, getters, rootState, rootGetters) => (
			!getters.currentPage.blocks.length && !rootGetters['gui/isNavigationVisible']
		),
	},
	mutations: {
		addPage: (state, {
			rootState,
			pageId,
			page,
		}) => {
			Vue.set(rootState.website.pages, pageId, page);
		},
		// TODO: remove page/block/component logic should be moved to RemovePageCommand
		removePage: (state, {
			rootState,
			pageId,
		}) => {
			Vue.delete(rootState.website.pages, pageId);
		},
		setCurrentPage: (state, { pageId }) => {
			state.currentPageId = pageId;
			window.scrollTo(0, 0);
		},
		setPageData: (state, {
			rootState,
			pageId,
			data,
		}) => {
			Vue.set(rootState.website.pages, pageId, data);
		},
		pushPageDataToHistory: (state, {
			rootState,
			pageId,
			oldData,
		}) => {
			const action = new PushPageDataToHistoryCommand({
				state: rootState,
				pageId,
				oldData,
			});
			const isApplied = action.execute();

			if (isApplied) {
				rootState.undoRedo.history.push(action);
				Vue.set(rootState.undoRedo, 'redoHistory', []);
			}
		},
		setMostRecentBuilderPage: (state, { pageId }) => {
			state.mostRecentBuilderPageId = pageId;
		},
	},
	actions: {
		// ---Base implementations---
		baseAddPage: ({
			commit,
			rootState,
		}, {
			pageId = nanoid(),
			name = 'New page',
			path = `/page-${pageId}`,
			blocks = [],
			type = 'default',
			...customProperties
		}) => {
			commit('addPage', {
				rootState,
				pageId,
				page: {
					name,
					path,
					blocks,
					type,
					...customProperties,
				},
			});
		},

		baseDuplicatePage: ({
			dispatch,
			commit,
			rootState,
			rootGetters,
		}, {
			pageId,
			newPageId = nanoid(),
			template,
		}) => {
			const blockIds = [];
			const duplicatedPage = template ? template.pages[pageId] : rootState.website.pages[pageId];
			const copyRoot = template || rootState.website;

			// Duplicate regular (non-slideshow) blocks
			duplicatedPage.blocks.forEach((blockId) => {
				if (!copyRoot.blocks[blockId]) {
					return;
				}

				// Add block with newly created element ids
				const newBlockId = nanoid();

				if (copyRoot.blocks[blockId].type === 'BlockSlideshow') {
					commit('executeAddBlockSlideshow', {
						...rootGetters
							.getBlockSlideshowDuplicateBySlides(
								copyRoot.blocks[blockId].slides,
								blockId,
								newBlockId,
							),
					}, { root: true });

					blockIds.push(newBlockId);

					return;
				}

				const { components } = copyRoot;

				// Generate new ids and zindexes
				const newZindexIds = cloneDeep(copyRoot.blocks[blockId].zindexes);
				const newBlockComponentIds = [];

				copyRoot.blocks[blockId].components.forEach((componentId) => {
					const newComponentId = nanoid();

					newBlockComponentIds.push(newComponentId);
					newZindexIds[newZindexIds.indexOf(componentId)] = newComponentId;
				});

				/**
				 * Add elements without adding to block
				 * Since they will be referenced manually when adding block below
				 * TODO: https://github.com/zyro-inc/zyro/issues/2934
				 */
				copyRoot.blocks[blockId].components.forEach((componentId, index) => {
					if (!components[componentId]) {
						return;
					}

					commit('addElement', {
						elementId: newBlockComponentIds[index],
						element: cloneDeep(components[componentId]),
						skipHistory: true,
					}, { root: true });
				});

				commit('addBlock', {
					blockId: newBlockId,
					block:
					{
						...cloneDeep(copyRoot.blocks[blockId]),
						components: newBlockComponentIds,
						zindexes: newZindexIds,
					},
				}, { root: true });

				blockIds.push(newBlockId);
			});

			dispatch('baseAddPage', {
				...duplicatedPage,
				pageId: newPageId,
				name: `${duplicatedPage.name}Copy`,
				path: `/${kebabCase(duplicatedPage.name)}-copy-${nanoid()}`,
				type: duplicatedPage.type,
				blocks: blockIds,
			});
		},

		baseRemovePage: ({
			rootState,
			commit,
			dispatch,
		}, { pageId }) => {
			const { pages } = rootState.website;

			const pageToDeleteBlocks = [];
			const otherPagesBlocks = [];

			Object.entries(pages).forEach(([id, page]) => {
				if (id === pageId) {
					pageToDeleteBlocks.push(...page.blocks);
				} else {
					otherPagesBlocks.push(...page.blocks);
				}
			});

			// Filter out blocks that are used in multiple pages
			const blocksToDelete = pageToDeleteBlocks.filter(
				(blockId) => !otherPagesBlocks.includes(blockId),
			);

			blocksToDelete.forEach((blockId) => {
				dispatch('removeBlock', {
					blockId,
					skipHistory: true,
				}, { root: true });
			});

			commit('removePage', {
				rootState,
				pageId,
			});
		},

		baseSetCurrentPage: ({
			commit,
			rootState,
		}, { pageId = rootState.website.meta.homepageId }) => {
			commit('setCurrentPage', { pageId });
		},

		baseSetPageData: async ({
			commit,
			state,
			rootState,
		},
		{
			pageId = state.currentPageId,
			data,
		}) => {
			const pageData = rootState.website.pages[pageId];
			const mergedData = merge(pageData, data);

			commit('setPageData', {
				rootState,
				pageId,
				data: mergedData,
			});
		},

		basePushPageDataToHistory: ({
			commit,
			rootState,
		}, {
			pageId,
			oldData,
		}) => {
			commit('pushPageDataToHistory', {
				rootState,
				pageId,
				oldData,
			});
		},

		/*
		 * ---Concrete implementations---
		 * Common
		 */
		changePage: (
			{
				commit,
				dispatch,
				rootState,
			},
			payload = {},
		) => {
			const pageId = payload.pageId ?? rootState.website.meta.homepageId;

			commit('unsetCurrentElement', undefined, { root: true });
			dispatch('baseSetCurrentPage', { pageId });
		},

		setMostRecentBuilderPage: ({ commit }, payload = {}) => {
			commit('setMostRecentBuilderPage', { pageId: payload.pageId });
		},

		// Navigation pages
		addDefaultPage: ({ dispatch }, {
			pageId = nanoid(),
			name,
			path,
			blocks,
		}) => {
			dispatch('baseAddPage', {
				pageId,
				name,
				path,
				blocks,
				type: 'default',
			});
			dispatch('navigation/addItem', {
				itemId: pageId,
				item: {
					type: NAVIGATION_PAGE_TYPE,
					subItems: [],
				},
			}, { root: true });
			dispatch('changePage', { pageId });
		},

		duplicateDefaultPage: ({ dispatch },
			{
				id = nanoid(),
				pageId,
				name,
				isHidden = false,
				template,
			}) => {
			dispatch('baseDuplicatePage', {
				pageId,
				name,
				newPageId: id,
				template,
			});
			dispatch('navigation/addItem', {
				itemId: id,
				item: {
					type: NAVIGATION_PAGE_TYPE,
					subItems: [],
				},
				isHidden,
			}, { root: true });
			dispatch('changePage', { pageId: id });
		},

		removeDefaultPage: ({
			state,
			dispatch,
			rootState,
		}, { pageId }) => {
			if (Object.keys(rootState.website.pages).length <= 1) {
				// TODO handle this error with toast message that can't delete last page
			}

			dispatch('baseRemovePage', { pageId });
			dispatch('navigation/removeItem', { itemId: pageId }, { root: true });
			if (pageId === state.currentPageId) {
				dispatch('changePage');
			}
		},

		setCurrentDefaultPage: ({ dispatch }, payload) => {
			dispatch('baseSetCurrentPage', { ...payload });
		},

		setDefaultPageData: ({ dispatch }, payload) => {
			dispatch('baseSetPageData', { ...payload });
		},

		pushDefaultPageDataToHistory: ({ dispatch }, payload) => {
			dispatch('basePushPageDataToHistory', { ...payload });
		},

		// Ecommerce pages
		addEcommercePage: ({
			commit,
			dispatch,
			getters,
		},
		{
			pageId = nanoid(),
			blockId = nanoid(),
		}) => {
			const pageNameSuffix = Object.values(getters.ecommercePages).length || '';

			dispatch('baseAddPage', {
				pageId,
				name: `Store${pageNameSuffix}`,
				path: `/store-${nanoid()}`,
				blocks: [],
				type: 'default',
			});

			commit('addBlock', {
				pageId,
				blockId,
				block: getEcwidBlock(),
			}, { root: true });

			dispatch('navigation/addItem', {
				itemId: pageId,
				item: {
					type: NAVIGATION_PAGE_TYPE,
					subItems: [],
				},
			}, { root: true });

			dispatch('changePage', { pageId });
		},

		duplicateEcommercePage: ({ dispatch }, payload) => {
			dispatch('baseDuplicatePage', { ...payload });
		},

		removeEcommercePage: ({ dispatch }, payload) => {
			dispatch('removeDefaultPage', { ...payload });
		},

		setCurrentEcommercePage: ({ dispatch }, payload) => {
			dispatch('baseSetCurrentPage', { ...payload });
		},

		setEcommercePageData: ({ dispatch }, payload) => {
			dispatch('baseSetPageData', { ...payload });
		},

		pushEcommercePageDataToHistory: ({ dispatch }, payload) => {
			dispatch('basePushPageDataToHistory', { ...payload });
		},

		/**
		 * Blog pages
		 * First, add a new blog header block, then add the ID to components array.
		 */
		addBlogPage: async (
			{
				commit,
				dispatch,
			},
			{
				pageId = nanoid(),
				hidden = true,
				changePage = true,
			},
		) => {
			const headerId = `${pageId}-header`;
			const sectionId = `${pageId}-section`;
			const customData = {
				hidden,
				coverImage: {
					src: '',
					alt: '',
				},
				// Returns the date in YYYY-MM-dd-H:m:s
				date: new Date().toISOString(),
				categories: [],
				minutesToRead: '0',
			};

			commit('addBlock', {
				blockId: headerId,
				block: getBlogHeaderBlock(),
			}, { root: true });
			commit('addBlock', {
				blockId: sectionId,
				block: getBlogPostGridBlock(),
			}, { root: true });

			commit('addElement', {
				blockId: sectionId,
				elementId: `${pageId}-section-title`,
				element: blogPageElements.getContentTextBox(i18n.t('builder.blog.blogPageElements.content')),
				skipHistory: true,
			}, { root: true });

			dispatch('baseAddPage', {
				pageId,
				type: 'blog',
				name: i18n.t('builder.blog.blogPost.title'),
				path: `/newBlogPost-${pageId}`,
				blocks: [
					headerId,
					sectionId,
				],
				meta: {
					title: i18n.t('builder.blog.blogPost.title'),
					description: i18n.t('builder.blog.blogPost.description'),
				},
				customData,
			});

			try {
				const data = await getRandom({ count: 1 });
				let coverImage = {};

				if (data.results.length) {
					coverImage = {
						src: data.results[0].urls.regular,
						alt: data.results[0].alt_description,
					};
				} else {
					// Take predefined image data as fallback
					coverImage = { ...predefinedImages.blogCoverImage };
				}

				// Optimization: get cover image only after adding page. Makes new page addition look faster.
				dispatch('baseSetPageData', {
					pageId,
					data: { customData: { coverImage } },
				});
			} catch (error) {
				console.error(error);
			}

			if (changePage) {
				dispatch('changePage', { pageId });
				EventLogApi.logEvent({ eventName: 'builder.blog.create_new_post' });
			}
		},

		addBlogListPage: async ({
			commit,
			dispatch,
		},
		{
			pageId = nanoid(),
			blockId = nanoid(),
		}) => {
			await dispatch('blog/addPredefinedPages', null, { root: true });
			dispatch('baseAddPage', {
				pageId,
				name: i18n.t('common.blog'),
			});

			commit('addBlock', {
				pageId,
				blockId,
				name: i18n.t('common.blog'),
				block: getBlogListBlock({ mockCategories: false }),
			}, { root: true });

			dispatch('navigation/addItem', {
				itemId: pageId,
				item: {
					type: NAVIGATION_PAGE_TYPE,
					subItems: [],
				},
			}, { root: true });

			dispatch('changePage', { pageId });

			EventLogApi.logEvent({ eventName: 'builder.blog.add_blog_page' });
		},

		duplicateBlogPage: ({
			dispatch,
			rootState,
		}, payload) => {
			const newPageId = payload.newPageId ?? nanoid();

			dispatch('baseDuplicatePage', {
				...payload,
				newPageId,
			});
			const newPageName = `${rootState.website.pages[payload.pageId].meta.title}Copy`;

			dispatch('baseSetPageData', {
				pageId: newPageId,
				data: {
					name: newPageName,
					meta: { title: newPageName },
				},
			});
		},

		removeBlogPage: ({ dispatch }, payload) => {
			dispatch('removeDefaultPage', { ...payload });
		},

		setCurrentBlogPage: ({ dispatch }, payload) => {
			dispatch('baseSetCurrentPage', { ...payload });
		},

		setBlogPageData: ({ dispatch }, payload) => {
			dispatch('baseSetPageData', { ...payload });
		},

		pushBlogPageDataToHistory: ({ dispatch }, payload) => {
			dispatch('basePushPageDataToHistory', { ...payload });
		},

		// ---Strategy handlers---
		addPage({ dispatch }, {
			type,
			payload,
		}) {
			const actionName = type ? `add${capitalizeFirstLetter(type)}Page` : 'baseAddPage';

			return dispatch(actionName, { ...payload });
		},

		removePage({ dispatch }, {
			type,
			payload,
		}) {
			const actionName = type ? `remove${capitalizeFirstLetter(type)}Page` : 'baseRemovePage';

			dispatch(actionName, { ...payload });
		},

		duplicatePage({ dispatch }, {
			type,
			payload,
		}) {
			const actionName = type ? `duplicate${capitalizeFirstLetter(type)}Page` : 'baseDuplicatePage';

			dispatch(actionName, { ...payload });
		},

		setCurrentPage({ dispatch }, {
			type,
			payload,
		}) {
			const actionName = type ? `setCurrent${capitalizeFirstLetter(type)}Page` : 'baseSetCurrentPage';

			dispatch(actionName, { ...payload });
		},

		setPageData({ dispatch }, {
			type,
			payload,
		}) {
			const actionName = type ? `set${capitalizeFirstLetter(type)}PageData` : 'baseSetPageData';

			dispatch(actionName, { ...payload });
		},

		pushPageDataToHistory({ dispatch }, {
			type,
			payload,
		}) {
			const actionName = type ? `push${capitalizeFirstLetter(type)}PageDataToHistory` : 'basePushPageDataToHistory';

			dispatch(actionName, { ...payload });
		},
	},
};
