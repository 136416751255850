import i18n from '@/i18n/setup';

export const NAVIGATION_LINK_TYPE = 'Link';
export const NAVIGATION_PAGE_TYPE = 'Page';
export const NAVIGATION_FOLDER_TYPE = 'Folder';
export const NAVIGATION_HOMEPAGE_TYPE = 'Homepage';

export const NAVIGATION_GROUP_ROOT = 'ROOT';
export const NAVIGATION_GROUP_HIDDEN = 'HIDDEN';

export const NAVIGATION_ICONS = {
	[NAVIGATION_LINK_TYPE]: {
		icon: 'link',
		title: i18n.t('common.link'),
	},
	[NAVIGATION_PAGE_TYPE]: {
		icon: 'page',
		title: i18n.t('common.page'),
	},
	[NAVIGATION_FOLDER_TYPE]: {
		icon: 'dropdown',
		title: i18n.t('common.dropdown'),
	},
	[NAVIGATION_HOMEPAGE_TYPE]: {
		icon: 'home',
		title: i18n.t('common.homePage'),
	},
};
