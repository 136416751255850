<template>
	<GridInstagramFeed :media="media" />
</template>

<script>
import { getTokenPublic } from '@/api/InstagramApi';

import GridInstagramFeed from './GridInstagramFeed.vue';
import { useGridInstagramFeed } from './useGridInstagramFeed';

export default {
	components: { GridInstagramFeed },
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props, context) {
		const {
			media,
			init,
		} = useGridInstagramFeed(props, context, {
			// TODO: replace it with URL instead of callback
			getInstagramTokenHandler: getTokenPublic,
		});

		init();

		return { media };
	},
};
</script>
