// STORE ENV-INDEPENDENT GLOBAL CONSTANTS HERE

// added as ?code=DISCOUNT_CODE adds 30% discount
export const DISCOUNT_CODE = 'e6a3e3a2b110e227ed285e702d3446f9';

// eslint-disable-next-line no-underscore-dangle
export const _000_WEBHOST = '000webhost';
export const HOSTINGER_REF = 'hostinger';

export const WWW_REDIRECT_PATHS = {
	HOME: 'home',
	SIGN_UP: 'signup',
	SIGN_IN: 'signin',
	GET_A_DOMAIN: 'get-a-domain',
	SITES: 'sites',
	TEMPLATES: 'templates',
	PAYMENTS: 'payments',
	PRICING: 'pricing',
	WIZARD: 'wizard',
	PAYMENTS_PERIODS: 'payments/periods',
	BUILDER_REDIRECT: 'builder-redirect',
	AI_HEATMAP: 'ai/heatmap',
	DESIGNER_DRAFTS: 'curated/drafts',
};

export const REDIRECT_PARAM_KEYS = {
	RETURN: 'return',
	PLAN: 'plan',
	AUTOUPGRADE: 'autoupgrade',
	PATH: 'path',
	LANG: 'lang',
	CODE: 'code',
};

export const REDIRECT_PARAM_VALUES = {
	TRUE: 'true',
	RETURN_BUILDER: 'builder',
	RETURN_PUBLISH: 'builder-publish',
	RETURN_STOREFRONT: 'builder-storefront',
};

export const REDIRECT_TYPES = {
	ASSIGN: 'assign',
	REPLACE: 'replace',
};

export const BLOG_LIST_PAGE_TYPE = 'blogList';
export const BLOG_PAGE_TYPE = 'blog';
export const ECOMMERCE_PAGE_TYPE = 'ecommerce';

export const GENERATED_TEMPLATE_ID = 'generated';

export const USER_MOBILE_BREAKPOINT = 920;
