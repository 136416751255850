import baseMessages from '@/i18n/translations/_base.json';

/* Loads `_base` language messages */
export default function loadBaseMessages() {
	if (process.env.VUE_APP_ENV_NAME === 'local') {
		const messages = {};
		const files = require.context('@/i18n/_base', true, /[\s\w,-]+\.json$/i);

		files.keys().forEach((key) => {
			const matched = key.match(/([\w-]+)\./i);

			if (matched?.length > 1) {
				const fileName = matched[1];

				messages[fileName] = files(key);
			}
		});

		return messages;
	}
	/*
	 * If not in `local` environment,
	 * default messages are loaded from `translations/_base.json` (which is always generated),
	 * because during runtime `_base` messages may only change in `local` environment
	 */

	return baseMessages;
}
