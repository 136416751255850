import axios from 'axios';

const UNSPLASH_API = `${process.env.VUE_APP_API_URL}/unsplash`;

export const getRandom = async ({
	query,
	count,
}) => {
	const { data } = await axios.post(`${UNSPLASH_API}/random`, {
		query,
		count,
	}, { withCredentials: false });

	return data;
};

export const searchImages = async ({
	query,
	page,
	count,
}) => {
	const { data } = await axios.post(`${UNSPLASH_API}/search`, {
		query,
		page,
		count,
	});

	return data;
};

/*
 * Unsplash TOS requires us to fire this everytime unsplash image is selected
 * https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
 */
export const logDownload = ({ imageJson }) => {
	try {
		axios.post(`${UNSPLASH_API}/download/`, { imageJson }, { common: { 'Content-Type': 'application/json' } });
	} catch (error) {
		console.error(error);
	}
};
