import axios from 'axios';
/*
 * this api has to be a new instance,
 * because this is not for user communication
 * and does not have to use some headers and session storage
 */
const api = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: { common: { Accept: 'application/json' } },
});

export default api;
