export const META_TAG = 'meta';
export const SCRIPT_TAG = 'script';
export const NOSCRIPT_TAG = 'noscript';
export const STYLE_TAG = 'style';
export const LINK_TAG = 'link';

export const ALLOWED_HEAD_TAGS = [
	META_TAG,
	SCRIPT_TAG,
	NOSCRIPT_TAG,
	STYLE_TAG,
	LINK_TAG,
];

export const SANITIZE_HEAD_CONFIG = {
	allowedTags: ALLOWED_HEAD_TAGS,
	allowedAttributes: {
		meta: [
			'id',
			'content',
			'name',
			'charset',
			'property',
		],
		script: [
			'id',
			'async',
			'charset',
			'defer',
			'src',
			'type',
			'integrity',
			'crossorigin',
			'data-*',
		],
		style: [
			'id',
			'media',
			'type',
		],
		link: [
			'id',
			'href',
			'hreflang',
			'media',
			'rel',
			'type',
		],
	},
	allowVulnerableTags: true,
};
