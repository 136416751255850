<template>
	<div
		class="input"
		:class="`input--${formThemeValue}`"
	>
		<label
			class="input__label"
			:class="`input__label--${formThemeValue}`"
		>
			{{ data.inputLabel }}{{ isAsteriskVisible ? '*' : '' }}
		</label>
		<!-- eslint-disable vue/no-mutating-props -->
		<Component
			:is="data.tag || 'input'"
			:placeholder="data.placeholder"
			type="text"
			class="input__component"
			:class="computedClasses"
			:readonly="!isInteractive"
			:tabindex="isInteractive ? 0 : -1"
			@input="context.model = $event.target.value"
			@click.prevent
			@dragstart.prevent
			@drag.prevent
		/>
		<!-- eslint-enable vue/no-mutating-props -->
		<div v-if="context.visibleValidationErrors">
			<p
				v-for="message in context.visibleValidationErrors"
				:key="message"
				class="input__error-message z-body-small"
			>
				{{ message }}
			</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		/*
		 * context object is passed by VueFormulate.
		 * Any props passed to FormulateInput will be found at context.attributes
		 */
		context: {
			type: Object,
			default: () => ({}),
		},
		tag: {
			type: String,
			default: 'input',
			validator: (value) => [
				'input',
				'textarea',
			].includes(value),
		},
	},
	computed: {
		...mapGetters(['currentElement']),
		data() {
			return this.context.attributes.data;
		},
		listeners() {
			return {
				...this.$listeners,
				input: (event) => {
					// eslint-disable-next-line vue/no-mutating-props
					this.context.model = event.target.value;
				},
			};
		},
		computedClasses() {
			return [
				{ 'input__component--textarea': this.data.tag === 'textarea' },
				{ 'input__component--read-only': !this.isInteractive },
				`input__component--${this.formThemeValue}`,
			];
		},
		formThemeValue() {
			return this.context.attributes.theme;
		},
		isInteractive() {
			return this.context.attributes['is-interactive'];
		},
		isRequired() {
			return !!this.data.validation.some(([rule]) => rule === 'required');
		},
		isSingleField() {
			return !!this.context.attributes['is-single-field'];
		},
		isAsteriskVisible() {
			return !this.isSingleField && this.isRequired;
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin placeholder {
	@include font-style('body', 'input__component');
	::placeholder { @content; }
}

.input {
	@include placeholder {
		font-size: 14px;
		color: var(--colors-grey-600);
	}

	&--dark {
		@include placeholder {
			font-size: 14px;
			color: var(--colors-grey-800);
		}
	}

	&__label {
		color: var(--colors-dark);

		&--dark {
			color: var(--colors-grey-100);
		}
	}

	&__component {
		width: 100%;
		height: 48px;
		padding: 16px 56px 16px 16px;
		background-color: var(--colors-grey-100);
		border: none;
		outline: none;

		&--read-only {
			cursor: move;
		}

		&--textarea {
			min-height: 96px;
			overflow: auto;
			resize: vertical;
		}

		&--dark {
			background-color: rgba($grey-100, 0.6);
		}
	}

	&__error-message {
		color: var(--colors-grey-600);
	}

	&--is-invalid {
		.input__component {
			border-color: var(--colors-danger);
		}
	}
}

</style>
