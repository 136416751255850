<template>
	<GridButton
		tag-name="button"
		:content="content"
		:type="type"
		:is-disabled="isLoadingStripeScript"
		@click="handleClick"
	/>
</template>

<script>

import {
	ref,
	getCurrentInstance,
} from '@vue/composition-api';

import GridButton from '@user/components/grid-components/button/GridButton.vue';
import { useGridButton } from '@user/components/grid-components/button/useGridButton';
import { useStripeGridButton } from '@user/components/grid-components/stripeButton/useGridStripeButton';

import { STRIPE_JS_URL } from './constants';

export default {
	name: 'GridStripeButtonProviderUser',
	components: { GridButton },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const {
			content,
			type,
		} = useGridButton(props);

		const {
			stripeInstance,
			priceId,
			successPageUrl,
			cancellationPageUrl,
			paymentType,
		} = useStripeGridButton(props);

		const isLoadingStripeScript = ref(false);

		const { website } = getCurrentInstance()?.$store?.state ?? {};

		// Loads and appends stripe script to DOM
		const loadScript = () => new Promise((resolve, reject) => {
			const isStripeLoaded = !!(stripeInstance.value && window.Stripe);

			if (isStripeLoaded) {
				resolve();

				return;
			}

			const script = document.createElement('script');

			script.addEventListener('load', () => {
				if (window.Stripe) {
					stripeInstance.value = window.Stripe(website.meta.stripePublicApiKey);
					resolve();
				}
			});

			script.addEventListener('error', () => {
				reject();
			});

			// Stripe docs require to always load from this url
			script.src = STRIPE_JS_URL;

			document.head.appendChild(script);
		});

		// Triggers load script, redirects to checkout
		const handleClick = async () => {
			if (!priceId.value) {
				return;
			}

			isLoadingStripeScript.value = true;

			await loadScript();

			isLoadingStripeScript.value = false;

			stripeInstance.value.redirectToCheckout({
				lineItems: [
					{
						price: priceId.value,
						quantity: 1,
					},
				],
				mode: paymentType.value,
				/**
				 * TODO:
				 * What to do in builder?
				 * Add default pages
				 */
				successUrl: `${window.location.origin}${successPageUrl.value}`,
				cancelUrl: `${window.location.origin}${cancellationPageUrl.value}`,
			})
				.then((result) => {
					if (result.error) {
						// eslint-disable-next-line no-console
						console.error(result.error.message);
					}
				});
		};

		return {
			content,
			type,
			isLoadingStripeScript,
			handleClick,
		};
	},
};
</script>
