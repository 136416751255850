import Vue from 'vue';

export default class {
	constructor(parameters) {
		this.type = 'ADD';
		this.params = parameters;
	}

	execute() {
		const {
			state,
			elementId,
			element,
			blockId,
			mobilePosition,
		} = this.params;

		Vue.set(state.website.components, elementId, element);
		if (blockId) {
			if (mobilePosition) {
				state.website.blocks[blockId].components.splice(mobilePosition, 0, elementId);
			} else {
				state.website.blocks[blockId].components.push(elementId);
			}

			state.website.blocks[blockId].zindexes.push(elementId);
		}
	}

	undo() {
		const {
			state,
			elementId,
			blockId,
		} = this.params;

		Vue.delete(state.website.components, elementId);
		if (blockId) {
			state.website.blocks[blockId].components = state.website.blocks[blockId].components.filter(
				(id) => id !== elementId,
			);
			state.website.blocks[blockId].zindexes = state.website.blocks[blockId].zindexes.filter(
				(id) => id !== elementId,
			);
		}

		state.currentElementId = '';
		state.currentElementRef = null;
		state.currentElementBoxRef = null;
	}
}
