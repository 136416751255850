import Vue from 'vue';

export default class {
	constructor(parameters) {
		this.type = 'REMOVE';
		this.params = parameters;
		this.oldContent = undefined;
		this.oldZindexes = undefined;
	}

	execute() {
		const {
			state,
			elementId,
			blockId,
		} = this.params;

		this.oldContent = state.website.components[elementId];
		this.oldZindexes = [...state.website.blocks[blockId].zindexes];

		Vue.delete(state.website.components, elementId);
		Vue.delete(
			state.website.blocks[blockId].components,
			state.website.blocks[blockId].components.indexOf(elementId),
		);
		Vue.delete(
			state.website.blocks[blockId].zindexes,
			state.website.blocks[blockId].zindexes.indexOf(elementId),
		);
	}

	undo() {
		const {
			state,
			elementId,
			blockId,
		} = this.params;

		Vue.set(state.website.components, elementId, this.oldContent);
		state.website.blocks[blockId].components.push(elementId);
		state.website.blocks[blockId].zindexes = this.oldZindexes;
	}
}
