import { USER_STYLES_DRAWER } from './drawers';

export const MAIN_PAGE_KEY = 'main-page';

export const USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY = 'typography-category-select';
export const USER_STYLES_HEADING_SELECT_KEY = 'heading-select';
export const USER_STYLES_PARAGRAPH_SELECT_KEY = 'paragraph-select';
export const USER_STYLES_COLORS_PALLET_EDIT = 'colors-pallet-edit';

const USER_STYLES_DRAWER_PAGES = {
	[MAIN_PAGE_KEY]: {
		component: 'UserStyles',
		title18nPath: 'builder.userStyles.title',
	},
	[USER_STYLES_COLORS_PALLET_EDIT]: {
		component: 'ColorPaletteEdit',
		title18nPath: 'builder.userStyles.colors.globalColorStyles',
		subtext18nPath: 'builder.userStyles.colors.globalColorStylesSubtext',
	},
	[USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY]: {
		component: 'TypographyCategorySelect',
		title18nPath: 'builder.userStyles.typography.textStyles',
		subtext18nPath: 'builder.userStyles.typography.textStylesSub',
	},
	[USER_STYLES_HEADING_SELECT_KEY]: {
		component: 'TypographyTypeSelect',
		title18nPath: 'builder.userStyles.typography.headings',
		subtext18nPath: 'builder.userStyles.typography.headingsSubtext',
		fontType: 'primary',
		previousPage: USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY,
		options: { currentTypographyType: null },
	},
	[USER_STYLES_PARAGRAPH_SELECT_KEY]: {
		component: 'TypographyTypeSelect',
		title18nPath: 'builder.userStyles.typography.paragraphs',
		subtext18nPath: 'builder.userStyles.typography.paragraphSubtext',
		fontType: 'secondary',
		previousPage: USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY,
		options: { currentTypographyType: null },
	},
};

export const DRAWER_PAGES = { [USER_STYLES_DRAWER]: USER_STYLES_DRAWER_PAGES };

export const DRAWER_PAGES_DEFAULT_STATE = { [USER_STYLES_DRAWER]: DRAWER_PAGES[USER_STYLES_DRAWER][MAIN_PAGE_KEY] };
