import { createNamespacedHelpers } from 'vuex';

import { getStore } from '@/api/EcwidApi';

// namespacing to use with `createNamespacedHelpers`
export const ONLINE_STORE_NAMESPACE = 'onlineStore';

// action type constants:
export const GET_STORE_MANAGER_URL = 'GET_STORE_MANAGER_URL';

// mutation type constants:
export const SET_STORE_MANAGER_URL = 'SET_STORE_MANAGER_URL';

/**
 * TODO: each namespacedHelper export should go to corresponding module state/action/mutation files
 */
export const {
	mapState: mapStateOnlineStore,
	mapGetters: mapGettersOnlineStore,
	mapActions: mapActionsOnlineStore,
} = createNamespacedHelpers(ONLINE_STORE_NAMESPACE);

export default {
	namespaced: true,
	state: {
		storeId: null,
		storeManagerUrl: null,
	},
	getters: {
		isPageWithEcwid: (state, getters, rootState, rootGetters) => Object.keys(rootGetters['pages/ecommercePages']).includes(rootState.pages.currentPageId),
		isSiteWithEcwid: (state, getters, rootState, rootGetters) => Object.keys(rootGetters['pages/ecommercePages']).length > 0,
	},
	mutations: {
		[SET_STORE_MANAGER_URL]: (state, storeManagerUrl) => {
			state.storeManagerUrl = storeManagerUrl;
		},
	},
	actions: {
		[GET_STORE_MANAGER_URL]: async ({ commit }) => {
			const { data } = await getStore();

			if (data) {
				commit('setWebsiteMeta', {
					key: 'ecwidStoreId',
					value: data.storeId,
				}, { root: true });
				commit(SET_STORE_MANAGER_URL, data.storeUrl);
			}
		},
	},
};
