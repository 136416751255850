export const DEFAULT_ICON = 'text-sm';
export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
export const THEMES = [
	THEME_LIGHT,
	THEME_DARK,
];
export const MINIMUM_LOAD_TIME = 200;
export const SUBMIT_ACTIONS = {
	SHOW_MESSAGE: 'showMessage',
	LINK_TO_PAGE: 'linkToPage',
};

export const NAME_FIELD = {
	title: 'Name',
	value: 'name',
	svg: 'user',
	field: {
		name: 'name',
		svg: 'user',
		children: [
			{
				name: 'firstName',
				placeholder: 'Your name',
				inputLabel: 'Name',
				type: 'GridInput',
				validation: [['optional']],
				'validation-messages': { required: 'This field is required' },
			},
			{
				name: 'lastName',
				placeholder: 'Your last name',
				inputLabel: 'Last name',
				validation: [['optional']],
				type: 'GridInput',
				'validation-messages': { required: 'This field is required' },
			},
		],
	},
};

export const EMAIL_FIELD = {
	title: 'Email',
	value: 'email',
	svg: 'mail',
	field: {
		svg: 'mail',
		name: 'email',
		inputLabel: 'Your email',
		validation: [
			['bail'],
			['optional'],
			['email'],
		],
		'validation-messages': {
			required: 'This field is required',
			email: 'Please enter a valid email address',
		},
		placeholder: 'Your email address',
		type: 'GridInput',
	},
};

export const PHONE_FIELD = {
	title: 'Phone',
	value: 'phone',
	svg: 'smartphone',
	field: {
		svg: 'smartphone',
		name: 'phone',
		inputLabel: 'Phone number',
		validation: [
			['bail'],
			['optional'],
			[
				'matches',
				// eslint-disable-next-line no-useless-escape
				'/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/',
			],
		],
		'validation-messages': {
			required: 'This field is required',
			matches: 'Please enter a valid phone number',
		},
		placeholder: 'Your phone number',
		type: 'GridInput',
	},
};

export const TEXT_FIELD = {
	title: 'Text field',
	value: 'text-field',
	svg: 'text-sm',
	field: {
		svg: 'text-sm',
		name: 'text-field',
		inputLabel: 'Text field',
		validation: [['optional']],
		'validation-messages': { required: 'This field is required' },
		placeholder: 'Custom text field',
		type: 'GridInput',
	},
};

export const TEXT_BOX = {
	title: 'Text box',
	value: 'content',
	svg: 'content',
	field: {
		svg: 'content',
		tag: 'textarea',
		inputLabel: 'Message',
		name: 'content',
		validation: [['optional']],
		'validation-messages': { required: 'This field is required' },
		placeholder: 'Enter your message',
		type: 'GridInput',
	},
};

export const FIELDS = [
	NAME_FIELD,
	EMAIL_FIELD,
	PHONE_FIELD,
	TEXT_FIELD,
	TEXT_BOX,
];
