<template>
	<div
		class="unsplash"
		:class="{ 'unsplash--preview-open': isPreviewOpen }"
	>
		<UnsplashPreview
			v-if="isPreviewOpen"
			:image-data="previewImage"
			:is-last-image="previewImageIndex === unsplashResultsFiltered.length - 1"
			:is-first-image="previewImageIndex === 0"
			:is-gallery="isGallery"
			@show-previous-image="decreasePreviewImageIndex"
			@show-next-image="increasePreviewImageIndex"
			@select-image="$emit('select-image', $event)"
		/>
		<div
			v-show="!isPreviewOpen"
			class="unsplash__wrapper"
		>
			<ZyroInput
				data-qa="chooseimage-inputfield-searchforphotos"
				:value="searchTerm"
				class="unsplash__search-input"
				:placeholder="$t('builder.assetManager.tabUnsplash.search')"
				@input="searchTerm = $event.target.value, startUnsplashSearch()"
			/>
			<div
				ref="unsplashContainer"
				class="unsplash__container"
				@scroll.passive="loadMoreFromUnsplash"
			>
				<div
					v-if="!isNewSearchRequestPending && !unsplashResultsFiltered.length"
					class="unsplash__no-results"
					data-qa="chooseimage-section-noresults"
				>
					<ZyroSvg name="sad-face" />
					<span class="z-h5">
						<i18n path="builder.assetManager.tabUnsplash.noResults">{{ searchTerm }}</i18n>
					</span>
					<span class="z-body-small unsplash__no-results-bottom-text">
						{{ $t('builder.assetManager.tabUnsplash.checkSpelling') }}
					</span>
				</div>
				<MasonryLayout
					v-else-if="unsplashResultsFiltered.length"
					data-qa="chooseimage-section-freeimages"
					:images="unsplashResultsFiltered"
					:loaded-thumbnails="loadedThumbnails"
					@select-image="setPreviewImageIndex"
					@thumbnail-loaded="loadedThumbnails.push($event)"
				/>
				<ZyroLoader
					v-if="isSearchRequestPending || isNewSearchRequestPending"
					data-qa="builder-loader"
				/>
			</div>
			<div class="unsplash__bottom-bar">
				<ZyroSvg name="unsplash" />
			</div>
		</div>
	</div>
</template>

<script>
import {
	getRandom,
	searchImages,
} from '@/api/UnsplashApi';
import {
	NOTIFICATION_TYPE_RENDERLESS,
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';
import { debounce } from '@/utils/debounce';

import MasonryLayout from './MasonryLayout.vue';
import UnsplashPreview from './UnsplashPreview.vue';

export default {
	name: 'UnsplashLayout',
	components: {
		MasonryLayout,
		UnsplashPreview,
	},
	props: {
		isPreviewOpen: {
			type: Boolean,
			required: true,
		},
		isGallery: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loadedThumbnails: [],
			isSearchRequestPending: true,
			isNewSearchRequestPending: false,
			previewImageIndex: -1,
			unsplashResults: [],
			searchTerm: '',
			scrollTop: 0,
			currentPage: 0,
		};
	},
	computed: {
		isOverflowHidden() {
			return !!this.previewImage;
		},
		// Unsplash returns a lot of stuff, this is easier to debug in vue tools
		unsplashResultsFiltered() {
			if (!this.unsplashResults?.results) {
				return [];
			}

			if (this.unsplashResults?.results?.errors?.length > 0) {
				this.notify({
					origin: this.$options.name,
					message: 'Image provider has failed to load images',
				});

				return [];
			}

			return this.unsplashResults.results
				.map((image) => ({
					urls: image.urls,
					id: image.id,
					user: image.user,
					width: image.width,
					height: image.height,
					blur_hash: image.blur_hash,
					alt_description: image.alt_description,
					// Used by download endpoint
					links: image.links,
				}));
		},
		previewImage() {
			if (!this.isPreviewOpen) {
				return null;
			}

			return this.unsplashResultsFiltered[this.previewImageIndex];
		},
	},
	watch: {
		async isPreviewOpen(newValue) {
			if (!newValue) {
				// Wait for unsplash container to be rendered again
				await this.$nextTick();
				// Reset scroll position back
				this.$refs.unsplashContainer.scrollTo(0, this.scrollTop);
			}
		},
	},
	async created() {
		try {
			this.unsplashResults = await getRandom({});
		} catch {
			this.notify({
				origin: this.$options.name,
				message: 'Failed to load images',
			});
		}

		this.isSearchRequestPending = false;
	},
	methods: {
		...mapActionsNotifications({ notify: NOTIFY }),
		/**
		 * Used for initial new keyword search
		 * TODO: Add immediate mode for deobunce, so we can use 'enter' key
		 * to trigger search instantly
		 */
		startUnsplashSearch: debounce(async function debouncedFunction() {
			if (!this.searchTerm) {
				return;
			}

			this.unsplashResults = [];
			this.loadedThumbnails = [];
			this.currentPage = 1;
			this.isNewSearchRequestPending = true;
			try {
				this.unsplashResults = await searchImages(
					{
						query: this.searchTerm,
						page: this.currentPage,
					},
				);
			} catch {
				this.notify({
					origin: this.$options.name,
					message: 'Failed to search for images',
				});
			} finally {
				this.isNewSearchRequestPending = false;
			}
		}, 500),
		async loadMoreFromUnsplash(isPreviewMode = false) {
			const { unsplashContainer } = this.$refs;

			/*
			 * This function should only be responsible for loading more images,
			 * but it is convenient to update this value here
			 */
			this.scrollTop = unsplashContainer?.scrollTop;

			// When less than 1400px is left to scroll, load more images
			const scrollThreshold = unsplashContainer?.offsetHeight + this.scrollTop + 1400;
			const scrollThresholdReached = scrollThreshold > unsplashContainer?.scrollHeight;

			if (
				// Dont load if random images are loaded or there is no search term
				!this.searchTerm
				// Dont load more if last page
				|| this.unsplashResults.total_pages <= this.currentPage
				// Dont load more if previous request is still pending
				|| this.isSearchRequestPending
				// Dont load if user hasn't scrolled enough, ignored in preview mode
				|| (!(scrollThresholdReached) && !isPreviewMode)
				// Dont load more if previous set of images hasn't loaded, ignored in preview mode
				|| ((this.loadedThumbnails.length < this.unsplashResultsFiltered.length) && !isPreviewMode)
			) {
				return;
			}

			this.currentPage += 1;

			/**
			 * Flag for request still loading is needed because
			 * code above will still pass conditionals after request is started
			 * and the function will request even more images till previous request isn't completed
			 */
			this.isSearchRequestPending = true;
			try {
				const unsplashResults = await searchImages(
					{
						query: this.searchTerm,
						page: this.currentPage,
					},
				);

				// Merge results
				this.unsplashResults.results = [
					...this.unsplashResults.results,
					...unsplashResults.results,
				];

				if (unsplashResults.results.length === 0) {
					this.currentPage = this.unsplashResults.total_pages;
				}
			} catch {
				this.notify({
					type: NOTIFICATION_TYPE_RENDERLESS,
					origin: this.$options.name,
					message: 'Failed to load more images',
				});
			} finally {
				this.isSearchRequestPending = false;
			}
		},
		increasePreviewImageIndex() {
			this.setPreviewImageIndex(this.previewImageIndex + 1);
		},
		decreasePreviewImageIndex() {
			this.setPreviewImageIndex(this.previewImageIndex - 1);
		},
		setPreviewImageIndex(index) {
			this.previewImageIndex = index;
			// Load more images if second to last image is opened
			if (index >= this.unsplashResultsFiltered.length - 2) {
				this.loadMoreFromUnsplash(true);
			}

			this.$emit('toggle-preview', index !== -1);
		},
	},
};
</script>

<style lang="scss" scoped>
.unsplash {
	height: 100%;

	&--preview-open {
		// Account for back button height in safari
		height: calc(100% - 48px);
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		// Account for navigation height
		height: calc(100% - 34px);
		padding: 0 24px;
		overflow: hidden;
	}

	&__container {
		// Fill space even if there is no images
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&__no-results {
		display: grid;
		grid-gap: 10px;
		justify-items: center;
		margin: auto;
	}

	&__no-results-bottom-text {
		color: $grey-800;
	}

	&__bottom-bar {
		padding: 24px;

		::v-deep {
			svg {
				display: block;
				margin-left: auto;
			}
		}
	}

	&__search-input {
		margin: 8px 0 16px;
	}
}
</style>
