import axios from 'axios';

const ECWID_API = `${process.env.VUE_APP_API_URL}/e1/stores`;
const ECWID_STOREFRONT_API = 'https://storefront.ecwid.com';

export const getStore = () => axios.get(`${ECWID_API}/get-store`);
export const deleteStore = (id) => axios.delete(`${ECWID_API}/${id}`);

export const getStoreSnapshot = (storeId) => axios.get(`${ECWID_STOREFRONT_API}/home-page/${storeId}/static-code`, {
	params: { 'tplvar_ec.storefront.show_footer_menu': true },
	withCredentials: false,
});
