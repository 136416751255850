export const capitalizeFirstLetter = (string) => {
	if (typeof string !== 'string') {
		return '';
	}

	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getExtension = (path) => {
	const basename = path.split(/[/\\]/).pop();
	const position = basename.lastIndexOf('.');

	if (basename === '' || position < 1) {
		return '';
	}

	return basename.slice(position + 1);
};

export const getFileNameFromURL = (url) => url.replace(/^.*[/\\]/, '').split('?')[0];
