const SET_NPS_VISIBLE_DAYS_PASSED = 'SET_DAYS_PASSED';
const TOGGLE_NPS_VISIBLE = 'TOGGLE_NPS_VISIBLE';
const MAX_DAYS_AFTER_LAST_SCORE = 30;

export default {
	namespaced: true,
	state: { isNpsVisible: false },
	mutations: {
		[SET_NPS_VISIBLE_DAYS_PASSED]: (state, { daysPassed }) => {
			// backend returns null value if the user has never sent the nps score
			state.isNpsVisible = daysPassed > MAX_DAYS_AFTER_LAST_SCORE || daysPassed === null;
		},
		[TOGGLE_NPS_VISIBLE]: (state, value) => {
			state.isNpsVisible = value;
		},
	},
	actions: {
		updateDaysPassed: ({ commit }, { daysPassed }) => {
			commit(SET_NPS_VISIBLE_DAYS_PASSED, { daysPassed });
		},
		setNpsVisibility: ({ commit }, value) => {
			commit(TOGGLE_NPS_VISIBLE, value);
		},
	},
};
