<template>
	<div :style="computedStyles">
		<button
			class="edit-image"
			data-qa="builder-sectionsettings-btn-addimage"
			@click="showAssetManager = true"
		>
			<img
				v-if="value"
				class="edit-image__image"
				:src="value"
			>
			<div class="edit-image__button-description z-button">
				<ZyroSvg
					class="edit-image__icon"
					name="camera"
				/>
				{{ value ? $t('common.replaceImage') : $t('common.addImage') }}
			</div>
		</button>
		<Portal to="app">
			<AssetManagerv2
				v-if="showAssetManager"
				@select-image="setNewImage($event), showAssetManager = false"
				@close="showAssetManager = false"
			/>
		</Portal>
	</div>
</template>

<script>
import AssetManagerv2 from '@/components/builder-modals/modals/AssetManagerv2.vue';

export default {
	components: { AssetManagerv2 },
	props: {
		value: {
			type: String,
			required: true,
		},
		objectFit: {
			type: String,
			default: 'contain',
			validator: (value) => [
				'contain',
				'cover',
			].includes(value),
		},
	},
	data() {
		return { showAssetManager: false };
	},
	computed: {
		computedStyles() {
			return { '--z-object-fit': this.objectFit || undefined };
		},
	},
	methods: {
		setNewImage(newValue) {
			this.$emit('input', newValue);
		},
	},
};
</script>

<style lang="scss" scoped>
.edit-image {
	position: relative;
	width: 100%;
	height: 200px;
	cursor: pointer;
	background-color: $grey-400;

	// Image is used instead of background image, because background-image flickers
	&__image {
		object-fit: var(--z-object-fit, contain);
		width: 100%;
		height: 100%;
		object-position: center center;
	}

	&__icon {
		margin-bottom: 10px;
	}

	&__button-description {
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 20px 0;
		color: $light;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(2px);
	}
}
</style>
