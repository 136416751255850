<template>
	<div
		v-click-outside="{
			handler: close,
			middleware: () => isOpen,
			events: [
				'dblclick', 'mousedown', 'drop'
			],
		}"
		class="popup"
		:class="{ 'popup--is-open': isOpen }"
	>
		<div
			ref="trigger"
			class="trigger"
			@click.stop="toggle"
		>
			<slot name="trigger" />
		</div>

		<Transition name="fade-in">
			<ZyroPopupCard
				v-if="isOpen"
				v-qa="dataQa"
				:title="title"
				:tabs="tabs"
				:type="type"
				:style="computedPosition"
				:current-tab="currentTab"
				:show-close-button="showCloseButton"
				:show-footer="showFooter"
				class="popup__card"
				@close="close"
				@save="$emit('save'), close()"
				@toggle="toggle"
			>
				<slot :toggle="toggle" />
			</ZyroPopupCard>
		</Transition>
	</div>
</template>

<script>
import ZyroPopupCard from './ZyroPopupCard.vue';

export default {
	components: { ZyroPopupCard },
	props: {
		title: {
			type: String,
			default: '',
		},
		offset: {
			type: Object,
			default() {
				return {
					left: 0,
					top: 65,
				};
			},
		},
		reposition: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			validator: (type) => [
				'no-padding',
				'editor',
				'',
			].includes(type),
			default: '',
		},
		dataQa: {
			type: String,
			default: '',
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: false,
		},
		tabs: {
			type: Array,
			default: () => [],
		},
		currentTab: {
			type: Object,
			default: () => {},
		},
		onToggle: {
			type: Function,
			default: () => null,
		},
	},
	data() {
		return {
			isOpen: false,
			position: {
				left: 0,
				top: 0,
			},
		};
	},
	computed: {
		computedPosition() {
			/*
			 * Iterate through user's passed offset keys and only set those, that are provided
			 * (not everything from this.position, which holds all the coordinates)
			 */
			return Object.keys(this.offset).reduce((agg, key) => {
				const offsetFromProperties = this.offset[key];

				if (!offsetFromProperties && offsetFromProperties !== 0) {
					return agg;
				}

				return {
					...agg,
					[key]: `${(this.position[key] || 0) + offsetFromProperties}px`,
				};
			}, {});
		},
	},
	mounted() {
		window.addEventListener('resize', this.updatePosition);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updatePosition);
	},
	methods: {
		close() {
			this.isOpen = false;
			this.$emit('close');
		},
		updatePosition() {
			if (this.reposition) {
				const {
					top,
					left,
					right,
					bottom,
				} = this.$refs.trigger.getBoundingClientRect();

				this.position = {
					top,
					left,
					right,
					bottom,
				};
			}
		},
		toggle() {
			this.isOpen = !this.isOpen;
			this.$emit('toggle', this.isOpen);

			if (this.isOpen) {
				this.updatePosition();
			}

			this.onToggle(this.isOpen, this.$refs.trigger.getBoundingClientRect());
		},
	},
};
</script>

<style lang="scss" scoped>
.popup__card {
	position: absolute;
}

.trigger {
	display: flex;
	align-items: center;
}

// Transitions
.fade-in {
	transform-origin: 50% -50px;

	&-enter-active,
	&-leave-active {
		transition: transform 0.25s, opacity 0.2s;
	}

	&-enter,
	&-leave-to {
		opacity: 0;
		transform: rotateX(-10deg) scaleY(0.98) scaleX(0.99);
	}
}
</style>
