<template>
	<div class="zyro-segment-control">
		<button
			v-for="control in controls"
			:key="control.title"
			v-qa="`builder-sectionsettings-tab-${control.title}`"
			:class="{ 'zyro-segment-control__button--active': control.title === activeControl.title }"
			class="zyro-segment-control__button z-body-small"
			@click="$emit('change', control)"
		>
			{{ control.title }}
		</button>
	</div>
</template>

<script>
export default {
	props: {
		controls: {
			type: Array,
			required: true,
			validator: (controls) => controls.every((item) => 'title' in item),
		},
		activeControl: {
			type: Object,
			required: true,
			validator: (activeControl) => 'title' in activeControl,
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-segment-control {
	display: flex;
	width: 100%;
	background-color: $grey-100;
	border-radius: $border-radius-round;

	&__button {
		display: flex;
		flex: 1 0;
		align-items: center;
		justify-content: center;
		min-height: 40px;
		padding: 8px 16px;
		color: $grey-800;
		cursor: pointer;
		background-color: transparent;
		border: 2px solid transparent;
		border-radius: $border-radius-round;
		transition: 0.2s all;

		&--active {
			color: $dark;
			background-color: $light;
			border: 2px solid rgba(0, 0, 0, 0.03);
		}
	}
}
</style>
