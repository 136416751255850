<template>
	<div
		v-if="background"
		class="background"
		:style="computedStyles"
	>
		<img
			v-if="backgroundImage"
			:alt="alt"
			:src="!isLazy && src"
			:data-src="isLazy && src"
			:data-srcset="isLazy && srcset"
			sizes="100vw"
			height="100%"
			width="100%"
			class="background__image"
			:class="{
				'background__image--fixed': isFixed,
				'lazyload': isLazy,
			}"
		>
		<div
			v-if="backgroundImage && background['overlay-opacity']"
			class="background__overlay"
			:style="{ opacity: background['overlay-opacity'] }"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import {
	getOptimizedSrc,
	getFullWidthSrcset,
} from '@user/utils/getSrcsets';

export const FULL_WIDTH = 1920; // assume that largest screen is 1920

export default {
	name: 'BlockBackground',
	props: {
		alt: {
			type: String,
			default: null,
		},
		background: {
			type: Object,
			default: () => ({}),
		},
		isLazy: {
			type: Boolean,
			default: false,
		},
		isFixed: {
			type: Boolean,
			default: false,
		},
		isNextToTransparentHeader: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('navigation', ['navigationHeight']),
		backgroundImage: ({ background }) => background.current === 'image' && background.image,
		backgroundColor: ({ background }) => background.current === 'color' && background.color,
		src: ({ backgroundImage }) => backgroundImage && getOptimizedSrc(backgroundImage, { width: FULL_WIDTH }),
		srcset: ({ backgroundImage }) => backgroundImage && getFullWidthSrcset(backgroundImage),
		computedStyles() {
			return {
				top: this.isNextToTransparentHeader ? `${-this.navigationHeight}px` : 0,
				backgroundColor: this.backgroundColor,
			};
		},
	},
};
</script>

<style lang="scss">
@import 'BlockBackground';
</style>
