<template>
	<div
		class="zyro-icon-select"
		:style="computedStyles"
	>
		<ZyroLabel :padding="labelPadding">
			{{ label }}
		</ZyroLabel>
		<div class="zyro-icon-select__options">
			<label
				v-for="(option, key) in options"
				:key="`icon-${key}`"
				v-qa="`option-${key}`"
				class="zyro-icon-select__label"
				:class="{ 'zyro-icon-select__label--current' : key === currentComputed }"
			>
				<input
					v-model="currentComputed"
					class="zyro-icon-select__input"
					type="radio"
					:name="option.name"
					:value="key"
				>
				<div
					class="zyro-icon-select__icon-container"
				>
					<ZyroSvg
						:name="option.icon"
						class="zyro-icon-select__icon"
						:dimensions="option.iconDimensions || '42px'"
					/>
				</div>
				<span class="zyro-icon-select__name z-body-small">
					{{ option.name }}
				</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		current: {
			type: String,
			required: true,
		},
		options: {
			type: Object,
			validator: (options) => Object.values(options).every((option) => option.name && option.icon),
			required: true,
		},
		label: {
			type: String,
			default: '',
		},
		labelPadding: {
			type: String,
			default: null,
		},
		itemsPerRow: {
			type: Number,
			default: 2,
		},
	},
	computed: {
		currentComputed: {
			get() {
				return this.current;
			},
			set(newCurrent) {
				this.$emit('update:current', newCurrent);
			},
		},
		computedStyles() {
			return { '--itemsPerRow': this.itemsPerRow };
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin active-style() {
	--select-border: #{$accent-two};
	--select-background: #{$grey-100};
}

.zyro-icon-select {
	$this: &;

	&__options {
		display: grid;
		grid-template-columns: repeat(var(--itemsPerRow), 1fr);
		grid-gap: 16px;
	}

	&__input {
		width: 0;
		height: 0;
		opacity: 0;

		&:focus-visible {
			+ #{$this}__icon-container {
				@include active-style;
			}
		}
	}

	&__label {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		&--current {
			@include active-style;
		}
	}

	&__name {
		margin-top: 8px;
		text-align: center;
	}

	&__icon {
		margin: auto;
		color: $grey-800;
	}

	&__icon-container {
		display: flex;
		height: 100%;
		padding: 18px 10px;
		background: var(--select-background);
		border: 1px solid var(--select-border, $grey-300);
		border-radius: $border-radius-small;
	}
}
</style>
