<template>
	<div class="zyro-tabs">
		<button
			v-for="tab in tabs"
			:key="tab.title"
			v-qa="`builder-tab-${tab.title}`"
			:class="{ 'zyro-tabs__tab--active': currentTab.title === tab.title }"
			class="zyro-tabs__tab z-body-small z-body-small--strong"
			@click="$emit('change', tab)"
		>
			{{ tab.title }}
		</button>
	</div>
</template>

<script>
export default {
	props: {
		tabs: {
			type: Array,
			required: true,
			validator: (tabs) => {
				const validTabs = tabs.filter((item) => 'title' in item);

				return validTabs.length === tabs.length;
			},
		},
		currentTab: {
			type: Object,
			required: true,
			validator: (currentTab) => 'title' in currentTab,
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-tabs {
	width: 100%;
	margin-bottom: 8px;
	border-bottom: 0.5px solid $grey-300;

	&__tab {
		position: relative;
		padding: 2px 5px;
		color: $grey-600;
		cursor: pointer;
		background-color: transparent;
		transition: color 0.2s ease;

		&:not(:last-child) {
			margin-right: 16px;
		}

		&--active {
			color: $dark;

			&::before {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				content: '';
				background-color: $dark;
			}
		}
	}
}
</style>
