<!-- TODO:
	1. Tests
-->
<template>
	<ZyroPopup
		ref="popup"
		type="no-padding"
		:show-close-button="false"
		class="dropdown"
		:class="{ 'is-open': isOpen }"
		:offset="offset"
		@toggle="isOpen = $event"
	>
		<template #trigger>
			<div class="dropdown__trigger">
				<ZyroLabel
					v-if="$attrs.label"
					:for="$attrs.id"
					:bold="bold"
					class="dropdown__label"
				>
					{{ $attrs.label }}
				</ZyroLabel>

				<ZyroFieldWrapper>
					<template #prefix>
						<slot name="prefix" />
					</template>
					<button
						class="dropdown__button z-body-small"
						:class="`dropdown__button--${buttonSize}`"
					>
						<ZyroSvg
							v-if="current.svg"
							:name="current.svg"
							class="dropdown__button-prefix-img"
						/>
						{{ selectedItems ? options[0].title : current.title }}
						<ZyroSvg
							name="chevron-down-small"
							class="dropdown__icon"
						/>
					</button>

					<template #suffix>
						<slot name="suffix" />
					</template>
				</ZyroFieldWrapper>
			</div>
		</template>

		<div class="select">
			<button
				v-for="option in options"
				:key="option.title"
				v-qa="`dropdown-option-${option.title}`"
				class="select__item z-body-small"
				@click="handleSelect(option)"
			>
				<ZyroSvg
					v-if="option.svg"
					:name="option.svg"
					class="select__item-prefix-icon"
					dimensions="16px"
				/>
				{{ option.title }}
				<ZyroSvg
					v-if="isChecked(option)"
					name="check-mark"
					class="select__item-icon"
				/>
			</button>
		</div>
	</ZyroPopup>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		options: {
			type: Array,
			required: true,
		},
		current: {
			type: Object,
			default: null,
		},
		selectedItems: {
			type: Array,
			default: null,
		},
		bold: {
			type: Boolean,
			default: true,
		},
		buttonSize: {
			type: String,
			default: '',
			validator: (value) => [
				'',
				'large',
			].includes(value),
		},
	},
	data() {
		return {
			offset: {
				top: 'auto',
				left: 'auto',
			},
			isOpen: false,
		};
	},
	methods: {
		handleSelect(option) {
			if (this.selectedItems) {
				const updatedItems = this.isChecked(option)
					? this.selectedItems.filter((item) => item.title !== option.title)
					: [
						...this.selectedItems,
						option,
					];

				this.$emit('update:selectedItems', updatedItems);
			} else {
				this.$emit('update:current', option);
				this.$refs.popup.toggle();
			}
		},
		isChecked(option) {
			return this.selectedItems
				? this.selectedItems.some((item) => item.title === option.title)
				: option.title === this.current?.title;
		},
	},
};
</script>
<style lang="scss" scoped>
.dropdown {
	$this: &;

	position: relative;
	width: 100%;
	margin-bottom: 16px;

	// styled with v-deep for now, couldn't find any better way
	// how to modify the popup layout used for dropdown look.
	// This is temporary, because dropdown shoudln't depend onn popup in
	// the first place, but for quick option will fit. TODO: Refactor this without
	// popup so no v-deep would be used and no not needed dependencies on other components
	// would be created.
	&::v-deep .popup__card {
		z-index: 1;
		width: 100%;
		max-width: none;

		.popup-card {
			&__head {
				padding: 0;
			}

			&__content {
				padding: 0;
			}
		}
	}

	&__label {
		margin: 8px 0;
		line-height: 16px;
	}

	&__trigger {
		width: 100%;
	}

	&__icon {
		margin-left: auto;
		transition: transform 0.1s;
	}

	&__button {
		display: flex;
		align-items: center;
		width: 100%;
		height: 40px;
		padding: 0 16px;
		background-color: $grey-100;
		transition: all 0.2s ease;

		&:hover,
		&:focus {
			background-color: $grey-200;
		}
	}

	&__button-prefix-img {
		margin-right: 10px;
	}

	&__button--large {
		height: 45px;
	}

	&.is-open {
		#{$this}__icon {
			transform: rotate(180deg);
		}
	}
}

.select {
	display: flex;
	flex-direction: column;
	width: calc(100% - 4px);
	max-height: 200px;
	padding: 8px 0;
	overflow-y: auto;
	border-radius: 5px;

	&__item {
		display: flex;
		align-items: center;
		padding: 10px 12px 10px 18px;
		text-align: start;
		transition: background-color 0.1s ease-in-out;

		&:hover,
		&:focus {
			background-color: $grey-200;
		}
	}

	&__item-icon {
		margin-left: auto;
	}

	&__item-prefix-icon {
		margin-right: 8px;
	}
}
</style>
