<template>
	<div
		class="zyro-field-range"
	>
		<ZyroLabel
			:for="$attrs.id"
			:bold="bold"
			class="zyro-field-range__label"
		>
			{{ $attrs.label }}
		</ZyroLabel>
		<ZyroRange
			v-qa="qa"
			v-bind="$attrs"
			:units="units"
			v-on="$listeners"
		/>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		bold: {
			type: Boolean,
			default: true,
		},
		units: {
			type: String,
			default: 'px',
		},
		qa: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-field-range {
	margin: 16px 0;

	&__label {
		margin-bottom: 16px;
		line-height: 16px;
		cursor: pointer;
	}
}
</style>
