import { render, staticRenderFns } from "./BlockBlogListEmptyBlockUser.vue?vue&type=template&id=4255cb6b&scoped=true&"
var script = {}
import style0 from "./BlockBlogListEmptyBlockUser.vue?vue&type=style&index=0&id=4255cb6b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4255cb6b",
  null
  
)

export default component.exports