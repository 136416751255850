import axios from 'axios';

const INSTAGRAM_API = `${process.env.VUE_APP_API_URL}/v3/instagram`;
const PUBLIC_INSTAGRAM_API = `${process.env.VUE_APP_API_URL}/u1/instagram`;
const REDIRECT_URL = `${process.env.VUE_APP_BUILDER_URL}/instagram/auth`;

// IMPORTANT: Set devServer.https in vue.config.js to true when working with this
export const openAuthorizationWindow = (state) => window.open(
	`https://api.instagram.com/oauth/authorize?client_id=${process.env.VUE_APP_INSTAGRAM_APP_ID}&redirect_uri=${REDIRECT_URL}&scope=user_profile,user_media&response_type=code&state=${state}`,
	'',
	'height=600,width=450',
);
export const authorize = async (code, elementId) => {
	const { data } = await axios.post(`${INSTAGRAM_API}/auth`, {
		code,
		elementId,
		redirectUrl: REDIRECT_URL,
	});

	return data;
};

export const getToken = async (elementId) => {
	const { accessToken } = (await axios.get(`${INSTAGRAM_API}/token/${elementId}`)).data;

	return accessToken;
};

export const getTokenPublic = async (elementId) => {
	const { accessToken } = (await axios.get(`${PUBLIC_INSTAGRAM_API}/token/${elementId}`, { withCredentials: false })).data;

	return accessToken;
};

export const deleteToken = async (elementId) => {
	await axios.delete(`${INSTAGRAM_API}/token/${elementId}`);
};

// https://developers.facebook.com/docs/instagram-basic-display-api/reference/media#fields
export const getMedia = async (fields = 'id,caption', accessToken) => {
	const { data } = await axios.get('https://graph.instagram.com/me/media', {
		withCredentials: false,
		params: {
			fields,
			access_token: accessToken,
		},
	});

	return data;
};
