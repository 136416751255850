<template>
	<div class="upload-bar">
		<div
			v-if="isGallery && selectedCount"
			class="upload-bar__info"
		>
			<p class="z-body-small">
				<i18n path="builder.assetManager.tabUser.selected">
					<span class="upload-bar__count">{{ selectedCount }}</span>
				</i18n>
			</p>
			<div class="upload-bar__separator" />
			<ZyroButton
				v-qa="'assetmanager-selectall'"
				theme="plain"
				icon-left="select-all"
				@click="$emit('select-all')"
			>
				{{ $t('builder.assetManager.tabUser.selectAll') }}
			</ZyroButton>
			<div class="upload-bar__separator" />
			<ZyroButton
				v-qa="'assetmanager-deselectall'"
				theme="plain"
				icon-left="deselect-all"
				@click="$emit('deselect-all')"
			>
				{{ $t('builder.assetManager.tabUser.deselectAll') }}
			</ZyroButton>
		</div>
		<p
			v-else
			class="upload-bar__drag-drop-text z-body-small"
		>
			<ZyroSvg name="cloud-small" />
			{{ $t('builder.assetManager.tabUser.dragDropBar') }}
		</p>
		<ZyroButton
			v-if="isGalleryDisplay"
			v-qa="'assetmanager-addimage'"
			theme="primary"
			size="xs"
			color="black"
			icon-left="plus"
			@click="onAddImagesClick"
		>
			{{ $t('builder.assetManager.gallery.addImages') }}
		</ZyroButton>
		<ZyroButton
			v-else
			v-qa="'assetmanager-uploadimage'"
			theme="primary"
			size="xs"
			color="black"
			icon-left="cloud-small"
			@click="$emit('open-file-dialog')"
		>
			{{ $t('builder.assetManager.tabUser.upload') }}
		</ZyroButton>
	</div>
</template>

<script>
import EventLogApi from '@/api/EventLogApi';

export default {
	props: {
		isGalleryDisplay: {
			type: Boolean,
			default: false,
		},
		isGallery: {
			type: Boolean,
			default: false,
		},
		selectedCount: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		onAddImagesClick() {
			this.$emit('open-asset-manager');
			EventLogApi.logEvent({ eventName: 'builder.image_gallery.add_images' });
		},
	},
};
</script>

<style lang="scss" scoped>
.upload-bar {
	display: flex;
	grid-area: 1/1/2/2;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	// Avoid layout shift
	min-height: 40px;
	margin: 14px 0 22px;

	&__info {
		display: flex;
		align-items: center;
		padding-left: 60px;
	}

	&__separator {
		align-self: stretch;
		width: 1px;
		margin: 0 20px;
		background-color: $grey-200;
	}

	&__count {
		display: inline-block;
		min-width: 2ch;
		text-align: right;
	}

	&__drag-drop-text {
		display: flex;
		align-items: center;
		padding: 10px 20px;

		::v-deep svg {
			margin-right: 10px;
		}
	}
}
</style>
