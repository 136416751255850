var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tagName,{tag:"Component",class:{
		'image': !_vm.isUnstyled,
		'image--unstyled': _vm.isUnstyled
	},attrs:{"href":_vm.href,"target":_vm.target,"title":_vm.alt,"to":_vm.to},on:{"click":function($event){return _vm.$emit('image-click', $event)}}},[_c('img',_vm._g({class:{
			'lazyload': _vm.isLazy,
			'image__image--zoom': _vm.isLightboxEnabled,
			'image__image--unstyled': _vm.isUnstyled,
			'image__image': !_vm.isUnstyled,
			'image__image--reset-m-position': _vm.resetMobilePosition
		},attrs:{"alt":_vm.alt,"src":_vm.isLazy ? _vm.placeholderSrc : _vm.src,"data-src":_vm.isLazy && _vm.src,"data-srcset":_vm.isLazy && _vm.srcset,"data-sizes":_vm.isLazy && _vm.sizes,"height":_vm.height,"width":_vm.width}},{
			drag: _vm.preventDrag ? function (e) { return e.preventDefault(); } : function () { return null; },
			dragstart: _vm.preventDrag ? function (e) { return e.preventDefault(); } : function () { return null; },
		})),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }