import Vue from 'vue';

import { cloneDeep } from '@/utils/object';

export default class {
	constructor(parameters) {
		this.type = 'SET';
		this.params = parameters;
		this.oldData = undefined;
		this.redoData = undefined;
	}

	execute() {
		const {
			state,
			pageId,
			oldData,
		} = this.params;

		if (typeof this.redoData === 'undefined') {
			if (JSON.stringify(state.website.pages[pageId]) === JSON.stringify(oldData)) {
				return false;
			}

			this.redoData = cloneDeep(state.website.pages[pageId]);
		}

		this.oldData = oldData;

		Vue.set(state.website.pages, pageId, this.redoData);

		return true;
	}

	undo() {
		const {
			state,
			pageId,
		} = this.params;

		Vue.set(state.website.pages, pageId, this.oldData);
	}
}
