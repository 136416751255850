import { onlyUnique } from '@/utils/array';
import {
	extractFontName,
	replaceSpacesWithPlus,
} from '@/utils/font';

export const PARAGRAPH_TEXT = 'Paragraphtext';

/**
 * Takes typographyStyles array that can be found in 'assets/data'
 * @param typographyStyles { array } - typographyStyles array that can be found in 'assets/data'
 * @returns { array } - returns unique font names that are found in typographyStyles
 */
export const getUniqueFontNamesForGoogleFontQuery = (typographyStyles) => typographyStyles
	.reduce((fontNames, {
		textElementData: {
			font: {
				primary,
				secondary,
			},
		},
	}) => [
		...fontNames,
		...[
			replaceSpacesWithPlus(extractFontName(primary)),
			replaceSpacesWithPlus(extractFontName(secondary)),
		],
	].filter(onlyUnique), []);

/**
 * Takes typographyStyles array that can be found in 'assets/data'
 * and returns constructed fonts and texts that are meant to be used for Google font request
 * @param typographyStyles {array} - typographyStyles array that can be found in 'assets/data'
 * @returns - { texts: {string}, fonts: {string} }
 * - { texts } says what characters need to be fetched
 * - { fonts } says what fonts need to be fetched
 */
export const constructTypographyStylesGoogleFontQuery = (typographyStyles) => {
	const uniqueFontNames = getUniqueFontNamesForGoogleFontQuery(typographyStyles);
	const uniqueFontNamesIndexCount = uniqueFontNames.length - 1;
	const googleQuery = uniqueFontNames.reduce((query, fontName, index) => ({
		...query,
		texts: `${query.texts}${fontName.replace(/\+/g, '')}`,
		fonts: `${query.fonts}${fontName}:400${uniqueFontNamesIndexCount !== index ? '|' : ''}`,
	}), {
		texts: '',
		fonts: '',
	});

	return {
		texts: `${googleQuery.texts}${PARAGRAPH_TEXT}`,
		fonts: googleQuery.fonts,
	};
};
