// eslint-disable-next-line unicorn/filename-case
import { computed } from '@vue/composition-api';

import { parseCSSSides } from '@/utils/parseCSSSides';

export const STYLE_KEYS = [
	'padding',
	'm-padding',
	'block-padding',
];

export const useInlineCSSVariables = (props) => {
	const variablesString = computed(() => {
		const styles = props.data?.settings?.styles;
		const backgroundExists = props.data?.background?.current;

		let backgroundStyles = '';
		/*
		 * uses temporary background object for a transparent header.
		 * otherwise it would change the original background's color in
		 * in preview mode once scrolled to the very top (color: 'transparent')
		 */
		const { background } = props.data;

		if (backgroundExists) {
			const useUrl = background.current === 'image';
			const backgroundValue = useUrl ? `url(${background[background.current]})` : background[background.current];

			backgroundStyles = `--background-${background.current}: ${backgroundValue};`;
		}

		if (!styles && !backgroundExists) {
			return '';
		}

		return Object.entries(styles).reduce(
			(styleString, [currentStyleKey, currentStyleValue]) => {
				const newStyle = STYLE_KEYS.includes(currentStyleKey)
					? Object.entries(parseCSSSides(currentStyleValue))
						.reduce(
							(styleStringCurrent, [side, value]) => `
							${styleStringCurrent} --${currentStyleKey}-${side}: ${value};`, `--${currentStyleKey}:${currentStyleValue};`,
						)
					: `--${currentStyleKey}:${currentStyleValue};`;

				return `${styleString} ${newStyle}`;
			},
			backgroundStyles,
		);
	});

	return { variablesString: computed(() => variablesString.value) };
};
