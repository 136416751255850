<template>
	<GridMap
		ref="mapRef"
		v-bind="composedProps"
	/>
</template>

<script>
import { ref } from '@vue/composition-api';

import GridMap from '@user/components/grid-components/map/GridMap.vue';
import { useGridMap } from '@user/components/grid-components/map/useGridMap';

export default {
	name: 'GridMapProviderUser',
	components: { GridMap },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const mapRef = ref(null);
		const { composedProps } = useGridMap(props, mapRef);

		return {
			mapRef,
			composedProps,
		};
	},
};
</script>
