<template>
	<GridButton
		:tag-name="tagName"
		:href="href"
		:target="target"
		:type="type"
		:button-type="buttonType"
		:content="content"
		@click="handleClick"
	/>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api';

import GridButton from '@user/components/grid-components/button/GridButton.vue';
import { useGridButton } from '@user/components/grid-components/button/useGridButton';
import { scrollToAnchorTag } from '@user/utils/anchorTag';

export default {
	name: 'GridButtonProviderUser',
	components: { GridButton },
	props: {
		data: {
			type: Object,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {
			tagName,
			href,
			target,
			internalLink,
			isExternalLink,
			type,
			buttonType,
			content,
		} = useGridButton(props);

		const {
			$store,
			$router,
		} = getCurrentInstance();

		const handleClick = (event) => {
			if (internalLink.value && !props.isPreviewMode) {
				event.preventDefault();
				$router.push(internalLink.value);

				return;
			}

			scrollToAnchorTag(href.value, event);

			if (props.isPreviewMode && !isExternalLink.value) {
				event.preventDefault();
				$store.dispatch('pages/changePage', { pageId: props.data.settings.href });
			}
		};

		return {
			tagName,
			href,
			target,
			type,
			buttonType,
			content,
			handleClick,
		};
	},
};
</script>
