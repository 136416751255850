<template>
	<div
		class="zyro-field-shorthand"
	>
		<ZyroLabel
			:bold="bold"
			:for="inputId"
		>
			{{ $attrs.label }}
		</ZyroLabel>
		<ZyroLabel
			:for="inputId"
			class="zyro-field-shorthand__label"
			:class="{ 'zyro-field-shorthand__label--disabled': disabled }"
		>
			{{ changeVertical ?
				$t('builder.cssShorthandControlRange.vertical'):
				$t('builder.cssShorthandControlRange.horizontal')
			}}
		</ZyroLabel>
		<ZyroRange
			:id="inputId"
			v-model="computedValue"
			v-qa="qa"
			class="zyro-field-shorthand__input"
			:disabled="disabled"
			type="number"
			min="0"
			step="1"
			max="128"
		/>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';

import { parseCSSSides } from '@/utils/parseCSSSides';

export default {
	inheritAttrs: false,
	props: {
		changeVertical: {
			type: Boolean,
			default: true,
		},
		bold: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			required: true,
		},
		units: {
			type: String,
			default: 'px',
		},
		qa: {
			type: String,
			default: null,
		},
	},
	data() {
		return { inputId: null };
	},
	computed: {
		parsedCSSSides() {
			return parseCSSSides(this.value);
		},
		computedValue: {
			get() {
				return this.changeVertical ? this.topBottom : this.leftRight;
			},
			set(event) {
				if (this.changeVertical) {
					this.topBottom = event;
				} else {
					this.leftRight = event;
				}
			},
		},
		topBottom: {
			get() {
				return Number.parseInt(this.parsedCSSSides.top, 10) || 0;
			},
			set(event) {
				const newValue = `${Number.parseInt(event.target.value, 10)}px`;
				const fullPadding = parseCSSSides(this.value);

				fullPadding.top = newValue;
				fullPadding.bottom = newValue;
				this.$emit('update:value', Object.values(fullPadding).join(' '));
			},
		},
		leftRight: {
			get() {
				return Number.parseInt(this.parsedCSSSides.left, 10) || 0;
			},
			set(event) {
				const newValue = `${Number.parseInt(event.target.value, 10)}px`;
				const fullPadding = parseCSSSides(this.value);

				fullPadding.left = newValue;
				fullPadding.right = newValue;
				this.$emit('update:value', Object.values(fullPadding).join(' '));
			},
		},
	},
	created() {
		this.inputId = nanoid();
	},
};
</script>

<style lang="scss" scoped>
.zyro-field-shorthand {
	width: 100%;

	&__label {
		display: block;
		width: 100%;
		margin: 8px 0 4px;
		line-height: 16px;
		cursor: pointer;

		&--disabled {
			color: $grey-800;
		}
	}
}
</style>
