import VueCompositionApi, {
	ref,
	computed,
} from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionApi);

const ecwidCartItems = ref([]);

export const useEcwidStore = () => {
	const ecwidCartItemCount = computed(() => ecwidCartItems.value.reduce((itemSum, item) => itemSum + item.quantity, 0));

	const openCart = () => {
		if (window.Ecwid) {
			window.Ecwid.openPage('cart');
		}
	};

	const setEcwidCartItems = (items) => {
		ecwidCartItems.value = items;
	};

	return {
		ecwidCartItems: computed(() => ecwidCartItems.value),
		ecwidCartItemCount,
		openCart,
		setEcwidCartItems,
	};
};
