<template>
	<Component
		:is="component"
		v-if="$options.IS_VITE_BUILD"
		:class="[`icon-${name}`, { 'icon__dimensions': dimensions }]"
		:style="computedStyles"
	/>
	<svg
		v-else
		:class="[`icon-${name}`, { 'icon__dimensions': dimensions }]"
		:style="computedStyles"
		v-on="$listeners"
	>
		<use :xlink:href="`#${name}`" />
	</svg>
</template>

<script>
const IS_VITE_BUILD = process.env.VUE_APP_IS_VITE_BUILD;

export default {
	IS_VITE_BUILD,
	props: {
		name: {
			type: String,
			required: true,
		},
		location: {
			type: String,
			default: '',
		},
		dimensions: {
			type: String,
			default: '',
		},
	},
	data() {
		return { component: null };
	},
	computed: {
		computedStyles() {
			return { '--dimensions': this.dimensions || null };
		},
	},
	watch: {
		name: {
			immediate: true,
			handler() {
				const location = this.location && `${this.location}/`;

				if (IS_VITE_BUILD) {
					this.component = () => import(`./../../assets/icons/${location}${this.name}.svg`);
				} else {
					import(/* @vite-ignore */ `@/assets/icons/${location}${this.name}.svg`);
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.icon {
	&__dimensions {
		width: var(--dimensions);
		height: var(--dimensions);
	}
}
</style>
