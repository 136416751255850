<template>
	<div
		class="zyro-toggle"
	>
		<input
			type="checkbox"
			v-bind="$attrs"
			class="zyro-toggle__input"
			v-on="$listeners"
		>
		<label
			:for="$attrs.id"
			class="zyro-toggle__label"
		/>
	</div>
</template>

<script>
export default { inheritAttrs: false };
</script>

<style lang="scss" scoped>
.zyro-toggle {
	$this: &;

	display: inline-block;
	height: 20px;
	font-size: 20px;

	&__label {
		position: relative;
		display: inline-block;
		width: 2em;
		height: 1em;
		cursor: pointer;
		background: $grey-600;
		border-radius: 1em;
		transition: background 300ms ease;

		&::before {
			position: absolute;
			left: 0;
			width: 1em;
			height: 1em;
			content: '';
			background: $light;
			border-radius: 1em;
			box-shadow: inset 0 0 0 1px $grey-600;
			transition: transform 300ms ease;
		}
	}

	&__input {
		display: none;
		visibility: hidden;
		appearance: none;

		&:checked + #{$this}__label {
			background: $dark;

			&::before {
				box-shadow: inset 0 0 0 1px $dark;
				transform: translateX(100%);
			}
		}

		&:disabled + #{$this}__label {
			background: $grey-300;

			&::before {
				box-shadow: inset 0 0 0 1px $grey-300;
				transform: translateX(100%);
			}
		}

		&:focus + #{$this}__label {
			outline: none;
		}
	}
}
</style>
