<template>
	<div class="slideshow">
		<div class="slideshow__slides">
			<slot />
		</div>
		<template v-if="isNavigationArrowsVisible">
			<SlideshowNavButton
				v-if="isPreviousSlideNavigationVisible"
				class="slideshow__nav-button slideshow__nav-button--left"
				:class="{ 'slideshow__nav-button--is-click-disabled': currentElementId }"
				@click.native="$emit('previous-slide:click')"
			/>
			<SlideshowNavButton
				v-if="isNextSlideNavigationVisible"
				class="slideshow__nav-button slideshow__nav-button--right"
				:class="{ 'slideshow__nav-button--is-click-disabled': currentElementId }"
				@click.native="$emit('next-slide:click')"
			/>
		</template>
		<SlideshowNavBottom
			v-if="isNavigationBulletsVisible"
			:slide-count="slidesCount"
			:active-slide-index="currentSlideIndex"
			v-on="$listeners"
		/>
	</div>
</template>
<script>
import SlideshowNavBottom from '@user/components/block-slideshow/misc/SlideshowNavBottom.vue';
import SlideshowNavButton from '@user/components/block-slideshow/misc/SlideshowNavButton.vue';

export default {
	components: {
		SlideshowNavBottom,
		SlideshowNavButton,
	},
	props: {
		slidesCount: {
			type: Number,
			required: true,
		},
		currentSlideIndex: {
			type: Number,
			required: true,
		},
		isNavigationArrowsVisible: {
			type: Boolean,
			required: true,
		},
		isNextSlideNavigationVisible: {
			type: Boolean,
			required: true,
		},
		isPreviousSlideNavigationVisible: {
			type: Boolean,
			required: true,
		},
		isNavigationBulletsVisible: {
			type: Boolean,
			required: true,
		},
		autoplaySlidesIntervalSeconds: {
			type: Number,
			required: true,
		},
		isLoopEnabled: {
			type: Boolean,
			required: true,
		},
		isAutoplayEnabled: {
			type: Boolean,
			required: true,
		},
		currentElementId: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss">
.slideshow {
	position: relative;
	z-index: 10;

	&__slides {
		position: relative;
	}

	&__nav-button {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: z-index(controls--slideshow);
		padding: 12px 58px;
		cursor: pointer;
		background: transparent;

		&:hover,
		&:focus-visible {
			opacity: 0.6;
		}

		&--left {
			left: 0;
			transform: rotate(180deg);
		}

		&--right {
			right: 0;
		}

		&--is-click-disabled {
			pointer-events: none;
		}
	}
}

@include zyro-media($media-grid) {
	.slideshow {
		&__nav-button {
			padding: 12px 28px;
		}
	}
}

</style>
