<template>
	<div
		class="zyro-input"
		:class="[
			{ [`zyro-input--${theme}`]: theme },
			{ 'z-body-small': theme === 'primary' },
			{ 'z-body': theme === 'secondary' },
			{ 'zyro-input--light' : color },
			{ 'zyro-input--error' : error },
			{ 'zyro-input--spinner-disabled' : isSpinnerDisabled },
		]"
		:style="computedStyles"
	>
		<input
			ref="inputRef"
			v-qa="dataQa"
			class="zyro-input__input"
			v-bind="$attrs"
			autocomplete="off"
			:autofocus="autofocus"
			:min="minValue"
			@keydown="handleInputControls"
			@keyup="resetPressedKeyModifiers"
			v-on="listeners"
		>
		<span
			v-if="suffix"
			ref="inputSuffix"
			class="zyro-input__suffix"
		>
			{{ suffix }}
		</span>
	</div>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '',
			validator(value) {
				return [
					'',
					'light',
				].includes(value);
			},
		},
		error: {
			type: [
				String,
				Boolean,
			],
			default: '',
		},
		padding: {
			type: String,
			default: '',
		},
		theme: {
			type: String,
			default: 'primary',
			validator: (value) => [
				'primary',
				'secondary',
			].includes(value),
		},
		minValue: {
			type: Number,
			default: null,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		suffix: {
			type: String,
			default: null,
		},
		focusOnMount: {
			type: Boolean,
			default: false,
		},
		isSpinnerDisabled: {
			type: Boolean,
			default: true,
		},
		inputType: {
			type: String,
			default: '',
			validator: (value) => [
				'',
				'number',
			].includes(value),
		},
		dataQa: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputTextCanvas: null,
			suffixPosition: null,
			shiftKeyPressed: false,
			metaKeyPressed: false,
		};
	},
	computed: {
		computedStyles() {
			return {
				'--z-padding': this.padding || undefined,
				'--suffix-left': this.suffixPosition || undefined,
			};
		},
		listeners() {
			return {
				...this.$listeners,
				input: (event) => {
					const eventCopy = event;

					/**
					 * Attribute type number allows input of e or E
					 */
					if (this.$attrs.type === 'number' || this.inputType === 'number') {
						eventCopy.target.value = this.replaceNonNumberCharacters(eventCopy.target.value);

						/**
						 * If first char is 0 and char after it is not . don't allow any other input
						 */
						if (Number(eventCopy.target.value[0]) === 0 && eventCopy.target.value[1] !== '.') {
							eventCopy.target.value = eventCopy.target.value.slice(0, 1);
						}
					}

					/**
					 * Max length attr does not work with type number
					 */
					if (this.$attrs.maxlength && this.$attrs.type === 'number') {
						eventCopy.target.value = eventCopy.target.value.slice(0, Math.max(0, Number(this.$attrs.maxlength)));
					}

					this.$emit('input', eventCopy);
				},
			};
		},
		getInputStyles() {
			const fontSize = window.getComputedStyle(this.$refs.inputRef, null).getPropertyValue('font-size');
			const fontFamily = window.getComputedStyle(this.$refs.inputRef, null).getPropertyValue('font-family');

			return `${fontSize} ${fontFamily}`;
		},
	},
	watch: {
		'$attrs.value': {
			handler() {
				if (this.suffix) {
					this.updateSuffixPosition();
				}
			},
		},
	},
	mounted() {
		if (this.suffix) {
			this.inputTextCanvas = document.createElement('canvas');
			this.getTextWidth(this.$attrs.value);
			this.updateSuffixPosition();
		}

		if (this.focusOnMount) {
			this.$refs.inputRef.focus();
		}
	},
	methods: {
		getTextWidth(text) {
			const context = this.inputTextCanvas.getContext('2d');
			const metrics = context.measureText(text);

			context.font = this.getInputStyles;

			return metrics.width;
		},
		updateSuffixPosition() {
			const INITIAL_SUFFIX_POSITION = 20;

			this.suffixPosition = `${this.getTextWidth(this.$attrs.value) + INITIAL_SUFFIX_POSITION}px`;
		},
		replaceNonNumberCharacters(value) {
			return value.replace(/[^\d.-]/g, '');
		},
		handleInputControls(event) {
			const INPUT_CONTROL_KEY_CODES = [
				16,
				91,
				38,
				40,
			];

			if (this.inputType !== 'number' || !INPUT_CONTROL_KEY_CODES.includes(event.keyCode)) {
				return;
			}

			event.preventDefault();

			if (event.keyCode === 16) {
				this.shiftKeyPressed = true;
			}

			if (event.keyCode === 91) {
				this.metaKeyPressed = true;
			}

			let value = Number(this.$attrs.value);

			// Arrow up = value + 1
			if ((!this.shiftKeyPressed && !this.metaKeyPressed) && event.keyCode === 38) {
				value += 1;
				// Arrow down = value + 1
			}

			if ((!this.shiftKeyPressed && !this.metaKeyPressed) && event.keyCode === 40) {
				value -= 1;
				// Arrow up + shift = value + 10
			}

			if (event.keyCode === 38 && this.shiftKeyPressed) {
				value += 10;
				// Arrow up + shift = value - 10
			}

			if (event.keyCode === 40 && this.shiftKeyPressed) {
				value -= 10;
				// Arrow up + shift = value + 100
			}

			if (event.keyCode === 38 && this.metaKeyPressed) {
				value += 100;
				// Arrow up + shift = value - 100
			}

			if (event.keyCode === 40 && this.metaKeyPressed) {
				value -= 100;
			}

			this.$emit('input', {
				...event,
				target: { value },
			});
		},
		resetPressedKeyModifiers(event) {
			if (event.keyCode === 16) {
				this.shiftKeyPressed = false;
			}

			if (event.keyCode === 91) {
				this.metaKeyPressed = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/global/zyro-input/ZyroInput';

.zyro-input {
	$this: &;

	&--spinner-disabled {
		input[type=number] {
			-moz-appearance: textfield;
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
		}
	}

	&--error {
		#{$this}__input {
			border-color: $secondary;
		}
	}

	&--light {
		background-color: $light;
	}

	&__suffix {
		position: absolute;
		top: 0;
		bottom: 0;
		left: var(--suffix-left);
		display: flex;
		align-items: center;
		pointer-events: none;
	}
}
</style>
