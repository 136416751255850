<template>
	<GridImage
		:alt="alt"
		:href="href"
		:sizes="sizes"
		:src="src"
		:srcset="srcset"
		:tag-name="tagName"
		:target="target"
		:to="internalLink"
		:is-lightbox-enabled="isLightboxEnabled"
		:is-lazy="!isPreviewMode"
		:height="componentHeight"
		:width="componentWidth"
		@image-click="handleImageClick"
	/>
</template>

<script>
import {
	computed,
	getCurrentInstance,
} from '@vue/composition-api';

import { useLightbox } from '@/components/reusable-components/lightbox/useLightbox';
import { parseCSSSides } from '@/utils/parseCSSSides';
import GridImage from '@user/components/grid-components/image/GridImage.vue';
import { scrollToAnchorTag } from '@user/utils/anchorTag';
import {
	getOptimizedSrc,
	getGridItemSrcset,
	getGridItemSizes,
	getFullWidthSrcset,
} from '@user/utils/getSrcsets';

const IMAGE_ACTION_NONE = 'none';
const IMAGE_ACTION_LIGHTBOX = 'lightbox';

export default {
	name: 'GridImageProviderUser',
	components: { GridImage },
	props: {
		data: {
			type: Object,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			required: true,
		},
		mobileBlockPadding: {
			type: String,
			required: true,
		},
		componentWidth: {
			type: Number,
			default: null,
		},
		componentHeight: {
			type: Number,
			default: null,
		},
	},
	setup(props) {
		const {
			state,
			dispatch,
		} = getCurrentInstance().$store ?? {};
		const {
			website,
			gui,
		} = state ?? {};
		const { isTemplatePreviewMode } = gui ?? {};
		const { pages } = website ?? {};
		const { addImagesToLightbox } = useLightbox();
		const internalLink = computed(() => pages[props.data.settings?.href]?.path ?? null);
		const href = computed(() => internalLink.value ?? props.data.settings.href);
		const imageAction = computed(() => props.data.settings.clickAction ?? IMAGE_ACTION_NONE);
		const isLightboxEnabled = computed(() => imageAction.value === IMAGE_ACTION_LIGHTBOX);
		// If it not internal link, has href prop and does not contain site host in href
		const isExternalLink = computed(() => !internalLink.value
			&& !!href.value
			&& !RegExp(`/${window.location.host}/`).test(href.value));
		const tagName = computed(() => {
			if (props.isPreviewMode && internalLink.value) {
				return 'a';
			}

			if (internalLink.value) {
				return 'router-link';
			}

			if (props.data.settings.href) {
				return 'a';
			}

			return 'div';
		});
		// Open link in new tab if it is preview mode and the link is external
		const target = computed(() => ((isTemplatePreviewMode && isExternalLink.value)
			? '_blank'
			: props.data.settings.target));
		const src = computed(() => getOptimizedSrc(props.data.settings.image, {
			width: props.componentWidth,
			height: props.componentHeight,
			shouldContain: props.data.settings.styles['object-fit'] === 'contain',
		}));
		const srcset = computed(() => getGridItemSrcset(props.data.settings.image, {
			width: props.componentWidth,
			height: props.componentHeight,
			shouldContain: props.data.settings.styles['object-fit'] === 'contain',
		}));
		const sizes = computed(() => {
			const { right } = parseCSSSides(props.mobileBlockPadding ?? '');
			const mobilePadding = right ? Number.parseInt(right, 10) : null;

			return getGridItemSizes(props.componentWidth, mobilePadding);
		});
		const handleImageClick = (event) => {
			if (props.isPreviewMode && internalLink.value) {
				event.preventDefault();
				dispatch('pages/changePage', { pageId: props.data.settings.href });
			}

			if (isLightboxEnabled.value) {
				addImagesToLightbox({
					src: getOptimizedSrc(props.data.settings.image),
					srcset: getFullWidthSrcset(props.data.settings.image),
					alt: props.data.settings.alt,
				});
			} else {
				scrollToAnchorTag(href.value, event);
			}
		};

		return {
			alt: computed(() => props.data.settings.alt),
			handleImageClick,
			href,
			internalLink,
			src,
			srcset,
			sizes,
			tagName,
			target,
			isLightboxEnabled,
		};
	},
};
</script>
