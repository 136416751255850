import { assignExperiment } from '@/api/GoogleOptimizeApi';

export default {
	namespaced: true,
	state: { current: {} },
	getters: {
		isExperimentSet: (state) => !!state.current.experimentID,
		getExperiment: (state) => state.current,
		firstActiveVariant: (state) => state.current?.$activeVariants?.[0] ?? null,
	},
	mutations: {
		setExperiment: (state, experiment) => {
			state.current = experiment;
		},
	},
	actions: {
		trackExperiment: ({ commit }, { to }) => {
			const experiment = assignExperiment(to);

			if (experiment.experimentID) {
				commit('setExperiment', experiment);
			}
		},
	},
};
