<template>
	<masonry
		:cols="4"
		:gutter="8"
	>
		<img
			v-for="(image, index) in images"
			:key="`image-${image.id}`"
			class="image"
			data-qa="chooseimage-unsplashimage"
			:src="image.urls.thumb"
			@click="$emit('select-image', index)"
			@load="$emit('thumbnail-loaded', image.id)"
		>
	</masonry>
</template>

<script>
export default {
	props: {
		images: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang='scss' scoped>
.image {
	width: 100%;
	cursor: pointer;
}
</style>
