<template>
	<ZyroButton
		class="color-button"
		:class="[
			`color-button--${type}`,
			{
				[`color-button--selected-${type}`]: isSelected,
				'color-button--border-radius': hasBorderRadius,
			},
			`color-button--hover-${type}`
		]"
		:style="computedStyles"
		:title="$options.convertColorToTitle(color)"
		@click="$emit('click', color)"
	>
		<div
			class="color-button__color"
			:class="[
				`color-button__color--${type}`,
				{ [`color-button__color--selected-${type}`]: isSelected }
			]"
		/>
	</ZyroButton>
</template>

<script>
import { convertColorToTitle } from '@/utils/colorVariableModifiers';

export default {
	convertColorToTitle,
	props: {
		color: {
			type: String,
			default: 'var(--colors-light)',
		},
		type: {
			type: String,
			default: 'round',
			validator: (value) => [
				'round',
				'rectangular',
			].includes(value),
		},
		selectedColor: {
			type: String,
			default: null,
		},
		colorDimensions: {
			type: String,
			default: '16px',
		},
		hasBorderRadius: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedStyles() {
			return {
				'--button-color': this.color,
				'--color-dimensions': this.colorDimensions,
			};
		},
		isSelected() {
			return this.selectedColor === this.color;
		},
	},
};
</script>

<style lang="scss" scoped>
.color-button {
	$this: &;

	&--hover-round:hover,
	&--hover-round:focus {
		background-color: $grey-200;
		border: none;
	}

	&--hover-rectangular:hover,
	&--hover-rectangular:focus,
	&--selected-rectangular {
		border: 2px solid $light;
	}

	&--rectangular {
		background-color: var(--button-color);
	}

	&--border-radius {
		border-radius: $border-radius-small;
	}

	&__color {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--button-color);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

		&--round {
			width: var(--color-dimensions);
			height: var(--color-dimensions);
			border: 1px solid $grey-400;
			border-radius: $border-radius-round;
		}

		&--selected-round::after {
			position: absolute;
			width: 24px;
			height: 24px;
			content: '';
			border: 1px solid $accent-two;
			border-radius: $border-radius-small;
		}
	}
}
</style>
