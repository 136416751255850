<template>
	<div class="block-blog-header">
		<div class="block-blog-header__content">
			<h1 class="font-primary block-blog-header__title block-blog-header__text-block">
				{{ post.meta.title }}
			</h1>
			<p
				v-if="data.settings.shownItems.description"
				class="font-secondary block-blog-header__text-block"
			>
				{{ post.meta.description }}
			</p>
			<BlockBlogListItemCategories
				v-if="data.settings.shownItems.categories && post.customData.categories.length"
				class="font-secondary"
				:categories="post.customData.categories"
			/>
			<BlockBlogListItemMeta
				class="block-blog-header__meta"
				v-bind="{
					authorName: userFullName,
					minutesAmount: post.customData.minutesToRead,
					date: post.customData.date,
					showAvatar: data.settings.shownItems.avatar,
					showName: data.settings.shownItems.authorFullName,
					showDate: data.settings.shownItems.date,
					showMinutes: data.settings.shownItems.minutesToRead
				}"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import BlockBlogListItemCategories from '@/components/block-blog/block-blog-list-item/BlockBlogListItemCategories.vue';
import BlockBlogListItemMeta from '@/components/block-blog/block-blog-list-item/BlockBlogListItemMeta.vue';

export default {
	components: {
		BlockBlogListItemMeta,
		BlockBlogListItemCategories,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState('user', ['user']),
		...mapGetters('pages', [
			'blogPages',
			'currentPage',
		]),
		userFullName() {
			return this.user?.fullName;
		},
		post() {
			// Exception for preview mode
			if (process.env.VUE_APP_MODE !== 'user') {
				return this.currentPage;
			}

			return Object.values(this.blogPages).find((value) => value.path === this.$route.path);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./BlockBlogHeader";
</style>
