import mergeWith from 'lodash.mergewith';

import { getLocaleMessages } from '@/api/TranslationsApi';
import LOCALES from '@/i18n/locales';
import { cloneDeep } from '@/utils/object';

// TODO: refactor to dynamic import

import loadBaseMessages from './loadBaseMessages';

// TODO: reuse 'SUPPORTED_LOCALES' from '@/utils/i18n/supportedLocales'

/**
 * This is a customizer function for lodash "mergeWith" function
 * It is used for correct messages object merging
 * (https://lodash.com/docs/4.17.15#mergeWith)
 *
 * @param {*} objValue - Object (original value)
 * @param {*} srcValue - Source value (incoming value)
 * @returns {*} - Object (original) value, if Source value is null or undefined.
 * If Source value exists, returns undefined (so mergeWith would continue handling by default logic)
 */
// eslint-disable-next-line consistent-return
function customizer(objectValue, sourceValue) {
	if (sourceValue === null || sourceValue === undefined) {
		return objectValue;
	}
}

function importMessageTranslationsAsync(localeId) {
	return process.env.VUE_APP_TRANSLATIONS_LIVE_TRANSLATE === 'true'
		? getLocaleMessages(localeId)
		: import(/* webpackChunkName: "lang-[request]" */ `../translations/${localeId}.json`).then((messages) => messages.default);
}

/**
 * Returns a chain of a given locale's fallbacks,
 * which should fallback in reverse order of the array
 * e.g. when given `es_MX` it will return ['es_ES', 'es_MX']
 *
 * @param {string} localeId - Locale, which to check for fallbacks
 * @param {Array} [accumulator=[]] - Array, into which to push the locales
 * @returns {Array} - Array of locales, that should fallback in reverse order
 */
function getLocaleFallbackChainRecursive(localeId, accumulator = []) {
	const locale = LOCALES.find((localeObject) => localeObject.localeId === localeId);

	accumulator.unshift(localeId);
	if (locale.fallback) {
		return getLocaleFallbackChainRecursive(locale.fallback, accumulator);
	}

	return accumulator;
}

/**
 * Merges the given locale with its fallbacks and base messages.
 *
 * @export
 * @param {string} localeId - Current Locale ID, which should have its translations merged
 * @returns {Object} - Merged translation object
 */
export default async function mergeMessageTranslations(localeId) {
	// recursively navigate and gather which locales should be fetched
	const localesToFetch = getLocaleFallbackChainRecursive(localeId);

	const localesMessages = await Promise.all(
		localesToFetch.map((locale) => importMessageTranslationsAsync(locale)),
	);

	const merged = cloneDeep(loadBaseMessages());

	/*
	 * merge iteratively, with the current language being last to be merged
	 * e.g. _base <- es_ES <- es_MX
	 */
	localesMessages.forEach((messagesObject) => {
		// mergeWith modifies the first argument passed (https://lodash.com/docs/4.17.15#mergeWith)
		mergeWith(
			merged,
			messagesObject,
			customizer,
		);
	});

	return merged;
}
