<template>
	<Transition :name="direction === 'right' ? 'drawer-slide-right' : 'drawer-slide-left'">
		<div
			v-click-outside="{
				handler: closeDrawer,
				middleware: beforeClose,
				events: ['click'],
			}"
			:class="{
				'app-drawer--sidebar-open': isSidebarOpen,
				'app-drawer--left': direction === 'left',
				'app-drawer--right': direction === 'right',
			}"
			:style="{ width }"
			class="app-drawer"
		>
			<ZyroButton
				v-if="showCloseButton"
				class="app-drawer__close"
				data-qa="builder-sidemenu-btn-close"
				icon="x"
				:title="$t('common.close')"
				@click="closeDrawer"
			/>
			<slot />
		</div>
	</Transition>
</template>

<script>
import {
	mapMutations,
	mapGetters,
} from 'vuex';

import {
	mapStateGui,
	mapActionsGui,
	CLOSE_DRAWER,
	OPEN_SIDEBAR,
} from '@/store/builder/gui';

export default {
	props: {
		direction: {
			type: String,
			default: 'left',
			validator: (direction) => [
				'right',
				'left',
			].includes(direction),
		},
		width: {
			type: String,
			default: '375px',
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		closeOnClickOutside: {
			type: Boolean,
			default: true,
		},
		unsetElementOnOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			/*
			 * Needed for drawers inside <keep-alive>
			 *  toggled by activated() & deactivated() lifecycle events
			 *  its good to know when its not active, so listeners as v-click-outside can be ignored
			 */
			active: true,
		};
	},
	computed: {
		...mapStateGui([
			'isSidebarOpen',
			'activeModalName',
		]),
		...mapGetters(['currentElement']),
	},
	mounted() {
		if (this.direction === 'left' && !this.isSidebarOpen) {
			this.openSidebar();
		}

		if (this.currentElement && this.unsetElementOnOpen) {
			this.unsetCurrentElement();
		}
	},
	activated() {
		this.active = true;
	},
	deactivated() {
		this.active = false;
	},
	methods: {
		...mapActionsGui({
			openSidebar: OPEN_SIDEBAR,
			closeDrawer: CLOSE_DRAWER,
		}),
		...mapMutations(['unsetCurrentElement']),
		beforeClose(event) {
			if (this.activeModalName || !this.active) {
				return false;
			}

			return this.closeOnClickOutside
				&& !event.target.closest('.color-picker')
				&& !event.target.closest('.modal-backdrop')
				&& !event.target.closest('.drawer-trigger')
				&& !event.target.closest('.toast');
		},
	},
};
</script>

<style lang="scss" scoped>
.app-drawer {
	$this: &;

	position: fixed;
	top: $header-height;
	bottom: 0;
	z-index: z-index(layout--drawer);
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
	box-shadow: $box-shadow;

	&__close:not(.s) {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 1;
	}

	&--right {
		right: 0;
	}

	&--left {
		left: $side-toolbar-width;
	}

	&__header {
		padding: 20px;
	}
}
</style>
