<template>
	<textarea
		ref="textareaRef"
		class="zyro-textarea"
		:class="[
			{ [`zyro-textarea--${theme}`]: theme },
			{ 'z-body-small': theme === 'primary' },
			{ 'z-body': theme === 'secondary' },
			{ 'zyro-textarea--light' : color },
			{ 'zyro-textarea--error' : error },
		]"
		v-bind="$attrs"
		autocomplete="off"
		v-on="$listeners"
	/>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '',
			validator(value) {
				return [
					'',
					'light',
				].includes(value);
			},
		},
		error: {
			type: String,
			default: '',
		},
		theme: {
			type: String,
			default: 'primary',
			validator: (value) => [
				'primary',
				'secondary',
			].includes(value),
		},
	},
	watch: {
		'$attrs.value': {
			handler: function setTextareContent() {
				this.$refs.textareaRef.innerHTML = this.$attrs.value;
			},
			deep: true,
		},
	},

};
</script>

<style lang="scss" scoped>
.zyro-textarea {
	box-sizing: border-box;
	width: 100%;
	font-family: inherit;
	resize: none;
	outline: none;
	transition: border-color 0.1s ease-in-out;

	&--primary {
		padding: 14px 16px;
		background-color: $grey-100;
		border: 1px solid $grey-100;

		&:hover,
		&-hover {
			border-color: $grey-300;
		}

		&:focus,
		&-focus {
			border-color: $grey-800;
		}
	}

	&--secondary {
		min-height: 130px;
		padding: 16px;
		color: $dark;
		border: 1px solid $grey-300;
		border-radius: 8px;

		&:hover,
		&-hover {
			border-color: $grey-600;
		}

		&:focus,
		&-focus {
			border-color: $accent-one-light;
		}

		&::placeholder {
			// Same as z-body
			font-size: 16px;
			line-height: 1.5;
			color: $grey-800;
			letter-spacing: 0.03em;
		}
	}

	&--light {
		background-color: $light;
	}

	&--error {
		border-color: $secondary;
	}

	&:disabled,
	&--disabled {
		color: $grey-600;
		border-color: $grey-100;

		&::placeholder {
			color: $grey-600;
		}
	}
}
</style>
