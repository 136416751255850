<template>
	<div
		class="block-preview"
		:style="blockPreviewContainerStyles"
		v-on="$listeners"
	>
		<BlockUser
			:id="blockId"
			ref="block-preview"
			class="block-preview__block"
			:data="block"
			:components="components"
			:style="blockPreviewStyles"
			:blocks="blocks"
			@child-component-mounted="calculatePreviewBlockHeight"
		/>
	</div>
</template>

<script>
import BlockUser from '@user/components/block/BlockUser.vue';

export default {
	components: { BlockUser },
	props: {
		blocks: {
			type: Object,
			default: () => ({}),
		},
		blockId: {
			type: String,
			required: true,
		},
		block: {
			type: Object,
			required: true,
		},
		components: {
			type: Object,
			default: () => ({}),
		},
		actualWidth: {
			type: [
				Number,
				String,
			],
			default: 1400,
		},
		previewWidth: {
			type: [
				Number,
				String,
			],
			default: 350,
		},
	},
	data() {
		return {
			blockPreviewHeight: null,
			blockPreviewResizeObserver: null,
		};
	},
	computed: {
		blockPreviewScale() {
			return this.previewWidth / this.actualWidth;
		},
		blockDOMElement() {
			return this.$refs['block-preview'].$el;
		},
		blockPreviewContainerStyles() {
			return {
				height: `${this.blockPreviewHeight}px`,
				width: `${this.previewWidth}px`,
			};
		},
		blockPreviewStyles() {
			return {
				width: `${this.actualWidth}px`,
				transform: `scale(${this.blockPreviewScale})`,
			};
		},
	},
	methods: {
		calculatePreviewBlockHeight() {
			this.blockPreviewHeight = this.blockDOMElement.offsetHeight * this.blockPreviewScale;

			/*
			 * After initial render, blocks may still change size,
			 * because of slow image loading, their children still rendering, etc.
			 * In order to prevent wrong sizing
			 * we use resize observers to watch their real height
			 */
			if (!this.blockPreviewResizeObserver) {
				this.blockPreviewResizeObserver = new ResizeObserver((entries) => {
					if (entries[0].contentRect.height !== this.blockPreviewHeight) {
						this.calculatePreviewBlockHeight();
					}
				});
				this.blockPreviewResizeObserver.observe(this.blockDOMElement);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.block-preview {
	&__block {
		pointer-events: none;
		user-select: none;
		transform-origin: 0 0;

		::v-deep .background {
			z-index: 0;
		}

		::v-deep .block-grid-item__component {
			pointer-events: none;
		}
	}
}
</style>
