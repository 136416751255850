import axios from 'axios';

const NPS_API = `${process.env.VUE_APP_API_URL}/v3/nps`;

export const getLastEvaluation = async () => (await axios.get(`${NPS_API}/last-evaluation`)).data;

export const sendNpsScore = async (score) => (await axios.post(`${NPS_API}/score/${score}`)).data.scoreId;

export const sendComment = (scoreId, comment) => axios.patch(`${NPS_API}/comment`, {
	scoreId,
	comment,
});
