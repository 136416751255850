import {
	ALLOWED_HEAD_TAGS,
	META_TAG,
} from '@/constants/sanitizeConfig';
import { isAppPrerendering } from '@user/utils/isAppPrerendering';

const getItemToAppend = (item) => {
	if (item.tagName !== 'SCRIPT') {
		return item;
	}

	const script = document.createElement('script');

	[...item.attributes].forEach((attribute) => {
		script.setAttribute(attribute.name, attribute.value);
	});
	const inlineScript = document.createTextNode(item.innerHTML);

	script.append(inlineScript);

	return script;
};

export const injectCustomScripts = (customScripts) => {
	const addedTagReferences = [];

	// skip injection - either site already has custom meta injected or has no customScripts at all
	if (isAppPrerendering || !customScripts) {
		return addedTagReferences;
	}

	// parse html elements for meta and scripts and for scripts make them as executables
	const temporaryElement = document.createElement('div');

	temporaryElement.innerHTML = customScripts;

	[...temporaryElement.children].forEach((item) => {
		// validate only valid HMTL tags in head
		if (!ALLOWED_HEAD_TAGS.includes(item.tagName) && item.tagName.toLowerCase() === META_TAG) {
			return;
		}

		const itemToAppend = getItemToAppend(item);

		// adding multiple meta or style in loop without setTimeout will add tag only once
		setTimeout(() => {
			document.head.append(itemToAppend);
		}, 0);

		addedTagReferences.push(itemToAppend);
	});

	return addedTagReferences;
};

/**
 * Gets elements by provided tagName from a provided string of custom scripts
 * @param { string } customScripts - string of custom scripts
 * @param { string } tagName - name of the element to filter out
 * @returns array of filtered HTML elements
 */
export const getScriptElementsByTagName = ({
	customScripts,
	tagName,
}) => {
	const temporaryElement = document.createElement('div');

	temporaryElement.innerHTML = customScripts;

	return [...temporaryElement.children].filter((item) => item.tagName.toLowerCase() === tagName.toLowerCase());
};

/**
 * Converts HTML element to JS object (<div class="hi" content="lol"/> -> { class: "hi", content: "lol" })
 * @param {HTML element} element - HTML element to construct an object from
 * @returns an object containing all HTML element keys and values
 */
export const convertHTMLElementToObject = (element) => {
	if (!element) {
		return {};
	}

	return [...element.attributes].reduce((finalObject, {
		nodeName,
		nodeValue,
	}) => ({
		...finalObject,
		[nodeName]: nodeValue,
	}), {});
};
