import { getStoreSnapshot } from '@/api/EcwidApi';
import { useEcwidStore } from '@user/useStore/useEcwidStore';

const ECWID_SNAPSHOT_HTML_ID = 'ecwid-snapshot-html';
const ECWID_SNAPSHOT_JS_ID = 'ecwid-snapshot-js';
const ECWID_SNAPSHOT_CSS_ID = 'ecwid-snapshot-css';

// push Ecwid notifications down
const extractNotifications = () => {
	const noticesElement = document.querySelector('.ec-notices');

	if (!noticesElement) {
		return;
	}

	const headerHeight = document.querySelector('.builder-header')?.clientHeight ?? 0;
	const stickyHeight = document.querySelector('.preview__sticky')?.clientHeight ?? 0;
	const noticesOffset = noticesElement.getBoundingClientRect().top + headerHeight + stickyHeight;

	noticesElement.style.top = `${noticesOffset}px`;
};

const removeEcwidSnapshotElements = () => {
	const snapshotDOMNodesIds = [
		ECWID_SNAPSHOT_HTML_ID,
		ECWID_SNAPSHOT_JS_ID,
		ECWID_SNAPSHOT_CSS_ID,
	];

	snapshotDOMNodesIds.forEach((id) => document.getElementById(id).remove());
};

const listenForEcwidCartChanges = (cart) => {
	const { setEcwidCartItems } = useEcwidStore();

	setEcwidCartItems(cart.items);
};

/*
 * add additional hooks to execute when Ecwid API is ready:
 * https://developers.ecwid.com/api-documentation/subscribe-to-events
 */
const subscribeToEcwidHooks = () => {
	// we cannot use Ecwid.OnAPILoaded as page (and notifications) are not loaded yet
	window.Ecwid.OnPageLoaded.add(extractNotifications);
	window.Ecwid.OnPageLoaded.add(removeEcwidSnapshotElements);
	window.Ecwid.OnCartChanged.add(listenForEcwidCartChanges);
};

export default {
	load(storeId) {
		// https://gist.github.com/makfruit/00e1c298f11bf12392f67c240e35e927#3--how-to-enable-clean-urls-if-you-dont-have-access-to-htaccess-file
		window.ec = window.ec || {};
		window.ec.config = window.ec.config || {};
		window.ec.config.storefrontUrls = window.ec.config.storefrontUrls || {};
		window.ec.config.storefrontUrls.cleanUrls = true;
		window.ec.config.storefrontUrls.queryBasedCleanUrls = true;

		// https://developers.ecwid.com/api-documentation/customize-behaviour#add-custom-javascript-code
		window.ecwid_script_defer = true;
		window.ecwid_dynamic_widgets = true;

		if (typeof Ecwid !== 'undefined') {
			window.Ecwid.destroy();
		}

		// eslint-disable-next-line no-underscore-dangle
		window._xnext_initialization_scripts = [
			{
				widgetType: 'ProductBrowser',
				id: `my-store-${storeId}`,
				arg: [
					'id=productBrowser',
					'categoriesPerRow=4',
					'views=grid(4,3) list(10) table(20)', // this loads 12 products per page
					'categoryView=grid',
					'searchView=list',
				],
			},
		];

		if (!document.querySelector('#ecwid-script')) {
			const script = document.createElement('script');

			script.defer = true;
			script.type = 'text/javascript';
			script.async = true;
			script.src = `https://app.ecwid.com/script.js?${storeId}`;
			script.id = 'ecwid-script';
			script.addEventListener('load', subscribeToEcwidHooks);
			document.body.append(script);
		} else {
			window.ecwid_onBodyDone();
		}
	},
	async preload(storeId) {
		const ecwidDiv = document.getElementById(`my-store-${storeId}`);

		/*
		 * Not catching here since BlockEcwidStoreUser.vue will load full ecwid anyway,
		 * it is handled in outer BlockEcwidStore.vue try catch
		 */
		const { data } = await getStoreSnapshot(storeId);

		// set temporary HTML content
		const htmlCode = document.createElement('div');

		htmlCode.setAttribute('id', ECWID_SNAPSHOT_HTML_ID);
		htmlCode.style.opacity = '0'; // hide html while external css is loading
		htmlCode.innerHTML = data.htmlCode;
		ecwidDiv.append(htmlCode);

		// load css files only once - navigating in builder will still keep css file
		if (!document.getElementById(ECWID_SNAPSHOT_CSS_ID)) {
			data.cssFiles.forEach((file) => {
				const link = document.createElement('link');

				link.setAttribute('id', ECWID_SNAPSHOT_CSS_ID);
				link.setAttribute('href', file);
				link.setAttribute('rel', 'stylesheet');
				link.addEventListener('load', () => {
					htmlCode.style.opacity = '1'; // display snapshot HTML code when css finished loading
				});
				document.head.append(link);
			});
		} else {
			htmlCode.style.opacity = '1';
		}

		// load js variables
		const ecwidSnapshotJs = document.getElementById(ECWID_SNAPSHOT_JS_ID);

		if (ecwidSnapshotJs) {
			// anonymous self-invoking function must be re-invoked when navigating back to store page
			window.ec.static_category_evaluated = false;
			ecwidSnapshotJs.remove();
		}

		const script = document.createElement('script');

		script.setAttribute('id', ECWID_SNAPSHOT_JS_ID);
		const inlineScript = document.createTextNode(data.jsCode);

		script.append(inlineScript);
		document.body.append(script);
	},
};
